export const Styles = (Theme) => ({
  btnBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "& > div": {
      maxWidth: "400px",
      width: "100%",
      "@media (max-width: 640px)": {
        width: "100%",
        maxWidth: "100%",
      },
    },
  },
  addItems: {
    margin: "15px 0",
    display: "flex",
    alignItems: "center",
    "@media(max-width:640px)": {
      padding: "20px",
      background: "#fff",
      borderRadius: "10px",
    },
    "& img": {
      marginRight: "5px",
    }
  },
  inputBox: {
    position: "relative",
    marginBottom: "20px",
    "& input": {
      paddingRight: "100px",
      background: "#3F44441A !important",
      borderColor: "#3F44441A !important",
    },
    "& span": {
      position: "absolute",
      right: "25px",
      top: "50%",
      transform: "translate(0, -50%)",
    },
  },
  lt: {
    "@media(max-width:640px)": {
      width: "50%",
    },
    "& span": {
      padding: "0 10px",
      display: "inline-block",
      "@media(max-width:640px)": {
        display: "none",
      },
    },
  },
  rt: {
    "@media(max-width:640px)": {
      width: "50%",
    },
    "& h4": {
      textAlign: "right",
      "& span": {
        padding: "0 10px",
        display: "inline-block",
      },
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "30px 0 0px",
    "@media(max-width:640px)": {
      display: "block",
    },
    "& h3": {
      marginBottom: "0",
    },
  },
  ltBox: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    "@media (max-width: 640px)": {
      width: "100%",
    },
    "& > div": {
      width: "50%",
      padding: "0 10px 10px 0",
      "@media (max-width: 640px)": {
        width: "100%",
        padding: "0 0 10px 0",
      },
      "& a": {
        marginBottom: "0px",
      },
    },
    "& > div:nth-child(even)": {
      padding: "0 0 0 0",
      "@media (max-width: 640px)": {
        padding: "0 0 10px 0",
      },
    },
  },
  totalBoxInner: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'flex-end',
    borderTop: "1px solid #3F4444BF",
    padding: "10px 0 0",
    "@media(max-width:640px)": {
      padding: "15px 0 0",
      marginTop: "10px",
    },
    "& h4": {
      marginBottom: "0"
    },
  },
  totalBox: {
    "& p": {
      textAlign: "right",
      margin: "0px 0 !important",
      color: "#3F4444",
    },
    "& span": {
      textAlign: "right",
    }
  },
  desContent: {
    width: "100%",
    textAlign: "right",
    "@media (max-width: 640px)": {
      textAlign: "left",
    },
  },
  btnBox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    "& button": {
      marginTop: "0",
      maxWidth: "250px",
      float: "right",
      "@media (max-width: 640px)": {
        maxWidth: "100%",
        float: "unset",
        marginTop: "10px",
      },
    },
    "& > div": {
      margin: "20px auto 0",
      maxWidth: "none",
    },
    "@media (max-width: 640px)": {
      width: "100%",
    },
  },
});
