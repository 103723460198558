export const Styles = (Theme) => ({
  dFlex: {
    display: "flex",
    alignItems: "flex-start",
    "& > div": {
      width: "50%",
    },
    "& >  :nth-child(2)": {
      marginLeft: "20px",
    },
  },
  wrap: {
    "@media(max-width:475px)": {
      display: "block",
    },
    "& > div": {
      "@media(max-width:475px)": {
        width: "100%",
      }
    },
    "& >  :nth-child(2)": {
      "@media(max-width:475px)": {
        marginLeft: "0",
      }
    },
  },
  fromControl: {
    margin: "0 0 25px",
  },
  datepickerBox: {
    "& input": {
      border: "1px solid #ddd !important",
      "@media(max-width:640px)": {
        border: "none !important",
      },
    },
  },
  numberField: {
    position: "relative",
    "& input": {
      paddingRight: "40px",
    },
    "& img": {
      position: "absolute",
      right: "15px",
      top: "50%",
      transform: "translate(-10%, -50%)",
    },
  },
  successMessage: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    marginBottom: "10px !important",
    "& img": {
      marginRight: "5px",
    },
    "& h6": {
      marginBottom: "0",
    },
  },
  inputBox: {
    position: "relative",
    "& input": {
      background: "#3F44441A !important",
      borderColor: "#3F44441A !important",
    },
    "& span": {
      position: "absolute",
      right: "25px",
      top: "50%",
      transform: "translate(0, -50%)",
    },
  },
});
