import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import FormLabel from "@mui/material/FormLabel";
import YellowBtn from "../../Components/YellowBtn/YellowBtn";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCard } from "../../redux/actions/credit/CreditActions";
import Loader from "react-spinner-loader";
import AlterMessage from "../AlertMessage/AlertMessage";
import { useLocation, useNavigate } from "react-router";
import UseButtonmulticlick from "../../useButtonmulticlick/UseButtonmulticlick";
const CARD_ELEMENT_OPTIONS = {
  showIcon: true,
  style: {
    base: {
      color: "#3F4444",
      fontFamily: "Noto Serif",
      fontSmoothing: "antialiased",
      fontSize: "16px",
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
const AddPaymentForm = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { loading, error, messages } = useSelector((state) => state?.addCard);
  const stripe = useStripe();
  const elements = useElements();
  const [isOpen, setIsOpen] = useState(false);
  const [incomplete, setIncomplete] = useState(false);
  const [isdecline, setisdecline] = useState(false);
  const [cardNumbererror, setcardNumbererror] = useState("");
  const [cardExpiryerror, setcardExpiryerror] = useState("");
  const [cardExpirynotemptyerror, setcardExpirynotemptyerror] = useState("");
  const [cardCvcerror, setcardCvcerror] = useState("");
  const [cardCvcnotemptyerror, setcardCvcnotemptyerror] = useState("");
  // const [lastPage, setLastPage] = useState(-1);
  const [cardDetails, setCardDetails] = useState({
    name: "",
    saveCard: false,
    inValid: false,
  });

  // useEffect(() => {
  //   if (location?.state !== null) {
  //     if (location?.state?.lastPage != undefined) {
  //       setLastPage(location?.state?.lastPage);
  //     }
  //   }
  // }, [location]);
  const checkBoxHandler = (check, name) => {
    setCardDetails((pre) => ({ ...pre, saveCard: check }));
  };
  const stateHandler = (e) => {
    const { name, value } = e.target;
    setCardDetails((pre) => ({ ...pre, [name]: value }));
  };
  const inputHandler = (event) => {
    console.log("inputHandler:", event);
    if (event?.elementType == "cardNumber" && event?.error?.code && event?.empty == true) {
      // console.log("inputHandlernumber")
      setcardNumbererror("")
      setIncomplete(event?.error?.code)
    }
    if (event?.elementType == "cardNumber" && event?.error?.code && event?.empty == false) {
      setcardNumbererror(event?.error?.code)
      setIncomplete("")
      // console.log("inputHandlerexpiry")

    }
    if (event?.elementType == "cardNumber" && event?.complete == true) {
      setIncomplete("")
      setcardNumbererror("")
      // console.log("inputHandlerexpiry")

    }
    if (event?.elementType == "cardExpiry" && event?.error?.code && event?.empty == true) {
      setcardExpiryerror(event?.error?.code)
      setcardExpirynotemptyerror("")
      // console.log("inputHandlerexpiry")

    }
    if (event?.elementType == "cardExpiry" && event?.error?.code && event?.empty == false) {
      setcardExpirynotemptyerror(event?.error?.code)
      setcardExpiryerror("")
      // console.log("inputHandlerexpiry")

    }
    if (event?.elementType == "cardExpiry" && event?.complete == true) {
      setcardExpiryerror("")
      setcardExpirynotemptyerror("")
      // console.log("inputHandlerexpiry")

    }
    if (event?.elementType == "cardCvc" && event?.error?.code && event?.empty == true) {
      setcardCvcerror(event?.error?.code)
      setcardCvcnotemptyerror("")
      // console.log("inputHandlercardcvc")

    }
    if (event?.elementType == "cardCvc" && event?.error?.code && event?.empty == false) {
      setcardCvcnotemptyerror(event?.error?.code)
      setcardCvcerror("")
      // console.log("inputHandlerexpiry")

    }
    if (event?.elementType == "cardCvc" && event?.complete == true) {
      setcardCvcerror("")
      setcardCvcnotemptyerror("")
      // console.log("inputHandlerexpiry")

    }
  };

  const paymentHandler = async (event) => {
    event.preventDefault();
    setisdecline(false);
    // if (cardDetails.name === "" || cardDetails?.name?.trim() === "") {
    //   setIncomplete(true);
    //   return setCardDetails((pre) => ({ ...pre, name: "", inValid: true }));
    // } else {
    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);
    const token = result.token;
    if (result.error) {
      console.log("result.error.message", result.error);
      if (result.error.code == "card_declined") {
        setisdecline(true);
      }
      if (cardDetails.name === "" || cardDetails?.name?.trim() === "") {
        // setIncomplete(true);
        // return 
        setCardDetails((pre) => ({ ...pre, name: "", inValid: true }));
      }
      setIncomplete(result.error);
      setcardNumbererror(result.error);
      setIsOpen(true);
      return setCardDetails((pre) => ({ ...pre, inValid: true }));
    } else {
      setIsOpen(true);
      const stripeToken = { token: token.id }; //for data in payload
      // console.log("stripeToken:", stripeToken);
      const payload = {
        make_default: cardDetails.saveCard,
        stripe_token: stripeToken.token,
        card_name: cardDetails.name,
      };
      if (cardDetails.name === "" || cardDetails?.name?.trim() === "") {
        setIncomplete(true);
        setcardNumbererror(true);
        return setCardDetails((pre) => ({ ...pre, name: "", inValid: true }));
      }
      dispatch(addCard(payload));
    }
    // }
  };
  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    setIsOpen(false);
    setisdecline(false);
  };

  const confirmpaymenthandler = UseButtonmulticlick(async (e) => {
    paymentHandler(e);
  });
  useEffect(() => {
    // console.log("messages", messages);
    // if (messages?.success) return navigate(lastPage, {
    //   replace: true,
    //   state: {
    //     lastPage: lastPage,
    //   },
    // });
    if (localStorage.getItem("selectedPlan") && messages?.success)
      return navigate("/subscription");
    if (localStorage.getItem("manage") === "true" && messages?.success)
      return navigate("/manage-payment-methods");
    if (messages?.success) return navigate("/purchase-credits", { state: 2 });
  }, [messages]);
  console.log("inputhandler", cardDetails, cardCvcerror, cardExpiryerror)
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {isdecline && (
        <AlterMessage
          closeHandler={snackHandler}
          url={"./images/error.svg"}
          description={"Your card is not supported."}
          title={"Error"}
          type={"Error"}
          open={isOpen}
        />
      )}
      {error && (
        <AlterMessage
          closeHandler={snackHandler}
          url={"./images/error.svg"}
          description={error}
          title={"Error"}
          type={"Error"}
          open={isOpen}
        />
      )}
      <div className={classes.formBox}>
        <div className={classes.fromControl}>
          <FormLabel>
            Name On Card <span className="req">*</span>
          </FormLabel>
          <input
            type="text"
            name="name"
            placeholder="Enter name on card"
            value={cardDetails.name}
            onChange={(e) => stateHandler(e)}
          />
          {cardDetails.inValid && cardDetails.name === "" ? (
            <p className="errorMessage">This field is required.</p>
          ) : (
            <div></div>
          )}
        </div>
        <div className={classes.fromControl}>
          <FormLabel>
            Card Number <span className="req">*</span>
          </FormLabel>
          <div className={classes.inputBox}>
            <CardNumberElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={inputHandler}
            />
          </div>
          {cardDetails.inValid && (incomplete?.code === "incomplete_number" ) ? (
            <p className="errorMessage">
              This field is required.
            </p>
          ) : null}
          {cardDetails.inValid && ( incomplete?.code=== "invalid_number") ? (
            <p className="errorMessage">
              Information entered is invalid. Please try again.
            </p>
          ) : null}
          {cardDetails.inValid && ( cardNumbererror=== "invalid_number") ? (
            <p className="errorMessage">
              Information entered is invalid. Please try again.
            </p>
          ) : null}

        </div>
        <div className={classes.fromControl}>
          <div className={classes.dFlex}>
            <div>
              <FormLabel>
                Expiry <span className="req">*</span>
              </FormLabel>{" "}
              <div className={classes.inputBox}>
                <CardExpiryElement
                  options={CARD_ELEMENT_OPTIONS}
                  onChange={inputHandler}
                />
              </div>
              {cardDetails.inValid &&
                cardExpiryerror == "incomplete_expiry" && (
                  <p className="errorMessage">
                    This field is required.
                  </p>
                )}
              {cardDetails.inValid &&
                (cardExpirynotemptyerror == "invalid_expiry_year" || cardExpirynotemptyerror == "invalid_expiry_year_past" ||cardExpirynotemptyerror =="invalid_expiry_month_past") && (
                  <p className="errorMessage">
                    Information entered is invalid. Please try again.
                  </p>
                )}
            </div>
            <div className={classes.ml}>
              {" "}
              <FormLabel>
                CVC <span className="req">*</span>
              </FormLabel>
              <div className={classes.inputBox}>
                <CardCvcElement
                  options={CARD_ELEMENT_OPTIONS}
                  onChange={inputHandler}
                />
              </div>
              {cardDetails.inValid && (cardCvcerror == "incomplete_cvc") ? (
                <p className="errorMessage">
                  This field is required.
                </p>
              ) : null}
              {cardDetails.inValid &&
                (cardCvcnotemptyerror == "incomplete_cvc") && (
                  <p className="errorMessage">
                    Information entered is invalid. Please try again.
                  </p>
                )}
            </div>
          </div>
        </div>
        <div className={classes.btnBox}>
          <label className={classes.textCheckbox}>
            <CustomCheckbox onChange={checkBoxHandler} name={"saveCard"} />
            <p>Set as default payment method</p>{" "}
          </label>
          <YellowBtn
            // name={"Add Card"}
            name={"CONFIRM"}
            clickHandler={(e) => confirmpaymenthandler(e)}
          />
        </div>
      </div>
    </>
  );
};

export default withStyles(Styles)(AddPaymentForm);
