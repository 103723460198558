import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import FormLabel from "@mui/material/FormLabel";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import YellowBtn from "../YellowBtn/YellowBtn";
import StrConstant from "../../Common/StrConstant";
import SocialBtn from "../SocialBtn/SocialBtn";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../../redux/actions/UserActions";
import { validateEmail, validatePassword } from "../../Common/Validations";
import Loader from "react-spinner-loader";
import AlterMessage from "../AlertMessage/AlertMessage";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LogoContainer from "../LogoContainer/LogoContainer";
import { ActionType } from "../../redux/ActionTypes";
const fields = {
  email: "",
  password: "",
  keepMeLogin: false,
  inValid: false,
};
const LoginForm = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const isChanged = localStorage.getItem("isChanged");
  const issessionChanges = sessionStorage.getItem("isChanged");
  const [formFields, setFormFields] = useState(fields);
  const [showPassword, setShowPassword] = useState(false);
  const userSignIn = useSelector((state) => state?.userSignin);
  const checkBoxHandler = (check, name) => {
    setFormFields((pre) => ({ ...pre, [name]: check }));
  };
  const inputHandler = (e) => {
    const { name, value } = e.target;
    setFormFields((pre) => ({ ...pre, [name]: value }));
  };
  const loginHandler = () => {
    dispatch({ type: ActionType.CLEAR_ERROR });
    if (
      validateEmail(formFields.email) ||
      validatePassword(formFields.password)
    ) {
      setFormFields((pre) => ({ ...pre, inValid: true }));
    } else
      dispatch(
        signin(formFields?.email, formFields?.password, formFields?.keepMeLogin)
        // setIsOpen(true)
      );
  };
  const handleSubmit = (event) => {
    if (event?.which === 13) {
      loginHandler();
    }
  };

  // const snackHandler = (event, reason) => {
  //   if (reason === "clickaway") return;
  //   setFormFields((pre) => ({ ...pre, inValid: false }));
  //   if (userSignIn?.error?.email)
  //     localStorage.setItem("email", userSignIn?.error?.email);
  //   setIsOpen(false);
  // };
  useEffect(() => {
    if (userSignIn?.status) {
      if (userSignIn?.userInfo?.data?.user_data?.login_count === 1) {
        navigate("/choose-plan");
      } else if (localStorage.getItem("chooseInit") === "/choose-mode") {
        localStorage.removeItem("chooseInit");
        // navigate("/my-dashboard");
        navigate("/choose-mode");
      } else {
        // navigate("/my-dashboard")
        document.location.reload();
      }
    }
  }, [userSignIn?.status]);

  useEffect(() => {
    if (userSignIn?.error?.email) {
      localStorage.setItem("email", userSignIn?.error?.email);
    }
  }, [userSignIn?.error]);
  useEffect(() => {
    dispatch({ type: ActionType.CLEAR_DATA_ERROR })
  }, [])
  // useEffect(() => {
  //   dispatch({ type: ActionType.CLEAR_ERROR });
  // }, []);
  const isChangedHandler = () => {
    localStorage.removeItem("isChanged");
    sessionStorage.removeItem('isChanged');
    setIsOpen(false);
  };
  useEffect(() => {
    if (isChanged || issessionChanges) return setIsOpen(true);
  }, [isChanged]);
  // console.log("location.pathname", location.pathname);
  return (
    <>
      {userSignIn?.loading && (
        <Loader
          show={userSignIn?.loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {/* {userSignIn?.error && (
        <AlterMessage
          closeHandler={snackHandler}
          description={
            typeof userSignIn?.error === "object"
              ? userSignIn?.error?.message
              : userSignIn?.error
          }
          title={"Error"}
          type={"Error"}
          open={isOpen}
          url={"./images/error.svg"}
        />
      )} */}
      {(isChanged === "true" || issessionChanges == "true") ? (
        <AlterMessage
          description={"Please login with your new password."}
          closeHandler={isChangedHandler}
          url={"./images/success.svg"}
          title={"Success"}
          type={"success"}
          open={isOpen}
        />
      ) : null}
      <div className={classes.formInner}>
        <LogoContainer
          heading={StrConstant.Login.heading}
          goUrl={"/forgot-password"}
          urlTxt={"Forgot your password?"}
        />
        <form onSubmit={loginHandler}>
          <div className={classes.fromControl}>
            <FormLabel>
              Email address <span className="req">*</span>
            </FormLabel>
            <input
              type="email"
              name="email"
              placeholder="Enter your email address"
              value={formFields.email}
              onKeyDown={handleSubmit}
              onChange={(e) => inputHandler(e)}
            />
            {formFields.inValid && formFields.email === "" ? (
              <p className="errorMessage">This field is required.</p>
            ) : (
              <div></div>
            )}
            {formFields.email !== "" && validateEmail(formFields.email) ? (
              <p className="errorMessage">
                Information entered is invalid. Please try again.
              </p>
            ) : null}
          </div>
          <div className={classes.fromControl}>
            <FormLabel>
              Password<span className="req">*</span>
            </FormLabel>
            <div className={classes.passwordField}>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter password"
                value={formFields.password}
                onKeyDown={handleSubmit}
                onChange={(e) => inputHandler(e)}
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className={classes.eyeIcon}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </span>
            </div>
            {formFields.inValid && formFields.password === "" ? (
              <p className="errorMessage">This field is required.</p>
            ) : (
              <div></div>
            )}
            {formFields.password !== "" &&
              validatePassword(formFields.password) ? (
              <p className="errorMessage">
                Information entered is invalid. Please try again.
              </p>
            ) : null}
          </div>
          <label className={classes.textCheckbox}>
            <CustomCheckbox onChange={checkBoxHandler} name={"keepMeLogin"} />
            <p>Keep me logged in</p>
          </label>

          {userSignIn?.error && userSignIn?.error != "You account is connected to Google or Facebook. Please use the Google or Facebook button to log in." && userSignIn?.error?.email === undefined && (
            <p className={classes.errorMessage}>
              Your password is incorrect or this account doesn’t exist. Please{" "}
              <Link
                to="/forgot-password"
                onClick={() => navigate("/forgot-password")}
              >
                reset your password
              </Link>{" "}
              or{" "}
              <Link to="/sign-up" onClick={() => navigate("/sign-up")}>
                create a new account
              </Link>
              .
            </p>
          )}

          {userSignIn?.error && userSignIn?.error == "You account is connected to Google or Facebook. Please use the Google or Facebook button to log in." && userSignIn?.error?.email === undefined && (
            <p className={classes.errorMessage}>
              You account is connected to Google or Facebook. Please use the Google or Facebook button to log in.
            </p>
          )}


          {userSignIn?.error?.email && (
            <p className={classes.errorMessage}>
              This email is not verified. Please verify your email first.{" "}
              <Link to={`/resend-email/${userSignIn?.error?.email}`}>
                Resend verification email{" "}
              </Link>
            </p>
          )}
          <div className={classes.btnTxt}>
            <YellowBtn name={"Login"} clickHandler={loginHandler} />
          </div>
        </form>
        <div className={classes.haveTxt}>
          <p>Don’t have an account?</p>
          <Link to="/sign-up"> Sign up here </Link>
        </div>
        <div className={classes.continueTxt}>
          <p>Or continue with</p>
        </div>
        <SocialBtn />
      </div>
    </>
  );
};
export default withStyles(Styles)(LoginForm);
