export const Styles = Theme => ({
    dFlex: {
        alignItems: "flex-start",
        flexDirection: "column",
        marginBottom: "10px",
        textAlign: "center",
        "@media(max-width:640px)": {
            textAlign: "left",
        },
        "& a": {
            paddingLeft: "5px",
            "@media(max-width:375px)": {
                paddingLeft: "0px",
            }
        },
        "& p": {
            margin: "0",
            color: "#3F4444BF",
        },
    },
    btTxt: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        padding: "10px 0 0",
        "@media(max-width:640x)": {
            justifyContent: "flex-start",
            padding: "0",
        },
        "& p": {
            color: "#3F4444BF",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "400",
            margin: "0",
            fontFamily: "'Noto Serif', serif",
            paddingRight: "5px",
        },
        "& a": {
            color: "#3F4444",
            fontSize: "16px",
            lineHeight: "16px",
            fontWeight: "700",
            fontFamily: "'Noto Serif', serif",
            textDecoration: "underline",
        }
    },
    otpBox: {
        margin: "20px 0 0",
        "& > div": {
            justifyContent: "center",
        },
        "& input": {
            height: "55px",
            width: "55px !important",
            background: "#f1eedb !important",
            padding: "12px 0",
            "@media(max-width:991px)": {
                height: "45px",
                width: "45px !important",
                background: "#fff !important",
            },
        }
    },
    space: {
        padding: "0 10px",
    },
    btnBox: {
        "& > div": {
            float: "unset",
            margin: "15px auto 10px",
            "@media(max-width:640px)": {
                float: "left",
            },
            "& button": {
                margin: "0 0 !important",
            }
        }
    }
})