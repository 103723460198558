export const Styles = Theme => ({
    fromControl: {
        margin: "20px 0 25px",
    },
    btnBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
        "@media(max-width:640px)": {
            display: "block",
        },
        "& :nth-child(2)": {
            marginLeft: "10px",
            "@media(max-width:640px)": {
                margin: "auto",
            }
        },
        "& > div": {
            "@media(max-width:640px)": {
                float: "unset",
                margin: "auto",
            }
        },
    },
})