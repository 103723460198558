import { useEffect, useState } from "react";

export const useDateState = () => {
  const [dateState, setDateState] = useState(new Date());
  useEffect(() => {
    setInterval(() => setDateState(new Date()), 30000);
  }, []);

  return dateState;
};
