import { Styles } from "./Style";
import { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import Loader from "react-spinner-loader";
import { Container } from "@mui/material";
import { useNavigate } from "react-router";
import { ActionType } from "../../redux/ActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { modeLinkHandler } from "../../Common/utils/utils";
import BackArrow from "../../Components/BackArrow/BackArrow";
import OrderStatus from "../../Components/OrderStatus/OrderStatus";
import OrderCardBox from "../../Components/OrderCardBox/OrderCardBox";
import { viewOrderHistoryAction } from "../../redux/actions/order/orderActions";
import Opps from "../../Components/Opps/Opps";
import ViewMore from "../../Components/ViewMore/ViewMore";
const OrderHistory = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setDefaultMode = localStorage.getItem("selected_modes");
  const { loading, viewOrderHistory } = useSelector(
    (state) => state?.viewOrderHistory
  );
  useEffect(() => {
    dispatch(viewOrderHistoryAction(page));
  }, []);

  //ScrollView Section
  const [orderData, setOrderData] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [page, setPage] = useState(1);

  // useEffect(() => {
  //   window.addEventListener("scroll", onScroll);
  //   return () => {
  //     window.removeEventListener("scroll", onScroll);
  //   };
  // });

  useEffect(() => {
    if (viewOrderHistory != null) {
      if (viewOrderHistory?.data?.orders.length < 10) {
        setIsComplete(true);
      }
      if (orderData?.length > 0) {
        if (page > 1) {
          if (viewOrderHistory?.data?.orders != undefined) {
            setOrderData([...orderData, ...viewOrderHistory?.data?.orders]);
          } else {
            setIsComplete(true);
          }
        }
      } else {
        setOrderData(viewOrderHistory?.data?.orders);
      }
    }
  }, [viewOrderHistory]);

  useEffect(() => {
    if (page === 1) {
      setOrderData([]);
    }
    // console.log("page", page);
    dispatch(viewOrderHistoryAction(page));
  }, [page]);

  // const onScroll = () => {
  //   const winHeight = window.innerHeight;
  //   const srcHeight = document.documentElement.scrollHeight;
  //   const scrollTop =
  //     document.body.scrollTop || document.documentElement.scrollTop;
  //   const toBottom = srcHeight - winHeight - scrollTop;

  //   if (toBottom <= 100) {
  //     if (!isComplete) {
  //       getData();
  //     }
  //   }
  // };

  // function getData() {
  //   if (loading) return;
  //   const nextPage = page + 1;
  //   setPage(nextPage);
  // }
  const loadMoreHandler = () => {
    const nextPage = page + 1;
    setPage(nextPage);
  };
  return (
    <>
      {loading && page === 1 && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <Container maxWidth="lg" className={classes.p0}>
        <div className={classes.outerInner}>
          <div className={`${classes.box} ${classes.p16}`}>
            <BackArrow
              clickHandler={() =>
                dispatch(
                  { type: ActionType.CLEAR_RESPONSE },
                  localStorage.removeItem("outletId"),
                  navigate(-1)
                )
              }
            />
            <h3>order history</h3>
          </div>
          {(!loading && orderData.length) === 0 ? (
            <Opps />
          ) : (
            <div>
              <div className={`${classes.box} ${classes.p16}`}>
                <div className={classes.cardOuter}>
                  <ul>
                    {orderData.map((order, index) => (
                      <li key={index}>
                        <OrderCardBox data={order} isRecurring={true} />
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <div className={classes.btnContent}> */}
                  <OrderStatus
                    // link={modeLinkHandler(setDefaultMode)}
                    link={"/choose-mode"}
                    text={"Place Order"}
                    clickHandler={() => {
                      // navigate(modeLinkHandler(setDefaultMode));
                      navigate("/choose-mode");
                    }}
                  />
                {/* </div> */}
              </div>
            </div>
          )}
        </div>
        <div className={classes.ViewMore}>
          {viewOrderHistory?.data?.pagination?.lastPage === page ||
          viewOrderHistory?.data?.pagination?.count === orderData.length ||
          orderData.length < 1 ? null : (
            <ViewMore name={"Load More"} clickHandler={loadMoreHandler} />
          )}
        </div>
      </Container>
    </>
  );
};

export default withStyles(Styles)(OrderHistory);
