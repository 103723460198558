import { ActionType } from "../../ActionTypes";

export const getProductListReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_PRODUCT_LIST_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_PRODUCT_LIST_SUCCESS:
      return { ...state, loading: false, productList: action.payload };
    case ActionType.GET_PRODUCT_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload };
      case ActionType.CLEAR_ProductResponse:
        return {};
    default:
      return state;
  }
};

export const getProductDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_OUTLET_ITEM_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_OUTLET_ITEM_DETAILS_SUCCESS:
      return { ...state, loading: false, productList: action.payload };
    case ActionType.GET_OUTLET_ITEM_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getFavoritesListReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_FAVORITES_LIST_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_FAVORITES_LIST_SUCCESS:
      return { ...state, loading: false, favoritesList: action.payload };
    case ActionType.GET_FAVORITES_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const toggleFavoritesReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.TOGGLE_FAVORITES_REQUEST:
      return { ...state, loading: true };
    case ActionType.TOGGLE_FAVORITES_SUCCESS:
      return { ...state, loading: false, toggleFavorites: action.payload };
    case ActionType.TOGGLE_FAVORITES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};
