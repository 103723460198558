import { Styles } from "./Style";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Loader from "react-spinner-loader";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StrConstant from "../../../../Common/StrConstant";
import { ActionType } from "../../../../redux/ActionTypes";
import GrayBtn from "../../../../Components/GrayBtn/GrayBtn";
import OuterBox from "../../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../../Components/BackArrow/BackArrow";
import AlertMessage from "../../../../Components/AlertMessage/AlertMessage";
import {
  userVerifyOtp,
  userSendOtp,
  userResendOtpAction,
} from "../../../../redux/actions/UserActions";
import YellowBtn from "../../../../Components/YellowBtn/YellowBtn";
import { CountdownTimer } from "../../../../Views/CountdownTimer";
const VerifyPhoneNumberOtp = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(null);
  const TWO_MIN_IN_MS = 120000;
  const NOW_IN_MS = new Date().getTime();
  const TimeAfterTowMins = NOW_IN_MS + TWO_MIN_IN_MS;
  const [isOpen, setIsOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const numberToVerify = localStorage.getItem("numberToVerify");
  const { error } = useSelector((state) => state?.sendOtp);
  const isVerify = useSelector((state) => state?.verifyOtp);

  const handleChangeOtp = (otpVal) => {
    setOtp(otpVal);
    // alert(otp)
  };
  const submitOtpHandler = () => {
    dispatch(userVerifyOtp(otp), setIsOpen(true));
  };

  const resendOtpHandle = () => {
    dispatch(userResendOtpAction());
    setDisable(true);
    setTimeout(() => setDisable(false), 120000);
  };
  useEffect(() => {
    if (isVerify?.success) {
      dispatch({ type: "CLEAR_OTP" });
      dispatch({type:ActionType.CLEAR_CHECKOUT})
      dispatch({ type: ActionType.CLEAR_RESPONSE })
      navigate("/phone-number-verified");}
  }, [isVerify]);
  useEffect(() => {
    dispatch({ type: "CLEAR_OTP" });
    
    dispatch(userSendOtp(numberToVerify), setIsOpen(true));
  }, []);
  return (
    <>
      {isVerify?.loading && (
        <Loader
          show={isVerify?.loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {error && (
        <AlertMessage
          closeHandler={() =>
            dispatch({ type: ActionType.CLEAR_RESPONSE }, setIsOpen(false))
          }
          url={"./images/error.svg"}
          description={error}
          title={"Error"}
          type={"Error"}
          open={isOpen}
        />
      )}
      {isVerify?.error && (
        <AlertMessage
          closeHandler={() =>
            dispatch({ type: ActionType.CLEAR_RESPONSE }, setIsOpen(false))
          }
          url={"./images/error.svg"}
          description={isVerify?.error}
          title={"Error"}
          type={"Error"}
          open={isOpen}
        />
      )}
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <h3>{StrConstant.OrderReviewVerifyOtp.heading}</h3>
        </div>
        <div>
          <div className={classes.dFlex}>
            <p>{StrConstant.VerifyOtp.description.concat(numberToVerify)}.</p>
            <p>
              {StrConstant.VerifyOtp.secondDescription}{" "}
              <Link to="/edit-phone-number">Edit your number</Link>
            </p>
          </div>
          <div className={classes.otpBox}>
            <OtpInput
              value={otp}
              numInputs={4}
              isInputNum={true}
              onChange={handleChangeOtp}
              separator={<span className={classes.space}></span>}
            />
          </div>
          <div className={classes.btnBox}>
            {otp !== null && otp?.length === 4 ? (
              <YellowBtn name={"Submit"} clickHandler={submitOtpHandler} />
            ) : (
              <GrayBtn name={"Submit"} />
            )}
          </div>
          {disable ? (
            <CountdownTimer targetDate={TimeAfterTowMins} />
          ) : (
            <div className={classes.btTxt}>
              <p>Didn’t receive code? </p>
              <Link to="" onClick={resendOtpHandle}>
                Request again
              </Link>
            </div>
          )}
        </div>
      </OuterBox>
    </>
  );
};
export default withStyles(Styles)(VerifyPhoneNumberOtp);
