export const Styles = (Theme) => ({
  outerInner: {
    maxWidth: "1000px",
    margin: "auto",
    display: "flex",
    width: "100%",
    alignItems: "center",
    height: "calc(100vh - 150px)",
    "@media(max-width:640px)": {
      display: "block",
    },
  },
  dFlex: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "@media(max-width:640px)": {
      display: "block",
    },
    "& > :nth-child(2)": {
      marginRight: "20px",
      "@media(max-width:475px)": {
        marginRight: "0",
      },
    },
  },
  btnBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media(max-width:640px)": {
      display: "block",
    },
  },
  addItems: {
    "& a": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      borderRadius: "10px",
      padding: "0 10px 0 0",
      background: "#fff",
      color: "#3F4444",
      "@media(max-width:640px)": {
        padding: "20px",
        marginTop: "20px",
      },
      "& img": {
        height: "15px",
        marginRight: "10px",
      },
      "& :hover": {
        color: "#3F4444",
      },
    },
  },
  card: {
    background: "#FFFFFF",
    borderRadius: "10px",
    padding: "15px 20px",
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "20px",
    borderBottom: "1px solid #ddd",
    width: "100%",
    cursor: "pointer",
    "@media(max-width:640px)": {
      borderBottom: "0",
    },
  },
  ltBox: {
    width: "80%",
    "& h4": {
      marginBottom: "5",
    },
    "& p": {
      marginBottom: "0",
      fontSize: "14px",
    },
  },
  rtBox: {
    width: "20%",
    display: "flex",
    paddingLeft: "10px",
    justifyContent: "flex-end",
  },

  imgContent: {
    margin: "0 5px",
  },
  noWrap: {
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "10px",
    "& span": {
      paddingLeft: "10px",
    },
  },
  primaryTag: {
    fontSize: "10px",
    fontWeight: "400",
    fontFamily: '"Noto Serif", serif',
  },
  isACtiveBG: {
    background: "#F1EEDB",
    marginBottom: "20px",
    borderRadius: "5px",
  },
  modalFlex: {
    display: "flex",
    "& :nth-child(2)": {
      marginLeft: "15px",
    },
  },
});
