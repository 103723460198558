import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstant from "../../Common/StrConstant";
import { useEffect } from "react";
import YellowBtn from "../../Components/YellowBtn/YellowBtn";
import { Button } from "@mui/material";
// import OrderStatus from '../OrderStatus/OrderStatus';
// import { modeLinkHandler } from "../../Common/utils/utils";
const Opps = (props) => {
  const {
    classes,
    description = "There's nothing to show here.",
    isBrowse = false,
    onBrowse,
  } = props;
  // const setDefaultMode = localStorage.getItem("selected_modes");
  return (
    <>
      <div className={classes.oppsOuter}>
        <div className={classes.oppsInner}>
          <h2>{StrConstant.Opps.heading}</h2>
          <p>{description}</p>
          {isBrowse && (
            <div className={classes.btnContent}>
              {" "}
              <Button onClick={() => onBrowse("0")}>Browse</Button>
            </div>
          )}
        </div>
        {/* <OrderStatus link={modeLinkHandler(setDefaultMode)} text={"Place Order"} /> */}
      </div>
    </>
  );
};
export default withStyles(Styles)(Opps);
