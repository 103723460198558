import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { Link } from "react-router-dom";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import FormLabel from "@mui/material/FormLabel";
import StepperCustom from "../../../Components/StepperCustom/StepperCustom";
import { useLocation, useNavigate } from "react-router";
import StrConstant from "../../../Common/StrConstant";
import Propover from "../../../Components/Propover/Propover";
import CommonSection from "../../../Components/CommonSection/CommonSection";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { useEffect, useState } from "react";
import AlterMessage from "../../../Components/AlertMessage/AlertMessage";
import { useDispatch, useSelector } from "react-redux";
import { validateCredit } from "../../../Common/Validations";
import { ActionType } from "../../../redux/ActionTypes";
import OneOfPurchaseCredits from "../OneOfPurchaseCredits/OneOfPurchaseCredits";
import { displayAmountFormat } from "../../../Common/utils/utils";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import DarkGrayBtn from "../../../Components/DarkGrayBtn/DarkGrayBtn";
// import { onPopState } from "../../../Common/utils/utils";
const GetOneTimeCredits = (props, route) => {
  const { classes } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(null);
  const [credit, setCredit] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const data = useSelector((state) => state?.addCard);
const TotalCredit=localStorage.getItem("TotalCredit")
const [creditpopup,setcreditpopup]=useState(false)
const submitHandler = () => {
    if (validateCredit(credit)) {
      // setCredit("");
      localStorage.setItem("credit", "");
      setIsValid(true);
    } else {
      localStorage.setItem("credit", credit);
      if(TotalCredit<3000){
        setcreditpopup(false)
        navigate("/purchase-credits", { state: 2 });
        setShow(<OneOfPurchaseCredits />);
      }else{
        setcreditpopup(true)
      }
    }
  };

  const snackHandler = (event, reason) => {
    setIsOpen(false);
    // if (reason === "clickaway") return;
  };

  /* MJ CODE START  */

  const onBackButtonEvent = (isBack = false) => {
    // console.log("isBack", isBack);
    // console.log("location.state", location.state);
    if (isBack) return navigate(-1);
    else if (location.state === 2) return setShow(<OneOfPurchaseCredits />);
    else if (location.state === 1) return setShow(null);
    else return false;
  };

  useEffect(() => {
    dispatch({ type: ActionType.CLEAR_CHECKOUT });
    localStorage.removeItem("selectedPlan");
    window.history.pushState(null, null, window.location.hash);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  /* MJ CODE END  */

  useEffect(() => {
    // console.log("state 1", location);

    if (location?.state !== null) {
      if (location?.state?.amount != undefined) {
        // localStorage.setItem("totalPayable", location?.state?.amount);
        setCredit(displayAmountFormat(location?.state?.amount?.toFixed(1)));
      }

      // if (location?.state?.lastPage != undefined) {
      //   if (location?.state?.lastPage === "/purchase-credits") {
      //     setShow(<OneOfPurchaseCredits />);
      //   }
      // }
    }

    onBackButtonEvent();
  }, [location]);

  return show ? (
    show
  ) : (
    <>
      {(data?.success || data?.error) && (
        <AlterMessage
          closeHandler={snackHandler}
          description={data?.messages?.message || data?.messages?.message}
          title={data?.success ? "Success" : "Error"}
          type={data?.success ? "Success" : "Error"}
          open={isOpen}
          url={data?.success ? "./images/success.svg" : "./images/error.svg"}
        />
      )}
      <CommonSection>
        <BackArrow
          clickHandler={() =>
            dispatch(
              { type: ActionType.CLEAR_RESPONSE },
              // navigate(location.state === 1 ? -1 : -1)
              onBackButtonEvent(true)
            )
          }
        />
        <StepperCustom step={1} />
        <div className={classes.description}>
          <h3>
            {localStorage.getItem("insufficient") !== "true"
              ? StrConstant.GetOneTimeCredits.heading
              : "top-up credits"}
          </h3>
          <p>
            {localStorage.getItem("insufficient") !== "true"
              ? StrConstant.GetOneTimeCredits.description
              : "To submit your order, please top-up your wallet."}
          </p>
        </div>
        <div className={classes.needTxt}>
          {localStorage.getItem("insufficient") !== "true" ? (
            <p>
              Need a <Link to="/menu"> peek into the menu</Link> to decide on
              quantity?
              <Link to="/menu">
                {" "}
                <img src="./images/arrowRight.svg" alt="Better Coffee" />
              </Link>{" "}
            </p>
          ) : null}
        </div>
        <div className={classes.dFlex}>
          <FormLabel>No Of Credits To Purchase </FormLabel>
          <Propover>
            {/* <span>{`The credit must be =>S$10 and <=S$1000`}</span> */}
            <span>Please enter an amount between 10 and 1000 credits.</span>
          </Propover>
        </div>
        <div className={classes.boxContent}>
          <div className={classes.inputBox}>
            <div className={classes.inputContent}>
              <input
                type="tel"
                name="credit"
                maxLength={4}
                value={credit}
                placeholder="Enter credits"
                // onChange={(event) => setCredit(event.target.value.match(/.{4}/g))}
                onChange={(event) =>
                  setCredit(
                    event.target.value.length &&
                      event.target.value.length != "" &&
                      !isNaN(parseInt(event.target.value))
                      ? event.target.value.length > 4
                        ? parseInt(event.target.value.match(/.{4}/g), 10)
                        : parseInt(event.target.value, 10)
                      : ""
                  )
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {credit === "" ? (
                <span>= S$ {credit}
                </span>
              ) : (
                <span>
                  = S${" "}
                  {localStorage.getItem("insufficient") !== "true"
                    ? parseFloat(credit).toFixed(0)
                    : credit}
                    {console.log("creditcredit",typeof credit)}
                </span>
              )}
            </div>
            {credit !== null && isValid && validateCredit(credit) ? (
              <p className="errorMessage">
                {/* {"The credit must be =>S$10 and <=S$1000"} */}
                Please enter an amount between 10 and 1000 credits.
              </p>
            ) : null}
          </div>
          <div className={classes.btnBox}>
            <YellowBtn
              // name={"Proceed to checkout"}
              name={"next"}
              clickHandler={submitHandler}
            />
          </div>
        </div>
        {localStorage.getItem("insufficient") !== "true" ? (
          <div 
          className={classes.btxt}
          >
            <p>
              {StrConstant.GetOneTimeCredits.btTxt.description} &nbsp;
              <Link to="/choose-plan" className={classes.textanchor}>
                {StrConstant.GetOneTimeCredits.btTxt.LinkTxt}
              </Link>
            </p>
          </div>
        ) : null}
      </CommonSection>
      <CustomModal
        open={creditpopup}
        modalClose={() => {
          setcreditpopup(false);
         
        }}
        title={"Are you sure you wish to proceed?"}
        // description={`Current wallet balance: ${TotalCredit} credits`}
        description={`You already have a lot of credits in your wallet.`}
      >
        <>
        <ul className={classes.modalBox}>
              <li>
                <div className={classes.dFlex}>
                  <div className={classes.ltPanel}>
                    <h5>Current Wallet Balance</h5>
                  </div>
                  <div className={classes.rtPanel}>
                    <p>{TotalCredit} Credits</p>
                  </div>
                </div>
              </li>
           
            </ul>
        <div
          className={classes.modalFlex}
          // style={{ display: "flex", justifyContent: "space-between" }}
          >
           <DarkGrayBtn
            name={"Cancel"}
            clickHandler={() => setcreditpopup(false)}
            />
          <YellowBtn
            name="TOp-UP"
            clickHandler={() => {
              setcreditpopup(false);
              navigate("/purchase-credits", { state: 2 });
              setShow(<OneOfPurchaseCredits />);
              
            }}
            />
        </div>
            </>
      </CustomModal>
    </>
  );
};

export default withStyles(Styles)(GetOneTimeCredits);
