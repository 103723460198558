import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Container from "@mui/material/Container";
const CommonSection = (props) => {
  const { classes, children } = props;
  return (
    <>
      <Container maxWidth="lg">
        <div className={classes.outer}>{children}</div>
      </Container>
    </>
  );
};
export default withStyles(Styles)(CommonSection);
