export const Styles = Theme => ({
    noExpandedBox: {
        background: "#FFFFFF",
        borderRadius: "15px !important",
        boxShadow: "none !important",
        padding:"20px",
        position: "relative !important",
        marginBottom:"20px",
        maxWidth:"100%",
    },
    dFlex: {
        display: "flex",
        width: "auto",
        justifyContent: "space-between",
        "& h4": {
            fontSize: "26px",
            lineHeight: "26px",
            padding: "0 2px",
            margin: "0",
            textAlign: "center",
        },
        "& span": {
            fontSize: "18px",
            lineHeight: "18px",
            fontFamily: "'Bebas Neue', serif",
        },
        "& p": {
            fontSize: "14px",
            lineHeight: "18px",
            margin: "0",
        }
    },
    topFirst: {
        marginBottom: "15px",
        width: "100%",
        "& h3": {
            margin: "0",
            textAlign: "left",
            fontSize: "26px",
            lineHeight: "26px",
        },
    },
    cardHeadline: {
        width: "100%",
        minHeight: "50px",
        paddingRight: "10px",
    },
    ltHeadingTxt: {
        display: "flex",
        alignItems: "baseline",
        width: "50%",
    },
    rtHeadingTxt: {
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-end",
        position: "relative !important",
        width: "50%",
        paddingLeft: "10px",
    },
})