import { createSlice } from "@reduxjs/toolkit";

export const addOutletitemslice= createSlice({
    name: "outlet_item_id",
    initialState:{
        value:'',
        count:0,
        totalcount:0
    },
    reducers:{
        incrementoutletid:(state,{payload})=>{
            state.value= payload
        },
        incrementcount:(state,action)=>{
            state.count=action.payload
        },
        incrementtotal:(state,{payload})=>{
            state.totalcount=payload
        }
    }
})

export const {incrementoutletid,incrementtotal,incrementcount}= addOutletitemslice.actions;
export default addOutletitemslice.reducer;