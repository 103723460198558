import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import FormLabel from "@mui/material/FormLabel";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import StrConstant from "../../../../Common/StrConstant";
import OuterBox from "../../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../../Components/BackArrow/BackArrow";
import DarkGrayBtn from "../../../../Components/DarkGrayBtn/DarkGrayBtn";
import GrayBtn from "../../../../Components/GrayBtn/GrayBtn";
import YellowBtn from "../../../../Components/YellowBtn/YellowBtn";
import CustomModal from "../../../../Components/CustomModal/CustomModal";
import { ActionType } from "../../../../redux/ActionTypes";
import { useDispatch } from "react-redux";
const DeleteAccount = (props) => {
  const { classes } = props;
  const [showModel, setShowModel] = useState(false);
  // back button
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const linkClickHandler = () => {
    dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1));
  };

  const buttonClick = () => {
    navigate("/account-deletion-success");
  };

  // modal Show
  const showPopup = () => {
    setShowModel(!showModel);
  };

  // modal Close
  const modalClose = () => {
    setShowModel(false);
  };

    return (
        <>
            <OuterBox>
                <div>
                    <BackArrow clickHandler={linkClickHandler} />
                    <h3>{StrConstant.DeleteAccount.heading}</h3>
                </div>
                <div>
                    <p>{StrConstant.DeleteAccount.description1}</p>
                    <p>{StrConstant.DeleteAccount.description2}</p>
                    <p>{StrConstant.DeleteAccount.description3}</p>
                    <p>{StrConstant.DeleteAccount.description4}</p>
                    <div className={classes.fromControl}>
                        <FormLabel>Password</FormLabel>
                        <div className={classes.passwordBox}>
                            <input type="text" name="" placeholder="Enter password" />
                            <Link to="">
                                <img src="./images/success.svg" alt="Better Coffee" />
                                {/* <img src="./images/eye-show.svg" alt="Better Coffee" /> */}
                            </Link>
                        </div>
                    </div>
                    <div className={classes.btnBox}>
                        <GrayBtn name={"delete account"} clickHandler={showPopup} />
                        <DarkGrayBtn name={"Return to my account"} clickHandler={linkClickHandler} />
                    </div>
                </div>
            </OuterBox>
            <CustomModal open={showModel} title={"ARE YOU SURE YOU WANT TO DELETE THIS CARD?"} description={""}>
                <div className={classes.modalFlex}>
                    <DarkGrayBtn name="Cancel" clickHandler={modalClose} />
                    <YellowBtn name="Delete" clickHandler={buttonClick} />
                </div>
            </CustomModal>
        </>
    )
}
export default withStyles(Styles)(DeleteAccount);
