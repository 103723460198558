import { reloadHandler } from "../../../Common/utils/utils";
import serverURL from "../../../APIHandler/serverURL";
import callAPI from "../../../APIHandler/callAPI";
import { ActionType } from "../../ActionTypes";

export const getModesList = () => async (dispatch, getState) => {
  dispatch({ type: "CLEAR_MODE" });
  dispatch({
    type: ActionType.MODES_LIST_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.getRequest(serverURL.modesList, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });
    dispatch({ type: ActionType.MODES_LIST_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.MODES_LIST_FAILURE, payload: message });
  }
};

export const setMode = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.SET_MODE_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(serverURL.setMode, payload, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });
    dispatch({
      type: ActionType.SET_MODE_SUCCESS,
      payload: data,
    });
    let mode;
    if (data?.data?.default_mode == 1) {
      mode = "Pick-Up Today";
    } else if (data?.data?.default_mode === 2) {
      mode = "Schedule Pick-Up";
    } else if (data?.data?.default_mode === 3) {
      mode = "Schedule Delivery";
    }
    // console.log("MODEID", typeof data?.data?.default_mode);
    localStorage.setItem("selected_modes", data?.data?.default_mode);
    localStorage.setItem("defaultMode", mode);
    // window.location.reload();
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.SET_MODE_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
