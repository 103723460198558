import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { Link } from "react-router-dom";
import Propover from "../../Components/Propover/Propover";
import StrConstant from "../../Common/StrConstant";
import CommonSection from "../../Components/CommonSection/CommonSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getConfiguration,
  getDashboardData,
} from "../../redux/actions/dashboard/DashboardActions";
import AlterMessage from "../../Components/AlertMessage/AlertMessage";
import { useEffect, useMemo, useState } from "react";
import CardContent from "../../Components/CardContent/CardContent";
import {
  getSpendPolicyAction,
  getUserProfileAction,
} from "../../redux/actions/UserActions";
import { useAuth } from "../../Hooks/useAuth";
// import { modeLinkHandler } from "../../Common/utils/utils";
import OrderStatus from "../../Components/OrderStatus/OrderStatus";
import Loader from "react-spinner-loader";
import {
  cartCounterAction,
  notificationCounterAction,
} from "../../redux/actions/cart/cartActions";
import { ActionType } from "../../redux/ActionTypes";
import { getMyWalletData } from "../../redux/actions/myWallet/MyWalletActions";
const Home = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { company_employee } = useAuth();
  // const setDefaultMode = localStorage.getItem("selected_modes");
  const { loading, dashboardDetail } = useSelector(
    (state) => state?.dashboardDetail
  );
  const cancelSubscription = useSelector((state) => state?.cancelPlan);
  const [isOpen, setIsOpen] = useState(false);

  // const isGroup =
  //   spendPolicy?.spendPolicy?.data?.spend_policy?.allowed_mode?.find(
  //     (element) => element === "Group By"
  //   );

  // useEffect(() => {
  //   if (company_employee) {
  //     dispatch(getSpendPolicyAction());
  //   }
  //   dispatch(getConfiguration());
  //   dispatch(getDashboardData());
  //   dispatch(getDashboardData());
  //   dispatch(getUserProfileAction());
  //   localStorage.removeItem("is_subscribed_process");
  //   localStorage.removeItem("insufficient");
  // }, []);

  useEffect(() => {
    if (dashboardDetail) {
      dispatch(cartCounterAction(dashboardDetail?.data?.cart_count));
      dispatch(
        notificationCounterAction(dashboardDetail?.data?.notification_count)
      );
    }
  }, [
    dashboardDetail?.data?.cart_count,
    dashboardDetail?.data?.notification_count,
  ]);
  console.log("dashboardDetail", dashboardDetail)
  // useEffect(() => {
  //   // console.log("userProfile", userProfile);
  //   if (userProfile?.success) {
  //     localStorage.setItem("is_subscribed", userProfile?.data?.is_subscribed);
  //   }
  // }, [userProfile?.data]);
  const snackHandler = (event, reason) => {
    dispatch({ type: ActionType.CLEAR_RESPONSE }, setIsOpen(false));
    // if (reason === "clickaway") return;
    // localStorage.setItem("isFirstView", "Yes");
  };
  // const isFirstView = localStorage.getItem("isFirstView") || "";
  useEffect(() => {
    setIsOpen(true);
  }, [cancelSubscription?.cancelPlan?.success]);

  useEffect(() => {
    dispatch({ type: ActionType.CLEAR_CHECKOUT });
    const isFirst = localStorage.getItem("isFirstView");
    if (isFirst === null) {
      // setSnack(true);
      setIsOpen(true);
      localStorage.setItem("isFirstView", "Yes");
    }
    if (company_employee) {
      dispatch(getSpendPolicyAction());
    }
    if (localStorage.getItem("userInfo")) {
      dispatch(getConfiguration());
      dispatch(getUserProfileAction());
      dispatch(getDashboardData());
    }
    localStorage.removeItem("is_subscribed_process");
    localStorage.removeItem("insufficient");
  }, []);

  const getpoloicy = () => {
    dispatch(getSpendPolicyAction());
  }
  useEffect(()=>{
    dispatch(getMyWalletData());
  },[])

  return (
    <>
      {/* {snack ? (
        <AlterMessage
          closeHandler={snackHandler}
          description={`Logged in successfully`}
          title="Success"
          type="Success"
          open={snack}
          url="./images/success.svg"
        />
      ) : null} */}
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {cancelSubscription?.cancelPlan?.success && (
        <AlterMessage
          closeHandler={snackHandler}
          description={cancelSubscription?.cancelPlan?.message}
          title="Success"
          type="Success"
          open={isOpen}
          url="./images/success.svg"
        />
      )}
      <CommonSection>
        <h3 className={classes.mt20}>{StrConstant.Home.heading}</h3>
        <CardContent data={dashboardDetail} />
        <div className={classes.flexDirectionChange}>
          <ul className={classes.cardOuter}>
            <li>
              <div className={classes.card}>
                <div className={classes.cardInner}>
                  <div className={classes.dFlex}>
                    <h5>Impact</h5>
                    <Propover>
                      <b>{StrConstant.Home.propoverTitle}</b>
                      <span>{StrConstant.Home.propoverDescription}</span>
                    </Propover>
                  </div>
                  <p>{dashboardDetail?.data?.tree_impacted}</p>
                  <p>Trees</p>
                </div>
              </div>
            </li>
            <li>
              <div className={classes.card}>
                <div className={classes.cardInner}>
                  <div className={classes.dFlex}>
                    <h5>Rewards</h5>
                    <Propover>
                      <b>{StrConstant.Home.propoverTitle}</b>
                      <span>{StrConstant.Home.propoverDescription}</span>
                    </Propover>
                  </div>
                  <p>-</p>
                  <p>Credits</p>
                </div>
              </div>
            </li>
            <li>
              <div className={classes.card}>
                <div className={classes.cardInner}>
                  <h5>Refer a friend</h5>
                  <p className={classes.fontWeightBold}>KJ32...</p>
                  <p>Tap to copy</p>
                </div>
              </div>
            </li>
          </ul>
          <div className={classes.noWrap}>
            {/* <div className={classes.noWrap}> */}
            <div className={`${classes.card} ${classes.viewMenu}`}>
              <h4>View Menu</h4>
              <Link to="/menu">
                <div className={classes.imgContent}>
                  <img src="./images/arrow.svg" alt="Better Coffee" />
                </div>
              </Link>
              <OrderStatus
                // link={modeLinkHandler(setDefaultMode)}
                onClick={() => getpoloicy()}
                link={"/choose-mode"}
                text={"Place Order"}
              />
            </div>
            {/* {isGroup ? (
              <div className={`${classes.card} ${classes.pdLt}`}>
                <div className={classes.cardInner}>
                  <div className={classes.dFlex}>
                    <h4>Group buy</h4>
                    <Propover
                      propoverTitle={StrConstent.Home.propoverTitle}
                      propoverDescription={StrConstent.Home.propoverDescription}
                    />
                  </div>
                  <div className={classes.btnContent}> <Yellow name={"Learn more"} /></div>
                </div>
              </div>
            ) : null} */}
            {/* </div> */}
            <div className={`${classes.card} ${classes.mLt}`}>
              <h4 className={classes.mb0}>My account</h4>
              <Link to="/my-account">
                <div className={classes.imgContent}>
                  <img src="./images/arrow.svg" alt="Better Coffee" />
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* <div className={classes.bannerImg}>
          <img src="./images/banner.svg" alt="Better Coffee" />
        </div> */}
      </CommonSection>
    </>
  );
};

export default withStyles(Styles)(Home);
