export const Styles = Theme => ({
    outer: {
        width: "100%",
        height: "auto",
        "@media(max-width:640px)": {
            margin: "0 0 !important",
            padding: "0 0 !important",
        }
    },
    p20: {
        "@media(max-width:640px)": {
            padding: "0 16px",
        }
    },
    btText: {
        display: "flex",
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "16px",
        }
    },
    outerBox: {
        height: "calc(100vh - 120px)",
        "@media(max-width:640px)": {
            height: "calc(100vh - 150px)",
        },
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    contentBox: {
        width: "100%",
    },
    billingTable: {
        width: "100%",
        marginBottom: "20px",
        "& thead": {
            "& > tr": {
                background: "#F1EEDB",
            },
        },
    },
    btText: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        padding: "20px 0",
        "@media(max-width:640px)": {
            padding: "10px 10px",
            display: "block",
            textAlign: "left",
            padding: "20px 16px",
        },
        "& p": {
            marginBottom: "0",
            paddingRight: "5px",
        },
    },
    rightTxt: {
        "@media(max-width:640px)": {
            textAlign: "right !important",
        },
    }
})