import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import React, { lazy, Suspense, useEffect, useState } from "react";
import FormLabel from "@mui/material/FormLabel";
import StrConstant from "../../../Common/StrConstant";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import DarkGrayBtn from "../../../Components/DarkGrayBtn/DarkGrayBtn";
import { useNavigate, useParams } from "react-router";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import CustomDatePicker from "../../../Components/CustomDatePicker/CustomDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { editOrderAction } from "../../../redux/actions/order/orderActions";
import { TimeSlot } from "../../../Views/TimeSlot";
import { supplyDateFormat } from "../../../Common/utils/utils";
import { ActionType } from "../../../redux/ActionTypes";
import { getOrderDetailAction } from "../../../redux/actions/order/orderActions";
import { getSpendPolicyAction } from "../../../redux/actions/UserActions";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import { Buffer } from "buffer";

const EditOrder = (props) => {
  const { classes } = props;
  const { orderId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [date, setDate] = useState("");
  const [isDiffer, setIsDiffer] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const Loader = lazy(() => import("react-spinner-loader"));
  const AlterMessage = lazy(() =>
    import("../../../Components/AlertMessage/AlertMessage")
  );
  const viewOrderDetail = useSelector((state) => state?.getOrderDetail);
  const { spendPolicy } = useSelector((state) => state?.spendPolicy);
  const { loading, editOrder, error } = useSelector(
    (state) => state?.editOrder
  );

  const [selectedSlot, setSelectedSlot] = useState(
    localStorage.getItem("timeslot")
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const order_number = localStorage.getItem("order_number");
  // const daysHandler =
  //   userInfo?.data?.user_data?.role_name === "company_employee"
  //     ? spendPolicy?.data?.spend_policy?.allowed_days
  //       ? spendPolicy?.data?.spend_policy?.allowed_days
  //       : null
  //     : null;



  const daysHandler = () => {
    let daysResult = null;
    if (userInfo?.data?.user_data?.role_name === "company_employee") {
      const arr1 = viewOrderDetail?.viewOrderDetail?.data?.outlet_weekly_calendar?.days_open
      const arr2 = spendPolicy?.data?.spend_policy?.allowed_days;
      var array = [];
      for (let i = 0; i < arr2?.length; i++) {
        if (arr1?.indexOf(arr2[i]) >= 0) {
          array.push(arr2[i])
        }
      }

      // const array = arr1?.map(
      //   (element, index) => arr2?.indexOf(element >= 0) && element
      // );

      daysResult = spendPolicy?.data?.spend_policy?.allowed_days ? array : null;
      // console.log("arr", array);
    } else {
      daysResult = viewOrderDetail?.viewOrderDetail?.data?.outlet_weekly_calendar?.days_open
        ? viewOrderDetail?.viewOrderDetail?.data?.outlet_weekly_calendar?.days_open
        : null;
    }
    return daysResult;
  };

  const bindingHandler = (date, name) => setDate(supplyDateFormat(date[""]));

  const submitHandler = () => {
    const payload = {
      order_date:
        date === "" ? viewOrderDetail?.viewOrderDetail?.data?.order_date : date,
      order_time_slot: selectedSlot,
    };
    // if (validateRequired(selectedSlot)) return;
    dispatch(
      editOrderAction(
        Buffer.from(orderId, "base64").toString("utf-8"),
        payload
      ),
      setIsSubmit(true)
    );
  };

  const snackHandler = (event, reason) => {
    dispatch({ type: ActionType.CLEAR_UPDATE }, setIsOpen(false));
    // if (reason === "clickaway") return;
  };

  const selectionHandler = (selection) => {
    // console.log("MJ _TAG");
    setSelectedSlot(selection);
  };

  const discardChangesHandler = () => {
    setDate("");
    setSelectedSlot(viewOrderDetail?.viewOrderDetail?.data?.order_time_slot);
    navigate(-1);
  };

  useEffect(() => {
    if (editOrder?.success == true) return navigate("/successfully-order");
    if (error) return setIsOpen(true);

  }, [editOrder]);

  // useEffect(() => {
  //   renderTimeSlot();
  // }, [date]);

  // const renderTimeSlot = () => {
  //   const orderDateValue =
  //     date === "" ? viewOrderDetail?.viewOrderDetail?.data?.order_date : date;
  //   const isToday = moment().isSame(orderDateValue, "day");

  //   // console.log("isToday", isToday);

  //   localStorage.setItem("isToday", isToday);
  //   return <TimeSlot onPrefill={selectedSlot} onSelection={selectionHandler} />;
  // };

  useEffect(() => {
    // console.log(
    //   "Buffer.from *",
    //   Buffer.from(orderId, "base64").toString("utf-8")
    // );
    // console.log("local *", localStorage.getItem("orderId"));
    if (
      localStorage.getItem("orderId") !=
      Buffer.from(orderId, "base64").toString("utf-8")
    ) {
      setIsDiffer(true);
    }
    dispatch(
      getOrderDetailAction(Buffer.from(orderId, "base64").toString("utf-8"))
    );
    getOrderDetailAction(Buffer.from(orderId, "base64").toString("utf-8"));
  }, [orderId]);

  useEffect(() => {
    // console.log(
    //   "order_time_slot *",
    //   viewOrderDetail?.viewOrderDetail?.data?.order_time_slot
    // );
    if (viewOrderDetail?.viewOrderDetail?.data?.order_time_slot) {
      setSelectedSlot(viewOrderDetail?.viewOrderDetail?.data?.order_time_slot);
    }
  }, [viewOrderDetail]);

  useEffect(() => {
    if (isDiffer) {
      navigate("/404");
    }
  }, [isDiffer]);

  useEffect(() => {
    window.addEventListener("popstate", setIsDiffer(false));

    if (
      JSON.parse(localStorage.getItem("userInfo"))?.data?.user_data
        ?.role_name === "company_employee"
    ) {
      dispatch(getSpendPolicyAction());
    }
  }, []);
  const [startdd,setstartdd]=useState()
  useEffect(()=>{
    // console.log("vikalp",new Date(viewOrderDetail?.viewOrderDetail?.data?.created_at))
    setstartdd(new Date(viewOrderDetail?.viewOrderDetail?.data?.created_at))
  },[])
useEffect(()=>{
  // console.log("vikalp",new Date(viewOrderDetail?.viewOrderDetail?.data?.created_at))
  setstartdd(new Date(viewOrderDetail?.viewOrderDetail?.data?.created_at))
},[viewOrderDetail])

  // console.log("allowdays",daysHandler(),userCart,spendPolicy,viewOrderDetail)
  const datenew = new Date(viewOrderDetail?.viewOrderDetail?.data?.created_at)
  
  return !viewOrderDetail?.loading || !isDiffer ? (
    <>
      <Suspense>
        {loading && (
          <Loader
            show={loading}
            type="body"
            stack="vertical"
            message="Loading..."
          />
        )}
        {error && isOpen && (
          <AlterMessage
            closeHandler={snackHandler}
            url={"./images/error.svg"}
            description={error}
            title={"Error"}
            type={"Error"}
            open={isOpen}
          />
        )}
      </Suspense>
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <h3>
            {StrConstant.EditOrder.heading}
            <span className={classes.orderNumber}> {order_number}</span>
          </h3>
        </div>
        <div>
          {viewOrderDetail?.viewOrderDetail?.data?.order_type_display !==
            "Pickup Today" ? (
            <div className={classes.fromControl}>
              <FormLabel>Date</FormLabel>
              <div className={classes.datepickerContent}>
                <CustomDatePicker
                  onBinding={bindingHandler}
                  name=""
                  dateValue={viewOrderDetail?.viewOrderDetail?.data?.order_date}
                  isPastDisable={true}
                  isWeekDayDisable={true}
                  allowAfter={0}
                  allowedDays={daysHandler()}
                  editdata={viewOrderDetail?.viewOrderDetail?.data}
                  isFutureDisable={true}
                  maxFutureDate={startdd}
                />
              </div>
            </div>
          ) : null}
          <div className={classes.fromControl}>
            <FormLabel>Time slot</FormLabel>
            <div className={classes.boxContainer}>
              <div className={classes.selectBox}>
                <TimeSlot
                  onPrefill={selectedSlot}
                  setonPrefill={setSelectedSlot}
                  onSelection={selectionHandler}
                  onDate={
                    date === ""
                      ? viewOrderDetail?.viewOrderDetail?.data?.order_date
                      : date
                  }
                />
              </div>
            </div>
            {selectedSlot === "" && isSubmit ? (
              <p className="errorMessage">Time slot can not be left empty</p>
            ) : null}
          </div>
          <div className={classes.descriptionBox}>
            <p>
              To change order mode, location, items or quantity please cancel
              this order and create another one.
            </p>
          </div>
          <div className={classes.btnBox}>
            <DarkGrayBtn
              name={"DISCARD CHANGES"}
              clickHandler={discardChangesHandler}
            />
            <YellowBtn
              name={"NEXT"}
              clickHandler={submitHandler}
            />
          </div>
        </div>
      </OuterBox>
      {/* <CustomModal
        open={isDiffer}
        title={"THERE IS NO SUCH RECORD!"}
        description={"Please try again..."}
      >
        <div style={{ marginRight: "35px" }}>
          <YellowBtn
            name="Go Back"
            clickHandler={() =>
              // navigate(`/view-details/${localStorage.getItem("orderId")}`)
              navigate(-1)
            }
          />
        </div>
      </CustomModal> */}
    </>
  ) : null;
};

export default withStyles(Styles)(EditOrder);
