export const Styles = Theme => ({
    outerInner: {
        maxWidth: "1000px",
        margin: "auto",
        display: "flex",
        width: "100%",
        alignItems: "flex-start",
        height: "calc(100vh - 150px)",
        "@media(max-width:640px)":{
            display: "block",
        }
    },
    heading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& a": {
            fontWeight: "500",
        }
    },
    btnBox: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        width:"30%",
        marginLeft:"10px",
        "@media(max-width:640px)":{
            display:"block",
            width:"100%",
            marginLeft:"0",
        },
    },
    txtBox:{
        marginBottom:"20px",
        "& a":{
            paddingLeft:"5px",
        },
    },
    addItems: {
        width: "50%",
        paddingBottom: "25px",
        "@media (max-width: 640px)": {
            width: "100%",
        },
        "& a": {
            display: "flex",
            alignItems: "center",
            background: "#F1EEDB",
            borderRadius: '10px',
            padding: "20px",
            color: "#3F4444",
            "& img": {
                height: "15px",
                marginRight: "10px",
            },
            "& :hover": {
                color: "#3F4444",
            }
        },
    },
    totalBox: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: 'flex-end',
        marginBottom: "10px",
        "@media (max-width: 640px)": {
            borderBottom: "1px solid #3F4444BF",
            justifyContent: 'space-between',
        },
    },
    dFlex: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: 'flex-end',
        marginBottom: "20px",
        "@media (max-width: 640px)": {
            display: "block",
        },
    },
    boxContainer: {
        display: "flex",
        justifyContent: 'space-between',
        "@media (max-width: 640px)": {
            display: "block",
        },
    },
    ltBox:{
        width:"50%",
        marginRight:"20px",
        "@media (max-width: 640px)": {
            marginRight:"0",
            width:"100%",
        }
    },
    lt: {
        "& h5": {
            "& span": {
                padding: "0 5px",
            }
        }
    },
    desContent: {
        width: "100%",
        textAlign: "right",
        "@media (max-width: 640px)": {
            textAlign: "left",
        }
    },
    box:{
        width:"100%",
    },
    inputBox:{
        position: 'relative',
        marginBottom:"50px",
        width: "70%",
        "@media(max-width:640px)":{
            width:"100%",
        },
        "& input":{
            paddingRight:"100px",
        },
        "& span":{
            position:"absolute",
            right:"25px",
            top: '50%',
            transform: "translateY(-50%)",
            color: "#3F444480",
        }
    },
    inputContent:{
        position: 'relative',
    },
    boxContent:{
        display: "flex",
        alignItems: "center",
        "@media(max-width:640px)":{
            display:"block",
        },
        "& button":{
            margin:"0 0  20px 0",
            "@media(max-width:640px)":{
                margin:"auto",
            },
        }
    },
})