const serverURL = {
  login: "/api/v1/b2c/users/signin",
  resendEmailVerify: "/api/v1/b2c/users/resend-email-verify",
  register: "/api/v1/b2c/users/signup",
  userLogout: "/api/v1/b2c/user/signout",
  dashboard: "/api/v1/b2c/user/dashboard",
  planList: "/api/v1/b2c/plans/list",
  setPassword: "/api/v1/b2c/users/set-password",
  addCard: "/api/v1/b2c/user/cards",
  changePassword: "/api/v1/b2c/profile/change-password",
  updateProfile: "/api/v1/b2c/profile/details/",
  cardList: "/api/v1/b2c/user/cards/list/",
  deleteCard: "/api/v1/b2c/user/cards/",
  getOneTimeCredits: "/api/v1/b2c/one-time-credit/checkout",
  modesList: "/api/v1/b2c/modes/list",
  setMode: "/api/v1/b2c/users/set-mode",
  purchase: "/api/v1/b2c/plans/checkout",
  getAddressList: "/api/v1/b2c/user/address",
  // addAddress: "/api/v1/b2c/user/address",
  addAddress: "/api/v1/b2c/user/address-new",
  // updateAddress: "/api/v1/b2c/user/address/",
  updateAddress: "/api/v1/b2c/user/address-new/",
  deleteAddress: "/api/v1/b2c/user/address/",
  getProductList: "/api/v1/b2c/outlet/items/",
  setDefaultAddress: "/api/v1/b2c/user/address/set-default/",
  getProductDetail: "/api/v1/b2c/outlet-item-detail/",
  outletsList: "/api/v1/b2c/outlets",
  getOutletCategory: "/api/v1/b2c/outlet/categories/", //*(public menu)
  addProductToCart: "/api/v1/b2c/user/cart/add-item/",
  getUserCart: "/api/v1/b2c/user/cart",
  finalizeOrderCheckout: "/api/v1/b2c/user/cart/checkout",
  sendOtp: "/api/v1/b2c/profile/details/",
  verifyOtp: "/api/v1/b2c/profile/otp-verified",
  deleteProduct: "/api/v1/b2c/user/cart/remove-item/",
  viewOrder: "/api/v1/b2c/user/orders",
  orderDetail: "/api/v1/b2c/user/orders/",
  checkCoupon: "/api/v1/b2c/coupon/check",
  applyCoupon: "/api/v1/b2c/coupon/apply",
  verifyEmail: "/api/v1/verify-email",
  verifyMobile: "/api/v1/verify-mobile",
  emailVerify: "/api/v1/b2c/users/email-verify/",
  forgotPassword: "/api/v1/b2c/users/forgot-password",
  getMyWallet: "/api/v1/b2c/user/wallet",
  calDeliveryFee: "/api/v1/b2c/delivery-fee/calculate",
  userNotifications: "/api/v1/b2c/user/notifications",
  configurations: "/api/v1/configurations/b2c",
  finalizeOrderVerify: "/api/v1/b2c/user/cart/verify",
  editOrder: "/api/v1/b2c/user/orders/",
  socialLogin: "/api/v1/b2c/users/social-login",
  getSpendPolicy: "/api/v1/b2c/company/policy",
  getFavoritesList: "/api/v1/b2c/user/favorites",
  toggleFavorites: "/api/v1/b2c/user/toggle-fav",
  getUserOutlet: "/api/v1/b2c/users/items/",
  checkPostalCode: "/api/v1/b2c/postal-code/check",
  billingHistory: "api/v1/b2c/user/billings",
  creditTransactions: "api/v1/b2c/user/transactions",
  resendOtp: "/api/v1/b2c/profile/resend-otp",
  getCategories: "/api/v1/b2c/categories/", //?Categories listing after login
  getOutlet: "/api/v1/b2c/outlet",
  getOutletid:"/api/v1/b2c/fetch-outlets/",
  getFutureSchedule: "/api/v1/b2c/order/future-schedule",
  changePlan: "/api/v1/b2c/plans/change",
  cancelOrder: "api/v1/b2c/user/orders/cancel/",
  readNotifications: "/api/v1/b2c/user/notifications/read",
  getUserProfile: "/api/v1/b2c/profile/details",
  cancelPlan: "/api/v1/b2c/plans/cancel",
  cardsDefault: "/api/v1/b2c/user/cards/default",
  viewRecurringOrder: "/api/v1/b2c/user/orders/recurring/",
  // viewMyOrder: "/api/v1/b2c/user/myorders",
  viewMyOrder: "/api/v1/b2c/user/myorders-new",
  removeCoupon: "/api/v1/b2c/coupon/remove",
  validateCheckout: "/api/v1/b2c/user/cart/validate-checkout",
  orderRating: "/api/v1/b2c/user/order-review/",
  ordernumber:"/api/v1/b2c/user/orders/recurring/active/",
  reOrders: "/api/v1/b2c/user/re-orders/",
  cartClear: "/api/v1/b2c/user/cart-clear",
  getcartdetails:"/api/v1/b2c/user/cart/item/",
  updatededitcard:"/api/v1/b2c/user/cart/update-item",
};
export default serverURL;
