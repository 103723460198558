export const Styles = (Theme) => ({
  cardContainer: {
    background: "#fff",
    borderRadius: "10px",
    // cursor: "pointer",
    "@media(max-width:640px)": {
      background: "transparent",
    },
  },
  arrowcenterdiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media(max-width:640px)": {
      display: "flex !important",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  ltBox: {
    paddingRight: "10px",
  },
  arrowcenter: {
    alignItems: "center !important",
    justifyContent: "space-between",
  },
  viewbold: {
    fontWeight: "800",
    cursor: "pointer",
    textDecoration: "underline",
  },
  rtBox: {
    // marginLeft:"15px",
    "& p": {
      // marginBottom: "5px",
      marginBottom: "15px",

      lineHeight: "18px",
    },
  },
  daysbox: {
    marginBottom: "20px",
  },
  daysbold: {
    fontWeight: "bold",
  },
  card: {
    position: "relative",
    background: "#FFFFFF",
    borderRadius: "10px",
    borderBottom: "1px solid #ddd",
    width: "100%",
    padding: "20px 20px 20px",
    marginBottom: "10px",
    // cursor: "pointer",
    "@media(max-width:640px)": {
      display: "block",
      marginBottom: "20px",
      borderBottom: "0",
      padding: "20px 20px",
    },
    "& ul": {
      margin: "0",
      paddingLeft: "5px",
      "& li": {
        display: "flex",
        alignItems: "flex-start",
        marginBottom: "5px",
      },
    },
  },
  notActive: {
    background: "#3F44441A",
    borderRadius: "10px 10px 10px 10px",
    "@media(max-width:640px)": {
      borderRadius: "10px",
    },
    "& h4": {
      opacity: ".5",
    },
    "& ul": {
      opacity: ".5",
    },
  },
  bookmark: {
    padding: "10px 15px",
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: "400",
    fontFamily: "'Bebas Neue', serif",
    textDecoration: "none",
    background: "#FFC200",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "32px 0px 0px 32px",
    position: "absolute",
    right: "0",
    top: "15px",
    minWidth: "120px",
    width: "auto",
    textAlign: "center",
    "&:hover": {
      color: "#3F4444 !important",
    },
  },
  mb10: {
    marginBottom: "15px",
    // minHeight: "35px",
  },
  outletsLeftBox: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
  },
  outletsRightBox: {
    width: "20%",
    paddingLeft: "10px",
  },
  imgContent: {
    textAlign: "right",
    // cursor: "pointer",
  },
  imgPointer: {
    cursor: "pointer",
  },
});
