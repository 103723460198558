export const Styles = Theme => ({
    outerBox: {
        display: "flex",
        alignItems: "center",
        height: "calc(100vh - 75px)",
        "@media(max-width:991px)": {
            display: "table",
            margin: "0  0 20px",
            height: "auto",
            width: "100%",
        },
        "& > :nth-child(1)": {
            width: "30%",
            marginRight: "10px",
            "@media(max-width:991px)": {
                width: "100%",
                marginRight: "0",
            },
        },
        "& > :nth-child(2)": {
            width: "70%",
            borderRadius: "10px",
            maxHeight: "calc(100vh - 150px)",
            overflowY: "auto",
            background: "#fff",
            padding: "40px 25px 40px 40px",
            "@media(max-width:991px)": {
                width: "100%",
                maxHeight: "100%",
                overflowY: "inherit",
                margin: "15px 0 0",
                display: "table",
            },
            "@media(max-width:640px)": {
                background: "transparent",
                padding: "0",
            },
            "&::-webkit-scrollbar": {
                appearance: "none",
            },
            "&::-moz-scrollbar": {
                appearance: "none",
            },
            "&::-o-scrollbar": {
                appearance: "none",
            },
            "&::-ms-scrollbar": {
                appearance: "none",
            },
        },
    },
})

