export const Styles = (Theme) => ({
  fromControl: {
    margin: "20px 0 25px",
  },
  btnBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "@media(max-width:640px)": {
      display: "block",
    },
    "& :nth-child(2)": {
      marginLeft: "10px",
      "@media(max-width:640px)": {
        margin: "auto",
      },
    },
    "& > div": {
      "@media(max-width:640px)": {
        float: "unset",
        margin: "auto",
      },
    },
  },
  mobileview: {
    "@media(max-width:600px)": {
      display: "flex !important",
      flexDirection: "column-reverse"
    }
  },
  imgBox: {
    maxHeight: "260px",
    maxWidth: "260px",
    margin: "auto",
    objectFit: "cover",
    marginBottom: "15px",
    "@media(max-width:640px)": {
      margin: "auto",
    },
    "& img": {
      height: "auto",
      width: "100%",
    },
  },
  orderInfo: {
    textAlign: "center",
    "@media(max-width:640px)": {
      // padding: "15px",
      // textAlign: "center",
      // borderRadius: "10px",
      // margin: "30px 0",
    },
  },
  content: {
    display: "block",
    marginBottom: "15px",
    "& h4": {
      marginBottom: "0",
      paddingLeft: "10px",
      textAlign: "center",
      "@media(max-width:640px)": {
        paddingLeft: "0",
        margin: "15px 0",
      },
    },
  },
  orderFlex: {
    "@media(max-width:640px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 150px)",
    },
  },
  imageContent: {
    margin: "20px 0",
    maxWidth: "100%",
    display: "flex",
    "& a": {
      textDecoration: "none",
      maxWidth: "300px",
      height: "auto",
      objectFit: "cover",
      width: "100%",
      margin: "auto",
      "@media(max-width:640px)": {
        maxWidth: "100%",
      },
    },
    "& img": {
      height: "auto",
      width: "100%",
    },
  },
});
