import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import FormLabel from "@mui/material/FormLabel";
import StrConstant from "../../../Common/StrConstant";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  userUpdateProfile,
  getUserProfileAction,
} from "../../../redux/actions/UserActions";
import CustomDatePicker from "../../../Components/CustomDatePicker/CustomDatePicker";
import { validatePhone } from "../../../Common/Validations";
import OrderStatus from "../../../Components/OrderStatus/OrderStatus";
import AlterMessage from "../../../Components/AlertMessage/AlertMessage";
import { ActionType } from "../../../redux/ActionTypes";
import { modeLinkHandler, supplyDateFormat } from "../../../Common/utils/utils";
import Loader from "react-spinner-loader";
// import MultiDatePicker from "../../../Components/MultiDatePicker/MultiDatePicker";
const fields = {
  first_name: "",
  last_name: "",
  date_of_birth: "",
  contact_number: "",
  gender: "",
  inValid: false,
};
const Profile = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { verifyOtp } = useSelector((state) => state?.verifyOtp);
  const { userInfo } = useSelector((state) => state?.userSignin); //!updated
  const { error, updateUser } = useSelector((state) => state?.updateProfile);
  const { loading, userProfile } = useSelector(
    (state) => state?.getUserProfile
  );
  const userData = userInfo?.data?.user_data;
  // const setDefaultMode = localStorage.getItem("selected_modes");
  const [isError, setIsError] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [formFields, setFormFields] = useState(fields);

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setFormFields((pre) => ({ ...pre, [name]: value }));
  };
  const updateHandler = () => {
    if (
      formFields.first_name === "" ||
      formFields.last_name === "" ||
      validatePhone(formFields.contact_number)
    )
      return setFormFields((pre) => ({ ...pre, inValid: true }));
    else {
      // const payload = {
      //   first_name: formFields.first_name,
      //   last_name: formFields.last_name,
      //   date_of_birth: formFields.date_of_birth,
      //   phone_number:
      //     userData.contact_number === formFields.contact_number
      //       ? null
      //       : formFields.contact_number,
      //   gender: formFields.gender,
      // };
      // console.log("formFields.contact_number", formFields.contact_number);
      // console.log(
      //   "userProfile?.contact_number",
      //   userProfile?.data?.contact_number
      // );
      // if (formFields.contact_number === userProfile?.data?.contact_number) {
      //   dispatch(
      //     userUpdateProfile(payload, userData?.user_id),
      //     setIsUpdated(true)
      //   );
      // } else {
      //   console.log("navigate");
      //   localStorage.setItem("updateProfilePayload", JSON.stringify(payload));
      //   // navigate("/verify-otp");
      // }
      const payload = {
        first_name: formFields.first_name,
        last_name: formFields.last_name,
        date_of_birth: formFields.date_of_birth,
        phone_number:
          userData.contact_number === formFields.contact_number
            ? null
            : formFields.contact_number,
        gender: formFields.gender,
      };
      dispatch(
        userUpdateProfile(payload, userData?.user_id),
        setIsUpdated(true),
        setIsError(true)
      );
    }
  };
  const bindingHandler = (date) => {
    setFormFields((pre) => ({
      ...pre,
      date_of_birth: supplyDateFormat(date[""]),
    }));
  };

  useEffect(() => {
    if (verifyOtp?.message) setIsVerified(true);
  }, [verifyOtp?.message]);

  useEffect(() => {
    // console.log("userProfile", userProfile);
    if (userProfile?.success) {
      setFormFields(userProfile?.data);
    }
  }, [userProfile?.data]);

  useEffect(() => {
    // userProfile?.success
    dispatch(getUserProfileAction());
  }, [updateUser]);

  useEffect(() => {
    localStorage.removeItem("updateProfilePayload");
    dispatch(getUserProfileAction());
  }, []);

  const handleKeyDown = (event) => {
    if (event.keyCode === 69) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (userProfile?.data?.contact_number == formFields?.contact_number) return;
    else {
      if (typeof userProfile?.data?.contact_number !== "undefined") {
        localStorage.setItem("numberToVerify", formFields?.contact_number);
        if (updateUser?.success) return navigate("/verify-otp");
      }
    }
  }, [updateUser]);

  // console.log("userProfile", userProfile?.data?.is_mobile_verified);

  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {updateUser?.success && isUpdated && (
        <AlterMessage
          closeHandler={() =>
            dispatch({ type: ActionType.CLEAR_RESPONSE }, setIsUpdated(false))
          }
          description={updateUser?.message}
          title={"Success"}
          type={"Success"}
          open={isUpdated}
          url={"./images/success.svg"}
        />
      )}
      {verifyOtp?.message && isVerified && (
        <AlterMessage
          closeHandler={() =>
            dispatch({ type: "CLEAR_OTP" }, setIsVerified(false))
          }
          description={verifyOtp?.message}
          title="Success"
          type="Success"
          open={isVerified}
          url="./images/success.svg"
        />
      )}
      {error && (
        <AlterMessage
          closeHandler={() =>
            dispatch({ type: ActionType.CLEAR_RESPONSE }, setIsError(false))
          }
          url="./images/error.svg"
          description={error}
          open={isError}
          title="Error"
          type="Error"
        />
      )}
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <h3>{StrConstant.Profile.heading}</h3>
        </div>
        <div>
          <div className={classes.fromControl}>
            <FormLabel>
              Name<span className="req">*</span>
            </FormLabel>
            <div className={classes.dFlex}>
              <div>
                <input
                  type="text"
                  name="first_name"
                  defaultValue={formFields.first_name}
                  value={formFields.first_name}
                  placeholder="First name"
                  onChange={(e) => inputHandler(e)}
                />
                {formFields.inValid && formFields.first_name === "" ? (
                  <p className="errorMessage">This field is required.</p>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last name"
                  defaultValue={formFields.last_name}
                  value={formFields.last_name}
                  onChange={(e) => {
                    inputHandler(e);
                  }}
                />
                {formFields.inValid && formFields.last_name === "" ? (
                  <p className="errorMessage">This field is required.</p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className={classes.dFlex}>
            <div className={classes.fromControl}>
              <FormLabel>Gender</FormLabel>
              <Select
                displayEmpty
                name="gender"
                value={formFields?.gender}
                className={classes.dropdownBox}
                onChange={(e) => inputHandler(e)}
              >
                <MenuItem value={""}>Select</MenuItem>
                <MenuItem
                  value={
                    formFields?.gender === "male" ? formFields?.gender : "male"
                  }
                >
                  Male
                </MenuItem>
                <MenuItem
                  value={
                    formFields?.gender === "female"
                      ? formFields?.gender
                      : "female"
                  }
                >
                  {" "}
                  Female
                </MenuItem>
                <MenuItem
                  value={
                    formFields?.gender === "Non-binary"
                      ? formFields?.gender
                      : "Non-binary"
                  }
                >
                  {" "}
                  Non-binary
                </MenuItem>
                <MenuItem
                  value={
                    formFields?.gender === "Prefer not to disclose"
                      ? formFields?.gender
                      : "Prefer not to disclose"
                  }
                >
                  {" "}
                  Prefer not to disclose
                </MenuItem>
              </Select>
              {/* {formFields.inValid && formFields.gender === "" ? (
                  <p className="errorMessage">Gender required</p>
                ) : (
                  <div></div>
                )} */}
            </div>
            <div className={classes.fromControl}>
              <FormLabel>Birthday</FormLabel>
              <div className={classes.datepickerBox}>
                <CustomDatePicker
                  onBinding={bindingHandler}
                  allowedDays={null}
                  isFutureDisable={true}
                  dateValue={formFields?.date_of_birth}
                  isBlank={formFields?.date_of_birth != "" ? false : true}
                  // maxDate={new Date()}
                />
                {/* <MultiDatePicker onBinding={bindingHandler} /> */}
              </div>
            </div>
          </div>
          <div className={`${classes.dFlex} ${classes.wrap}`}>
            <div className={classes.fromControl}>
              <FormLabel>
                Phone Number <span className="req">*</span>
              </FormLabel>
              <div className={classes.numberField}>
                <input
                  type="number"
                  name="contact_number"
                  placeholder="Enter Phone Number"
                  defaultValue={formFields.contact_number}
                  value={formFields.contact_number}
                  onChange={(e) => inputHandler(e)}
                  onKeyDown={handleKeyDown}
                  onInput={(e) => {
                    if (e?.target?.value != "") {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 8);
                    }
                  }}
                />
                {formFields.contact_number !== ""
                  ? userProfile?.data?.is_mobile_verified === 1 &&
                    userProfile?.data?.contact_number ==
                      formFields?.contact_number && (
                      <img src="./images/success.svg" alt="Better Coffee" />
                    )
                  : null}
                {formFields.inValid && formFields.contact_number === "" ? (
                  <p className="errorMessage">This field is required.</p>
                ) : (
                  <div></div>
                )}
                {formFields.contact_number !== "" &&
                validatePhone(formFields.contact_number) ? (
                  <p className="errorMessage">
                    Information entered is invalid. Please try again.{" "}
                  </p>
                ) : null}
              </div>
            </div>
            <div className={classes.fromControl}>
              <FormLabel>Email</FormLabel>
              <div className={classes.inputBox}>
                <input defaultValue={formFields.email} placeholder="Email" />
              </div>
            </div>
          </div>
          <div className={classes.btnBox}>
            <OrderStatus
              // link={modeLinkHandler(setDefaultMode)}
              link={"/choose-mode"}
              text={"Place order"}
            />
            {/* {updateUser?.success && !isUpdated && !isVerified ? (
                <div className={classes.successMessage}>
                  <img src="./images/success.svg" alt="Better Coffee" />
                  <h6>Updated successfully</h6>
                </div>
              ) : (
                <div></div>
              )} */}
            <YellowBtn
              name={"UPDATE PROFILE"}
              clickHandler={() => updateHandler()}
            />
          </div>
        </div>
      </OuterBox>
    </>
  );
};
export default withStyles(Styles)(Profile);
