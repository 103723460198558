import { ActionType } from "../../ActionTypes";

export const dashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_DASHBOARD_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_DASHBOARD_DETAILS_SUCCESS:
      return { ...state, loading: false, dashboardDetail: action.payload };
    case ActionType.GET_DASHBOARD_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const configurationReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_CONFIGURATION_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_CONFIGURATION_SUCCESS:
      return { ...state, loading: false, configuration: action.payload };
    case ActionType.GET_CONFIGURATION_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
