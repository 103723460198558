import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate, useParams } from "react-router";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import FormLabel from "@mui/material/FormLabel";
import StrConstant from "../../../Common/StrConstant";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import OrderStatus from "../../../Components/OrderStatus/OrderStatus";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CustomCheckbox from "../../../Components/CustomCheckbox/CustomCheckbox";
import DarkGrayBtn from "../../../Components/DarkGrayBtn/DarkGrayBtn";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-spinner-loader";
import {
  validatePostalCode,
  validateRequired,
  validateUnitNumber,
} from "../../../Common/Validations";
import {
  addAddressDetail,
  checkPostalCodeAction,
  getAddressDetailList,
  updateAddressDetail,
} from "../../../redux/actions/userAddress/UserAddressActions";
import { ActionType } from "../../../redux/ActionTypes";
import { modeLinkHandler } from "../../../Common/utils/utils";
import axios from "axios";
const fields = {
  address_line1: "",
  address_line2: "",
  address_tag: "",
  unit_number: "",
  custom_address_tag: "",
  postal_code: "",
  is_default: false,
  inValid: false,
};
const AddAddresses = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const addressId = localStorage.getItem("addressId");
  const setDefaultMode = localStorage.getItem("selected_modes");
  const Editadress = JSON.parse(localStorage.getItem("EditAddress"));
  const { id } = useParams();
  // console.log("Editaddress",Editadress);
  const addressData = useSelector((state) => state?.userAddressList);
  const selected = addressData?.userInfo?.data?.address?.find((address) =>
    address?.address_id == addressId ? address : null
  );
  const { deliveryFee } = useSelector((state) => state?.checkPostalCode);
  console.log("deleiveryfree", deliveryFee);
  const updatedData = useSelector((state) => state?.updateUserAddress);
  const addaddress = useSelector((state) => state?.addAddress);
  // const [formFields, setFormFields] = useState(addressId ? selected : fields);
  const [formFields, setFormFields] = useState(id ? Editadress : fields);
  const linkClickHandler = () => {
    localStorage.removeItem("addressId");
    dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1));
  };
  const [popupon, setpopupon] = useState(false);
  const [addpopupon, setaddpopupon] = useState(false);
  useEffect(() => {
    setpopupon(false);
    if (updatedData?.error) {
      console.log("updateData", updatedData);
      setShowModel(true);
      setpopupon(true);
    } else if (updatedData?.userInfo?.success == true) {
      console.log("updateData", updatedData);
      navigate(-1);
    }
  }, [updatedData]);
  useEffect(() => {
    setaddpopupon(false);
    console.log("address", addaddress);
    if (!addaddress?.address) {
      if (addaddress?.error) {
        setShowModel(true);
        setaddpopupon(true);
      }
    } else if (addaddress?.address?.success == true) {
      console.log("hyhyhy");
      navigate(-1);
    }
  }, [addaddress]);

  // const [langitude,setlangitude]=useState('')
  // const [latitude,setlatitude]=useState('')
  const langlong = async (pincode) => {
    //   try{
    //   const maplanglong= await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${pincode}&key=AIzaSyAzl5_EjfecUj_cYcxnxjs5lXswxQb5pGQ`)
    //   // console.log("langlong",maplanglong.data.results[0].geometry.location.lat);
    //   // setFormFields((pre) => ({ ...pre, ["latitude"]: maplanglong.data.results[0].geometry.location.lat }));
    //   // setFormFields((pre) => ({ ...pre, ["longitude"]: maplanglong.data.results[0].geometry.location.lng }));
    // }
    //   catch(e){
    //     console.log(e)
    //   }
  };
  //   useEffect(()=>{
  // langlong()
  //   },[])
  // console.log("formFields",selected);
  const AddAddress = () => {
    setFormFields((pre) => ({ ...pre, inValid: false }));
    if (
      validateRequired(formFields.address_line1) ||
      validatePostalCode(formFields.postal_code) ||
      validateRequired(formFields.address_tag) ||
      (formFields.address_tag === "Custom" &&
        formFields.custom_address_tag === "")
    )
      return setFormFields((pre) => ({ ...pre, inValid: true }));
    else if (
      formFields.unit_number !== "" &&
      validateUnitNumber(formFields.unit_number)
    )
      return setFormFields((pre) => ({ ...pre, inValid: true }));
    else {
      dispatch(
        checkPostalCodeAction(
          { postal_code: formFields.postal_code },
          setIsSubmit(true)
        )
      );
    }
  };

  const handleKeyDown = (event) => {
    // if (
    //   event.keyCode === 69 ||
    // ) {
    //   event.preventDefault();
    // }

    if (
   (event.key>=0 && event.key<=9)||(event.keyCode>=37 &&event.keyCode<=40)||
    (event.keyCode>=8 &&event.keyCode<=10)
    ) {

    } else {
      event.preventDefault();
    }
  };

  const addHandler = () => {
    // console.log("for y is_deliverable:", deliveryFee?.data?.is_deliverable);
    if (deliveryFee?.data?.is_deliverable == 1 && isSubmit)
      return addressId
        ? dispatch(
            updateAddressDetail(addressId, formFields),
            // localStorage.removeItem("addressId"),
            setShowModel(true)
            // navigate(-1)
          )
        : dispatch(
            addAddressDetail(formFields),
            setShowModel(true)
            // navigate(-1)
          );
    else if (deliveryFee?.data?.is_deliverable == 0) return setShowModel(true);
  };
  const inputHandler = (e) => {
    const { name, value } = e.target;
    // console.log("postalcode", value);
    // console.log("postalcode",value);
    setFormFields((pre) => ({ ...pre, [name]: value }));
  };
  const checkBoxHandler = (check, name) => {
    setFormFields((pre) => ({ ...pre, [name]: check }));
  };
  const showModelPopupHandler = () => {
    localStorage.removeItem("addressId");
    // setFormFields(addressId ? selected : fields);
    setFormFields(fields);
    setShowModel(false);
  };
  useEffect(() => {
    dispatch(getAddressDetailList());
    dispatch({ type: ActionType.CLEAR_RESPONSE });
  }, []);
  useEffect(() => {
    addHandler();
  }, [deliveryFee]);

  return (
    <>
      {addressData?.loading && (
        <Loader
          show={addressData?.loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <OuterBox>
        <div>
          <BackArrow clickHandler={linkClickHandler} />
          <h3>
            {addressId ? "Edit Addresses" : StrConstant.AddAddresses.heading}
          </h3>
        </div>
        <div>
          <div className={classes.fromControl}>
            <FormLabel>
              Address Line 1<span className="req">*</span>
            </FormLabel>
            <input
              type="text"
              name="address_line1"
              value={formFields?.address_line1}
              placeholder="Enter address line 1"
              onChange={(e) => inputHandler(e)}
            />
            {formFields?.inValid && formFields.address_line1 === "" ? (
              <p className="errorMessage">This field is required.</p>
            ) : (
              <div></div>
            )}
          </div>
          <div className={classes.fromControl}>
            <FormLabel>Address Line 2</FormLabel>
            <input
              type="text"
              name="address_line2"
              value={formFields?.address_line2}
              placeholder="Enter address line 2 (optional)"
              onChange={(e) => inputHandler(e)}
            />
          </div>
          <div className={classes.dFlex}>
            <div className={classes.fromControl}>
              <FormLabel>Unit Number</FormLabel>
              <input
                type={"tel"}
                name="unit_number"
                value={formFields?.unit_number}
                placeholder="Enter unit number"
                onChange={(e) => inputHandler(e)}
              />
              {validateUnitNumber(formFields.unit_number) &&
              formFields.unit_number !== "" ? (
                <p className="errorMessage">Unit number invalid</p>
              ) : (
                <div></div>
              )}
            </div>
            <div className={classes.fromControl}>
              <FormLabel>
                Postal Code<span className="req">*</span>
              </FormLabel>
              <input
                // type="number"
                name="postal_code"
                value={formFields?.postal_code}
                placeholder="Enter postal code"
                onChange={(e) => {
                  if (e.target.value.length > 6) {
                    return false;
                  } else {
                    if (e.target.value.length == 6) {
                      langlong(e.target.value);
                    }

                    inputHandler(e);
                  }
                }}
                onKeyDown={handleKeyDown}
      //  onKeyPress={handleKeyDown}
                // onInput={(e) => {
                //   e.target.value = Math.max(0, parseInt(e.target.value))
                //     .toString()
                //     .slice(0, 6);
                // }}
              />
              {formFields.inValid && formFields.postal_code === "" ? (
                <p className="errorMessage">This field is required.</p>
              ) : (
                <div></div>
              )}
              {formFields?.postal_code !== "" &&
              validatePostalCode(formFields?.postal_code) ? (
                <p className="errorMessage">
                  Information entered is invalid. Please try again.
                </p>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <div className={classes.fromControl}>
            <FormLabel>
              Address Name<span className="req">*</span>
            </FormLabel>
            <Select
              className={classes.dropdownBox}
              name="address_tag"
              value={
                formFields?.address_tag == ""
                  ? "Enter address tag"
                  : formFields?.address_tag
              }
              onChange={(e) => inputHandler(e)}
              renderValue={(selected) => {
                if (selected === "") {
                  return <span>Enter Address</span>;
                }

                return selected;
              }}
            >
              <MenuItem disabled value="">
                <span>Address Name</span>
              </MenuItem>
              <MenuItem value="Home">Home</MenuItem>
              <MenuItem value="Office">Office</MenuItem>
              <MenuItem value="Custom">Custom</MenuItem>
            </Select>
            {formFields.inValid && formFields.address_tag === "" ? (
              <p className="errorMessage">This field is required.</p>
            ) : (
              <></>
            )}
          </div>
          {formFields?.address_tag === "Custom" && (
            <div className={classes.fromControl}>
              <FormLabel>
                Enter Address Name<span className="req">*</span>
              </FormLabel>
              <input
                type="text"
                name="custom_address_tag"
                value={formFields?.custom_address_tag}
                placeholder="Enter address name"
                onChange={(e) => inputHandler(e)}
              />
              {formFields.inValid &&
              formFields.address_tag === "Custom" &&
              formFields.custom_address_tag === "" ? (
                <p className="errorMessage">This field is required.</p>
              ) : (
                <></>
              )}
            </div>
          )}
          <div className={classes.fromControl}>
            <label className={classes.textCheckbox}>
              <CustomCheckbox
                onChange={checkBoxHandler}
                isDefaultSelected={formFields?.is_default}
                name={"is_default"}
              />
              <p>Set as default address</p>{" "}
            </label>
          </div>
          <OrderStatus
            // link={modeLinkHandler(setDefaultMode)}
            link={"/choose-mode"}
            text={"Place order"}
          />
          <div className={classes.btnBox}>
            <DarkGrayBtn
              name={"RESET"}
              clickHandler={() => setFormFields(fields)}
            />
            <YellowBtn
              name={addressId ? "Update Address" : "CONFIRM"}
              clickHandler={() => AddAddress()}
            />
          </div>
        </div>
      </OuterBox>
      {deliveryFee?.data?.is_deliverable === 0 && fields !== "" && (
        <CustomModal
          open={showModel}
          title={"your address is not within delivery zone "}
        >
          <div className={`${classes.box} ${classes.modalContent}`}>
            <div className={classes.modalFlex}>
              <DarkGrayBtn
                name={"Add new address"}
                clickHandler={() => showModelPopupHandler()}
              />
              <YellowBtn
                name={"Edit address"}
                clickHandler={() => setShowModel(false)}
              />
            </div>
          </div>
        </CustomModal>
      )}
      {popupon && (
        <CustomModal
          open={showModel}
          title={"your address is not within delivery zone "}
        >
          <div className={`${classes.box} ${classes.modalContent}`}>
            <div className={classes.modalFlex}>
              <DarkGrayBtn
                name={"Add new address"}
                clickHandler={() => showModelPopupHandler()}
              />
              <YellowBtn
                name={"Edit address"}
                clickHandler={() => setShowModel(false)}
              />
            </div>
          </div>
        </CustomModal>
      )}
      {addpopupon && (
        <CustomModal
          open={showModel}
          title={"your address is not within delivery zone "}
        >
          <div className={`${classes.box} ${classes.modalContent}`}>
            <div className={classes.modalFlex}>
              <DarkGrayBtn
                name={"Add new address"}
                clickHandler={() => showModelPopupHandler()}
              />
              <YellowBtn
                name={"Edit address"}
                clickHandler={() => setShowModel(false)}
              />
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};
export default withStyles(Styles)(AddAddresses);
