export const Styles = Theme => ({
    fromControl: {
        margin: "20px 0 25px",
    },
    orderNumber: {
        color: "#333539",
        fontSize: "35px",
        fontWeight: "400",
        lineHeight: "35px",
    },
    datepickerContent: {
        "& input": {
            border: "1px solid #ddd !important",
            "@media(max-width:640px)": {
                border: "none !important",
            },
        },
    },
    descriptionBox: {
        margin:"30px 0 0",
        "& p": {
            textAlign: "center",
            "@media(max-width:768px)": {
                textAlign: "left",
            }
        },
    },
    btnBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
        "@media(max-width:640px)": {
            display: "flex",
            flexDirection: 'column-reverse',
        },
        "& :nth-child(2)": {
            marginLeft: "10px",
            "@media(max-width:640px)": {
                margin: "auto",
            }
        },
        "& > div": {
            "@media(max-width:640px)": {
                float: "unset",
                margin: "auto",
            }
        },
    },
})