import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import Loader from "react-spinner-loader";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import FormLabel from "@mui/material/FormLabel";
import StrConstant from "../../../Common/StrConstant";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "../../../redux/ActionTypes";
import Propover from "../../../Components/Propover/Propover";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import { modeLinkHandler } from "../../../Common/utils/utils";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import OrderStatus from "../../../Components/OrderStatus/OrderStatus";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import { userChangePassword } from "../../../redux/actions/UserActions";
import AlterMessage from "../../../Components/AlertMessage/AlertMessage";
import {
  validateConfirmPassword,
  validatePassword,
} from "../../../Common/Validations";
const fields = {
  current_password: "",
  new_password: "",
  confirm_password: "",
  inValid: false,
};
const ForgotPassword = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [formFields, setFormFields] = useState(fields);
  const setDefaultMode = localStorage.getItem("selected_modes");
  const [showCurrentPassword, setCurrentShowPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { loading, changePassword, error } = useSelector(
    (state) => state?.changePassword
  );
  const inputHandler = (e) => {
    const { name, value } = e.target;
    setFormFields((pre) => ({ ...pre, [name]: value }));
  };
  const saveChangesHandler = (e) => {
    e.preventDefault();
    if (
      validatePassword(formFields.current_password) ||
      validatePassword(formFields.new_password) ||
      validatePassword(formFields.confirm_password) ||
      validateConfirmPassword(
        formFields.new_password,
        formFields.confirm_password
      )
    )
      return setFormFields((pre) => ({ ...pre, inValid: true }));
    else {
      const payload = {
        current_password: formFields.current_password,
        new_password: formFields.new_password,
        confirm_password: formFields.confirm_password,
      };
      dispatch(userChangePassword(payload), setIsOpen(true));
    }
  };
  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    setFormFields((pre) => ({ ...pre, inValid: false }));
    setIsOpen(false);
    setShowModel(false);
  };
  // useEffect(() => {
  //   if (changePassword?.success) return setShowModel(true);
  // }, [changePassword]);
  useEffect(() => {
    setShowModel(false);
  }, []);
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {error && (
        <AlterMessage
          closeHandler={snackHandler}
          url={"./images/error.svg"}
          description={error}
          title={"Error"}
          type={"Error"}
          open={isOpen}
        />
      )}
      {changePassword?.success && (
        <AlterMessage
          closeHandler={snackHandler}
          url={"./images/success.svg"}
          description={"Your password has been updated successfully."}
          title={"Success"}
          type={"Success"}
          open={isOpen}
        />
      )}
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() => {
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1));
            }}
          />
          <h3>{StrConstant.ForgotPassword.heading}</h3>
        </div>
        <div>
          <div className={classes.fromControl}>
            <FormLabel>
              Enter current password <span className="req">*</span>
            </FormLabel>
            <div className={classes.passwordField}>
              <input
                type={showCurrentPassword ? "text" : "password"}
                onChange={(e) => inputHandler(e)}
                value={formFields.current_password}
                name="current_password"
                placeholder="Enter your current password"
              />
              <span
                onClick={() => setCurrentShowPassword(!showCurrentPassword)}
              >
                {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
              </span>
            </div>
            {formFields.inValid && formFields.current_password === "" ? (
              <p className="errorMessage">This field is required.</p>
            ) : (
              <div></div>
            )}
            {formFields.current_password !== "" &&
            validatePassword(formFields.current_password) ? (
              <p className="errorMessage">
                Your password must be at least 8 characters, with at least one number, one special character, and have a mixture of uppercase and lowercase letters.{" "}
              </p>
            ) : formFields.current_password !== "" ? (
              // <img src="./images/csuccess.svg" alt="Better Coffee" />
              <></>
            ) : null}
          </div>
          <div className={classes.fromControl}>
            <FormLabel className={classes.passLabel}>
              Enter new password <span className="req">*</span>
              <Propover>
                <b>{StrConstant.SignUp.propoverTitle}</b>
                <ul>
                  <li>
                    <span>{StrConstant.SignUp.propoverDescription1}</span>
                  </li>
                  <li>
                    <span>{StrConstant.SignUp.propoverDescription2}</span>
                  </li>
                  <li>
                    <span>{StrConstant.SignUp.propoverDescription3}</span>
                  </li>
                  <li>
                    <span>{StrConstant.SignUp.propoverDescription4}</span>
                  </li>
                </ul>
              </Propover>
            </FormLabel>
            <div className={classes.passwordField}>
              <input
                // type="password"
                onChange={(e) => inputHandler(e)}
                value={formFields.new_password}
                type={showPassword ? "text" : "password"}
                name="new_password"
                placeholder="Enter your new password"
              />
              <span onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </span>
            </div>

            {formFields.inValid && formFields.new_password === "" ? (
              <p className="errorMessage">This field is required.</p>
            ) : (
              <div></div>
            )}
            {formFields.new_password !== "" &&
            validatePassword(formFields.new_password) ? (
              <p className="errorMessage">
                Your password must be at least 8 characters, with at least one number, one special character, and have a mixture of uppercase and lowercase letters.{" "}
              </p>
            ) : null}
          </div>
          <div className={classes.fromControl}>
            <FormLabel className={classes.passLabel}>
              Re-Enter New Password<span className="req">*</span>
              <Propover>
                <b>{StrConstant.SignUp.propoverTitle}</b>
                <ul>
                  <li>
                    <span>{StrConstant.SignUp.propoverDescription1}</span>
                  </li>
                  <li>
                    <span>{StrConstant.SignUp.propoverDescription2}</span>
                  </li>
                  <li>
                    <span>{StrConstant.SignUp.propoverDescription3}</span>
                  </li>
                  <li>
                    <span>{StrConstant.SignUp.propoverDescription4}</span>
                  </li>
                </ul>
              </Propover>
            </FormLabel>
            <div className={classes.passwordField}>
              <input
                type={showConfirmPassword ? "text" : "password"}
                onChange={(e) => inputHandler(e)}
                value={formFields.confirm_password}
                name="confirm_password"
                placeholder="Re-enter your new password"
              />
              <span
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </span>
            </div>
            {formFields.inValid && formFields.confirm_password === "" ? (
              <p className="errorMessage">This field is required.</p>
            ) : (
              <div></div>
            )}
            {formFields.confirm_password !== "" &&
            validateConfirmPassword(
              formFields.new_password,
              formFields.confirm_password
            ) ? (
              <p className="errorMessage">
                The passwords do not match. Please try again.
              </p>
            ) : null}
          </div>
          <OrderStatus
            // link={modeLinkHandler(setDefaultMode)}
            link={"/choose-mode"}
            text={"Place order"}
          />
          <div className={classes.btnBox}>
            {/* <Link to="/forgot-password">Forgot your password?</Link> */}
            <YellowBtn
              name={"CONFIRM"}
              clickHandler={saveChangesHandler}
            />
          </div>
        </div>
      </OuterBox>
      {/* <CustomModal
        open={showModel}
        title={changePassword?.message}
        modalClose={snackHandler}
      >
        <div className={`${classes.box} ${classes.modalContent}`}>
          <div className={classes.modalFlex}>
            <YellowBtn
              name={"Back to My Account"}
              clickHandler={() => navigate("/my-account")}
            />
          </div>
        </div>
      </CustomModal> */}
    </>
  );
};
export default withStyles(Styles)(ForgotPassword);
