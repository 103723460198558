import React, { Fragment, useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate, useParams } from "react-router";
import DarkGrayBtn from "../../../Components/DarkGrayBtn/DarkGrayBtn";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import StrConstant from "../../../Common/StrConstant";
import { Link } from "react-router-dom";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetailAction, ordernumber } from "../../../redux/actions/order/orderActions";
import { ActionType } from "../../../redux/ActionTypes";
import Loader from "react-spinner-loader";
import moment from "moment";
import CommonRadio from "../../../Components/CommonRadio/CommonRadio";
import { Container } from "@mui/system";
import { reOrderCartAction } from "../../../redux/actions/cart/cartActions";
import { Buffer } from "buffer";
import { useDateState } from "../../../Hooks/useDateState";
import { useRef } from "react";
import { incrementcount } from "../../../redux/addOutletitem";
import axios from "axios";
import serverURL from "../../../APIHandler/serverURL";
import { displayAmountFormat } from "../../../Common/utils/utils";

const radioData = [
  {
    name: "Just this  order.",
    value: "0",
  },
  {
    name: "This and all unfulfilled orders in this set of recurring orders.",
    value: "1",
  },
];
const ViewDetails = (props) => {
  const { classes } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useDateState();
  const [showModel, setShowModel] = useState(false);
  const [cancelOrder, setCancelOrder] = useState(false);
  const { loading, viewOrderDetail } = useSelector(
    (state) => state?.getOrderDetail
  );
  const totalcountschedule = useSelector(state => state?.outletitemid)
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const countcount = JSON.parse(localStorage.getItem("workingcount"))
  const totalcountcount = (localStorage.getItem("totalcount"))
  const orderCancel = useSelector((state) => state?.cancelOrder);
  const { userCart } = useSelector((state) => state?.getUserCart);
  const [cancel_all_recurring, setCancelAllRecurring] = useState(null);
  const ordernumbers = useSelector(state => state?.ordernumber)
  const bindingHandler = (reason) => {
    setCancelAllRecurring(reason);
    localStorage.setItem("cancel_all_recurring", reason);
  };

  const [errMsg, setErrMsg] = useState("");
  // localStorage.setItem("order_number", viewOrderDetail?.data?.order_number);
  const cancelOrderHandler = () => {
    setCancelOrder(true);
  };

  useEffect(() => {
    if (cancel_all_recurring === "0" || cancel_all_recurring === "1")
      setErrMsg("");
  }, [cancel_all_recurring]);

  useEffect(() => {
    if (orderCancel?.cancelOrder?.success) return navigate("/my-orders");
  }, [orderCancel?.cancelOrder?.success]);
  // Contact our customer service team link
  const moveToCustomMenu = (url) => {
    // window.location.href = url;
    // cancelHandler();
    window.open(url, "_blank");
  };
  useEffect(() => {
    if (userCart?.success) {
      if (localStorage.getItem("selected_modes") === "1") {
        localStorage.setItem("isToday", true);
      } else localStorage.setItem("isToday", false);
    }
  }, [userCart]);
  // lol
  // const [currentDateTime, setCurrentDateTime] = useState(moment());
  // useEffect(() => {
  //   setInterval(() => setCurrentDateTime(moment()), 30000);
  // }, []);

  const daysHandler = () => {
    let daysResult = null;
    if (userInfo?.data?.user_data?.role_name === "company_employee") {
      const arr1 = viewOrderDetail?.data?.outlet_weekly_calendar?.days_open
      const arr2 = viewOrderDetail?.data?.spend_policy
      var array = [];
      for (let i = 0; i < arr2?.length; i++) {
        if (arr1?.indexOf(arr2[i]) >= 0) {
          array.push(arr2[i])
        }
      }

      // const array = arr1?.map(
      //   (element, index) => arr2?.indexOf(element >= 0) && element
      // );

      daysResult = viewOrderDetail?.data?.spend_policy ? array : null;
      // console.log("arr", array);
    } else {
      daysResult = viewOrderDetail?.data?.outlet_weekly_calendar?.days_open
        ? viewOrderDetail?.data?.outlet_weekly_calendar?.days_open
        : null;

    }
    return daysResult;
  };

  const count = useRef(0)
  const weakdayscount = () => {
    const orderdate = moment(viewOrderDetail?.data?.order_date).format("dddd")
    return orderdate == "Sunday" ? 0 : orderdate == "Monday" ? 1 : orderdate == "Tuesday" ? 2 : orderdate == "Wednesday" ? 3 : orderdate == "Thursday" ? 4 : orderdate == "Friday" ? 5 : 6
  }
  let countdays = 0;
  const newdate = new Date()
  const days = daysHandler()
  const validateWorkingDays = (processDate, afterDay, diffweak) => {
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let countWorkingDay = 1;

    const days = daysHandler()
    for (let i = 0; i < diffweak; i++) {
      const preAddedDate = (i * 7);

      weekdays.map((data, index) => {
        const setTempDate = addDaysWRONG(processDate, preAddedDate + index); //new DateObject().add(1 + index, "days");
        const day = weekdays[setTempDate.getDay()];
        if (days?.indexOf(day) > -1) {
          countdays++
          localStorage.setItem("workingcount", countWorkingDay++)

        }
      });


    }

    // totalschedulecount()
    return totalvalue(countdays)
    // console.log("countWorkingDay", countWorkingDay,countdays)
  }
  const totalvalue = (value) => {
    let startdate = newdate.getDay();
    let endcount = weakdayscount();
    let arr = []
    for (let i = 0; i < days?.length; i++) {
      if (days[i] == "Sunday") {
        arr.push(0)
      }
      else if (days[i] == "Monday") {
        arr.push(1)
      }
      else if (days[i] == "Tuesday") {
        arr.push(2)
      }
      else if (days[i] == "Wednesday") {
        arr.push(3)
      }
      else if (days[i] == "Thursday") {
        arr.push(4)
      }
      else if (days[i] == "Friday") {
        arr.push(5)
      }
      else if (days[i] == "Saturday") {
        arr.push(6)
      }
    }

    let counts = 0
    let endcounts = 0
    for (let j = 0; j < arr?.length; j++) {
      if (arr[j] <= startdate) {
        counts++
      }
    }

    for (let j = 0; j < arr?.length; j++) {
      if (arr[j] > endcount) {
        endcounts++
      }
    }



    // let total = value - (days?.length - (endcount - startdate))
    let total = value - counts - endcounts

    if (startdate <= endcount) {
      console.log("wwww");
      count.current = total
      localStorage.setItem("totalcount", total)
      dispatch(incrementcount(total))
      console.log("wwwww", startdate, endcount, value, total, counts, endcount, days)
    } else {
      let totals = value - counts - endcounts
      localStorage.setItem("totalcount", totals)
      dispatch(incrementcount(totals))
      count.current = totals
      console.log("wwwww", startdate, endcount, value, totals, counts, endcount, days)
    }


  }

  const addDaysWRONG = (date, days) => {
    const result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  };

  // const totalschedulecount = () => {
  //   let startdate = newdate.getDay();
  //   let endcount = weakdayscount();
  //   let total = countcount - (days?.length - (endcount - startdate))
  //   console.log("wwwww", startdate, endcount, countcount, total)
  //   if (startdate <= endcount) {
  //     console.log("wwww");
  //     localStorage.setItem("totalcount", total)
  //   } else {
  //     let totals = countcount - Math.abs(-(days?.length - endcount - 1) - (startdate + 1))
  //     localStorage.setItem("totalcount", totals)
  //     console.log("wwwww", startdate, endcount, countcount, totals)
  //   }

  // }

  useEffect(() => {

    dispatch(ordernumber(id))
  }, [])

  // console.log("validateWorkingDays", validateWorkingDays(newdate, 4));
  const renderEditBtnView = () => {
    const currentDateTime = moment();
    const isAvailable = moment(
      viewOrderDetail?.data?.time_slot_start,
      "YYYY-MM-DD hh:mm:ss"
    );
    let diff = 0;
    if (isAvailable.year() - currentDateTime.year() == 0) {
      diff = (isAvailable.week() - currentDateTime.week()) + 1
    } else {
      diff = (52 - currentDateTime()) + isAvailable.week()
    }

    // const isAvailable = moment("2023-01-21 13:06:00", "YYYY-MM-DD hh:mm:ss");
    const isEditable = isAvailable.diff(currentDateTime, "minute");
    const iseditday = isAvailable.diff(currentDateTime, "day");


    validateWorkingDays(newdate, 4, diff)
    const orderMode = viewOrderDetail?.data?.order_mode;
    const order_type_display = viewOrderDetail?.data?.order_type_display;
    console.log("isEditable", isAvailable, isEditable, orderMode);
    // if (orderMode === "pickup" && isEditable > 16) {
    //   const isEdit = (isEditable - 16) * 60 * 1000;
    //   console.log("isEdit out", isEdit);
    //   setTimeout(() => {
    //     console.log("isEdit in", isEdit);
    //     renderEditBtnView();
    //   }, isEdit);
    // }
    console.log("totalcountcount", totalcountcount);
    return (orderMode === "Pick-Up" && isEditable < 16) || (orderMode === "delivery" && totalcountschedule?.count < 4) ? (
      <></>
    ) : (
      <div className={classes.btnFlex}>
        <DarkGrayBtn name={"Cancel order"} clickHandler={cancelOrderHandler} />

        <YellowBtn
          name={"Edit Order"}
          clickHandler={() => setShowModel(true)}
        />
      </div>
    );
  };
  console.log("viewOrderDetail", countcount)
  useEffect(() => {
    if (viewOrderDetail?.success) {
      // console.log("outletId of this order", viewOrderDetail?.data?.order_id);
      localStorage.setItem("outletId", viewOrderDetail?.data?.outlet_id);
      localStorage.setItem("orderId", viewOrderDetail?.data?.order_id);
      localStorage.setItem("timeslot", viewOrderDetail?.data?.order_time_slot)
    }
  }, [viewOrderDetail]);

  useEffect(() => {
    dispatch(getOrderDetailAction(id));

    dispatch({ type: ActionType.CLEAR_UPDATE });
  }, []);
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <Container maxWidth="lg" className={classes.p0}>
        <div className={classes.outerInner}>
          {viewOrderDetail?.data?.order_number ? (
            <>
              <div className={classes.description}>
                <BackArrow
                  clickHandler={() =>
                    dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
                  }
                />
              </div>
              <div className={classes.contentBox}>
                <div className={classes.maxWidth}>
                  <div className={classes.dFlex}>
                    <h3>
                      {StrConstant.ViewDetails.heading}{" "}
                      {viewOrderDetail?.data?.order_number}
                    </h3>
                  </div>
                  <div className={classes.labelBox}>
                    {/* <h4> */}
                    {/* {viewOrderDetail?.data?.order_mode === "pickup"
                        ? "Pickup"
                        : "Delivery"}{" "}
                      {`(${viewOrderDetail?.data?.order_type})`} */}
                    <h4>
                      {viewOrderDetail?.data?.order_type_display ==
                        "Pickup Today"
                        ? "PICK-UP TODAY"
                        : viewOrderDetail?.data?.order_type_display ==
                          "One-Time Pickup"
                          ? "One-time Pick-up"
                          : viewOrderDetail?.data?.order_type_display ==
                            "Pickup Recurring"
                            ? "Recurring Pick-up"
                            : viewOrderDetail?.data?.order_type_display ==
                              "Pickup Custom"
                              ? "Custom Pick-up"
                              : viewOrderDetail?.data?.order_type_display ==
                                "Delivery Recurring"
                                ? "Recurring Delivery"
                                : viewOrderDetail?.data?.order_type_display ==
                                  "Delivery Custom"
                                  ? "Custom Delivery"
                                  : viewOrderDetail?.data?.order_type_display}
                    </h4>

                    {/* </h4> */}
                  </div>
                  <div className={classes.listBox}>
                    <ul>
                      <li>
                        <div className={classes.lt}>
                          <h6>Date</h6>
                        </div>
                        <div className={classes.rt}>
                          <p>
                            {viewOrderDetail?.data?.display_date} {","}{" "}
                            {moment(viewOrderDetail?.data?.order_date).format(
                              "dddd"
                            )}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className={classes.lt}>
                          <h6>Time slot</h6>
                        </div>
                        <div className={classes.rt}>
                          <p>{viewOrderDetail?.data?.order_time_slot}</p>
                        </div>
                      </li>
                      <li>
                        <div className={classes.lt}>
                          <h6>Address</h6>
                        </div>
                        <div className={classes.rt}>
                          <p>
                            {` 
                            ${viewOrderDetail?.data?.address?.address_line1 ===
                                "-" ||
                                viewOrderDetail?.data?.address?.address_line1 ===
                                ""
                                ? ""
                                : viewOrderDetail?.data?.address?.address_line1
                              }
                            ${viewOrderDetail?.data?.address?.address_line2 ===
                                "-" ||
                                viewOrderDetail?.data?.address?.address_line2 ===
                                ""
                                ? ""
                                : viewOrderDetail?.data?.address?.address_line2
                              }
                          ${viewOrderDetail?.data?.address?.unit_number ===
                                "-" ||
                                viewOrderDetail?.data?.address?.unit_number === ""
                                ? ""
                                : viewOrderDetail?.data?.address?.unit_number
                              }
                          ${viewOrderDetail?.data?.address?.postal_code ===
                                "-" ||
                                viewOrderDetail?.data?.address?.postal_code === ""
                                ? ""
                                : viewOrderDetail?.data?.address?.postal_code
                              }`}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className={classes.labelBox}>
                    <h4>Order Summary</h4>
                  </div>
                  <div className={classes.listBox}>
                    <ul>
                      {viewOrderDetail?.data?.items?.map((data, index) => (
                        <Fragment key={index}>
                          <li>
                            <div className={classes.leftBox}>
                              <div className={classes.ltBox}>
                                <h6 className={classes.number}>{data?.item_quantity}</h6>
                                <h6>
                                  {data?.item_name}
                                </h6>
                              </div>
                            </div>
                            <div className={classes.rightBx}>
                              <p className={classes.fontWeight700}>
                                {data?.item_total} Credits
                              </p>
                            </div>
                          </li>
                          {data?.hide_size === 0 && (
                            <li>
                              <div className={classes.leftBox}>
                                <p>{data?.item_size}</p>
                              </div>
                              <div className={classes.rightBx}>
                                <p>{data?.unit_price} Credits</p>
                              </div>
                            </li>
                          )}
                          <li>
                            <div className={classes.leftBox}>
                              {data?.modifiers?.map((modifiers, i) => (
                                <p key={i}>{modifiers?.modifier_name}</p>
                              ))}
                            </div>
                            <div className={classes.rightBx}>
                              {data?.modifiers?.map((modifiers, i) => (
                                <p key={i}>
                                  {modifiers?.modifier_price=="0.0"?"-": modifiers?.modifier_price ? (modifiers?.modifier_price?.includes(".0") ? modifiers?.modifier_price?.split(".")[0] : modifiers?.modifier_price) : modifiers?.modifier_price} {modifiers?.modifier_price=="0.0"?"":"Credits"}
                                </p>
                              ))}
                            </div>
                          </li>
                        </Fragment>
                      ))}
                    </ul>
                  </div>
                  <div className={classes.listBox}>
                    <ul>
                      {viewOrderDetail?.data?.order_mode === "delivery" && (
                        <li>
                          <div className={classes.lt}>
                            <h6>Delivery fee</h6>
                          </div>
                          <div className={classes.rt}>
                            <p>
                              {viewOrderDetail?.data?.delivery_fee === "0.00"
                                ? "0"
                                : displayAmountFormat(parseFloat(
                                  viewOrderDetail?.data?.delivery_fee
                                ).toFixed(1))}{" "}
                              Credits
                            </p>
                          </div>
                        </li>
                      )}
                      {viewOrderDetail?.data?.coupon_used !== "" ? (
                        <li>
                          <div className={classes.lt}>
                            <div className={classes.paraNotWrap}>
                              <h6>Promo code</h6>{" "}
                              <p className={classes.pLt5}>
                                {" "}
                                {viewOrderDetail?.data?.coupon_used}
                              </p>
                            </div>
                          </div>
                          <div className={classes.rt}>
                            <p>
                              -{viewOrderDetail?.data?.discount_availed} Credits
                            </p>
                          </div>
                        </li>
                      ) : null}
                      {/* <li>
                        <div className={classes.lt}>
                          <h6>Rewards claimed</h6>
                        </div>
                        <div className={classes.rt}>
                          <p>
                            -{viewOrderDetail?.data?.discounted_total} Credits
                          </p>
                        </div>
                      </li> */}
                      <li className={classes.totalBox}>
                        <div className={classes.lt}>
                          <h6>TOTAL BILL</h6>
                        </div>
                        <div className={classes.rt}>
                          <p className={classes.fontWeight700}>
                            {/* {viewOrderDetail?.data?.discounted_total
                              ? viewOrderDetail?.data?.delivery_fee === "0.00"
                                ? 0
                                : parseFloat(
                                    viewOrderDetail?.data?.delivery_fee
                                  ) + viewOrderDetail?.data?.discounted_total
                              : viewOrderDetail?.data?.grand_total} */}
                            {viewOrderDetail?.data?.grand_total} Credits
                            {/* {parseFloat(viewOrderDetail?.data?.delivery_fee)+viewOrderDetail?.data?.discounted_total} Credits */}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className={classes.btnContainer}>
                    <div className={classes.btnContent}>
                      <div className={classes.btTxt}>
                        {viewOrderDetail?.data?.is_canceled !== 1 &&
                          viewOrderDetail?.data?.status !== "completed" ? null : (
                          <p>
                            This order has been{" "}
                            {viewOrderDetail?.data?.is_canceled !== 1
                              ? "fulfilled"
                              : "canceled"}{" "}
                            and cannot be changed on your end.
                          </p>
                        )}
                        <p>
                          See something not right?{" "}
                          <Link
                            to=""
                            onClick={() =>
                              moveToCustomMenu(
                                "https://www.order.bettr.coffee/contact-us/"
                              )
                            }
                          >
                            Contact our customer service team
                          </Link>
                        </p>
                      </div>
                    </div>
                    {viewOrderDetail?.data?.is_canceled !== 1 &&
                      viewOrderDetail?.data?.status !== "completed" ? (

                      renderEditBtnView()
                    ) : (
                      <div className={classes.btnFlex}>
                        {viewOrderDetail?.data?.is_canceled !== 1 && (
                          <YellowBtn
                            name={"Review order"}
                            clickHandler={() => navigate("/order-rating")}
                          />
                        )}

                        {/* <YellowBtn
                          name={"Order Again"}
                          clickHandler={() =>
                            dispatch(
                              reOrderCartAction(id),
                              navigate("/cart-detail")
                            )
                          }
                        /> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>{" "}
            </>
          ) : null}
        </div>
      </Container>
      <CustomModal
        open={showModel}
        title={"Note"}
        description={
          "You are only able to edit the date and time of delivery/pick-up."
        }
      >
        <p>
          If you wish to make other changes, please cancel this order and create
          a new one.
        </p>
        <div className={classes.modalFlex}>
          <DarkGrayBtn name="Cancel" clickHandler={() => setShowModel(false)} />
          <YellowBtn
            name="OKAY"
            clickHandler={() =>
              navigate(
                `/edit-order/${Buffer.from(
                  localStorage.getItem("orderId")
                ).toString("base64")}`
              )
            }
          />
        </div>
      </CustomModal>
      <CustomModal
        open={cancelOrder}
        title={
          viewOrderDetail?.data?.order_type === "Recurring" ||
            viewOrderDetail?.data?.order_type === "Custom"
            ? "What would you like to cancel?"
            : "Are you sure you want to cancel your order?"
        }
        description={""}
      >
        {viewOrderDetail?.data?.order_type === "Recurring" ||
          viewOrderDetail?.data?.order_type === "Custom" ? (
          <div className={classes.Bottom}>
            <CommonRadio
              radioData={radioData}
              name={cancel_all_recurring}
              onBinding={bindingHandler}
            />

            <p className="errorMessage">{errMsg === "" ? "" : errMsg}</p>
          </div>
        ) : null}

        <div className={classes.modalFlex}>
          <DarkGrayBtn name="No" clickHandler={() => setCancelOrder(false)} />
          <YellowBtn
            name="Yes"
            clickHandler={() => {
              if (viewOrderDetail?.data?.order_type !== "One-time") {
                if (
                  cancel_all_recurring === null ||
                  cancel_all_recurring === ""
                ) {
                  setErrMsg("This field is required.");
                } else navigate(`/cancel-order`);
              } else {
                navigate(`/cancel-order`);
              }
            }}
          />
        </div>
      </CustomModal>
    </>
  );
};

export default withStyles(Styles)(ViewDetails);
