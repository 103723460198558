export const Styles = (Theme) => ({
  box: {
    width: "100%",
    transition: ".5s ease",
    "@media (max-width: 640px)": {
      width: "100%",
    },
    "& > div": {
      display: "flex",
      alignItems: "flex-start",
      background: "#fff",
      borderRadius: "10px",
      width: "100%",
      padding: "10px",
      marginBottom: "20px",
      color: "#3F4444",
      cursor: "pointer",
      textDecoration: "none !important",
      borderBottom: "1px solid #eee",
      "&:hover": {
        background: "#F1EEDB !important",
        transition: ".5s ease",
      },
    },
  },
  boxActive: {
    width: "100%",
    transition: ".5s ease",
    "@media (max-width: 640px)": {
      width: "100%",
    },
    "& > div": {
      display: "flex",
      alignItems: "flex-start",
      background: "#F1EEDB",
      borderRadius: "10px",
      width: "100%",
      padding: "10px",
      marginBottom: "20px",
      color: "#3F4444",
      textDecoration: "none !important",
      borderBottom: "1px solid #eee",
    },
    "& :hover": {
      background: "#F1EEDB",
      transition: ".5s ease",
    },
  },
  imgBox: {
    height: "auto",
    maxWidth: "80px",
    "& img": {
      height: "auto",
      width: "100%",
      objectFit: "cover",
    },
  },
  description: {
    display: "flex",
    alignItems: "flex-start",
    padding: "0 0 0 15px",
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    "& svg": {
      height: "18px",
      marginLeft: "5px",
      color: "#3F4444",
    },
    "& p": {
      display: "flex",
      alignItems: "baseline",
      marginBottom: "0",
      "& span": {
        fontSize: "12px",
        paddingLeft: "5px",
      },
      "& b": {
        fontSize: "24px",
        lineHeight: "0",
        paddingRight: "5px",
      },
    },
    "& span": {
      display: "block",
      fontSize: "12px",
      fontWeight: "400",
      color: "#3F4444",
      lineHeight: "14px",
      letterSpacing: "1px",
      "@media (max-width: 640px)": {
        fontSize: "14px",
        lineHeight: "14px",
      },
    },
  },
  boxBg: {
    width: "100%",
    marginLeft: "0",
    "& > a": {
      background: "#F1EEDB !important",
      margin: "20px 0 0",
    },
  },
  modalFlex: {
    display: "flex",
    "& div:nth-child(2)": {
      marginLeft: "15px",
    },
  },
  margin2rem: {
    margin: "1.5rem 0",
  },
  setDefault: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    "& a": {
      display: "block",
      marginBottom: "5px",
      fontSize: "12px",
      textAlign: "right",
      "& svg": {
        color: "#3F4444",
      },
    },
  },
  cardOuter: {
    padding: "0 5px",
    display: "flex",
    alignItems: "flex-start",
    padding: "0 0 0 15px",
    width: "100%",
    justifyContent: "space-between",
  },
  cardInner: {
    display: "flex",
    flexDirection: "column",
    "& p": {
      display: "flex",
      alignItems: "baseline",
      marginBottom: "0",
      "& span": {
        fontSize: "12px",
        paddingLeft: "5px",
      },
      "& b": {
        fontSize: "24px",
        lineHeight: "0",
        paddingRight: "5px",
      },
    },
  },
  cartDetailBox: {
    width: "100%",
    transition: ".5s ease",
    "@media (max-width: 640px)": {
      width: "100%",
    },
    "& > div": {
      display: "flex",
      alignItems: "flex-start",
      background: "#F1EEDB",
      borderRadius: "10px",
      width: "100%",
      padding: "10px",
      marginBottom: "20px",
      color: "#3F4444",
      textDecoration: "none !important",
      borderBottom: "1px solid #eee",
      cursor: "inherit",
    },
  }
});
