import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from "react-router-dom";
// import StrConstant from "../../Common/StrConstant";
const CardBox = (props) => {
  const { classes, isSelected, feature, isCurrent, isNewPlan,isplanfree } = props;
  // console.log("isCurrent:", isCurrent);
  const [desktop, getDesktop] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDesktop({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [desktop]);
  console.log("isplanfree",isplanfree)
  return (
    <>
      {desktop.dynamicWidth > 768 ? (
        <div
          className={`${
            !isCurrent ? classes.cardInnerWithHover : classes.cardInner
          } ${isSelected ? classes.cardInnerActive : classes.cardInner}`}
        >
          <div className={classes.accordionOuter}>
            <div className={classes.dFlex}>
              <div className={classes.topFirst}>
                <div className={classes.cardHeadline}>
                  <h3>{props?.planName}</h3>
                </div>

                {isCurrent ? (
                  <Link to="" className={classes.bookmark}>
                    {"Current plan"}
                  </Link>
                ) : // ) : props?.placeholder?.length > 0 ? (
                //   <></>
                props?.placeholder?.length > 0 && isNewPlan ? (
                  <Link to="" className={classes.bookmark}>
                    {props?.placeholder}
                  </Link>
                ) : null}

                {/* {isUpcoming ? (
                  <Link to="" className={classes.bookmark}>
                    {"Upcoming plan"}
                  </Link>
                ) : props?.placeholder?.length > 0 ? (
                  <></>
                ) : // <Link to="" className={classes.bookmark}>
                //   {props?.placeholder}
                // </Link>
                null} */}
              </div>
            </div>
            <div className={classes.dFlex}>
              <div className={classes.ltHeadingTxt}>
                <span>S$</span>
                <h4>{props?.amount}</h4>
                <p>/mth</p>
              </div>
              <div className={classes.rtHeadingTxt}>
                <h4>{props.credit}</h4>
                <span> credits</span>
                {/* <img src="images/arrow-close.svg" alt="Better Coffee" /> */}
              </div>
            </div>
          </div>
          <div>
            {feature !== "" && (
              <div className={feature !== "" ? classes.descriptionBox : null}>
                <div className={classes.bottomBox}>
                  {/* <small> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: feature,
                    }}
                  ></div>
                  {/* </small> */}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Accordion
          className={`${
            !isCurrent ? classes.cardInnerWithHover : classes.cardInner
          } ${isSelected ? classes.cardInnerActive : classes.cardInner}`}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            // className={`${classes.accordionMob} ${classes.nodes}`}
            className={feature !== "" ? classes.accordionMob : classes.noDescription}
          >
            <div className={classes.dFlex}>
              <div className={classes.topFirst}>
                <div className={classes.cardHeadline}>
                  <h3>{props?.planName}</h3>
                </div>
                {isCurrent ? (
                  <Link to="" className={classes.bookmark}>
                    {"Current plan"}
                  </Link>
                ) : props?.placeholder?.length > 0 ? (
                  <Link to="" className={classes.bookmark}>
                    {props?.placeholder}
                  </Link>
                ) : null}
              </div>
            </div>

            <div className={classes.dFlex}>
              <div className={classes.ltHeadingTxt}>
                <span>S$ </span>
                <h4>{props.amount}</h4>
                <p>/mth</p>
              </div>
              <div className={classes.rtHeadingTxt}>
                <h4>{props.credit}</h4>
                <span>credits</span>
              </div>
            </div>
          </AccordionSummary>
          {feature !== "" && (
            <AccordionDetails>
              <div className={classes.listBox}>
                <div className={feature !== "" ? classes.descriptionBox : null}>
                  <div className={classes.bottomBox}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: feature,
                      }}></div>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          )}
        </Accordion>
      )}
    </>
  );
};
export default withStyles(Styles)(CardBox);
