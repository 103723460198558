export const Styles = (Theme) => ({
  btnBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    "@media(max-width:640px)": {
      marginTop: "8rem",
      display: "block",
    },
    "& :nth-child(2)": {
      marginLeft: "10px",
      "@media(max-width:640px)": {
        margin: "auto",
      },
    },
    "& > div": {
      "@media(max-width:640px)": {
        float: "unset",
        margin: "auto",
      },
    },
  },
  modalFlex: {
    display: "flex",
    marginTop: "25px",
    "& div:nth-child(2)": {
      marginLeft: "15px",
    },
  },
  subscriptionModal: {
    marginTop: "25px",
    "& > div": {
      maxWidth: "100%",
    },
  },
});
