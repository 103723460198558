export const Styles = Theme => ({
    toogleBtnBox: {
        "& button": {
            width: "100%",
            height: "auto",
            padding: "15px 10px",
            borderRadius: "10px !important",
            background: "#fff !important",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            minHeight: "80px",
            border:"none",
            margin:"0 15px 20px 0",
            marginLeft:"0 !important",
            "& span":{
                lineHeight:"normal",
                fontSize:"12px !important",
                lineHeight:"18px",
                color:"#3F4444BF",
                fontFamily:"'Noto Serif', serif",
            },
        },
    },
})