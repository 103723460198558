import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { Link } from "react-router-dom";
import Propover from "../../Components/Propover/Propover";
import StrConstant from "../../Common/StrConstant";
import OuterBox from "../../Components/OuterBox/OuterBox";
import OrderStatus from "../../Components/OrderStatus/OrderStatus";
import YellowBtn from "../../Components/YellowBtn/YellowBtn";
import { modeLinkHandler } from "../../Common/utils/utils";

const Dashboard = (props) => {
  const { classes } = props;
  const setDefaultMode = localStorage.getItem("selected_modes");

  return (
    <OuterBox>
      <h3>{StrConstant.Home.heading}</h3>
      <div className={classes.noWrap}>
        <div className={classes.card}>
          <div className={classes.cardInner}>
            <div className={classes.dFlex}>
              <h4>My next Order</h4>
            </div>
            <div className={classes.btnContent}>
              <YellowBtn name={"cancel order"} />
            </div>
          </div>
        </div>
        <div className={`${classes.card} ${classes.mLt}`}>
          <div className={classes.ltBox}>
            <h4>My wallet</h4>
            <p className={classes.fontWeightBold}>----------------- credits</p>
            <p>Expires on --------------------------</p>
          </div>
          <div className={classes.rtBox}>
            <Link to="">
              <div className={classes.imgContent}>
                <img src="./images/arrow.svg" alt="Better Coffee" />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className={classes.flexDirectionChange}>
        <ul className={classes.cardOuter}>
          <li>
            <div className={classes.card}>
              <div className={classes.cardInner}>
                <div className={classes.dFlex}>
                  <h5>Impact</h5>
                  <Propover
                    propoverTitle={StrConstant.Home.propoverTitle}
                    propoverDescription={StrConstant.Home.propoverDescription}
                  />
                </div>
                <p>-</p>
                <p>Trees</p>
              </div>
            </div>
          </li>
          <li>
            <div className={classes.card}>
              <div className={classes.cardInner}>
                <div className={classes.dFlex}>
                  <h5>Rewards</h5>
                  <Propover
                    propoverTitle={StrConstant.Home.propoverTitle}
                    propoverDescription={StrConstant.Home.propoverDescription}
                  />
                </div>
                <p>-</p>
                <p>Credits</p>
              </div>
            </div>
          </li>
          <li>
            <div className={classes.card}>
              <div className={classes.cardInner}>
                <h5>Refer a friend</h5>
                <p className={classes.fontWeightBold}>KJ32...</p>
                <p>Tap to copy</p>
              </div>
            </div>
          </li>
        </ul>
        <div className={classes.noWrap}>
          <div className={`${classes.card} ${classes.viewMenu}`}>
            <h4>View Menu</h4>
            <Link to="">
              <div className={classes.imgContent}>
                <img src="./images/arrow.svg" alt="Better Coffee" />
              </div>
            </Link>
            <OrderStatus
              //  link={modeLinkHandler(setDefaultMode)}
              link={"/choose-mode"}
              text={"Place Order"}
            />
          </div>
          <div className={`${classes.card} ${classes.mLt}`}>
            <div className={classes.cardInner}>
              <div className={classes.dFlex}>
                <h4>Group buy</h4>
                <Propover
                  propoverTitle={StrConstant.Home.propoverTitle}
                  propoverDescription={StrConstant.Home.propoverDescription}
                />
              </div>
              <div className={classes.btnContent}>
                <YellowBtn name={"Learn more"} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.card}>
        <h4>My account</h4>
        <Link to="">
          <div className={classes.imgContent}>
            <img src="./images/arrow.svg" alt="Better Coffee" />
          </div>
        </Link>
      </div>
      <div className={classes.bannerImg}>
        <img src="./images/banner.svg" alt="Better Coffee" />
      </div>
    </OuterBox>
  );
};

export default withStyles(Styles)(Dashboard);
