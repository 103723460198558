import serverURL from "../../../APIHandler/serverURL";
import callAPI from "../../../APIHandler/callAPI";
import { ActionType } from "../../ActionTypes";
import { reloadHandler } from "../../../Common/utils/utils";

export const viewOrderAction =
  (currentPage = 1) =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionType.VIEW_ORDERS_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        serverURL.viewMyOrder.concat(`?page=${currentPage}`),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({ type: ActionType.VIEW_ORDERS_SUCCESS, payload: data });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      return dispatch({
        type: ActionType.VIEW_ORDERS_FAILURE,
        payload: message,
      });
    }
  };

export const getOrderDetailAction = (id) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GET_ORDER_DETAIL_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.getRequest(
      serverURL.orderDetail.concat(id),
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({ type: "CLEAR_DETAILS" });
    dispatch({ type: ActionType.GET_ORDER_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.GET_ORDER_DETAIL_FAILURE,
      payload: message,
    });
  }
};

export const editOrderAction = (id, payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.EDIT_ORDER_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(
      serverURL.editOrder.concat(id),
      payload,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.EDIT_ORDER_SUCCESS, payload: data });
    // localStorage.removeItem("orderId");
    // localStorage.removeItem("outletId");
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.EDIT_ORDER_FAILURE,
      payload: message,
    });
  }
};

export const cancelOrderAction = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.CANCEL_ORDER_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(
      serverURL.cancelOrder.concat(payload?.order_id),
      payload,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch(viewOrderAction());
    dispatch({ type: ActionType.CANCEL_ORDER_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.CANCEL_ORDER_FAILURE,
      payload: message,
    });
  }
};

export const viewRecurringOrderAction =
  (orderId, currentPage) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.VIEW_RECURRING_ORDER_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        serverURL.viewRecurringOrder.concat(orderId + `?page=${currentPage}`),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({
        type: ActionType.VIEW_RECURRING_ORDER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      return dispatch({
        type: ActionType.VIEW_RECURRING_ORDER_FAILURE,
        payload: message,
      });
    }
  };

export const viewOrderHistoryAction =
  (currentPage) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.VIEW_ORDERS_HISTORY_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        serverURL.viewOrder.concat(`?page=${currentPage}`),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({ type: ActionType.VIEW_ORDERS_HISTORY_SUCCESS, payload: data });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      return dispatch({
        type: ActionType.VIEW_ORDERS_HISTORY_FAILURE,
        payload: message,
      });
    }
  };

export const orderRatingAction = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.ORDERS_RATING_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(
      serverURL.orderRating.concat(payload?.id),
      payload,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.ORDERS_RATING_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.ORDERS_RATING_FAILURE,
      payload: message,
    });
  }
};


export const ordernumber=(payload)=> async (dispatch,getState)=>{
  dispatch({
    type: ActionType.ORDERS_NUMBER_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();

  try {
    const { data } = await callAPI.getRequest(
      serverURL.ordernumber.concat(payload),
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    console.log("vikalpdata",data)
    localStorage.setItem("ordernumberno",data?.data)
    dispatch({ type: ActionType.ORDERS_NUMBER_SUCCESS, payload: data });
  } 
  catch (error) {
    console.log("vikalp",error)
    // reloadHandler(error?.response?.status);
    // const message =
    //   error.response && error.response.data.message
    //     ? error.response.data.message
    //     : error.message;
    // return dispatch({
    //   type: ActionType.ORDERS_NUMBER_FAILURE,
    //   payload: message,
    // });
  }
}