import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getPlanListDetail } from "../../redux/actions/planList/PlanListActions";
import { useMemo } from "react";
const NoExpandedBox = (props) => {
  const { classes, index } = props;
  const dispatch = useDispatch();
  // console.log("🚀 ( ͡◉ ͜ʖ ͡◉) index", index);
  const plansData = useSelector((state) => state.planDetail);
  const [filter, setFilter] = useState();
  // console.log(
  //   "plansData?.planDetail?.data?.plans:",
  //   plansData?.planDetail?.data?.plans
  // );
  useEffect(() => {
    dispatch(getPlanListDetail());
  }, [dispatch]);
  useMemo(() => {
    setFilter(
      plansData?.planDetail?.data?.plans.find((list, i) =>
        i == index ? list : null
      )
    );
  }, [plansData?.planDetail?.data?.plans, index]);
  useEffect(() => {
    // console.log("filter:", filter);
  }, [filter]);
  return (
    <>
      <div className={classes.noExpandedBox}>
        <div className={classes.dFlex}>
          <div className={classes.topFirst}>
            <div className={classes.cardHeadline}>
              <h3>{filter?.plan_name}</h3>
            </div>
          </div>
        </div>
        <div className={classes.dFlex}>
          <div className={classes.ltHeadingTxt}>
            <span>S$</span>
            <h4>{filter?.amount}</h4>
            <p>/{filter?.interval}</p>
          </div>
          <div className={classes.rtHeadingTxt}>
            <h4>{"" + filter?.credits}</h4>
            <span>credits</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default withStyles(Styles)(NoExpandedBox);
