import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { saveData } from "../../redux/actions/UserActions";
import {
  deleteAddressDetail,
  getAddressDetailList,
  setDefaultAddressDetail,
} from "../../redux/actions/userAddress/UserAddressActions";
import AlterMessage from "../../Components/AlertMessage/AlertMessage";
// import OrderStatus from "../OrderStatus/OrderStatus";
// import { modeLinkHandler } from "../../Common/utils/utils";
import Loader from "react-spinner-loader";
import DarkGrayBtn from "../DarkGrayBtn/DarkGrayBtn";
import CustomModal from "../CustomModal/CustomModal";
import YellowBtn from "../YellowBtn/YellowBtn";
import { ActionType } from "../../redux/ActionTypes";
import { getOutletid } from "../../redux/actions/outlet/OutletActions";
const Addresses = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [selectedAddressID, setSelectedAddressID] = useState();
  // const address_id = localStorage.getItem("address_id");
  // const setDefaultMode = localStorage.getItem("selected_modes");
  const { address } = useSelector((state) => state?.addAddress);
  const deleteData = useSelector((state) => state?.deleteUserAddress);
  const updatedData = useSelector((state) => state?.updateUserAddress);
  const { loading, userInfo } = useSelector((state) => state?.userAddressList);
// console.log("updateData",updatedData)
  useEffect(() => {
    dispatch(getAddressDetailList());
  }, []);

  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    setIsOpen(false);
    dispatch({ type: ActionType.CLEAR_RESPONSE });
  };

  useEffect(() => {
    setIsOpen(true);
  }, [updatedData?.userInfo?.success, updatedData?.userInfo?.message]);

  const handleClicks = (type, addressID) => {
    dispatch({ type: ActionType.CLEAR_RESPONSE }, setIsOpen(true));
    // 1 for outlet, 2 for delete, 3 for edit
    if (type === 1) {
      dispatch(saveData("address_id", addressID));
    } else if (type === 2) {
      setShowModel(true);
      setSelectedAddressID(addressID);
    } else if (type === 3) {
      dispatch(saveData("addressId", addressID), navigate(`/add-address/${addressID}`));
    }
  };

  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}

      {address && (
        <AlterMessage
          closeHandler={snackHandler}
          description={address?.message}
          title={"Success"}
          type={"Success"}
          open={isOpen}
          url={"./images/success.svg"}
        />
      )}

      {(deleteData?.userInfo?.message || deleteData?.message) && (
        <AlterMessage
          closeHandler={snackHandler}
          description={deleteData?.userInfo?.message || deleteData?.message}
          title={deleteData?.userInfo?.success ? "Success" : "Error"}
          type={deleteData?.userInfo?.success ? "Success" : "Error"}
          open={isOpen}
          url={
            deleteData?.userInfo?.success
              ? "./images/success.svg"
              : "./images/error.svg"
          }
        />
      )}

      {(updatedData?.userInfo?.message || updatedData?.message) && (
        <AlterMessage
          closeHandler={snackHandler}
          description={updatedData?.userInfo?.message || updatedData?.message}
          title={updatedData?.userInfo?.success ? "Success" : "Error"}
          type={updatedData?.userInfo?.success ? "Success" : "Error"}
          open={isOpen}
          url={
            updatedData?.userInfo?.success
              ? "./images/success.svg"
              : "./images/error.svg"
          }
        />
      )}

      {deleteData?.error && (
        <AlterMessage
          description={deleteData?.error}
          closeHandler={snackHandler}
          url={"./images/error.svg"}
          title={"Error"}
          type={"Error"}
          open={isOpen}
        />
      )}

      {userInfo?.data?.address?.length === 0 ? (
        <p>No address to display</p>
      ) : (
        userInfo?.data?.address?.map((list, index) => (
          <div key={index}>
            <div
              className={`${classes.card} ${
                list.is_default && classes.isACtiveBG
              }`}
              onClick={()=>{
                dispatch(getOutletid(list?.address_id))
     
              }}
            >
              <div
                className={classes.ltBox}
                onClick={() => {
                  handleClicks(1, list?.address_id);
                  if (list.is_default) return;
                  dispatch({ type: "RESET_CART" });
                  dispatch(setDefaultAddressDetail(list?.address_id));
                }}
              >
                <h4>
                  {list.address_tag === "Custom"
                    ? list?.custom_address_tag
                    : list.address_tag}{" "}
                  {list.is_default && (
                    <span className={classes.primaryTag}> (Primary)</span>
                  )}
                </h4>
                <p>{list.address_line1}</p>
              </div>
              <div className={classes.rtBox}>
                <div
                  className={classes.imgContent}
                  onClick={() => handleClicks(2, list?.address_id)}
                >
                  <img src="./images/delete.svg" alt="Better Coffee" />
                </div>
                <div
                  className={classes.imgContent}
                  onClick={() =>{ handleClicks(3, list?.address_id)
                  // console.log("Editaddress",list);
                  localStorage.setItem('EditAddress',JSON.stringify(list))
                  }}
                >
                  <img src="./images/pencil.svg" alt="Better Coffee" />
                </div>
              </div>
            </div>
            {/* <OrderStatus
              link={modeLinkHandler(setDefaultMode)}
              text={"Place order"}
            /> */}
          </div>
        ))
      )}
      <div className={classes.btnBox}>
        <div className={classes.addItems}>
          <Link to="/add-address">
            <img src="./images/plus.svg" alt="Better Coffee" />
            <span>Add new address</span>
          </Link>
        </div>
      </div>

      <CustomModal
        open={showModel}
        title={"Are you sure you want to delete this address?"}
        description={"You won’t be able to use this address anymore."}
      >
        <div className={classes.modalFlex}>
          <DarkGrayBtn
            name={"Cancel"}
            clickHandler={() => setShowModel(false)}
          />
          <YellowBtn
            name={"Delete"}
            clickHandler={() =>
              dispatch(
                deleteAddressDetail(selectedAddressID),
                setShowModel(false),
                setIsOpen(true)
              )
            }
          />
        </div>
      </CustomModal>
    </>
  );
};
export default withStyles(Styles)(Addresses);
