import { useState, useEffect } from "react";
// ?the check
const getDeviceConfig = (width) => {
  if (width < 320) {
    return "xs";
  } else if (width >= 320 && width < 720) {
    return "sm";
  } else if (width >= 720 && width < 1024) {
    return "md";
  } else if (width >= 1024) {
    return "lg";
  }
};
// *initialValue
export const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );
  // ?for every brkPnt
  useEffect(() => {
    const calcInnerWidth = function () {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    };
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);
  // !output
  return brkPnt;
};
