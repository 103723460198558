export const Styles = Theme => ({
    back: {
        "& button": {
            height: "auto",
            margin: "20px 0 25px",
            padding: "0",
            minWidth: "auto",
            display: "flex",
            alignItems: "center",
            "& span": {
                display: "inline-block",
                fontSize: "25px",
                lineHeight: "normal",
                whiteSpace: "nowrap",
                paddingLeft: "12px",
                color:"#333539",
                fontFamily: "'Bebas Neue', serif",
                "@media(max-width:768px)":{
                    display:"none",
                }
            },
            "& img": {
                height: "auto",
                width: "100%",
                maxWidth: "50px",
                objectFit: "cover",
            },
            "&:hover": {
                background: "transparent !important",
            },
            "&:active": {
                background: "transparent !important",
            }
        }
    },
})