import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import StrConstant from "../../../Common/StrConstant";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  getUserNotificationsAction,
  // readNotificationsAction,
  // readNotificationAction,
} from "../../../redux/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { convertDDMMYYY } from "../../../Common/utils/utils";
import { ActionType } from "../../../redux/ActionTypes";
// import AlterMessage from "../../../Components/AlertMessage/AlertMessage";
import Loader from "react-spinner-loader";
// import CustomModal from "../../../Components/CustomModal/CustomModal";
import ViewMore from "../../../Components/ViewMore/ViewMore";
import { notificationCounterAction } from "../../../redux/actions/cart/cartActions";
import Opps from "../../../Components/Opps/Opps";
const Notifications = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  // const [isOpen, setIsOpen] = useState(false);
  const [notiData, setNotiData] = useState([]);
  const { userProfile } = useSelector((state) => state?.getUserProfile);
  const { loading, notificationList } = useSelector(
    (state) => state?.notificationList
  );
  // const { markAllNotification } = useSelector(
  //   (state) => state?.readNotifications
  // );
  // const { singleNotification } = useSelector(
  //   (state) => state?.readNotification
  // );
  // const [cartArray, setCartArray] = useState();

  // const snackHandler = (event, reason) => {
  //   dispatch({ type: ActionType.CLEAR_RESPONSE });
  //   if (reason === "clickaway") return;
  // };

  // useEffect(() => {
  //     setCartArray(notifications?.data?.notifications);
  // }, [notifications]);

  // useEffect(() => {
  //   setIsOpen(true);
  //   setNotiData([]);
  //   setIsComplete(false);
  //   setPage(1);
  //   dispatch(getUserNotificationsAction(page));
  // }, [readNotification?.readNotifications?.success]);

  const selectionHandler = (redirectId) => {
    return "/view-details/".concat(redirectId);
  };
  const individualNotificationHandler = (id, notification_for, status) => {
    if (status == 0) {
      // dispatch(readNotificationAction(id, page));

      const itemData = notiData.findIndex((sd) => {
        return sd.notification_id === id;
      });

      const updatedObj = notiData;
      updatedObj[itemData].status = 1;
      setNotiData(updatedObj);
    }
    if (
      (notification_for == "credits" || notification_for == "subscription") &&
      status == 0
    ) {
      // showNotifyPopup();
    }
  };
  // const showNotifyPopup = () => {
  //   setShowModel(!showModel);
  // };
  //ScrollView Section
  // const [isComplete, setIsComplete] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("scroll", onScroll);
  //   return () => {
  //     window.removeEventListener("scroll", onScroll);
  //   };
  // });

  useEffect(() => {
    if (notificationList != null) {
      if (notiData?.length > 0) {
        if (page > 1) {
          setNotiData([...notiData, ...notificationList?.data?.notifications]);
        }
      } else {
        setNotiData(notificationList?.data?.notifications);
      }
    }
  }, [notificationList]);
  // const isMarkAllButton = notiData?.find((element) => element?.status === 0);

  useEffect(() => {
    if (page === 1) {
      setNotiData([]);
    }
    dispatch(getUserNotificationsAction(page));
    localStorage.removeItem("is_subscribed_process");
    localStorage.removeItem("insufficient");
  }, [page]);

  // const onScroll = () => {
  //   const winHeight = window.innerHeight;
  //   const srcHeight = document.documentElement.scrollHeight;
  //   const scrollTop =
  //     document.body.scrollTop || document.documentElement.scrollTop;
  //   const toBottom = srcHeight - winHeight - scrollTop;

  //   if (toBottom <= 100) {
  //     if (!isComplete) {
  //       getData();
  //     }
  //   }
  // };

  // function getData() {

  //   if (loading?.loading) return;
  //   const nextPage = page + 1;
  //   setPage(nextPage);
  // }
  // useEffect(() => {
  //   dispatch(readNotificationsAction());
  // }, []);
  const loadMoreHandler = () => {
    const nextPage = page + 1;
    setPage(nextPage);
  };
  useEffect(() => {
    if (notificationList) {
      dispatch(
        notificationCounterAction(notificationList?.data?.notification_unread)
      );
    }
  }, [notificationList?.data?.notification_unread]);
  console.log("loading",loading)
  return (
    <>
      {loading && page === 1 && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {/* {markAllNotification?.success && (
        <AlterMessage
          description={markAllNotification?.message}
          closeHandler={snackHandler}
          url="./images/success.svg"
          title="Success"
          type="Success"
          open={isOpen}
        />
      )}
      {singleNotification?.success && (
        <AlterMessage
          description={singleNotification?.message}
          closeHandler={snackHandler}
          url="./images/success.svg"
          title="Success"
          type="Success"
          open={isOpen}
        />
      )} */}
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <h3>{StrConstant.Notifications.heading}</h3>
        </div>
        <div>
          {/* {notiData.findIndex((sd) => { return sd.status === 0; }) > -1 ? (
            <Link
              to=""
              onClick={() =>
                dispatch(readNotificationsAction(), setIsOpen(true))
              }
            >
              Mark all as read
            </Link>
          ) : null} */}
          {notiData?.length == 0 && loading==false ? (
            <Opps />
          ) :(
            <div className={classes.notificationsOuter}>
              <ul>
                {notiData?.map((data, index) => (
                  <li
                    key={index}
                    onClick={() =>
                      individualNotificationHandler(
                        data?.notification_id,
                        data?.notification_for,
                        data?.status
                      )
                    }
                  >
                    <div
                      className={`${classes.notBox} ${data?.status === 1 ? classes.readNot : null
                        }`}
                    >
                      <h4>{data?.title}</h4>
                      <p>{data?.message}</p>
                      <div className={classes.dFlex}>
                        {data?.notification_for == "order" ||
                          data?.title === "ORDER UPDATED " ? (
                          <Link
                          to={"/my-orders"} 
                          // to={selectionHandler(data?.redirect_id)}
                          >
                            View My Orders
                          </Link>
                        ) : null}
                        {
                          (data?.title === "CREDIT ALLOCATION" || data?.title === "CREDIT DEDUCTION"||data?.title=="SUBSCRIPTION UPDATED") && (
                            <Link to={"/my-wallet"}>View My Wallet</Link>
                          )
                        }

                        {data?.title === "CREDITS EXPIRED" ? (
                          <Link to={"/choose-plan"}>Place Order</Link>
                        ) : null}
                        {data?.title === "SUBSCRIPTION CANCELLED" ? (
                          <Link
                            to={
                              userProfile?.data?.is_subscribed === true
                                ? "/choose-plan"
                                : "/manage-subscription"
                            }
                          >
                            Subscribe again
                          </Link>
                        ) : null}
                        {data?.title === "FREE TRIAL ENDING" ? (
                          <Link to={"/choose-plan"}>Manage My Subscription</Link>
                        ) : null}
                        {data?.title === "CREDITS EXPIRED" ||
                          data?.title === "CREDITS EXPIRING" ? (
                          <Link to={"/purchase-credits"}>Purchase Credits</Link>
                        ) : null}
                        {data?.title === "Top-up completed" ||
                          data?.title === "Subscription Renewed" ||
                          data?.title === "CREDIT SUBSCRIPTION SUCCESS" ||
                          data?.title === "PlAN UPDATED" ||
                          data?.title === "SUBSCRIPTION RENEWED" ||
                          data?.title === "Credit Purchase Success" ? (
                          <Link to={"/my-wallet"}>View My Wallet</Link>
                        ) : null}
                        <p>{convertDDMMYYY(data?.created_at)}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div>
                {notificationList?.data?.pagination?.lastPage === page ||
                  notificationList?.data?.pagination?.count === notiData.length ||
                  notiData.length < 1 ? null : (
                  <ViewMore name={"Load More"} clickHandler={loadMoreHandler} />
                )}
              </div>
            </div>
          ) }
        </div>
      </OuterBox>
    </>
  );
};
export default withStyles(Styles)(Notifications);
