export const Styles = Theme => ({
    imageContent: {
        margin: "20px 0",
        maxWidth: "100%",
        display: "flex",
        "& a": {
            textDecoration: "none",
            maxWidth: "300px",
            height: "auto",
            objectFit: "cover",
            width: "100%",
            margin: "auto",
            "@media(max-width:640px)": {
                maxWidth: "100%",
            }
        },
        "& img": {
            height: "auto",
            width: "100%",
        }
    },
    btndFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "&  div": {
            maxWidth: "100%",

            marginRight: "10px",
            "@media(min-width:640px)": {
                width: "auto",
            },
            "@media(max-width:640px)": {
                marginRight: "0",
            }
        },
        "@media(max-width:640px)": {
            display: "block",
            margin: "20px 0",
        },
    },
    mobilepurchase: {
        "@media(max-width:640px)": {
            // width: "100% !important",
            display: "flex !important",
            flexDirection: "column-reverse"
        }
    },
    outerInner: {
        margin: "auto",
        display: "table",
        width: "100%",
        alignItems: "center",
        "@media(max-width:640px)": {
            display: "block",
        }
    },
})