export const Styles = Theme => ({
    outerInner: {
        margin: "auto",
        width: "100%",
        height: "auto",
        paddingBottom: "20px",
    },
    searchBox: {
        position: 'relative',
        "& input": {
            paddingRight: "80px",
        },
        "& img": {
            position: "absolute",
            right: "15px",
            top: "50%",
            transform: 'translate(-50%, -50%)',
        },
        "& button": {
            position: "absolute",
            right: "20px",
            top: "50%",
            transform: "translate(-10px, -50%) !important",
            background: "transparent !important",
            "& svg": {
                color: "#3F4444",
            }
        }
    },
    tabDesign: {
        margin: "20px 0 10px",
        borderBottom: '1px solid #D5D4DC',
        "&  span": {
            backgroundColor: '#FFC200',
        },
        "& button": {
            padding: "15px 0 5px",
            minWidth: "auto",
            marginRight: "35px",
            minHeight: "auto",
            fontSize: "20px",
            fontWeight: "400",
            color: "#3F4444",
            lineHeight: "20px",
            fontFamily: "'Bebas Neue',serif",
            "&  span": {
                display: "none",
            },
            "@media(max-width:475px)": {
                marginRight: "25px",
            }
        },
        "& :last-child": {
            marginRight: "0",
        },
        "& [aria-selected='true']": {
            color: "#FFC200 !important",
        },
    },
    tabContainer: {
        padding: "0 !important"
    },
    chipDesign: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "15px",
        "& > div ": {
            margin: "0 10px 10px 0",
        },
        "& > div > div": {
            borderRadius: "54px",
            background: '#F1EEDB',
            padding: '10px',
            fontSize: "15px",
            fontWeight: "400",
            color: "#3F4444",
            lineHeight: "15px",
            fontFamily: "'Bebas Neue',serif",
            minWidth: "60px",
            cursor: "pointer",
        },
        "& span": {
            overflow: "inherit",
            whiteSpace: "inherit",
        }
    },
    active: {
        background: '#FFC200 !important',
        color: "#fff !important",
    },
    boxOuter: {
        "& ul": {
            margin: "0",
        },
        "& li": {
            display: "inline-block",
            verticalAlign: "middle",
            width: "20%",
            paddingRight: "20px",
            margin: "2.5rem 0 15px 0",
            "@media(max-width:1024px)": {
                width: "25%",
            },
            "@media(max-width:991px)": {
                width: "33.33%",
            },
            "@media(max-width:575px)": {
                width: "50%",
                paddingRight: "16px",
            },
            "&:nth-child(5n+5)": {
                paddingRight: "0",
                "@media(max-width:1024px)": {
                    paddingRight: "20px",
                },
            },
            "&:nth-child(3n+3)": {
                "@media(max-width:991px)": {
                    paddingRight: "0",
                },
                "@media(max-width:575px)": {
                    paddingRight: "15px",
                },
            },
            "&:nth-child(even)": {
                "@media(max-width:575px)": {
                    paddingRight: "0 !important",
                },
            }
        }
    },
    noDataFound: {
        "& > div": {
            height: "auto",
        }
    },
    oppsBox: {
        "& > div": {
            height: "calc(100vh - 350px)",
            "@media(max-width:640px)": {
                position: "inherit",
                transform: "inherit",
            }
        }
    }
})

