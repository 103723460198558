export const Styles = Theme => ({
    navbarOuter: {
        padding: "60px 2rem 1rem",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position:"relative",
    },
    navbarInner: {
        height: "100%",
        "& ul": {
            padding: "1rem 0 2rem",
            margin: 0,
        },
        "& li": {
            padding: "10px 0 0",
        },
        "& a": {
            fontSize: "16px",
            lineHeight: "16px",
            color: "#373636 !important",
            textDecoration: "none ",
        },
        "& span": {
            fontSize: "24px",
            lineHeight: "24px",
            fontWeight: "700",
            color: "#373636 !important",
            textDecoration: "none ",
            cursor: "pointer",
            "@media(max-width:640px)": {
                fontSize: "18px",
                lineHeight: "18px",
            }
        }
    },
    topBox: {
        display: "flex",
        alignItems: 'flex-start',
        justifyContent: "flex-end",
        position: "absolute",
        right: "15px",
        top: "15px",
        "& p": {
            fontSize: "18px",
            lineHeight: "18px",
            fontWeight: "700",
            color: "#373636 !important",
            margin: "0",
        },
    },
    close: {
        borderRadius: "100%",
        padding: "4px 7px",
        background: "#fff",
        height: "30px",
        width: "30px",
        position: "relative",
        border: "1px solid #ccc",
        "& img": {
            height: "14px",
            width: "14px",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            transition: ".3s ease",
        },
        "&:hover": {
            color: "#FFC200",
            transition: ".3s ease",
            "& :nth-child(1)": {
                display: "none",
            },
            "& :nth-child(2)": {
                display: "inline-block",
                transition: ".3s ease",
            }
        }
    },
    closeYellow: {
        display: "none",
    },
    customDropDown: {
        "& button": {
            padding: "0",
            fontSize: "16px",
            lineHeight: "16px",
            fontWeight: "400",
            color: "#373636 !important",
            textTransform: "capitalize",
            minWidth: "auto",
            display: "flex",
            alignItems: "baseline",
            "& span": {
                fontFamily: "'Noto Serif', serif !important",
                cursor: "pointer",
                "@media(max-width:640px)": {
                    fontSize: "18px",
                    lineHeight: "18px",
                }
            },
            "&:hover": {
                background: "transparent !important",
            },
            "&:active": {
                background: "transparent !important",
            },
            "& img": {
                height: "15px",
                width: "15px",
                marginLeft: "5px",
            }
        },
    },
    bottomDropDown: {
        maxWidth: "20px",
        float: "right",
    },
    dropdownItems: {
        marginTop: "8px",
        "& li": {
            padding: "0 !important",
        },
        "& span": {
            fontSize: "16px",
            lineHeight: "20px",
            fontFamily: "'Noto Serif', serif !important",
            textDecoration: "none !important",
            padding: "10px",
            display: "table",
            minHeight: "30px",
            width: "100%",
            fontFamily: "'Noto Serif', serif !important",
            "@media(max-width:640px)": {
                fontSize: "14px",
            },
        }
    },
    modaldFlex: {
        display: "flex",
        "@media(max-width:375px)": {
            display: "block",
        },
        "& > :nth-child(2)": {
            marginLeft: "20px",
            "@media(max-width:375px)": {
                marginLeft: "0",
            }
        },
        "& button": {
            "@media(max-width:375px)": {
                margin: "10px 0",
            }
        }
    },
    logoContent: {
        maxWidth: "173px",
        height: "auto",
        objectFit: "cover",
        "& a": {
            display: "block",
        },
        "& img": {
            height: "auto",
            width: "100%",
        }
    },
    navbar: {
        padding: "0 1rem 0 1rem",
        "@media(max-width:640px)": {
            padding: "2rem 0 0 0",
        }
    },
    secondList: {
        marginTop: "1.5rem",
    },
    secondList: {
        "& span": {
            fontSize: "16px",
            lineHeight: "16px",
            fontWeight: "400",
            color: "#3F4444 !important",
            textDecoration: "none !important",
            "@media(max-width:640px)": {
                fontSize: "16px",
                lineHeight: "16px",
            }
        }
    },
    btBox: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "space-between",
        borderTop: "1px solid #DDDDDD",
        paddingTop: "10px",
    },
    btLeft: {
        width: "90%",
        padding: "0 5px",
    },
    btRight: {
        width: "10%",
    },
    profileSelect: {
        "& span": {
            fontSize: "13px",
            lineHeight: "16px",
            fontWeight: "400",
            color: "#373636 !important",
            fontFamily: "'Noto Serif', serif !important",
        }
    },
    userName: {
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: "700",
        color: "#3F4444 !important",
        fontFamily: "'Noto Serif', serif !important",
        margin: "0",
    },
    modalFlex: {
        display: "flex",
        "& :nth-child(2)": {
            marginLeft: "15px",
        }
    }
})