import React, {
  lazy,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { withStyles } from "@mui/styles";
import * as moment from "moment";
import { Styles } from "./Style";
import BackArrow from "../../../../Components/BackArrow/BackArrow";
import StrConstant from "../../../../Common/StrConstant";
import YellowBtn from "../../../../Components/YellowBtn/YellowBtn";
import GroupToggleBtn from "../../../../Components/GroupToggleBtn/GroupToggleBtn";
import ToggleButton from "@mui/material/ToggleButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useNavigate } from "react-router";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import MultiDatePicker from "../../../../Components/MultiDatePicker/MultiDatePicker";
import CustomModal from "../../../../Components/CustomModal/CustomModal";
import { Link } from "react-router-dom";
import DarkGrayBtn from "../../../../Components/DarkGrayBtn/DarkGrayBtn";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import {
  finalizeOrderVerify,
  getUserCart,
  finalizeOrderCheckout,
  validateCheckoutAction,
  applyCoupon,
} from "../../../../redux/actions/cart/cartActions";
import CustomDatePicker from "../../../../Components/CustomDatePicker/CustomDatePicker";
import MultiGroupToggler from "../../../../Components/MultiGroupToggler/MultiGroupToggler";
import { getAddressDetailList } from "../../../../redux/actions/userAddress/UserAddressActions";
import { TimeSlot } from "../../../../Views/TimeSlot";
import { getOutlet } from "../../../../redux/actions/outlet/OutletActions";
import { futureScheduleAction } from "../../../../redux/actions/cart/cartActions";
import { displayAmountFormat, supplyDateFormat } from "../../../../Common/utils/utils";
import { ActionType } from "../../../../redux/ActionTypes";
import PromoCode from "../../../../Components/PromoCode/PromoCode";
import ViewMore from "../../../../Components/ViewMore/ViewMore";
import { Button } from "@mui/material";
import { Link as Linkscroll } from "react-scroll";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import UseButtonmulticlick from "../../../../useButtonmulticlick/UseButtonmulticlick";
const OrderReview = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AlterMessage = lazy(() =>
    import("../../../../Components/AlertMessage/AlertMessage")
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [end_date, setEnd_date] = useState(null);
  const [start_date, setStart_date] = useState(null);
  const cart_id = localStorage.getItem("cart_id");
  const Loader = lazy(() => import("react-spinner-loader"));
  const checkout = useSelector((state) => state?.finalizeOrderCheckout);
  const selected_outlet_id = localStorage.getItem("selected_outlet_id");
  const selected_modes = localStorage.getItem("selected_modes");
  const address_id = localStorage.getItem("address_id");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [orderType, setOrderType] = useState("one-time");
  const [frequency, setFrequency] = useState("daily");
  const [custom_dates, setCustom_dates] = useState([]);
  const [completePaymentModal, setCompletePaymentModal] = useState(false);
  const { userInfo } = useSelector((state) => state?.userSignin);
  const { userCart } = useSelector((state) => state?.getUserCart);
  const addressData = useSelector((state) => state?.userAddressList);
  const loadingAddressData = useSelector((state) => state?.userAddressList);
  const futureSchedule = useSelector((state) => state?.futureSchedule);
  const { validate, error } = useSelector((state) => state?.validateCheckout);
  const validateerror = useSelector((state) => state?.validateCheckout);
  const [discountpercentage, setdiscountpercentage] = useState("0");
  const { status } = useSelector((state) => state?.applyCoupon);
  const outlet = useSelector((state) => state?.getOutlet);
  const selectedAddress = addressData?.userInfo?.data?.address?.find(
    (address) => (address.address_id == address_id ? address : null)
  );
  const data = useSelector((state) => state?.outletList);
  const days =
    localStorage.getItem("spend_policy_days") === "undefined"
      ? ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
      : JSON.parse(localStorage.getItem("spend_policy_days"));

  // const days = ["Mon", "Tue", "Wed", "Thu", "Fri"];
  console.log("futureSchedule", futureSchedule, userCart);
  // console.log("days", days);
  const [order_dates, setOrder_dates] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [confirmred, setconfirmred] = useState(false);
  const [totalPayable, setTotalPayable] = useState(null);
  const [discount, setDiscount] = useState(0);

  const [showTimeSlotModel, setShowTimeSlotModel] = useState(false);

  const buttonClasses = {
    color: "#3F4444",
    width: "100%",
    margin: "20px 0 0",
    padding: "15px 24px",
    fontSize: "20px",
    background: "#FFC200",
    fontFamily: "'Bebas Neue', serif",
    fontWeight: "400",
    lineHeight: "20px",
    whiteSpace: "nowrap",
    borderRadius: "32px",
    letterSpacing: "0",
  };

  // const { userInfo } = useSelector((state) => state?.userSignin);
  const [viewmores, setviewmore] = useState(false);

  const { checkoutOrder } = useSelector(
    (state) => state?.finalizeOrderCheckout
  );

  console.log("finalizeOrderCheckout", checkoutOrder);
  const [currentWalletBalance, setCurrentWalletBalance] = useState(null);
  const defaultMode = localStorage.getItem("selected_modes");


  let frequencyTotal = 1;
  const orderDatesHandler = () => {
    const temp = [];

    futureSchedule?.frequency?.data?.orders?.map((order) => {
      if (order?.is_fullfilled === 1) temp?.push(order?.date);
    });

    setOrder_dates(temp);
  };

  useEffect(() => {
    if (orderType === "one-time") {
      setEnd_date(null);
    }
  }, [orderType]);

  // useEffect(()=>{
  //     localStorage.removeItem("daysOpenB2B")
  //     localStorage.removeItem("daysOpen")
  // },[])

  // useEffect(()=>{
  //   if(typeof outlet?.outlet?.data?.outlet_name !=="undefined")
  //   localStorage.setItem("selected_outlet_name",outlet?.outlet?.data?.outlet_name)
  // },[outlet])

  const selectedmoderender = () => {
    console.log("selectdemode", selected_modes)
    return selected_modes == 3 ? "Delivery Day" : "Pick-Up Day"
  }

  useEffect(() => {
    if (checkout?.error && checkout?.status === 408) {
      setShowTimeSlotModel(true);
    }
  }, [checkout]);

  useEffect(() => {
    frequencyTotal =
      futureSchedule?.frequency?.data?.total_order === undefined &&
        frequencyTotal === 1
        ? 1
        : futureSchedule?.frequency?.data?.total_order;
    calculateOrder(frequencyTotal);
  }, [order_dates, futureSchedule, orderType, status]);

  let [totalRemainingCoupon, setTotalRemainingCoupon] = useState(1);
  //changed
  const count = useRef(1);

  const couponApply = () => {
    //max coupon
    let overAllTotalCoupon = userCart?.data?.over_all_Coupon_usage;
    //coupon used till now
    let totalCouponUsedCount = userCart?.data?.coupon_used_overall_count;
    let discountedPrice = userCart?.data?.discounted_total;
    console.log("discountedPrice", discountedPrice);
    let originalPrice = userCart?.data?.grand_total;
    //one user how many times can use a coupon
    let perUserCouponLimit = userCart?.data?.user_coupon_usage;
    // till now how much coupon logged in user have used
    let loggedInUserCouponUsedTillNowCount =
      userCart?.data?.coupon_used_user_count;

    let totalOrder = futureSchedule?.frequency?.data?.total_order;
    let totalCouponUserCount = userCart?.data?.coupon_used_overall_count;

    let totalPriceValue;
    let Total_remainig_per_coustmer_coupon =
      perUserCouponLimit - loggedInUserCouponUsedTillNowCount;

    // const totalcondition=()=>{
    if (perUserCouponLimit == 0 && overAllTotalCoupon !== 0) {
      // console.log("totalcoupondduservik")

      setTotalRemainingCoupon(overAllTotalCoupon - totalCouponUsedCount);
      if (totalOrder > totalRemainingCoupon) {
        let remainingTotalOrderNotApplicableToCoupon =
          totalOrder - totalRemainingCoupon;

        let couponAppliedOrdersValue = totalRemainingCoupon * discountedPrice;
        let withOutCouponAppliedValue =
          originalPrice * remainingTotalOrderNotApplicableToCoupon;

        totalPriceValue = parseFloat(
          couponAppliedOrdersValue + withOutCouponAppliedValue
        ).toFixed(1);
        //86.4
      } else {
        // console.log("totalcoupondduservik")
        setTotalRemainingCoupon(totalOrder);
        totalPriceValue = parseFloat(totalOrder * discountedPrice).toFixed(1);
      }
    } else if (perUserCouponLimit !== 0 && overAllTotalCoupon !== 0) {
      // console.log("totalcoupondduservik")
      setTotalRemainingCoupon(overAllTotalCoupon - totalCouponUsedCount);
      if (totalRemainingCoupon < Total_remainig_per_coustmer_coupon) {
        // let totalOrder = futureSchedule?.frequency?.data?.total_order;
        if (totalRemainingCoupon < totalOrder) {
          let coupon_not_applied = Math.abs(totalOrder - totalRemainingCoupon);
          let coupon_not_applied_price = coupon_not_applied * originalPrice;
          let coupon_applied = totalRemainingCoupon * discountedPrice;
          totalPriceValue = coupon_not_applied_price + coupon_applied;
        } else {
          // console.log("totalcoupondduservik")
          setTotalRemainingCoupon(totalOrder);
          totalPriceValue = totalOrder * discountedPrice;
        }
      } else {
        if (Total_remainig_per_coustmer_coupon < totalOrder) {
          // console.log("totalcoupondduservik")
          setTotalRemainingCoupon(Total_remainig_per_coustmer_coupon);
          let coupon_not_applied =
            totalOrder - Total_remainig_per_coustmer_coupon;
          let coupon_not_applied_price = coupon_not_applied * originalPrice;
          let coupon_applied_price =
            Total_remainig_per_coustmer_coupon * discountedPrice;
          totalPriceValue = coupon_not_applied_price + coupon_applied_price;
        } else {
          // console.log("totalcoupondduservik")
          setTotalRemainingCoupon(totalOrder);
          totalPriceValue = totalOrder * discountedPrice;
        }
      }
    }
    // console.log(totalRemainingCoupon, "gykjfhjgch");
    else if (overAllTotalCoupon == 0 && perUserCouponLimit !== 0) {
      let remainig_coupon =
        perUserCouponLimit - loggedInUserCouponUsedTillNowCount;

      if (totalOrder > remainig_coupon) {
        let coupon_not_applied = totalOrder - remainig_coupon;
        let coupon_applied_price = remainig_coupon * discountedPrice;
        let coupon_not_applied_price = coupon_not_applied * originalPrice;
        // console.log("totalcoupondduservik")
        setTotalRemainingCoupon(remainig_coupon);
        totalPriceValue = coupon_applied_price + coupon_not_applied_price;
      } else {
        totalPriceValue = discountedPrice * totalOrder;
        // console.log("totalcoupondduservik")
        setTotalRemainingCoupon(totalOrder);
      }
    } else if (overAllTotalCoupon == 0 && perUserCouponLimit == 0) {
      if (totalOrder == undefined) {
      } else {
        count.current = totalOrder;
        // console.log("totalcoupondduservik", totalOrder)
        // console.log(count.current);
        setTotalRemainingCoupon(totalOrder);
      }

      totalPriceValue = totalOrder * discountedPrice;
    }
  };


  useEffect(() => {
    if (userCart == undefined) {
    } else {
      console.log("totalcoupondduser", count.current);
      couponApply();
    }
  }, [calculateOrder, userCart, orderType]);
  function calculateOrder(fTotal = 1) {
    console.log("totalcouponddeeapply", count);
    let frequencyTotal = fTotal;
    if (orderType === "one-time") frequencyTotal = 1;
    console.log("frequency2", frequencyTotal);

    const total =
      orderType == "recurring" || orderType == "custom"
        ? parseFloat(
          (userCart?.data?.grand_total !== ""
            ? userCart?.data?.grand_total
            : 0) +
          (userCart?.data?.delivery_fee !== "" ||
            userCart?.data?.delivery_fee !== "0.00"
            ? parseFloat(userCart?.data?.delivery_fee)
            : 0)
        ).toFixed(1)
        : parseFloat(
          (userCart?.data?.grand_total !== ""
            ? userCart?.data?.grand_total
            : 0) +
          (userCart?.data?.delivery_fee !== "" ||
            userCart?.data?.delivery_fee !== "0.00"
            ? parseFloat(userCart?.data?.delivery_fee)
            : 0) -
          (userCart?.data?.discounted_total !== "" ||
            userCart?.data?.discounted_total !== 0
            ? userCart?.data?.discounted_total
            : 0)
        ).toFixed(2);
    console.log(
      "orderonetime",
      total,
      userCart?.data?.grand_total,
      userCart?.data?.delivery_fee,
      userCart?.data?.discounted_total
    );


    const discount =
      userCart?.data?.coupon_used !== ""
        ? parseFloat(
          //i put && instead of ||
          (userCart?.data?.grand_total !== "" &&
            userCart?.data?.grand_total > 0
            ? userCart?.data?.grand_total
            : 0) -
          //i put && instead of ||
          (userCart?.data?.discounted_total !== "" &&
            userCart?.data?.discounted_total !== 0
            ? userCart?.data?.discounted_total
            : 0)
        ).toFixed(2)
        : 0;
    console.log("discount", discount);

    setDiscount(discount);


    console.log("totalcouponddee", totalRemainingCoupon);
    if (typeof totalRemainingCoupon == "undefined") {
      totalRemainingCoupon = 1;
    } else if (orderType == "one-time") {
      totalRemainingCoupon = 1;
    }
    let couponApplicableTo = totalRemainingCoupon;
    console.log("totalcoupondd", totalRemainingCoupon);
    // if (frequencyTotal < diffBetweenMax_Used_CouponCount) {
    //   couponApplicableTo = frequencyTotal;
    // }
    let discountpercentage = (
      100 / Math.ceil((discount / userCart?.data?.grand_total) * 100)
    ).toFixed(2);
    setdiscountpercentage(
      (100 / Math.ceil((discount / userCart?.data?.grand_total) * 100)).toFixed(
        2
      )
    );
    console.log(
      "coupontotal",
      userCart,
      total,
      frequencyTotal,
      couponApplicableTo
    );
    console.log(
      "tatalpaydata",
      discountpercentage,
      total,
      userCart?.data?.delivery_fee,
      frequencyTotal,
      userCart?.data?.discounted_total,
      couponApplicableTo,
      userCart?.data?.grand_total
    );
    setTotalPayable(


      userCart?.data?.discounted_total !== 0 &&
        userCart?.data?.coupon_used !== ""
        ? parseFloat(
          (
            parseFloat(userCart?.data?.delivery_fee) * frequencyTotal +
            parseFloat(userCart?.data?.discounted_total) *
            couponApplicableTo +
            parseFloat(userCart?.data?.grand_total) *
            parseFloat(frequencyTotal - couponApplicableTo)
          ).toFixed(1)
        )
        : discountpercentage == "1.00"
          ? 0
          : parseFloat(parseFloat(total) * parseFloat(frequencyTotal)).toFixed(1)
    );
  }

  const [alterpopup, setalterpopup] = useState(false);
  const yourOrder = userCart?.data?.cart_items;
  // console.log("checkout",checkout,checkout?.error,isOpen,checkout?.status,validate,error,validateerror)

  useEffect(() => {
    if (checkout?.error && isOpen && checkout?.status !== 408) {
      setalterpopup(true);
    }
  }, [checkout]);

  useEffect(() => {
    if (validateerror?.error && isOpen && validateerror?.status !== 408) {
      setShowModel(false)
      setalterpopup(true);


    } else if ((parseFloat(currentWalletBalance) < parseFloat(totalPayable)) && (validateerror?.validate)) {
      setShowModel(true)
    }

  }, [validateerror]);

  const snackHandler = (event, reason) => {
    setIsOpen(false);
    setShowModel(false);
    dispatch({ type: ActionType.CLEAR_RESPONSE }, setDisable(false));
    dispatch({ type: ActionType.CLEAR_COUPON });
    dispatch({ type: ActionType.CLEAR_CHECKOUT });
    // if (reason === "clickaway") return;
  };

  const bindingHandler = (date, name) => {
    if (date?.start_date) {
      return setStart_date(supplyDateFormat(date?.start_date));
    }
    if (date?.end_date) {
      return setEnd_date(supplyDateFormat(date?.end_date));
    }
  };


  const multiBindingHandler = (dates) => {
    setCustom_dates(
      dates?.map((date) => `${date?.year}-${date?.month?.number}-${date?.day}`)
    );
    // let temp = [];
    // dates?.map(
    //   (date) =>
    //     temp.push(date?.weekDay?.name) ?? selectedDays !== date?.weekDay?.name
    // );
    // console.log("temp", temp);
    // setSelectedDays(temp);
  };

  const payloadHandler = () => {
    if (orderType === "recurring" && frequency === "daily") return [frequency];
    else if (orderType === "custom" && frequency === "daily")
      return [frequency];
    else if (selected_modes === "pickup" && frequency === "daily")
      return selectedDays;
    else return selectedDays;
  };

  const confirmOrderHandler = () => {
    setconfirmred(false);
    const delivery_days = payloadHandler();
    // console.log("handled", delivery_days);
    const payload = {
      cart_id,
      delivery_days,
      end_date,
      // end_date: custom_dates.length !== 0 ? custom_dates.pop() : end_date,
      item_count: userCart?.data?.item_count,
      number_of_order: futureSchedule?.frequency?.data?.total_order,
      order_date: order_dates?.length > 0 ? order_dates[0] : start_date,
      order_dates,
      order_mode: selected_modes,
      order_time_slot: selectedSlot,
      order_type: orderType,
      outlet_id: selected_outlet_id,
      recurring_frequency: frequency,
      start_date,
    };

    // if (userCart?.data?.is_mobile_verified === 1) {
    if (parseFloat(currentWalletBalance) < parseFloat(totalPayable)) {
      // localStorage.setItem("checkoutData", JSON.stringify(payload));
      // localStorage.setItem("totalPayable", totalPayable);
      // setShowModel(true);

      dispatch(
        validateCheckoutAction(payload, "wallet"),
        localStorage.setItem("totalPayable", totalPayable),
        // setShowModel(true),
        setIsOpen(true)
      );

      // if (validateerror?.error && validateerror?.status !== 408) {
      //   setalterpopup(true);

      // }
    } else {
      if (userCart?.data?.is_mobile_verified === 0) {
        dispatch(
          validateCheckoutAction(payload, "mobile"),
          setDisable(true),
          setIsOpen(true)
        );
      } else {
        if (userCart?.data?.is_mobile_verified === 1) {
          dispatch(
            finalizeOrderCheckout(payload),
            setDisable(true),
            localStorage.removeItem("checkoutData")
          );
          setIsOpen(true);
        }
      }
      // dispatch(finalizeOrderVerify(payload));
    }
    // }
  };
  const selectionHandler = (selection) => {
    setSelectedSlot(selection);
  };
  // const daysdays=(userCart?.data?.outlet_weekly_calendar?.days_open.includes(userCart?.data?.spend_policy))
  // console.log("useInforday",daysdays);
  const arr1 = userCart?.data?.outlet_weekly_calendar?.days_open;
  const arr2 = userCart?.data?.spend_policy;
  console.log("array", arr1, arr2);

  console.log("userInfor", userInfo, userCart);
  const daysHandler = () => {
    let daysResult = null;
    if (userInfo?.data?.user_data?.role_name === "company_employee") {
      const arr1 = userCart?.data?.outlet_weekly_calendar?.days_open;
      const arr2 = userCart?.data?.spend_policy;
      var array = [];
      for (let i = 0; i < arr2?.length; i++) {
        if (arr1.indexOf(arr2[i]) >= 0) {
          array.push(arr2[i]);
        }
      }

      // const array = arr1?.map(
      //   (element, index) => arr2?.indexOf(element >= 0) && element
      // );

      if (array.length > 0) {
        localStorage.setItem("daysOpenB2B", JSON.stringify(array));
      }

      if (localStorage.getItem("daysOpenB2B") != null) {
        daysResult = JSON.parse(localStorage.getItem("daysOpenB2B"));
      } else {
        daysResult = [];
      }
      // daysResult = userCart?.data?.spend_policy ? array : null;
      // daysResult = localStorage.getItem("daysOpenB2B")!=null ? JSON.parse(localStorage.getItem("daysOpenB2B")):[];
    } else {

      daysResult =
        userCart?.data?.outlet_weekly_calendar?.days_open == undefined
          ? JSON.parse(localStorage?.getItem("daysOpen"))
          : userCart?.data?.outlet_weekly_calendar?.days_open;
    }
    allafterdays(daysResult)
    return daysResult;
  };
const countss =useRef(1)
  const allafterdays = (value) => {
    const days = value;
    let affowdaysafter = ""
    const newdate = new Date()
    let newdays = newdate.getDay() == 0 ? "Sunday" : newdate.getDay() == 1 ? "Monday" : newdate.getDay() == 2 ? "Tuesday" : newdate.getDay() == 3 ? "Wednesday" : newdate.getDay() == 4 ? "Thursday" : newdate.getDay() == 5 ? "Friday" : "Saturday"
    for (let i = 0; i < days?.length; i++) {
      if (days[i]?.indexOf(newdays) > -1) {

        affowdaysafter = "np"
        countss.current=1
      }
    }
if(selected_modes==2 && affowdaysafter==""){
  countss.current=2
}
    console.log("affowdaysafter", affowdaysafter, selected_modes)
  }
  useEffect(() => {
    dispatch(getUserCart(cart_id));
  }, [status]);
console.log("countss.current",countss.current)
  useEffect(() => {
    const forder =
      futureSchedule?.frequency?.data?.total_order != 0
        ? futureSchedule?.frequency?.data?.total_order
        : 1;
    calculateOrder(forder);
  }, [orderType, userCart, status, totalRemainingCoupon]);

  useEffect(() => {
    if (futureSchedule?.error) {
      dispatch({ type: "CLEAR_FUTURE_SCHEDULE" }, setDisable(true));
    }
  }, [futureSchedule, orderType]);

  useEffect(() => {
    let payload = {
      outlet_id: selected_outlet_id,
      order_mode: selected_modes,
      delivery_frequency: frequency,
      delivery_days: frequency === "weekly" ? selectedDays : days,
      start_date,
      end_date: orderType === "custom" ? "" : end_date,
      order_time_slot: selectedSlot,
      order_type: orderType,
      custom_dates,
    };
    if (
      orderType === "one-time" &&
      futureSchedule?.frequency?.data?.total_order > 0
    ) {
      dispatch({ type: "CLEAR_FUTURE_SCHEDULE" });
    }

    if (selectedSlot === "") {
      dispatch({ type: "CLEAR_FUTURE_SCHEDULE" });
    }

    if (start_date != null && selectedSlot != "") {
      if (orderType === "recurring") {
        if (end_date != null) {
          dispatch(futureScheduleAction(payload));
        }
      } else if (orderType === "custom") {
        // if (custom_dates.length > 0) {
        dispatch(futureScheduleAction(payload));
        // }
      }
    }
    if (orderType === "one-time") {
      // frequencyTotal = 1;
      const folder =
        futureSchedule?.frequency?.data?.total_order != 0
          ? futureSchedule?.frequency?.data?.total_order
          : 1;
      calculateOrder(folder);
    }
  }, [
    selectedSlot,
    frequency,
    selectedDays,
    start_date,
    end_date,
    orderType,
    custom_dates,
    status,
    totalRemainingCoupon,
  ]);

  useMemo(() => {
    orderDatesHandler();
  }, [futureSchedule, userCart, status]);

  useEffect(() => {
    setCurrentWalletBalance(userCart?.data?.wallet_balance);
    const forder =
      futureSchedule?.frequency?.data?.total_order != 0
        ? futureSchedule?.frequency?.data?.total_order
        : 1;
    calculateOrder(forder);
  }, [userCart, orderType, status, totalRemainingCoupon]);

  useEffect(() => {
    if (validate?.success && validate?.type === "mobile")
      return navigate("/edit-phone-number");
  }, [validate]);
  const [startdd, setstartdd] = useState()
  useEffect(() => {
    setstartdd(new Date())
  }, [])

  useEffect(() => {
    if (futureSchedule?.error) {
      let msg = "The end date must be a date before or equal";
      if (futureSchedule?.error.indexOf(msg) > -1) {
        setErrorMessage(futureSchedule?.error);
      }
    } else {
      setErrorMessage("");
    }
  }, [futureSchedule]);

  useEffect(() => {
    if (checkout?.checkoutOrder?.success) return navigate("/order-confirm");
  }, [checkoutOrder]);

  useEffect(() => {
    dispatch({ type: ActionType.CLEAR_COUPON });
    dispatch(getAddressDetailList());
    dispatch(getOutlet(selected_outlet_id));
    dispatch(getUserCart(cart_id));
  }, []);

  const renderPaymentSummary = () => {
    let applicableTo = totalRemainingCoupon,
      totalDiscount;

    if (orderType === "one-time") {
      if (
        futureSchedule?.frequency?.data?.total_order &&
        futureSchedule?.frequency?.data?.total_order !== 0
      ) {
        applicableTo = futureSchedule?.frequency?.data?.total_order;
      } else {
        applicableTo = 1;
      }
    } else if (orderType === "recurring" || orderType === "custom") {
      // if (futureSchedule?.frequency?.data?.total_order < applicableTo) {
      //   applicableTo = futureSchedule?.frequency?.data?.total_order;
      // }
      // userCart?.data?.max_coupon_usage_count -
      // userCart?.data?.coupon_used_count;
      // console.log("f*", futureSchedule?.frequency?.data?.total_order);
      // console.log(
      //   "c*",
      //   userCart?.data?.max_coupon_usage_count -
      //     userCart?.data?.coupon_used_count
      // );
      // console.log("applicableTo*", applicableTo);
      // if (futureSchedule?.frequency?.data?.total_order > applicableTo) {
      //   applicableTo = futureSchedule?.frequency?.data?.total_order;
      // } else {
      // }
      // console.log("G*", applicableTo);
      // }
      // if (futureSchedule?.frequency?.data?.total_order < applicableTo) {
      //   applicableTo = futureSchedule?.frequency?.data?.total_order;
      //   // console.log("Q*", applicableTo);
      // }
    }
    applicableTo =
      applicableTo == 0 ? 1 : applicableTo == undefined ? 1 : applicableTo;
    let discountper = (
      100 / Math.ceil((discount / userCart?.data?.grand_total) * 100)
    ).toFixed(2);
    totalDiscount =
      selected_modes == 3
        ? parseFloat(discount) * applicableTo
        : parseFloat(discount) * applicableTo;

    const basePrice = parseFloat(totalPayable) + parseFloat(totalDiscount);
    console.log(
      "totalDiscount",
      totalDiscount,
      applicableTo,
      discount,
      discountper,
      parseFloat(userCart?.data?.delivery_fee),
      totalPayable,
      parseFloat(userCart?.data?.delivery_fee / discountper)
    );
    // console.log("applicableTo", applicableTo);
    // console.log("discount", discount);
    return (
      <div className={classes.listingOuter}>
        <h4>Payment summary</h4>
        <ul className={classes.listingBox}>
          <li>
            <div className={classes.dFlex}>
              <div className={classes.ltBox}>
                <h5>
                  subtotal
                  {(orderType === "recurring" || orderType === "custom") &&
                    `/Order`}
                </h5>
              </div>
              <div className={classes.rtBox}>
                <p>{userCart?.data?.grand_total} Credits</p>
              </div>
            </div>
            {selected_modes === "3" && (
              <div className={classes.dFlex}>
                <div className={classes.ltBox}>
                  <h5>
                    Delivery fee
                    {(orderType === "recurring" || orderType === "custom") &&
                      `/Order`}
                  </h5>
                </div>
                <div className={classes.rtBox}>
                  <p>
                    {/* {parseFloat(userCart?.data?.delivery_fee).toFixed(1)} */}
                    {displayAmountFormat(parseFloat(userCart?.data?.delivery_fee).toFixed(1))}
                    {" "}Credits
                  </p>
                </div>
              </div>
            )}
            {(orderType === "recurring" || orderType === "custom") && (
              <div className={classes.dFlex}>
                <div className={classes.ltBox}>
                  <h5>Total no of orders</h5>
                </div>
                <div className={classes.rtBox}>
                  <p>{futureSchedule?.frequency?.data?.total_order} Orders</p>
                </div>
              </div>
            )}
            <div className={classes.dFlex}>
              <PromoCode />
            </div>
            {userCart?.data?.discounted_total !== 0 &&
              userCart?.data?.coupon_used !== "" &&
              userCart?.data?.discounted_total !== "" && (
                <div className={classes.dFlex}>
                  <div className={classes.ltBox}>
                    <h5>DISCOUNT</h5>
                  </div>
                  <div className={classes.rtBox}>
                    <p>- {displayAmountFormat( parseFloat(totalDiscount).toFixed(1))} Credits</p>
                    {futureSchedule?.frequency?.data?.total_order > 1 &&
                      userCart?.data?.coupon_used !== "" &&
                      (totalRemainingCoupon &&
                        futureSchedule?.frequency?.data?.total_order >
                        totalRemainingCoupon ? (
                        <p>
                          Your discount of {parseFloat(totalDiscount / totalRemainingCoupon).toFixed(1)} credits is applicable to {totalRemainingCoupon} of {futureSchedule?.frequency?.data?.total_order} orders.
                          {/* {`Coupon is applicable to ${totalRemainingCoupon} out of ${futureSchedule?.frequency?.data?.total_order} ${orderType} orders`} */}
                        </p>
                      ) : (
                        <p>
                          Your discount of {parseFloat(totalDiscount / totalRemainingCoupon).toFixed(1)} credits is applicable to all orders.
                          {/* Yay! Coupon is applicable to all {orderType} orders */}
                        </p>
                      ))}
                  </div>
                </div>
              )}
            <div className={classes.dFlex}>
              <div className={classes.ltBox}>
                <h5>Current Wallet Balance</h5>
              </div>
              <div className={classes.rtBox}>
                <p>{currentWalletBalance} Credits</p>
              </div>
            </div>
            <div className={classes.dFlex}>
              <div className={classes.ltBox}>
                <h5 className={classes.m0}>Total Payable</h5>
              </div>
              <div className={classes.rtBox}>
                <p className="fontWeight700">
                  {userCart?.data?.discounted_total !== 0 &&
                    userCart?.data?.discounted_total !== "" &&
                    userCart?.data?.coupon_used != "" && (
                      <strike className="oldTotal">
                        {displayAmountFormat(parseFloat(basePrice).toFixed(1))}
                      </strike>
                    )}
                  {/* {discountpercentage == "1.00" && (
                    <strike className="oldTotal">
                      {parseFloat(basePrice).toFixed(1)}
                    </strike>
                  )

                  } */}
                  {/* {totalPayable} */}
                  {/* {console.log("totalpayablevikalp",typeof totalPayable)} */}
{displayAmountFormat(typeof totalPayable=="number"?totalPayable?.toString():totalPayable)}{" "}
                   Credits
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  };

  const confirmbutton = UseButtonmulticlick(async () => {
    confirmOrderHandler()
  })

  const renderConfirmButton = () => {
    let tempStatus = true;
    if (orderType === "one-time" && selectedSlot !== "") {
      tempStatus = false;
    } else {
      if (futureSchedule?.frequency?.data?.orders?.length > 0) {
        tempStatus = false;
      }
    }
    return (
      <div className={classes.p16}>
        {futureSchedule?.frequency?.data?.orders?.find(
          (index) => index.is_fullfilled == 0
        ) ? (
          <>
            <YellowBtn
              name={"Confirm"}
              disable={tempStatus}
              // clickHandler={confirmOrderHandler}
              clickHandler={() => setconfirmred(!confirmred)}
            // clickHandler={proceedToDeliveryHandler}
            />
          </>
        ) : (
          <>
            <YellowBtn
              name={"Confirm"}
              disable={tempStatus}
              // clickHandler={confirmOrderHandler}
              clickHandler={confirmbutton}
            // UseButtonmulticlick(confirmOrderHandler())
            // clickHandler={() => setShowModel(!showModel)}
            // clickHandler={proceedToDeliveryHandler}
            />
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (status?.success) {
      setIsOpen(false);
    }
  }, [status]);

  const [startallow, setstartallow] = useState(1);
  const allowsd = () => {
    if (selected_modes == 3) {
      if (orderType == "recurring") {
        setstartallow(3);
      } else {
        setstartallow(3.1);
        setTimeout(() => {
          setstartallow(3);
        }, 100);
      }
    } else {
      if (orderType == "recurring") {
        setstartallow(1);
      } else {
        setstartallow(1.1);
        setTimeout(() => {
          setstartallow(1);
        }, 100);
      }
    }
  };
  useEffect(() => {
    allowsd();
  }, [orderType]);

const startdatename=()=>{
  if(selected_modes==3){
    if(orderType!=="recurring"){
      return "Delivery on"
    }else{
     return "Start Date"
    }
  }
  else{
    if(orderType!=="recurring"){
      return "Pick-up on"
    }else{
     return "Start Date"
    } 
  }

}

  const renderdayscomponents = () => {
    const daysallow = daysHandler();

    const daysmap = daysallow?.map((item, i) => {
      return item.slice(0, 3)
    })
    console.log("days", daysmap, days)

    // let disableday;
    // for (let i = 0; i < daysmap.length; i++) {
    //   console.log("days", daysmap[i])
    //   disableday = daysmap[i]
    // }
    return <MultiGroupToggler
      onSelected={selectedDays}
      setOnSelected={setSelectedDays}
    >
      {days?.map((element, index) => (
        (daysmap.indexOf(element) >= 0) && (
          <ToggleButton key={index} value={element}
            disabled={daysmap.indexOf(element) == -1 ? true : false}
          >
            <h5>{element}</h5>
          </ToggleButton>
        )
      ))}
    </MultiGroupToggler>
  }

  const totalPayablefun=()=>{
    return typeof totalPayable=="number"?totalPayable?.toString():totalPayable
  }
  return (
    <>
      <Suspense>
        {data?.loading && (
          <Loader
            show={data?.loading}
            type="body"
            stack="vertical"
            message="Loading..."
          />
        )}
        {loadingAddressData?.loading && (
          <Loader
            show={loadingAddressData?.loading}
            type="body"
            stack="vertical"
            message="Loading..."
          />
        )}
        {/* {(checkout?.checkoutOrder?.success || checkout?.error) && isOpen && (
          <AlterMessage
            closeHandler={snackHandler}
            description={
              checkout?.checkoutOrder?.success
                ? checkout?.checkoutOrder?.message
                : checkout?.error
            }
            title={checkout?.checkoutOrder?.success ? "Success" : "Error"}
            type={checkout?.checkoutOrder?.success ? "Success" : "Error"}
            open={isOpen}
            url={
              checkout?.checkoutOrder?.success
                ? "./images/success.svg"
                : "./images/error.svg"
            }
          />
        )} */}

        {checkout?.error && checkout?.status === 408 && (
          <CustomModal
            open={showTimeSlotModel}
            modalClose={() => {
              setShowTimeSlotModel(false);
              dispatch({ type: ActionType.CLEAR_CHECKOUT });
            }}
            // title={"TIME SLOT FULL!"}
            title={"THE CHOSEN TIME SLOT IS UNAVAILABLE"}
            // description={"Sorry, the selected time slot is currently full"}
            description={"Please select a different time slot."}
          >
            <div className={classes.customBtn}>
              <Button
                style={buttonClasses}
                onClick={() => {
                  setShowTimeSlotModel(false);
                  dispatch({ type: ActionType.CLEAR_CHECKOUT });
                }}
              >
                OKAY
              </Button>
              {/* <YellowBtn
                name="RESELECT TIMESLOT"
                clickHandler={() => {
                  setShowTimeSlotModel(false);
                  dispatch({ type: ActionType.CLEAR_CHECKOUT });
                }}
              /> */}
            </div>
          </CustomModal>
        )}

        {/* {checkout?.error && isOpen && checkout?.status !== 408 && (
          <AlterMessage
            description={checkout?.error}
            url={"./images/error.svg"}
            closeHandler={snackHandler}
            title={"Error"}
            type={"Error"}
            open={isOpen}
          />
        )} */}
        {/* {error && (
          <AlterMessage
            closeHandler={snackHandler}
            url={"./images/error.svg"}
            description={error?.message}
            title={"Error"}
            type={"Error"}
            open={isOpen}
          />
        )} */}
        {/* {status?.success && (
          <AlterMessage
            description={status?.message}
            closeHandler={snackHandler}
            url={"./images/success.svg"}
            open={status?.success}
            title={"Success"}
            type={"Success"}
          />
        )} */}
      </Suspense>

      <Container maxWidth="lg" className={classes.p0}>
        <div className={classes.outer}>
          <div className={classes.pY20}>
            <BackArrow
              clickHandler={() =>
                dispatch(
                  { type: ActionType.CLEAR_RESPONSE },
                  setDisable(false),
                  navigate("/cart-detail")
                )
              }
            />
            <h3 className={classes.m30}>{StrConstant.OrderReview.heading}</h3>
            <div>
              {selected_modes !== "3" ? (
                <div className={classes.innerBox}>
                  <div className={classes.headingBox}>
                    <h4>Pick-up location</h4>
                  </div>
                  <div className={classes.boxContainer}>
                    <div className={classes.pickupName}>
                      <div>
                        <p>{outlet?.outlet?.data?.outlet_name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={classes.innerBox}>
                  <div className={classes.headingBox}>
                    <h4>Delivery location</h4>
                  </div>
                  <div className={classes.boxContainer}>
                    <div className={classes.addressBox}>
                      <div className={classes.leftBox}>
                        <h4>{selectedAddress?.address_line1}</h4>
                        <p>{selectedAddress?.address_line2}</p>
                      </div>
                      <div className={classes.rightBox}>
                        <div>
                          {/* <div
                            className={classes.imgBox}
                            onClick={() => {
                              navigate("/schedule-delivery");
                            }}
                          >
                            {" "}
                            <img
                              src="images/pencil-gray.svg"
                              alt="better coffee"
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selected_modes !== "1" ? (
                <div className={`${classes.innerBox} ${classes.m0}`}>
                  <div className={classes.headingBox}>
                    <h4>Order Type</h4>
                  </div>
                  <div className={classes.boxContainer}>
                    <div className={classes.toggleBtnContent}>
                      <GroupToggleBtn>
                        <ToggleButton
                          selected={orderType === "one-time" ? true : false}
                          value="one-time"
                          onClick={() =>
                            dispatch(
                              { type: "CLEAR_FUTURE_SCHEDULE" },
                              setOrderType("one-time"),
                              setDisable(false)
                            )
                          }
                        >
                          <h5>One - Time</h5>
                        </ToggleButton>
                        <ToggleButton
                          value="recurring"
                          selected={orderType === "recurring" ? true : false}
                          onClick={() =>
                            dispatch(
                              { type: "CLEAR_FUTURE_SCHEDULE" },
                              setOrderType("recurring"),
                              setDisable(false)
                            )
                          }
                        >
                          <h5>Recurring</h5>
                        </ToggleButton>
                        <ToggleButton
                          value="custom"
                          selected={orderType === "custom" ? true : false}
                          onClick={() =>
                            dispatch(
                              { type: "CLEAR_FUTURE_SCHEDULE" },
                              setOrderType("custom"),
                              setDisable(false)
                            )
                          }
                        >
                          <h5>Custom</h5>
                        </ToggleButton>
                      </GroupToggleBtn>
                    </div>
                  </div>
                </div>
              ) : null}

              {orderType === "recurring" && (
                <div className={classes.innerBox}>
                  <div className={classes.headingBox}>
                    <h4>Frequency</h4>
                  </div>
                  <div className={classes.boxContainer}>
                    <div className={classes.toggleBtnContent}>
                      <GroupToggleBtn>
                        <ToggleButton
                          value="daily"
                          selected={frequency === "daily" ? true : false}
                          onClick={() => setFrequency("daily")}
                        >
                          <h5>Daily</h5>
                          {/* <span>Mon - Fri</span> */}
                        </ToggleButton>
                        <ToggleButton
                          value="weekly"
                          selected={frequency === "weekly" ? true : false}
                          onClick={() => setFrequency("weekly")}
                        >
                          <h5>Weekly</h5>
                        </ToggleButton>
                      </GroupToggleBtn>
                    </div>
                  </div>
                </div>
              )}
              {orderType === "recurring" && frequency === "weekly" && (
                <div className={classes.innerBox}>
                  <div className={classes.headingBox}>
                    <h4>{selectedmoderender()}</h4>
                  </div>
                  <div className={classes.boxContainer}>
                    <div
                      className={`${classes.toggleBtnContent} ${classes.toggleBox}`}
                    >
                      {/* <MultiGroupToggler
                        onSelected={selectedDays}
                        setOnSelected={setSelectedDays}
                      >
                        {days?.map((element, index) => (
                          <ToggleButton key={index} value={element}>
                            <h5>{element}</h5>
                          </ToggleButton>
                        ))}
                      </MultiGroupToggler> */}
                      {renderdayscomponents()}
                    </div>
                  </div>
                </div>
              )}

              {defaultMode != 1 ? (
                <div className={classes.dateContainer}>
                  <div className={`${classes.innerBox} ${classes.startDate}`}>
                    <div className={classes.headingBox}>
                      <h4>

                    

{startdatename()}
                        {/* {orderType === "custom" ? "Deliver on" : "Start Date"} */}
                      </h4>
                    </div>
                    <div className={classes.boxContainer}>
                      {orderType === "custom" ? (
                        <MultiDatePicker
                          onBinding={multiBindingHandler}
                          allowedDays={daysHandler()}
                          allowAfter={selected_modes === "3" ? 4 : 1}
                        />
                      ) : (
                        <CustomDatePicker
                          onBinding={bindingHandler}
                          name={"start_date"}
                          isPastDisable={true}
                          isWeekDayDisable={true}
                          // allowAfter={selected_modes === 3 ? 3 : 1}

                          allowAfter={
                            selected_modes == 3
                              ? orderType == "recurring"
                                ? 3.9
                                : 4
                              : orderType == "recurring"
                                ? 0.9
                                : 1
                          }
                          isFutureDisable={true}
                          maxFutureDate={startdd}
                          allowedDays={daysHandler()}
                        />
                      )}
                    </div>
                  </div>
                  {orderType === "recurring" && (
                    <>
                      <div className={`${classes.innerBox} ${classes.endDate}`}>
                        <div className={classes.headingBox}>
                          <h4>End Date</h4>
                        </div>
                        <div className={classes.boxContainer}>
                          <CustomDatePicker
                            onBinding={bindingHandler}
                            allowedDays={daysHandler()}
                            name={"end_date"}
                            isPastDisable={true}
                            isFutureDisable={true}
                            isWeekDayDisable={true}
                            allowAfter={countss.current}
                            maxFutureDate={start_date}

                          />
                          {errorMessage === "" ? null : (
                            <p className="errorMessage">{errorMessage}</p>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : null}

              <div className={classes.innerBox}>
                <div className={classes.headingBox}>
                  <h4>Time slot</h4>
                </div>
                <div className={classes.boxContainer}>
                  <div className={classes.selectBox}>
                    <TimeSlot
                      onSelection={selectionHandler}
                      onDate={
                        futureSchedule?.frequency?.data?.orders[0]?.date
                          ? futureSchedule?.frequency?.data?.orders[0]?.date
                          : orderType === "custom"
                            ? custom_dates[0]
                            : start_date
                      }
                    />
                  </div>
                </div>
              </div>

              {/* {orderType === "recurring" && (
              <div className={classes.yourOrder}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownCircleIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                   
                  >
                    <div className={classes.orderHeading}><h4>Order schedule</h4></div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.scheduleBox}>
                      <p>
                        Total numbers of the orders:{" "}
                        <b>{futureSchedule?.frequency?.data?.total_order}</b>
                      </p>
                      <p>
                        Your last order is{" "}
                        {futureSchedule?.frequency?.data?.last_order?.date}
                      </p>
                    </div>
                    <table className={classes.orderTable} border="0">
                      <thead>
                        <tr>
                          <th>Date selection</th>
                          <th>Day</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {futureSchedule?.frequency?.data?.orders?.map(
                          (order, index) => (
                            <tr key={index}>
                              <td>{order?.date}</td>
                              <td>{order?.day}</td>
                              <td>{order?.order_time_slot}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                    <div className={classes.btnBox}>
                      <YellowBtn name={"LOAD MORE"} />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            )} */}
            </div>
          </div>
          {(orderType === "recurring" || orderType === "custom") && (
            <Accordion
              className={classes.orderSchedule}
              expanded={
                futureSchedule?.frequency?.data?.orders.length > 0
                  ? true
                  : false
              }
              id="viewmores"
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownCircleIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.orderHeading}>
                  <h4>Order schedule </h4>
                  {futureSchedule?.frequency?.data?.orders?.find(
                    (index) => index.is_fullfilled == 0
                  ) && (
                      <div className={classes.infoIcon}>
                        {/* <ErrorOutlineIcon style={{ color: "#ff5722" }} /> */}
                        <CancelSharpIcon style={{ color: "#ff5722" }} />
                        <span>
                          Orders highlighted in red cannot be fulfilled because your chosen store / day / time slot is unavailable.
                        </span>
                      </div>
                    )}


                </div>
              </AccordionSummary>
              <AccordionDetails>
                {futureSchedule?.frequency?.data?.orders.length > 0 && (
                  <>
                    <div className={classes.scheduleBox}>
                      <p>
                        Total numbers of the orders:{" "}
                        <b>{futureSchedule?.frequency?.data?.total_order}</b>
                      </p>
                      {futureSchedule?.frequency?.data?.last_order
                        ?.display_date === "" ? null : (
                        <p>
                          Your last order is{" "}
                          {
                            futureSchedule?.frequency?.data?.last_order
                              ?.display_date
                          }
                        </p>
                      )}
                    </div>
                    <table
                      className={classes.orderTable}
                      border="0"
                      cellSpacing={0}
                      cellPadding={0}
                    >
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th className={classes.centerTxt}>Day</th>
                          <th className={classes.rightTxt}>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {futureSchedule?.frequency?.data?.orders
                          ?.slice(
                            0,
                            viewmores
                              ? futureSchedule?.frequency?.data?.orders?.length
                              : 10
                          )
                          .map((order, index) => (
                            <tr key={index}>
                              <td
                                className={
                                  order?.is_fullfilled == 1
                                    ? ""
                                    : classes.redTxt
                                }
                              >
                                {order?.display_date}
                              </td>
                              <td
                                className={
                                  order?.is_fullfilled == 1
                                    ? classes.centerTxt
                                    : classes.centerRedTxt
                                }
                              >
                                {order?.day}
                              </td>
                              <td
                                className={
                                  order?.is_fullfilled == 1
                                    ? classes.rightTxt
                                    : classes.RedrightTxt
                                }
                              >
                                <div className={classes.infoIconTable}>
                                  {order?.order_time_slot}
                                  {order?.is_fullfilled == 0 && (
                                    // <ErrorOutlineIcon />
                                    <CancelSharpIcon
                                      style={{ color: "#ff5722" }}
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                )}
                {/* <div className={classes.btnBox}>
                      {
                        futureSchedule?.frequency?.data?.orders.length > 2 && (
                          <YellowBtn name={"LOAD MORE"} />
                        )
                      }
                      
                    </div> */}
                <div className={classes.btnBox}>
                  {futureSchedule?.frequency?.data?.orders.length > 10 && (
                    <>
                      <Linkscroll
                        activeClass="active"
                        to="viewmores"
                        spy={true}
                        smooth={true}
                        offset={50}
                        duration={300}
                      >
                        <ViewMore
                          name={viewmores ? "View Less" : "View More"}
                          clickHandler={() => setviewmore(!viewmores)}
                        />
                      </Linkscroll>
                    </>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          )}
          <div className={classes.yourOrder}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ArrowDropDownCircleIcon />}
                // PaymentSumma
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h4>Your order </h4>
              </AccordionSummary>
              <AccordionDetails>
                {yourOrder?.map((element, index) => (
                  <ul key={index} className={classes.listingBox}>
                    <li>
                      <div className={classes.dFlex}>
                        <div className={classes.ltBox}>
                          {/* <h5>subtotal</h5> */}
                          <h6 className={classes.number}>
                            {element?.item_quantity}{" "}
                          </h6>
                          <h6> {element?.item_name}</h6>
                        </div>
                        <div className={classes.rtBox}>
                          <p className={classes.fontWeight700}>
                            {element?.item_total} Credits
                          </p>
                        </div>
                      </div>
                      {element?.hide_size != 1 && (
                        <div className={`${classes.dFlex} ${classes.pl}`}>
                          <div className={classes.ltBox}>
                            <p>{element?.item_size}</p>
                          </div>
                          {/* <div className={classes.rtBox}>
                            <p>
                              {element?.unit_price
                                ? `${
                                    parseInt(element?.unit_price) ===
                                    element?.unit_price
                                      ? parseInt(
                                          parseFloat(element?.unit_price) *
                                            parseFloat(element?.item_quantity)
                                        )
                                      : parseFloat(
                                          parseFloat(element?.unit_price) *
                                            parseFloat(element?.item_quantity)
                                        ).toFixed(1)
                                  }  Credits`
                                : "-"}{" "}
                            </p>
                          </div> */}
                        </div>
                      )}
                      {element?.modifiers.map((mData) => (
                        <div
                          key={Math.random()}
                          className={`${classes.dFlex} ${classes.pl}`}
                        >
                          <div className={classes.ltBox}>
                            <p>{mData?.modifier_name}</p>
                          </div>
                          {/* <div className={classes.rtBox}>
                            {element?.item_quantity *
                              parseFloat(mData?.modifier_price) ==
                            0 ? (
                              <p>-</p>
                            ) : (
                              <p>
                                {element?.item_quantity *
                                  parseFloat(mData?.modifier_price)}{" "}
                                Credits
                              </p>
                            )}
                          </div> */}
                        </div>
                      ))}

                      <div className={`${classes.dFlex} ${classes.pl}`}>
                        <div className={classes.ltBox}>
                          <p>
                            {element?.item_comment != ""
                              ? '"' + element?.item_comment + '"'
                              : ""}
                          </p>
                        </div>
                        <div className={classes.rtBox}></div>
                      </div>
                    </li>
                  </ul>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
          {/* render */}
          {renderPaymentSummary()}
          {/* <PaymentSummary userCart={userCart} /> */}
          {renderConfirmButton()}
        </div>
      </Container>
      <CustomModal
        open={completePaymentModal}
        title={"TO PROCEED, YOUR CART WILL BE CLEARED."}
        description={""}
      >
        <div className={classes.modalBtn}>
          <DarkGrayBtn
            name="cancel"
            clickHandler={() => setCompletePaymentModal(false)}
          />
          <YellowBtn
            name="confirm"
            clickHandler={() =>
              dispatch(
                navigate("/schedule-delivery"),
                localStorage.removeItem("address_id")
              )
            }
          />
        </div>
      </CustomModal>
      <CustomModal
        open={showModel}
        modalClose={() => setShowModel(false)}
        title={"YOU DON'T HAVE ENOUGH CREDITS"}
        description={`${userInfo?.data?.user_data?.role_name === "company_employee"
          ? "To proceed, remove items from your order or contact your admin for additional credits."
          : "To complete your order, please top-up your wallet."
          }`}
      >
        {userInfo?.data?.user_data?.role_name === "company_employee" ?
          (
            <ul className={classes.modalBox}>
              <li>
                <div className={classes.dFlex}>
                  <div className={classes.ltPanel}>
                    <h5>Current Wallet Balance</h5>
                  </div>
                  <div className={classes.rtPanel}>
                    <p>{currentWalletBalance} Credits</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={classes.dFlex}>
                  <div className={classes.ltPanel}>
                    <h5>Total Payable</h5>
                  </div>
                  <div className={classes.rtPanel}>
                    <p>
                      {/* {totalPayable} */}
                      {totalPayablefun()}
                     {/* {displayAmountFormat(totalPayable)}{" "} */}
                     Credits</p>
                  </div>
                </div>
              </li>
            </ul>
          ) : (
            <ul className={classes.modalBox}>
              <li>
                <div className={classes.dFlex}>
                  <div className={classes.ltPanel}>
                    <h5>Current Wallet Balance</h5>
                  </div>
                  <div className={classes.rtPanel}>
                    <p>{currentWalletBalance} Credits</p>
                  </div>
                </div>
              </li>
              <li>
                <div className={classes.dFlex}>
                  <div className={classes.ltPanel}>
                    <h5>Total Payable</h5>
                  </div>
                  <div className={classes.rtPanel}>
                    <p>
                      {/* {totalPayable}  */}
                    {displayAmountFormat(typeof totalPayable=="number"?totalPayable?.toString():totalPayable)}{" "}
                    Credits</p>
                  </div>
                </div>
              </li>
            </ul>
          )}

        <div className={classes.modalFlex}>
          {userInfo?.data?.user_data?.role_name === "company_employee" ? (
            <YellowBtn
              name="Back To Cart"
              clickHandler={() => navigate("/cart-detail")}
            />
          ) : (
            <YellowBtn
              name="Top - Up  Credits"
              clickHandler={() => {
                localStorage.setItem("insufficient", true);
                navigate("/purchase-credits", {
                  replace: true,
                  state: {
                    amount:
                      parseFloat(totalPayable) -
                      parseFloat(currentWalletBalance),
                    pageType: "Insufficient",
                  },
                });
              }}
            />
          )}
        </div>
      </CustomModal>

      {futureSchedule?.frequency?.data?.orders?.find(
        (index) => index.is_fullfilled == 0
      ) && futureSchedule?.frequency?.data?.orders?.find(
        (index) => index.is_fullfilled == 1
      ) ? <>

        <CustomModal
          open={confirmred}
          modalClose={() => setconfirmred(false)}
          title={"SOME ORDERS CANNOT BE FULFILLED"}
          description={
            "Orders highlighted in red cannot be fulfilled because your chosen store / day / time slot is unavailable. You may edit your selection, or proceed without those orders."}
        >
          <div
            //  className={classes.modalFlex}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ width: "45%" }}>
              <DarkGrayBtn
                name="Edit"
                clickHandler={() => setconfirmred(false)}
              />
            </div>
            <div style={{ width: "50%" }}>
              <YellowBtn name="Continue" clickHandler={confirmbutton} />
            </div>
          </div>
        </CustomModal>
      </> : <>

        <CustomModal
          open={confirmred}
          modalClose={() => setconfirmred(false)}
          title={"YOUR ORDERS CANNOT BE FULFILLED"}
          description={
            "Orders highlighted in red cannot be fulfilled because your chosen store / day / time slot is unavailable. Please edit your selection to proceed."}
        >
          <div
            className={classes.modalFlex}

          >

            <YellowBtn

              name="Edit"
              clickHandler={() => setconfirmred(false)}
            />


          </div>
        </CustomModal>
      </>}



      <CustomModal
        open={alterpopup}
        modalClose={() => {
          setalterpopup(false);
          dispatch({ type: ActionType.CLEAR_CHECKOUT });
        }}
        title={"THIS STORE IS CLOSED ON THE CHOSEN DAY"}
        description={"Please select a different day."}
      >
        <div
          className={classes.modalFlex}
        // style={{ display: "flex", justifyContent: "space-between" }}
        >
          <YellowBtn
            name="OKAY"
            clickHandler={() => {
              setalterpopup(false);
              dispatch({ type: ActionType.CLEAR_CHECKOUT });
            }}
          />
        </div>
      </CustomModal>
    </>
  );
};
export default withStyles(Styles)(OrderReview);
