import { Styles } from "./Style";
import { Link } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Loader from "react-spinner-loader";
import { useNavigate } from "react-router";
import FormLabel from "@mui/material/FormLabel";
import StrConstant from "../../../Common/StrConstant";
import { useDispatch, useSelector } from "react-redux";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import CommonRadio from "../../../Components/CommonRadio/CommonRadio";
import AlterMessage from "../../../Components/AlertMessage/AlertMessage";
import { cancelOrderAction } from "../../../redux/actions/order/orderActions";
import { useEffect, useState } from "react";
import { ActionType } from "../../../redux/ActionTypes";
import DarkGrayBtn from "../../../Components/DarkGrayBtn/DarkGrayBtn";
const radioData = [
  {
    name: "I ordered wrongly.",
    value: "I ordered wrongly.",
  },
  {
    name: "My schedule has changed.",
    value: "My schedule has changed.",
  },
];
const CancelOrder = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [iniValid, setIniValid] = useState(false);
  const [cancellation_reason, setCancellation_reason] = useState(null);
const orderno=localStorage.getItem('ordernumberno')
  const cancel_all_recurring = localStorage.getItem("cancel_all_recurring");
  const { loading, error, cancelOrder } = useSelector(
    (state) => state?.cancelOrder
  );
  const { viewOrderDetail } = useSelector((state) => state?.getOrderDetail);
  const submitReview = () => {
    setIniValid(false);
    const payload = {
      order_id: viewOrderDetail?.data?.order_id,
      cancel_all_recurring,
      cancellation_reason,
      comment,
    };
    if (cancellation_reason === null || cancellation_reason === "")
      return setIniValid(true);
    else return dispatch(cancelOrderAction(payload), setIsOpen(true));
  };
  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    dispatch({ type: ActionType.CLEAR_RESPONSE }, setIsOpen(false));
  };
  const bindingHandler = (binding) => {
    setCancellation_reason(binding);
  };
  useEffect(() => {
    if (cancelOrder?.success) navigate(-1);
  }, [cancelOrder]);
  console.log(viewOrderDetail, 'viewOrderDetail')

  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {error && (
        <AlterMessage
          closeHandler={snackHandler}
          url={"./images/error.svg"}
          description={error}
          title={"Error"}
          type={"Error"}
          open={isOpen}
        />
      )}
      <OuterBox>
        <div>
          <BackArrow clickHandler={() => navigate(-1)} />
          <h3>
            CANCEL ORDER
            {/* {viewOrderDetail?.data?.order_number}{" "} */}
            {/* {StrConstant.CancleOrder.heading} */}
          </h3>
        </div>
        <div>
          <div className={classes.mb10}>
            {/* <p className={classes.m0}>You are cancelling order(s):</p> */}
            {/* <p className={classes.m0}>{viewOrderDetail?.data?.order_number}{" "}</p> */}
          </div>

          <p>You are cancelling {cancel_all_recurring==1 && orderno?`${orderno} `:`1 `}order(s).  Upon confirmation, the credits will be refunded to your wallet immediately.</p>
          <p className={classes.m0}>Please tell us why you are cancelling your order(s):</p>
          <CommonRadio
            radioData={radioData}
            name={cancellation_reason}
            onBinding={bindingHandler}
          />
          {iniValid && <p className="errorMessage">This field is required.</p>}
          <div className={classes.fromControl}>
            <FormLabel>Comments</FormLabel>
            <textarea
              rows={5}
              value={comment}
              aria-label="comment"
              placeholder="Enter your comments"
              onChange={(event) => setComment(event.target.value)}
            ></textarea>
          </div>
          <div className={classes.btnBox}>
            {/* <Link to="">Back to my orders</Link> */}
            <DarkGrayBtn
              name={"Return to My Orders"}
              clickHandler={() => navigate(-1)}
            />
            <YellowBtn name={"Submit"} clickHandler={submitReview} />
          </div>
        </div>
      </OuterBox>
    </>
  );
};

export default withStyles(Styles)(CancelOrder);
