export const Styles = (Theme) => ({
  cardConteiner: {
    background: "#fff",
    borderRadius: "10px",
    "@media(max-width:640px)": {
      background: "transparent",
    },
  },

  ltBox: {
    paddingRight: "10px",
  },
  rtBox: {
    "& p": {
      lineHeight: "18px",
    },
  },
  card: {
    background: "#FFFFFF",
    borderRadius: "10px",
    padding: "20px",
    borderBottom: "1px solid #eee",
    width: "100%",
    marginBottom: "10px",
    "@media(max-width:640px)": {
      display: "block",
      marginBottom: "20px",
      borderBottom: "0",
    },
    "& ul": {
      margin: "0",
      "& li": {
        display: "flex",
        alignItems: "flex-start",
      },
    },
  },
  disable: {
    background: "#3F44441A",
    borderRadius: "0 0 10px 10px",
    "@media(max-width:640px)": {
      borderRadius: "10px",
    },
  },
});
