import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import FormLabel from "@mui/material/FormLabel";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Propover from "../../Components/Propover/Propover";
import {
  validateConfirmPassword,
  validatePassword,
  validateRequired,
} from "../../Common/Validations";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-spinner-loader";
import AlterMessage from "../AlertMessage/AlertMessage";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { userLogOut, userSetPassword } from "../../redux/actions/UserActions";
import StrConstant from "../../Common/StrConstant";
import LogoContainer from "../LogoContainer/LogoContainer";
const fields = {
  password: "",
  password_confirmation: "",
  inValid: false,
};
const SetPasswordForm = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { password_token } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [formFields, setFormFields] = useState(fields);
  const data = useSelector((state) => state?.setPassword);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const inputHandler = (e) => {
    const { name, value } = e.target;
    setFormFields((pre) => ({ ...pre, [name]: value }));
  };
  const setPasswordHandler = (e) => {
    e.preventDefault();
    if (
      validatePassword(formFields.password) ||
      validateRequired(formFields.password_confirmation)
    )
      return setFormFields((pre) => ({ ...pre, inValid: true }));
    else {
      const payload = {
        password_token,
        password: formFields.password,
        password_confirmation: formFields.password_confirmation,
      };
      dispatch(userSetPassword(payload), setIsOpen(true));
    }
  };
  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    setFormFields((pre) => ({ ...pre, inValid: false }));
    setIsOpen(false);
  };
  useEffect(() => {
    // if (data?.success) dispatch(userLogOut(true));
    if (data?.success) {
      localStorage.setItem("isChanged", true);
      sessionStorage.setItem("isChanged",true);
      navigate("/login");
    }
  }, [data]);
  return (
    <>
      {data?.loading && (
        <Loader
          show={data?.loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {/* {(data?.success || data?.error) && (
        <AlterMessage
          closeHandler={snackHandler}
          description={data?.success ? data?.setpassMesg?.data : data?.error}
          title={data?.error ? "Error" : "Success"}
          type={data?.error ? "Error" : "Success"}
          open={isOpen}
          url={data?.error ? "./images/error.svg" : "./images/success.svg"}
        />
      )} */}
      {data?.error && (
        <AlterMessage
          description={data?.error}
          closeHandler={snackHandler}
          url={"./images/error.svg"}
          title={"Error"}
          type={"Error"}
          open={isOpen}
        />
      )}
      <div className={classes.formInner}>
        <div>
          <LogoContainer
            heading={StrConstant.SetPassword.heading}
            goUrl={""}
            urlTxt={""}
          />
          {/* <h2 className={classes.mb20}>Set Password</h2> */}
          <form>
            <div className={classes.fromControl}>
              <FormLabel>
                New Password<span className="req">*</span>
                <Propover>
                  <b>{StrConstant.SignUp.propoverTitle}</b>
                  <ul>
                    <li>
                      <span>{StrConstant.SignUp.propoverDescription1}</span>
                    </li>
                    <li>
                      <span>{StrConstant.SignUp.propoverDescription2}</span>
                    </li>
                    <li>
                      <span>{StrConstant.SignUp.propoverDescription3}</span>
                    </li>
                    <li>
                      <span>{StrConstant.SignUp.propoverDescription4}</span>
                    </li>
                  </ul>
                </Propover>
              </FormLabel>
              <div className={classes.passwordBox}>
                <div className={classes.passwordField}>
                  <input
                    type={showNewPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter your new password"
                    value={formFields.password}
                    onChange={(e) => inputHandler(e)}
                  />
                  <span onClick={() => setShowNewPassword(!showNewPassword)}>
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </span>
                </div>
                {formFields.inValid && formFields.password === "" ? (
                  <p className="errorMessage">This field is required.</p>
                ) : null}
                {formFields.password !== "" &&
                validatePassword(formFields.password) ? (
                  <p className="errorMessage">
                    Information entered is invalid. Please try again.
                  </p>
                ) : null}
              </div>
            </div>
            <div className={classes.fromControl}>
              <FormLabel>
                Confirm New Password<span className="req">*</span>
                <Propover>
                  <b>{StrConstant.SignUp.propoverTitle}</b>
                  <ul>
                    <li>
                      <span>{StrConstant.SignUp.propoverDescription1}</span>
                    </li>
                    <li>
                      <span>{StrConstant.SignUp.propoverDescription2}</span>
                    </li>
                    <li>
                      <span>{StrConstant.SignUp.propoverDescription3}</span>
                    </li>
                    <li>
                      <span>{StrConstant.SignUp.propoverDescription4}</span>
                    </li>
                  </ul>
                </Propover>
              </FormLabel>
              <div className={classes.passwordBox}>
                <div className={classes.passwordField}>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="password_confirmation"
                    placeholder="Re-enter your new password"
                    value={formFields.password_confirmation}
                    onChange={(e) => inputHandler(e)}
                  />
                  <span
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </span>
                </div>
                {formFields.inValid &&
                formFields.password_confirmation === "" ? (
                  <p className="errorMessage">This field is required.</p>
                ) : null}
                {formFields.password_confirmation !== "" &&
                validateConfirmPassword(
                  formFields.password,
                  formFields.password_confirmation
                ) ? (
                  <p className="errorMessage">
                    The passwords do not match. Please try again.
                  </p>
                ) : null}
              </div>
            </div>
          </form>
        </div>
        <div className={classes.customBtn}>
          <Button onClick={(e) => setPasswordHandler(e)}>SUBMIT</Button>
        </div>
      </div>
    </>
  );
};
export default withStyles(Styles)(SetPasswordForm);
