import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { Link } from "react-router-dom";
import FormLabel from "@mui/material/FormLabel";
import StepperCustom from "../../../Components/StepperCustom/StepperCustom";
import { useLocation, useNavigate } from "react-router";
import StrConstant from "../../../Common/StrConstant";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { useDispatch, useSelector } from "react-redux";
import CardPayment from "../../../Components/CardPayment/CardPayment";
import { useEffect, useState } from "react";
import {
  getCardsList,
  getOneTimeCredits,
} from "../../../redux/actions/credit/CreditActions";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import CommonSection from "../../../Components/CommonSection/CommonSection";
import { ActionType } from "../../../redux/ActionTypes";
import AlterMessage from "../../../Components/AlertMessage/AlertMessage";
// import CustomPropover from "../../../Components/CustomPropover/CustomPropover";
import Loader from "react-spinner-loader";
import GetOneTimeCredits from "../GetOneTimeCredits/GetOneTimeCredits";

const OneOfPurchaseCredits = (props) => {
  const { classes } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [card_id, setCard_id] = useState(null);
  const [disable, setDisable] = useState(false);
  const credit = localStorage.getItem("credit");
  const [isDeleted, setIsDeleted] = useState(true);
  const [staticErrorCredit, setStaticErrorCredit] = useState(false);
  const [staticErrorCard, setStaticErrorCard] = useState(false);
  const { messageDlt } = useSelector((state) => state?.deleteCard);
  const { loading, cards } = useSelector((state) => state?.cardList);
  const [cardErr, setCardErr] = useState(false);
  const {
    loading: loadingPurchase,
    messages,
    error,
  } = useSelector((state) => state?.getOneTimeCredits);

  const submitHandler = () => {
    if (
      card_id !== "" &&
      credit !== "" &&
      card_id !== null &&
      credit !== null
    ) {
      const payload = {
        card_id: card_id,
        total_credits: credit,
      };
      dispatch(getOneTimeCredits(payload), setDisable(true));
    } else {
      if (card_id === null) setStaticErrorCard(true);
      if (credit === null) setStaticErrorCredit(true);
    }
  };

  const selectHandler = (selection) => setCard_id(selection);

  useEffect(() => {
    if (error && error !== "") {
      setCardErr(true);
    }
  }, [error]);

  useEffect(() => {
    if (messages?.success) {
      dispatch(
        { type: ActionType.CLEAR_RESPONSE },
        navigate("/purchase-complete")
      );
    } else return setDisable(false);
  }, [messages]);

  useEffect(() => {
    setDisable(false);
  }, [error]);

  useEffect(() => {
    dispatch(getCardsList());
    dispatch({ type: ActionType.CLEAR_RESPONSE });
  }, []);

  useEffect(() => {
    // console.log("state 2", location);
    // if (location?.state === 1) {
    //   setShow(<GetOneTimeCredits />);
    // }
  }, [location]);

  return (
    <>
      {(loading || loadingPurchase) && (
        <Loader
          show={loading || loadingPurchase}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {messageDlt?.message && (
        <AlterMessage
          closeHandler={() =>
            dispatch({ type: ActionType.CLEAR_RESPONSE }, setIsDeleted(false))
          }
          description={messageDlt?.message}
          url="./images/success.svg"
          open={isDeleted}
          title="Success"
          type="Success"
        />
      )}
      <AlterMessage
        closeHandler={() => setStaticErrorCard(false)}
        description={"Please add a card"}
        url="./images/error.svg"
        open={staticErrorCard}
        title="Error"
        type="Error"
      />
      <AlterMessage
        closeHandler={() => setStaticErrorCredit(false)}
        description={"Please add amount"}
        url="./images/error.svg"
        open={staticErrorCredit}
        title="Error"
        type="Error"
      />
      <AlterMessage
        closeHandler={() => setCardErr(false)}
        description={error}
        url="./images/error.svg"
        open={cardErr}
        title="Error"
        type="Error"
      />
      <CommonSection>
        <BackArrow
          clickHandler={() =>
            dispatch(
              { type: ActionType.CLEAR_RESPONSE },
              navigate("/purchase-credits", { state: 1 })
            )
          }
        />
        <StepperCustom step={2} />
        <div className={classes.boxOuter}>
          <div className={classes.description}>
            <h3>
              {localStorage.getItem("insufficient") !== "true"
                ? StrConstant.OneOfPurchaseCredits.heading
                : "top-up credits"}
            </h3>
            <FormLabel>No Of Credits To Purchase </FormLabel>
            <div className={classes.inputBox}>
              <input placeholder="Enter card Number" value={credit} readOnly />
              {/* {validation && !credit ? (
                <p className="errorMessage">Please add amount</p>

              ) : (
                <div></div>
              )} */}
              <span>S$ {credit}</span>
            </div>
          </div>
          <div className={classes.heading}>
            <h3>{StrConstant.OneOfPurchaseCredits.secondHeading}</h3>
            <Link
              to={`/add-card/`}
              state={{ lastPage: "/purchase-credits" }}
              className={classes.addItems}
            >
              <img src="./images/plus.svg" alt="Better Coffee" />
              Add payment method
            </Link>
          </div>
          <div className={classes.ltBox}>
            {cards ? (
              cards?.data?.cards ? (
                <CardPayment
                  cardList={cards?.data?.cards}
                  onSelection={selectHandler}
                />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={classes.totalBox}>
          <div className={classes.totalBoxInner}>
            <div className={classes.lt}>
              <h4>
                Total<span>:</span>
              </h4>
            </div>
            <div className={classes.rt}>
              <p>S$ {credit}</p>
            </div>
          </div>
          {/* {validation && card_id !== "" ? (
            <span className="errorMessage">Please add a card</span>
          ) : (
            <div></div>
          )} */}
        </div>
        <div>
          <div className={classes.desContent}>
            {/* <small>
              *This is a recurring plan and your next billing date is 10 May
              2022. Please cancel before 3 May 2022 to avoid getting charged.
            </small> */}
          </div>
          <div className={classes.btnBox}>
            <YellowBtn
              disable={disable}
              // name={"Confirm and Pay"}
              name={"Confirm"}
              clickHandler={submitHandler}
            />
          </div>
        </div>
      </CommonSection>
    </>
  );
};
export default withStyles(Styles)(OneOfPurchaseCredits);
