import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import StrConstant from "../../../Common/StrConstant";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import DarkGrayBtn from "../../../Components/DarkGrayBtn/DarkGrayBtn";
import { ActionType } from "../../../redux/ActionTypes";
import { useDispatch } from "react-redux";
const PromotionalOffer = (props) => {
  const { classes } = props;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const linkClickHandler = () => {
    dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1));
  };
  return (
    <>
      <OuterBox>
        <div>
          <BackArrow clickHandler={linkClickHandler} />
          {/* <h3>{StrConstant.PromotionalOffer.heading}</h3> */}
        </div>
        <div>
          <h3>Promotional Offer - (Two lines Placeholder)</h3>
          <p>23 Feb 2022</p>
          <p>You get flat 10% off on your first order. </p>
          <div className={classes.btnBox}>
            <YellowBtn name="Share now" />
            <DarkGrayBtn name={"Remined me later"} />
          </div>
        </div>
      </OuterBox>
    </>
  );
};
export default withStyles(Styles)(PromotionalOffer);
