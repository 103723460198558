import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { Link } from "react-router-dom";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import CardBox from "../../../Components/CardBox/CardBox";
import StrConstant from "../../../Common/StrConstant";
import StepperCustom from "../../../Components/StepperCustom/StepperCustom";
import CommonSection from "../../../Components/CommonSection/CommonSection";
import { useNavigate } from "react-router";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPlanListDetail } from "../../../redux/actions/planList/PlanListActions";
import Loader from "react-spinner-loader";
import AlterMessage from "../../../Components/AlertMessage/AlertMessage";
import { ActionType } from "../../../redux/ActionTypes";
import ViewMore from "../../../Components/ViewMore/ViewMore";
const ChoosePlan = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const plansData = useSelector((state) => state?.planDetail);
  const { loading } = useSelector((state) => state?.planDetail);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [alertSnack, setAlertSnack] = useState(false);
  const [page, setpage] = useState(1);
  const [totalPlan, setTotalPlan] = useState([])
  const walletdetails=localStorage.getItem("walletdetails")
  const handleProcessButton = () => {
    if (selectedPlan !== null) {
      window.localStorage.setItem("selectedPlan", selectedPlan);
      navigate(`/subscription`);
    } else return setAlertSnack(true);
  };

  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    setAlertSnack(false);
  };
  useEffect(() => {
    dispatch(getPlanListDetail());
    localStorage.removeItem("selectedPlan");
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    dispatch(getPlanListDetail(page));
  }, [page])


  useEffect(() => {
    // const plansData=plansData?.planDetail?.data?.plans?
    if (plansData?.planDetail?.data?.plans != null && plansData?.planDetail?.data?.plans != undefined) {
      if (totalPlan?.length > 0) {

        if (page > 1) {
          if (plansData?.planDetail?.data?.pagination?.currentPage == page) {
            console.log("totalplans", plansData?.planDetail?.data?.plans)
            setTotalPlan([...totalPlan, ...plansData?.planDetail?.data?.plans])
          }

        }
      }
      else {
        console.log("totalPlan", plansData?.planDetail?.data?.plans)
        setTotalPlan(plansData?.planDetail?.data?.plans)

      }
    }
  }, [plansData])
  useEffect(() => {
    const walletdetailsdata=walletdetails?JSON.parse(walletdetails):""
    console.log("wallet", walletdetailsdata)
    if ( (Object.keys(walletdetailsdata?.data?.active_subscription!=null && walletdetailsdata?.data?.active_subscription!=undefined ?walletdetailsdata?.data?.active_subscription :{})?.length === 0)||walletdetailsdata?.data?.active_subscription?.plan_name == "" ) {
      // navigate("/choose-plan")
      return
    }else{
      navigate("/manage-subscription")
    }
  })

  const handleMoreplan = () => {
    setpage(page + 1);
  }
  console.log("totalPlan", totalPlan)
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <AlterMessage
        closeHandler={snackHandler}
        description={"Choose a plan"}
        title="Error"
        type="Error"
        open={alertSnack && selectedPlan === null}
        url="./images/error.svg"
      />
      <CommonSection>
        <div className={classes.outerInner}>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <StepperCustom step={1} />
          <div className={classes.description}>
            <h3>{StrConstant.ChoosePlan.heading}</h3>
            <p>{StrConstant.ChoosePlan.description}</p>
          </div>
          <div className={classes.needTxt}>
            <p>
              Need a <Link to="/menu"> peek into the menu</Link> before
              choosing?{" "}
              <Link to="/menu">
                {" "}
                <img src="./images/arrowRight.svg" alt="Better Coffee" />
              </Link>{" "}
            </p>
          </div>
          <div className={classes.cardOuter}>
            <ul>
              {totalPlan?.map((list, index) => (
                <li key={index} onClick={() => {
                  setSelectedPlan(index)
                  localStorage.setItem("isplanfree", (list?.is_trail))
                }}>
                  <CardBox
                    amount={list?.amount}
                    credit={list?.credits}
                    planName={list?.plan_name}
                    placeholder={list?.placeholder}
                    feature={list?.feature}
                    isNewPlan={true}
                    isplanfree={list?.is_trail}
                    isSelected={selectedPlan === index ? true : false}
                  />
                </li>
              ))}
            </ul>
          </div>
          {plansData?.planDetail?.data?.pagination?.lastPage > page &&
            <ViewMore name={"Load More"} clickHandler={handleMoreplan} />

          }

          {width > 640 ? (
            <div className={classes.botTxt}>
              <p>
                {StrConstant.ChoosePlan.btTxt.description}
                <Link to="/purchase-credits">
                  {StrConstant.ChoosePlan.btTxt.LinkTxt}
                </Link>
              </p>
              {/* <YellowBtn
                name={"Proceed to checkout"}
                clickHandler={handleProcessButton}
              /> */}
              <YellowBtn
                name={"next"}
                clickHandler={handleProcessButton}
              />
            </div>
          ) : (
            <div className={classes.botTxt}>
              <YellowBtn
                name={"next"}
                clickHandler={handleProcessButton}
              />
              {/* <YellowBtn
                name={"Proceed to checkout"}
                clickHandler={handleProcessButton}
              /> */}
              <p className={classes.purchaseBtn}>
                {StrConstant.ChoosePlan.btTxt.description}
                <Link to="/purchase-credits">
                  {StrConstant.ChoosePlan.btTxt.LinkTxt}
                </Link>
              </p>
            </div>
          )}
        </div>
      </CommonSection>
    </>
  );
};
export default withStyles(Styles)(ChoosePlan);
