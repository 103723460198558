import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { Styles } from "./Style";
const LogoContainer = (props) => {
    const { classes, heading, goUrl, urlTxt } = props;
    return (
        <>
            <div className={classes.logoContent}>
                <div className={classes.pr10}>
                    <img
                        className={classes.logo}
                        src="/images/logo.png"
                        alt="Better COffee"
                    />
                    <h2 className={classes.m0}>{heading}</h2>
                </div>
                <Link to={goUrl} className={classes.haveAccount}>{urlTxt}</Link>
            </div>
        </>
    )
}
export default withStyles(Styles)(LogoContainer);