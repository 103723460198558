export const Styles = Theme => ({
    viewMore: {
        "& button": {
            padding: "12px 20px",
            background: "#FFC200",
            color: "#3F4444",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "400",
            fontFamily: "'Bebas Neue', serif",
            margin: "20px auto 0",
            letterSpacing: "0",
            whiteSpace: "nowrap",
            borderRadius: "32px",
            display: "block",
            minWidth:"100px",
            transition: "inherit",
            "@media(max-width:640px)": {
                fontSize: "14px",
                lineHeight: "16px",
            },
            "&:hover": {
                background: "#FFC200",
            },
        },
    }
})