import { ActionType } from "../../ActionTypes";

export const getMyWalletReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_MY_WALLET_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_MY_WALLET_SUCCESS:
      return { ...state, loading: false, myWallet: action.payload };
    case ActionType.GET_MY_WALLET_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};