import { Styles } from "./Style";
import { Link } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import Loader from "react-spinner-loader";
import { useEffect, useState } from "react";
import StrConstant from "../../../Common/StrConstant";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "../../../redux/ActionTypes";
import OuterBox from "../../../Components/OuterBox/OuterBox";
// import { modeLinkHandler } from "../../../Common/utils/utils";
// import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import CardPayment from "../../../Components/CardPayment/CardPayment";
import AlterMessage from "../../../Components/AlertMessage/AlertMessage";
import { getCardsList } from "../../../redux/actions/credit/CreditActions";
import OrderStatus from "../../../Components/OrderStatus/OrderStatus";
const ManagePaymentMethods = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [snakMessage, setSnakMessage] = useState("");
  const { messages } = useSelector((state) => state?.addCard);
  // const setDefaultMode = localStorage.getItem("selected_modes");
  const { messageDlt } = useSelector((state) => state?.deleteCard);
  const { loading, cards } = useSelector((state) => state?.cardList);
  const { cardsDefault } = useSelector((state) => state?.cardsDefault);
  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    dispatch({ type: ActionType.CLEAR_RESPONSE }, setIsOpen(false));
  };
  useEffect(() => {
    localStorage.removeItem("selectedPlan");
    localStorage.removeItem("manage");
    dispatch(getCardsList());
  }, []);

  const selectionHandler = (selection) => {
    // console.log("selection:", selection);
    // setCard_id(selection);
  };
  useEffect(() => {
    if (messages?.success) {
      setSnakMessage(messages?.message);
      setIsOpen(true);
      dispatch({ type: ActionType.CLEAR_RESPONSE });
    }
  }, [messages]);

  useEffect(() => {
    if (cardsDefault?.success) {
      setSnakMessage(cardsDefault?.message);
      setIsOpen(true);
      dispatch({ type: ActionType.CLEAR_RESPONSE });
    }
  }, [cardsDefault]);

  useEffect(() => {
    if (messageDlt?.success) {
      setSnakMessage(messageDlt?.message);
      setIsOpen(true);
      dispatch({ type: ActionType.CLEAR_RESPONSE });
    }
  }, [messageDlt]);

  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {isOpen && (
        <AlterMessage
          closeHandler={snackHandler}
          description={snakMessage}
          title={"Success"}
          type={"Success"}
          open={isOpen}
          url={"./images/success.svg"}
        />
      )}
      <OuterBox>
        <div>
          <BackArrow clickHandler={() => navigate("/my-account")} />
          <h3>{StrConstant.ManagePaymentMethods.heading}</h3>
        </div>
        <div>
          {cards ? (
            cards?.data?.cards ? (
              <CardPayment
                setting={true}
                cardList={cards?.data?.cards}
                onSelection={selectionHandler}
              />
            ) : (
              <p>No card to display</p>
            )
          ) : (
            <></>
          )}
          <div className={classes.addItems}>
            <Link
              to="/add-card"
              onClick={() => localStorage.setItem("manage", true)}
            >
              <img src="./images/plus.svg" alt="Better Coffee" />
              <span>Add card</span>
            </Link>
            {/* <div className={classes.btnContent}>
            {" "}
            <YellowBtn
              name={"Place Order"}
              clickHandler={() => {
                navigate(modeLinkHandler(setDefaultMode));
              }}
            />
          </div> */}
          </div>
        </div>
        <OrderStatus
          // link={modeLinkHandler(setDefaultMode)}
          link={"/choose-mode"}
          text={"Place order"}
        />
      </OuterBox>
    </>
  );
};

export default withStyles(Styles)(ManagePaymentMethods);
