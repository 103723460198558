export const Styles = Theme => ({
    fromControl: {
        margin: "0 0 25px",
    },
    btnBox: {
        display:"flex",
        alignItems:"center",
        justifyContent:"flex-end",
    },

    forgotPassword: {
        textAlign: "right",
        display: "inherit",
    },
})