export const Styles = Theme => ({
    outer: {
        // display: "table",
        width: "100%",
        height: "auto",
        padding: "0 0 20px",
        display:"table",
        "@media(max-width:640px)": {
            margin:"20px 0",
        }
    }
})

