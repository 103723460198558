export const ActionType = {
  USER_SIGNIN_REQUEST: "USER_SIGNIN_REQUEST",
  USER_SIGNIN_SUCCESS: "USER_SIGNIN_SUCCESS",
  USER_SIGNIN_FAILURE: "USER_SIGNIN_FAIL",

  RESEND_EMAIL_VERIFY_REQUEST: "RESEND_EMAIL_VERIFY_REQUEST",
  RESEND_EMAIL_VERIFY_SUCCESS: "RESEND_EMAIL_VERIFY_SUCCESS",
  RESEND_EMAIL_VERIFY_FAILURE: "RESEND_EMAIL_VERIFY_FAILURE",

  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAILURE: "USER_REGISTER_FAILURE",

  USER_LOGOUT_REQUEST: "USER_LOGOUT_REQUEST",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  USER_LOGOUT_FAILURE: "USER_LOGOUT_FAILURE",

  GET_DASHBOARD_DETAILS_REQUEST: "DASHBOARD_REQUEST",
  GET_DASHBOARD_DETAILS_SUCCESS: "DASHBOARD_SUCCESS",
  GET_DASHBOARD_DETAILS_FAILURE: "DASHBOARD_FAILURE",

  GET_PLAN_LIST_DETAILS_REQUEST: "GET_PLAN_LIST_DETAILS_REQUEST",
  GET_PLAN_LIST_DETAILS_SUCCESS: "GET_PLAN_LIST_DETAILS_SUCCESS",
  GET_PLAN_LIST_DETAILS_FAILURE: "GET_PLAN_LIST_DETAILS_FAILURE",

  USER_SET_PASSWORD_SUCCESS: "USER_SET_PASSWORD_SUCCESS",
  USER_SET_PASSWORD_REQUEST: "USER_SET_PASSWORD_REQUEST",
  USER_SET_PASSWORD_FAILURE: "USER_SET_PASSWORD_FAILURE",

  USER_ADD_CARD_REQUEST: "USER_ADD_CARD_REQUEST",
  USER_ADD_CARD_SUCCESS: "USER_ADD_CARD_SUCCESS",
  USER_ADD_CARD_FAILURE: "USER_ADD_CARD_FAILURE",

  CARDS_LIST_REQUEST: "CARDS_LIST_REQUEST",
  CARDS_LIST_SUCCESS: "CARDS_LIST_SUCCESS",
  CARDS_LIST_FAIL: "CARDS_LIST_FAIL",

  DELETE_CARD_REQUEST: "DELETE_CARD_REQUEST",
  DELETE_CARD_SUCCESS: "DELETE_CARD_SUCCESS",
  DELETE_CARD_FAIL: "DELETE_CARD_FAIL",

  USER_CHANGE_PASSWORD_REQUEST: "USER_CHANGE_PASSWORD_REQUEST",
  USER_CHANGE_PASSWORD_SUCCESS: "USER_CHANGE_PASSWORD_SUCCESS",
  USER_CHANGE_PASSWORD_FAILURE: "USER_CHANGE_PASSWORD_FAILURE",

  USER_UPDATE_PROFILE_REQUEST: "USER_UPDATE_PROFILE_REQUEST",
  USER_UPDATE_PROFILE_SUCCESS: "USER_UPDATE_PROFILE_SUCCESS",
  USER_UPDATE_PROFILE_FAILURE: "USER_UPDATE_PROFILE_FAILURE",

  TOPUP_MESSAGE_RESET: "TOPUP_MESSAGE_RESET",

  GET_ONE_TIME_CREDITS_REQUEST: "GET_ONE_TIME_CREDITS_REQUEST",
  GET_ONE_TIME_CREDITS_SUCCESS: "GET_ONE_TIME_CREDITS_SUCCESS",
  GET_ONE_TIME_CREDITS_FAILURE: "GET_ONE_TIME_CREDITS_FAILURE",

  MODES_LIST_REQUEST: "MODES_LIST_REQUEST",
  MODES_LIST_SUCCESS: "MODES_LIST_SUCCESS",
  MODES_LIST_FAILURE: "MODES_LIST_FAILURE",

  GET_ADDRESS_DETAIL_LIST_REQUEST: "GET_ADDRESS_DETAIL_LIST_REQUEST",
  GET_ADDRESS_DETAIL_LIST_SUCCESS: "GET_ADDRESS_DETAIL_LIST_SUCCESS",
  GET_ADDRESS_DETAIL_LIST_FAILURE: "GET_ADDRESS_DETAIL_LIST_FAILURE",

  ADD_ADDRESS_DETAIL_REQUEST: "ADD_ADDRESS_DETAIL_REQUEST",
  ADD_ADDRESS_DETAIL_SUCCESS: "ADD_ADDRESS_DETAIL_SUCCESS",
  ADD_ADDRESS_DETAIL_FAILURE: "ADD_ADDRESS_DETAIL_FAILURE",

  UPDATE_ADDRESS_DETAIL_REQUEST: "UPDATE_ADDRESS_DETAIL_REQUEST",
  UPDATE_ADDRESS_DETAIL_SUCCESS: "UPDATE_ADDRESS_DETAIL_SUCCESS",
  UPDATE_ADDRESS_DETAIL_FAILURE: "UPDATE_ADDRESS_DETAIL_FAILURE",

  DELETE_ADDRESS_DETAIL_REQUEST: "DELETE_ADDRESS_DETAIL_REQUEST",
  DELETE_ADDRESS_DETAIL_SUCCESS: "DELETE_ADDRESS_DETAIL_SUCCESS",
  DELETE_ADDRESS_DETAIL_FAILURE: "DELETE_ADDRESS_DETAIL_FAILURE",

  SET_MODE_REQUEST: "SET_MODE_REQUEST",
  SET_MODE_SUCCESS: "SET_MODE_SUCCESS",
  SET_MODE_FAILURE: "SET_MODE_FAILURE",

  PURCHASE_CREDITS_REQUEST: "PURCHASE_CREDITS_REQUEST",
  PURCHASE_CREDITS_SUCCESS: "PURCHASE_CREDITS_SUCCESS",
  PURCHASE_CREDITS_FAILURE: "PURCHASE_CREDITS_FAILURE",

  SET_DEFAULT_ADDRESS_REQUEST: "SET_DEFAULT_ADDRESS_REQUEST",
  SET_DEFAULT_ADDRESS_SUCCESS: "SET_DEFAULT_ADDRESS_SUCCESS",
  SET_DEFAULT_ADDRESS_FAILURE: "SET_DEFAULT_ADDRESS_FAILURE",

  GET_OUTLETS_LISTING_REQUEST: "GET_OUTLETS_LISTING_REQUEST",
  GET_OUTLETS_LISTING_SUCCESS: "GET_OUTLETS_LISTING_SUCCESS",
  GET_OUTLETS_LISTING_FAILURE: "GET_OUTLETS_LISTING_FAILURE",

  GET_OUTLET_ITEM_DETAILS_REQUEST: "GET_OUTLET_ITEM_DETAILS_REQUEST",
  GET_OUTLET_ITEM_DETAILS_SUCCESS: "GET_OUTLET_ITEM_DETAILS_SUCCESS",
  GET_OUTLET_ITEM_DETAILS_FAILURE: "GET_OUTLET_ITEM_DETAILS_FAILURE",

  GET_PRODUCT_LIST_REQUEST: "GET_PRODUCT_LIST_REQUEST",
  GET_PRODUCT_LIST_SUCCESS: "GET_PRODUCT_LIST_SUCCESS",
  GET_PRODUCT_LIST_FAILURE: "GET_PRODUCT_LIST_FAILURE",

  GET_OUTLET_CATEGORY_REQUEST: "GET_OUTLET_CATEGORY_REQUEST",
  GET_OUTLET_CATEGORY_SUCCESS: "GET_OUTLET_CATEGORY_SUCCESS",
  GET_OUTLET_CATEGORY_FAILURE: "GET_OUTLET_CATEGORY_FAILURE",
  GET_OUTLET_CATEGORY_CLEAR:"GET_OUTLET_CATEGORY_CLEAR",

  GET_OUTLET_ID_CATEGORY_REQUEST:"GET_OUTLET_ID_CATEGORY_REQUEST",
  GET_OUTLET_ID_CATEGORY_SUCCESS:"GET_OUTLET_ID_CATEGORY_SUCCESS",
  GET_OUTLET_ID_CATEGORY_FAILURE:"GET_OUTLET_ID_CATEGORY_FAILURE",
  CLEAR_OUTLET_ID:"CLEAR_OUTLET_ID",

  ADD_PRODUCT_TO_CART_REQUEST: "ADD_PRODUCT_TO_CART_REQUEST",
  ADD_PRODUCT_TO_CART_SUCCESS: "ADD_PRODUCT_TO_CART_SUCCESS",
  ADD_PRODUCT_TO_CART_FAILURE: "ADD_PRODUCT_TO_CART_FAILURE",
  CLEARADDPRODUCT:"CLEARADDPRODUCT",

  UPDATED_PRODUCT_TO_CART_REQUEST:"UPDATED_PRODUCT_TO_CART_REQUEST",
  UPDATED_PRODUCT_TO_CART_SUCCESS:"UPDATED_PRODUCT_TO_CART_SUCCESS",
  UPDATED_PRODUCT_TO_CART_FAILURE:"UPDATED_PRODUCT_TO_CART_FAILURE",
  CLEARUPDATEDPRODUCT:"CLEARUPDATEDPRODUCT",



  GET_USER_CART_REQUEST: "GET_USER_CART_REQUEST",
  GET_USER_CART_SUCCESS: "GET_USER_CART_SUCCESS",
  GET_USER_CART_FAILURE: "GET_USER_CART_FAILURE",

  FINALIZE_ORDER_CHECKOUT_REQUEST: "FINALIZE_ORDER_CHECKOUT_REQUEST",
  FINALIZE_ORDER_CHECKOUT_SUCCESS: "FINALIZE_ORDER_CHECKOUT_SUCCESS",
  FINALIZE_ORDER_CHECKOUT_FAILURE: "FINALIZE_ORDER_CHECKOUT_FAILURE",

  SEND_OTP_REQUEST: "SEND_OTP_REQUEST",
  SEND_OTP_SUCCESS: "SEND_OTP_SUCCESS",
  SEND_OTP_FAILURE: "SEND_OTP_FAILURE",

  VERIFY_OTP_REQUEST: "VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",

  SAVE_DATA_REQUEST: "SAVE_DATA_REQUEST",
  SAVE_DATA_SUCCESS: "SAVE_DATA_SUCCESS",
  SAVE_DATA_FAILURE: "SAVE_DATA_FAILURE",

  REMOVE_CART_PRODUCT_REQUEST: "REMOVE_CART_PRODUCT_REQUEST",
  REMOVE_CART_PRODUCT_SUCCESS: "REMOVE_CART_PRODUCT_SUCCESS",
  REMOVE_CART_PRODUCT_FAILURE: "REMOVE_CART_PRODUCT_FAILURE",

  VIEW_ORDERS_REQUEST: "VIEW_ORDERS_REQUEST",
  VIEW_ORDERS_SUCCESS: "VIEW_ORDERS_SUCCESS",
  VIEW_ORDERS_FAILURE: "VIEW_ORDERS_FAILURE",

  EDIT_ORDER_REQUEST: "EDIT_ORDER_REQUEST",
  EDIT_ORDER_SUCCESS: "EDIT_ORDER_SUCCESS",
  EDIT_ORDER_FAILURE: "EDIT_ORDER_FAILURE",

  CHECK_COUPON_REQUEST: "CHECK_COUPON_REQUEST",
  CHECK_COUPON_SUCCESS: "CHECK_COUPON_SUCCESS",
  CHECK_COUPON_FAILURE: "CHECK_COUPON_FAILURE",

  APPLY_COUPON_REQUEST: "APPLY_COUPON_REQUEST",
  APPLY_COUPON_SUCCESS: "APPLY_COUPON_SUCCESS",
  APPLY_COUPON_FAILURE: "APPLY_COUPON_FAILURE",

  VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAILURE: "VERIFY_EMAIL_FAILURE",

  VERIFY_MOBILE_REQUEST: "VERIFY_MOBILE_REQUEST",
  VERIFY_MOBILE_SUCCESS: "VERIFY_MOBILE_SUCCESS",
  VERIFY_MOBILE_FAILURE: "VERIFY_MOBILE_FAILURE",

  EMAIL_VERIFY_REQUEST: "EMAIL_VERIFY_REQUEST",
  EMAIL_VERIFY_SUCCESS: "EMAIL_VERIFY_SUCCESS",
  EMAIL_VERIFY_FAILURE: "EMAIL_VERIFY_FAILURE",

  USER_FORGOT_PASSWORD_REQUEST: "USER_FORGOT_PASSWORD_REQUEST",
  USER_FORGOT_PASSWORD_SUCCESS: "USER_FORGOT_PASSWORD_SUCCESS",
  USER_FORGOT_PASSWORD_FAILURE: "USER_FORGOT_PASSWORD_FAILURE",

  GET_MY_WALLET_REQUEST: "GET_MY_WALLET_REQUEST",
  GET_MY_WALLET_SUCCESS: "GET_MY_WALLET_SUCCESS",
  GET_MY_WALLET_FAILURE: "GET_MY_WALLET_FAILURE",

  DELIVERY_FEE_CALCULATE_REQUEST: "DELIVERY_FEE_CALCULATE_REQUEST",
  DELIVERY_FEE_CALCULATE_SUCCESS: "DELIVERY_FEE_CALCULATE_SUCCESS",
  DELIVERY_FEE_CALCULATE_FAILURE: "DELIVERY_FEE_CALCULATE_FAILURE",

  USER_NOTIFICATIONS_REQUEST: "USER_NOTIFICATIONS_REQUEST",
  USER_NOTIFICATIONS_SUCCESS: "USER_NOTIFICATIONS_SUCCESS",
  USER_NOTIFICATIONS_FAILURE: "USER_NOTIFICATIONS_FAILURE",

  GET_CONFIGURATION_REQUEST: "GET_CONFIGURATION_REQUEST",
  GET_CONFIGURATION_SUCCESS: "GET_CONFIGURATION_SUCCESS",
  GET_CONFIGURATION_FAILURE: "GET_CONFIGURATION_FAILURE",

  FINALIZE_ORDER_VERIFY_REQUEST: "FINALIZE_ORDER_VERIFY_REQUEST",
  FINALIZE_ORDER_VERIFY_SUCCESS: "FINALIZE_ORDER_VERIFY_SUCCESS",
  FINALIZE_ORDER_VERIFY_FAILURE: "FINALIZE_ORDER_VERIFY_FAILURE",

  GET_ORDER_DETAIL_REQUEST: "GET_ORDER_DETAIL_REQUEST",
  GET_ORDER_DETAIL_SUCCESS: "GET_ORDER_DETAIL_SUCCESS",
  GET_ORDER_DETAIL_FAILURE: "GET_ORDER_DETAIL_FAILURE",

  USER_SOCIAL_LOGIN_REQUEST: "USER_SOCIAL_LOGIN_REQUEST",
  USER_SOCIAL_LOGIN_SUCCESS: "USER_SOCIAL_LOGIN_SUCCESS",
  USER_SOCIAL_LOGIN_FAILURE: "USER_SOCIAL_LOGIN_FAILURE",

  SPEND_POLICY_REQUEST: "SPEND_POLICY_REQUEST",
  SPEND_POLICY_SUCCESS: "SPEND_POLICY_SUCCESS",
  SPEND_POLICY_FAILURE: "SPEND_POLICY_FAILURE",

  GET_FAVORITES_LIST_REQUEST: "GET_FAVORITES_LIST_REQUEST",
  GET_FAVORITES_LIST_SUCCESS: "GET_FAVORITES_LIST_SUCCESS",
  GET_FAVORITES_LIST_FAILURE: "GET_FAVORITES_LIST_FAILURE",

  TOGGLE_FAVORITES_REQUEST: "TOGGLE_FAVORITES_REQUEST",
  TOGGLE_FAVORITES_SUCCESS: "TOGGLE_FAVORITES_SUCCESS",
  TOGGLE_FAVORITES_FAILURE: "TOGGLE_FAVORITES_FAILURE",

  CHECK_POSTAL_CODE_REQUEST: "CHECK_POSTAL_CODE_REQUEST",
  CHECK_POSTAL_CODE_SUCCESS: "CHECK_POSTAL_CODE_SUCCESS",
  CHECK_POSTAL_CODE_FAILURE: "CHECK_POSTAL_CODE_FAILURE",

  BILLING_HISTORY_REQUEST: "BILLING_HISTORY_REQUEST",
  BILLING_HISTORY_SUCCESS: "BILLING_HISTORY_SUCCESS",
  BILLING_HISTORY_FAILURE: "BILLING_HISTORY_SUCCESS",

  CREDIT_TRANSACTIONS_REQUEST: "CREDIT_TRANSACTIONS_REQUEST",
  CREDIT_TRANSACTIONS_SUCCESS: "CREDIT_TRANSACTIONS_SUCCESS",
  CREDIT_TRANSACTIONS_FAILURE: "CREDIT_TRANSACTIONS_FAILURE",

  RESEND_OTP_VERIFY_REQUEST: "RESEND_OTP_VERIFY_REQUEST",
  RESEND_OTP_VERIFY_SUCCESS: "RESEND_OTP_VERIFY_SUCCESS",
  RESEND_OTP_VERIFY_FAILURE: "RESEND_OTP_VERIFY_FAILURE",

  GET_OUTLET_REQUEST: "GET_OUTLET_REQUEST",
  GET_OUTLET_SUCCESS: "GET_OUTLET_SUCCESS",
  GET_OUTLET_FAILURE: "GET_OUTLET_FAILURE",

  GET_FUTURE_SCHEDULE_REQUEST: "GET_FUTURE_SCHEDULE_REQUEST",
  GET_FUTURE_SCHEDULE_SUCCESS: "GET_FUTURE_SCHEDULE_SUCCESS",
  GET_FUTURE_SCHEDULE_FAILURE: "GET_FUTURE_SCHEDULE_FAILURE",

  CLEAR_RESPONSE: "CLEAR_RESPONSE",
  CLEAR_ProductResponse:"CLEAR_ProductResponse",

  CHANGE_PLAN_REQUEST: "CHANGE_PLAN_REQUEST",
  CHANGE_PLAN_SUCCESS: "CHANGE_PLAN_SUCCESS",
  CHANGE_PLAN_FAILURE: "CHANGE_PLAN_FAILURE",

  CANCEL_ORDER_REQUEST: "CANCEL_ORDER_REQUEST",
  CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
  CANCEL_ORDER_FAILURE: "CANCEL_ORDER_FAILURE",
  //for multiple
  READ_NOTIFICATIONS_REQUEST: "READ_NOTIFICATIONS_REQUEST",
  READ_NOTIFICATIONS_SUCCESS: "READ_NOTIFICATIONS_SUCCESS",
  READ_NOTIFICATIONS_FAILURE: "READ_NOTIFICATIONS_FAILURE",
  //for single
  READ_NOTIFICATION_REQUEST: "READ_NOTIFICATION_REQUEST",
  READ_NOTIFICATION_SUCCESS: "READ_NOTIFICATION_SUCCESS",
  READ_NOTIFICATION_FAILURE: "READ_NOTIFICATION_FAILURE",

  GET_USER_PROFILE_REQUEST: "GET_USER_PROFILE_REQUEST",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_FAILURE: "GET_USER_PROFILE_FAILURE",

  CANCEL_PLAN_REQUEST: "CANCEL_PLAN_REQUEST",
  CANCEL_PLAN_SUCCESS: "CANCEL_PLAN_SUCCESS",
  CANCEL_PLAN_FAILURE: "CANCEL_PLAN_FAILURE",



  CART_DETAILS_REQUEST:"CART_DETAILS_REQUEST",
  CART_DETAILS_SUCCESS:"CART_DETAILS_SUCCESS",
  CART_DETAILS_FAILURE:"CART_DETAILS_FAILURE",


  CARD_DEFAULT_REQUEST: "CARD_DEFAULT_REQUEST",
  CARD_DEFAULT_SUCCESS: "CARD_DEFAULT_SUCCESS",
  CARD_DEFAULT_FAILURE: "CARD_DEFAULT_FAILURE",

  VIEW_RECURRING_ORDER_REQUEST: "VIEW_RECURRING_ORDER_REQUEST",
  VIEW_RECURRING_ORDER_SUCCESS: "VIEW_RECURRING_ORDER_SUCCESS",
  VIEW_RECURRING_ORDER_FAILURE: "VIEW_RECURRING_ORDER_FAILURE",

  VIEW_ORDERS_HISTORY_REQUEST: "VIEW_ORDERS_HISTORY_REQUEST",
  VIEW_ORDERS_HISTORY_SUCCESS: "VIEW_ORDERS_HISTORY_SUCCESS",
  VIEW_ORDERS_HISTORY_FAILURE: "VIEW_ORDERS_HISTORY_FAILURE",

  REMOVE_COUPON_REQUEST: "REMOVE_COUPON_REQUEST",
  REMOVE_COUPON_SUCCESS: "REMOVE_COUPON_SUCCESS",
  REMOVE_COUPON_FAILURE: "REMOVE_COUPON_FAILURE",

  VALIDATE_CHECKOUT_REQUEST: "VALIDATE_CHECKOUT_REQUEST",
  VALIDATE_CHECKOUT_SUCCESS: "VALIDATE_CHECKOUT_SUCCESS",
  VALIDATE_CHECKOUT_FAILURE: "VALIDATE_CHECKOUT_FAILURE",

  CLEAR_ERROR: "CLEAR_ERROR",
  CLEAR_DATA_ERROR:"CLEAR_DATA_ERROR",

  CLEAR_CHECKOUT: "CLEAR_CHECKOUT",

  CLEAR_COUPON: "CLEAR_COUPON",

  CLEAR_UPDATE: "CLEAR_UPDATE",

  ORDERS_RATING_REQUEST: "ORDERS_RATING_REQUEST",
  ORDERS_RATING_SUCCESS: "ORDERS_RATING_SUCCESS",
  ORDERS_RATING_FAILURE: "ORDERS_RATING_FAILURE",

  ORDERS_NUMBER_REQUEST:"ORDERS_NUMBER_REQUEST",
  ORDERS_NUMBER_SUCCESS:"ORDERS_NUMBER_SUCCESS",
  ORDERS_NUMBER_FAILURE:"ORDERS_NUMBER_FAILURE",
  CLEAR_NUMBER_RESPONSE:"CLEAR_NUMBER_RESPONSE",

  REORDER_CART_REQUEST: "REORDER_CART_REQUEST",
  REORDER_CART_SUCCESS: "REORDER_CART_SUCCESS",
  REORDER_CART_FAILURE: "REORDER_CART_FAILURE",

  CLEAR_CART_REQUEST: "CLEAR_CART_REQUEST",
  CLEAR_CART_SUCCESS: "CLEAR_CART_SUCCESS",
  CLEAR_CART_FAILURE: "CLEAR_CART_FAILURE",
CLEAR_CART_DETAILS:"CLEAR_CART_DETAILS",

  

  CLEAR_COUPON_ERROR: "CLEAR_COUPON_ERROR",
};
