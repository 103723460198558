import { Link } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
const OrderStatus = (props) => {
    const { classes,link, text, clickHandler } = props
    return (
        <>
            <Link to={link} onClick={clickHandler} className={classes.roundBox}>{text}</Link>
        </>
    )
}
export default withStyles(Styles)(OrderStatus);