export const Styles = Theme => ({
    heading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "30px 0 10px",
        "@media (max-width:640px)": {
            display: "block",
        },
        "& h3": {
            marginBottom: "0",
        },
    },
    addItems: {
        display: "flex",
        alignItems: "center",
        "@media (max-width: 640px)": {
            margin: "20px 0",
            background: "#fff",
            padding: "20px",
            borderRadius: "5px",
        },
        "& img": {
            marginRight: "5px",
        }
    },
    dFlex: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: 'flex-end',
        marginBottom: "20px",
        "@media (max-width: 640px)": {
            justifyContent: 'space-between',
            display: "block",
        },
    },
    boxContainer: {
        display: "flex",
        flexDirection: "column-reverse",
        justifyContent: 'space-between',
        "@media (max-width: 640px)": {
            display: "block",
        },
    },
    ltBox: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        "@media (max-width: 640px)": {
            width: "100%",
        },
        "& > div": {
            width: "50%",
            padding: "0 10px 10px 0",
            "@media (max-width: 640px)": {
                width: "100%",
                padding: "0 0 10px 0",
            },
            "& a": {
                marginBottom: "0px",
            }
        },
        "& > div:nth-child(even)": {
            padding: "0 0 0 0",
            "@media (max-width: 640px)": {
                padding: "0 0 10px 0",
            },
        }
    },
    lt: {
        "& h4": {
            display:"flex",
            "@media (max-width: 640px)": {
                fontSize: "20px",
                lineHeight: "20px",
            },
            "& span": {
                padding: "0 10px",
                display: "inline-block",
                // "@media (max-width: 640px)": {
                //     display: "none",
                // }
            }
        }
    },
    rt: {
        "& h4": {
            textAlign: "right",
            "@media (max-width: 640px)": {
                fontSize: "20px",
                lineHeight: "20px",
                textAlign: "left",
            },
        },
    },
    totalBoxInner: {
        display: "flex",
        // alignItems: "flex-start",
        // alignItems: "center",
        // justifyContent: 'flex-end',
        justifyContent: 'space-between',
        borderTop: "1px solid #3F4444BF",
        padding: "10px 0 0",
        "@media(max-width:640px)": {
          padding: "15px 0",
          marginTop:"10px",
        },
        "& h4": {
          marginBottom: "10px"
        },
        "@media (max-width: 640px)": {
          padding:"8px 0",
          justifyContent: 'space-between',
        },
      },
    totalBox: {
        "& p": {
            textAlign: "right",
            margin:"0 0 10px !important",
        },
    },
    desContent: {
        width: "100%",
        textAlign: "right",
        "@media (max-width: 640px)": {
            textAlign: "left",
        }
    },
    btnBox: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        "& button": {
            marginTop: "0",
            maxWidth: "250px",
            float: "right",
            "@media (max-width: 640px)": {
                maxWidth: "100%",
                float: "unset",
            },
        },
        "& > div": {
            margin: "20px auto",
            maxWidth: "none",
        },
        "@media (max-width: 640px)": {
            width: "100%",
        }
    },
})