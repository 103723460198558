export const Styles = Theme => ({
    outerInner: {
        maxWidth: "1000px",
        margin: "auto",
        display: "flex",
        width: "100%",
        alignItems: "center",
        height: "calc(100vh - 150px)",
        "@media(max-width:640px)":{
            display: "block",
        }
    },
    description:{
        width:"30%",
        "@media(max-width:640px)":{
            width:"100%",
        }
    },
    contentBox: {
        width: "70%",
        height: "auto",
        boxShadow: "0px 0px 10px 0px #ccc",
        background: "#fff",
        padding: "40px 30px",
        maxHeight: "600px",
        overflowY: "auto",
        margin:"50px 0",
        "@media(max-width:640px)": {
            width: "100%",
            paddingLeft: "0",
            boxShadow: "none",
            padding: "0",
            background: "transparent",
            maxHeight: "0",
            overflowY: "inherit",
            margin:"0 0",
        },
        "&::-webkit-scrollbar": {
            appearance: "none",
        },
        "&::-moz-scrollbar": {
            appearance: "none",
            width: "0",
        },
        "&::-o-scrollbar": {
            appearance: "none",
            width: "0",
        },
        "&::-ms-scrollbar": {
            appearance: "none",
            width: "0",
        },
    },
    dFlex: {
        display: "flex",
        alignItems:"flex-start",
        justifyContent:"space-between",
        "@media(max-width:640px)":{
            display: "block",
        },
        "& > :nth-child(2)": {
            marginRight: "20px",
            "@media(max-width:475px)":{
                marginRight: "0",
            },
        },
    },
    btnBox:{
        display: "flex",
        alignItems:"center",
        justifyContent:"space-between",
        "@media(max-width:640px)":{
            display: "block",
        },
    },
    addItems: {
        "& a": {
            display: "flex",
            alignItems: "center",
            justifyContent:"flex-start",
            borderRadius: '10px',
            padding: "0 10px",
            background:"#fff",
            color: "#3F4444",
            "@media(max-width:640px)":{
                padding:"20px",
                marginTop:"20px",
            },
            "& img": {
                height: "15px",
                marginRight: "10px",
            },
            "& :hover": {
                color: "#3F4444",
            }
        },
    },
    card: {
        background: "#FFFFFF",
        borderRadius: "10px",
        padding: "15px 20px",
        display: "flex",
        alignItems: "flex-start",
        marginBottom: "20px",
        borderBottom: "1px solid #eee",
        width: "100%",
        "@media(max-width:640px)": {
            borderBottom: "0",
        }
    },
    ltBox: {
        width: "80%",
        "& h4": {
            marginBottom: "0",
        },
        "& p": {
            marginBottom: "0",
        }
    },
    rtBox: {
        width: "20%",
        display: "flex",
        paddingLeft: "10px",
        justifyContent: "flex-end",
    },

    imgContent:{
        margin:"0 5px",
    },
    noWrap:{
        display: "flex",
        alignItems: "flex-end",  
        marginBottom: "10px",
        "& span":{
            paddingLeft:"10px",
        }
    },
    roundBox: {
        background: "#FFC200",
        boxShadow: "2px -2px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.03)",
        borderRadius: "100%",
        position: "fixed",
        right: "30px",
        bottom: "30px",
        height: "80px",
        width: "80px",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        fontSize: "16px",
        lineHeight: "18px",
        textAlign: "center",
        fontFamily: "'Bebas Neue', serif",
        justifyContent: "center",
        fontWeight: "400",
        padding: "10px",
        color: "#3F4444 !important",
        zIndex:"9",
        "@media(max-width:640px)": {
            position: "fixed",
            right: "6%",
            bottom: "28%",
            position: "absolute",
            height: "60px",
            width: "60px",
        },
        "&:hover": {
            color: "#3F4444 !important",
        }
    },
})