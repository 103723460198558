import { Styles } from "./Style";
import { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import Loader from "react-spinner-loader";
import { Container } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { ActionType } from "../../redux/ActionTypes";
import NoDataFound from "../NoDataFound/NoDataFound";
import { useDispatch, useSelector } from "react-redux";
import { modeLinkHandler } from "../../Common/utils/utils";
import BackArrow from "../../Components/BackArrow/BackArrow";
import OrderStatus from "../../Components/OrderStatus/OrderStatus";
import OrderCardBox from "../../Components/OrderCardBox/OrderCardBox";
import { viewRecurringOrderAction } from "../../redux/actions/order/orderActions";
import ViewMore from "../../Components/ViewMore/ViewMore";

const RecurringDetails = (props) => {
  const { classes } = props;
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentOrder = localStorage.getItem("currentOrder");
  const typeDisplay = localStorage.getItem("typeDisplay");
  const totalOrders = localStorage.getItem("totalOrders");
  const setDefaultMode = localStorage.getItem("selected_modes");
  const { loading, viewRecurringOrder } = useSelector(
    (state) => state?.viewRecurringOrder
  );

  useEffect(() => {
    dispatch(viewRecurringOrderAction(orderId, page));
  }, []);

  //ScrollView Section
  const [orderData, setOrderData] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [page, setPage] = useState(1);

  // useEffect(() => {
  //   window.addEventListener("scroll", onScroll);
  //   return () => {
  //     window.removeEventListener("scroll", onScroll);
  //   };
  // });
useEffect(()=>{
localStorage.removeItem("ordernumberno")
},[])
  useEffect(() => {
    if (viewRecurringOrder != null) {
      if (viewRecurringOrder?.data?.orders.length < 10) {
        setIsComplete(true);
      }
      if (orderData?.length > 0) {
        if (page > 1) {
          if (viewRecurringOrder?.data?.orders != undefined) {
            setOrderData([...orderData, ...viewRecurringOrder?.data?.orders]);
          } else {
            setIsComplete(true);
          }
        }
      } else {
        setOrderData(viewRecurringOrder?.data?.orders);
      }
    }
  }, [viewRecurringOrder]);

  useEffect(() => {
    if (page === 1) {
      setOrderData([]);
    }
    // console.log("page", page);
    dispatch(viewRecurringOrderAction(orderId, page));
  }, [page]);

  // const onScroll = () => {
  //   const winHeight = window.innerHeight;
  //   const srcHeight = document.documentElement.scrollHeight;
  //   const scrollTop =
  //     document.body.scrollTop || document.documentElement.scrollTop;
  //   const toBottom = srcHeight - winHeight - scrollTop;

  //   if (toBottom <= 100) {
  //     if (!isComplete) {
  //       getData();
  //     }
  //   }
  // };

  // function getData() {
  //   if (loading) return;
  //   const nextPage = page + 1;
  //   setPage(nextPage);
  // }

  const loadMoreHandler = () => {
    const nextPage = page + 1;
    setPage(nextPage);
  };

  return (
    <>
      {loading && page === 1 && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <Container maxWidth="lg" className={classes.p0}>
        <div className={classes.outerInner}>
          <div className={`${classes.box} ${classes.p16}`}>
            <BackArrow
              clickHandler={() =>
                dispatch(
                  { type: ActionType.CLEAR_RESPONSE },
                  localStorage.removeItem("outletId"),
                  navigate(-1)
                )
              }
            />
            <h3>
              {/* {`${totalOrders} ${typeDisplay}`} */}
              {`${totalOrders} `}
            {/* {console.log("vikalp",orderData[0])} */}
            {orderData[0]?.order_type_display=="Pickup Today"?"PICK-UP TODAY":orderData[0]?.order_type_display=="One-Time Pickup"?"One-time Pick-up":orderData[0]?.order_type_display=="Pickup Recurring"?"Recurring Pick-up":orderData[0]?.order_type_display=="Pickup Custom"?"Custom Pick-up":orderData[0]?.order_type_display=="Delivery Recurring"?"Recurring Delivery":orderData[0]?.order_type_display=="Delivery Custom"?"Custom Delivery":orderData[0]?.order_type_display}
            </h3>
          </div>
          {(!loading && orderData.length) === 0 ? (
            <NoDataFound />
          ) : (
            <div>
              <div className={`${classes.box} ${classes.p16}`}>
                <div className={classes.cardOuter}>
                  <ul>
                    {orderData.map((order, index) => (
                      <li key={index}>
                        <OrderCardBox data={order} />
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={classes.btnContent}>
                  <OrderStatus
                    // link={modeLinkHandler(setDefaultMode)}
                    link={"/choose-mode"}
                    text={"Place Order"}
                    clickHandler={() => {
                      // navigate(modeLinkHandler(setDefaultMode));
                      navigate("/choose-mode");
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {viewRecurringOrder?.data?.pagination?.lastPage === page ||
          viewRecurringOrder?.data?.pagination?.count === orderData.length ||
          orderData.length < 1 ? null : (
            <ViewMore name={"Load More"} clickHandler={loadMoreHandler} />
          )}
        </div>
      </Container>
    </>
  );
};

export default withStyles(Styles)(RecurringDetails);
