export const Styles = (Theme) => ({
  dFlex: {
    display: "flex",
    alignItems: "flex-start",
    // "@media(max-width:375px)": {
    //   display: "block",
    // },
    "& > div": {
      width: "50%",
      // "@media(max-width:375px)": {
      //   width: "100%",
      // },
    },
    "& >:nth-child(2)": {
      marginLeft: "20px",
      // "@media(max-width:375px)": {
      //   marginLeft: "0",
      // },
    },
  },
  fromControl: {
    margin: "0 0 25px",
  },
  btnBox: {
    display: "flex",
    justifyContent: "flex-end",
    "@media(max-width:640px)": {
      flexDirection: "column-reverse",
      justifyContent: "center",
    },
    "& > div": {
      margin: "0 0 0 10px",
      "@media(max-width:640px)": {
        margin: "auto",
      },
    },
  },
  textCheckbox: {
    display: "flex",
    alignItems: "center",
    margin: "0px 0px 10px !important",
    maxWidth: "fit-content",
    "& p": {
      padding: "0",
      margin: "0",
      color: "#3F4444",
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: "400",
      fontFamily: "'Noto Serif', serif",
      cursor: "pointer",
      "& a": {
        fontWeight: "700",
        padding: "0 2px",
        fontSize: "14px",
        lineHeight: "14px",
      },
    },
    "& span": {
      padding: "0",
      margin: "0 5px 0 0",
      color: "#3F4444",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: "400",
      fontFamily: "'Noto Serif', serif",
    },
  },
  modalFlex: {
    display: "flex",
    "& > :nth-child(2)": {
      marginLeft: "15px",
    },
    "& button": {
      "@media(max-width:375px)": {
        margin: "10px 0",
      },
    },
  },
});
