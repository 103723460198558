export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!email || regex?.test(email) === false) {
    return true;
  }
  return false;
};

export const validatePhone = (phone) => {
  const toCheck = phone?.charAt(0);
  const allowedNumber = ["6", "8", "9"];
  const found = allowedNumber?.filter((number) => number == toCheck);
  if (phone?.length < 8 || phone?.length > 8 || found?.length === 0) {
    return true;
  } else return false;
};

export const validateName = (name) => {
  if (name?.length < 3) return true;
  else return false;
};

export const validateUEN = (uen) => {
  if (uen?.length < 9 || uen?.length > 10) return true;
  else return false;
};

export const validatePassword = (password) => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~#$@!%&*?^()_+|])[A-Za-z\d#$@!%&*?()_+|~^]{8,}$/;
  if (!password || regex?.test(password) === false) {
    return true;
  }
  return false;
};

export const validateConfirmPassword = (password, confirmPassword) => {
  if (confirmPassword !== password) {
    return true;
  } else return false;
};

export const validateAmount = (amount) => {
  if (amount < 10 || amount > 500) return true;
  else return false;
};

export const validateCredit = (credit) => {
  if (credit < 10 || credit > 1000) return true;
  else return false;
};

export const validateRequired = (value) => (value === "" ? true : false);

export const validateUnitNumber = (number) => {
  const regex = /^(?=.*?[0-9])[0-9#-]+$/;
  if (!number || regex?.test(number) === false || number?.length > 10) {
    return true;
  }
  return false;
};

export const validatePostalCode = (code) =>
  code === "" || code?.length < 6 || code?.length > 6 ? true : false;
