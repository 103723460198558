export const Styles = Theme => ({
    textCheckbox: {
        display: "flex",
        alignItems: "center",
        margin: "0px 0px 10px 10px !important",
        "@media(max-width:640px)": {
            marginLeft: "0 !important",
        },
        maxWidth: "250px",
        "& p": {
            padding: "0",
            margin: "0",
            color: "#3F4444",
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            cursor:"pointer",
            "& a": {
                fontWeight: "700",
                padding: "0 2px",
                fontSize: "14px",
                lineHeight: "14px",
            }
        },
        "& span": {
            padding: "0",
            margin: "0 5px 0 0",
            color: "#3F4444",
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
        },
    },
    dFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "20px 0 0",
        "@media(max-width:640px)": {
            display: "block",
            margin: "0px 0",
        },
    },
    addItems: {
        display: "flex",
        alignItems: "center",
        paddingRight: "15px",
        "@media(max-width:640px)": {
            paddingRight: "0",
        },
        "& a": {
            display: "flex",
            "@media(max-width:640px)": {
                padding: " 20px 20px !important",
                background: "#fff",
                borderRadius: "10px",
                width: "100%",
            },
        },
        "& img": {
            paddingRight: "6px",
        }
    },
    btnContent: {
        "& button": {
            marginTop: "0",
        }
    }
})