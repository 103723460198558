export const Styles = (Theme) => ({
  oppsOuter: {
    width: "100%",
    padding: "0 20px",
    height: "calc(100vh - 350px)",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 20px",
    position: "relative",
    "@media(max-width:640px)": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: "auto",
    }
  },
  oppsInner: {
    textAlign: "center",
    "& h2": {
      margin: "0 0 10px",
    },
    "& p": {
      marginBottom: "0",
    },
  },
  btnContent: {
    "& button": {
      padding: "12px 20px",
      background: "#FFC200",
      color: "#3F4444",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "400",
      fontFamily: "'Bebas Neue', serif",
      margin: "20px auto 0",
      letterSpacing: "0",
      whiteSpace: "nowrap",
      borderRadius: "32px",
      display: "block",
      minWidth: "100px",
      "@media(max-width:640px)": {
        fontSize: "14px",
        lineHeight: "16px",
      },
      "&:hover": {
        background: "#FFC200",
      },
    },
  },
});
