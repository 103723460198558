import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import StrConstant from "../../../Common/StrConstant";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { Link } from "react-router-dom";
import DarkGrayBtn from "../../../Components/DarkGrayBtn/DarkGrayBtn";
import OrderStatus from "../../../Components/OrderStatus/OrderStatus";
import { useDispatch, useSelector } from "react-redux";
import { userLogOut } from "../../../redux/actions/UserActions";
import { ActionType } from "../../../redux/ActionTypes";
import { modeLinkHandler } from "../../../Common/utils/utils";
const MyAccount = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setDefaultMode = localStorage.getItem("selected_modes");
  const userInfo = useSelector((state) => state?.userSignin);
  // modal
  const [modalType, setModalType] = useState();
  const [showModel, setShowModel] = useState(false);
  const showLogoutPopup = () => {
    setModalType(0);
    setShowModel(!showModel);
  };
  // const showAccountPopup = () => {
  //   setModalType(1);
  //   setShowModel(!showModel);
  // };
  // modal Close
  const modalClose = () => {
    setShowModel(false);
  };
  const logoutHandler = () => {
    dispatch(userLogOut());
  };

  return (
    <>
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch(
                { type: ActionType.CLEAR_RESPONSE },
                navigate("/my-dashboard")
              )
            }
          />
          <h3>{StrConstant.MyAccount.heading}</h3>
        </div>
        <div>
          <div className={classes.card}>
            <Link to="/profile">
              <div className={classes.ltBox}>
                <h4>Profile</h4>
              </div>
              <div className={classes.rtBox}>
                <div className={classes.imgContent}>
                  <img src="./images/arrow.svg" alt="Better Coffee" />
                </div>
              </div>
            </Link>
          </div>
          {userInfo?.userInfo?.data?.user_data?.role_name ===
          "company_employee" ? null : (
            <div className={classes.card}>
              <Link to="/manage-payment-methods">
                <div className={classes.ltBox}>
                  <h4>Manage Payment Methods</h4>
                </div>
                <div className={classes.rtBox}>
                  <div className={classes.imgContent}>
                    <img src="./images/arrow.svg" alt="Better Coffee" />
                  </div>
                </div>
              </Link>
            </div>
          )}
          <div className={classes.card}>
            <Link to="/manage-addresses">
              <div className={classes.ltBox}>
                <h4>Manage Addresses</h4>
              </div>
              <div className={classes.rtBox}>
                <div className={classes.imgContent}>
                  <img src="./images/arrow.svg" alt="Better Coffee" />
                </div>
              </div>
            </Link>
          </div>

          {(userInfo?.userInfo?.data?.user_data?.provider=="google"
          || userInfo?.userInfo?.data?.user_data?.provider=="facebook")
          ?
          null
          :(
 <div className={classes.card}>
 <Link to="/change-password" className={classes.borderBottom0}>
   <div className={classes.ltBox}>
     <h4>Change Password</h4>
   </div>
   <div className={classes.rtBox}>
     <div className={classes.imgContent}>
       <img src="./images/arrow.svg" alt="Better Coffee" />
     </div>
   </div>
 </Link>
</div>
          )}
         
          <OrderStatus
            // link={modeLinkHandler(setDefaultMode)}
            link={"/choose-mode"}
            text={"Place order"}
          />
          <div className={classes.btnBox}>
            {/* <Link
                to="/delete-account"
                onClick={showAccountPopup}
                className={classes.txtLink}
              >
                Delete my account
              </Link> */}
            <YellowBtn name={"Log out"} clickHandler={showLogoutPopup} />
          </div>
        </div>
      </OuterBox>
      {/* Modal */}
      <CustomModal
        open={showModel}
        title={
          modalType === 0
            ? "Are you sure you want to log out?"
            : "Are you sure you want to Delete my account ?"
        }
        description={"You'll be missed, see you again soon!"}
      >
        <div className={`${classes.box} ${classes.modalContent}`}>
          <div className={classes.modalFlex}>
            <DarkGrayBtn name="Cancel" clickHandler={modalClose} />
            <YellowBtn
              name={modalType === 0 ? "Log out" : "Delete"}
              clickHandler={logoutHandler}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};
export default withStyles(Styles)(MyAccount);
