import { ActionType } from "../../ActionTypes";
import serverURL from "../../../APIHandler/serverURL";
import callAPI from "../../../APIHandler/callAPI";
import { reloadHandler } from "../../../Common/utils/utils";

export const getMyWalletData = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GET_MY_WALLET_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    if (userInfo?.data?.user_data?.auth_token) {
      const { data } = await callAPI.getRequest(serverURL.getMyWallet, {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      });
      localStorage.setItem("walletdetails",JSON.stringify(data))
      return dispatch({
        type: ActionType.GET_MY_WALLET_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.GET_MY_WALLET_FAILURE,
      payload: message,
    });
  }
};
