import React from "react";
import { Styles } from "./Style";
import Alert from "@mui/material/Alert";
import { withStyles } from "@mui/styles";
import Snackbar from "@mui/material/Snackbar";
const AlterMessage = (props) => {
  const { classes, url, title, description, type, open, closeHandler } = props;
  const isCloseHandler = (event, reason) => {
    if (reason === "clickaway") return;
    else closeHandler();
  };
  return (
    <div
      className={
        type === "Success"
          ? `${classes.alertSuccess}`
          : type === "Info"
          ? `${classes.alertInfo}`
          : type === "Error"
          ? `${classes.alertUnSuccess}`
          : null
      }
    >
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={null}
        onClose={isCloseHandler}
      >
        <Alert
          onClose={isCloseHandler}
          className={`${open ? `${classes.alertInner}` : null}`}
          style={{ backgroundColor: `white` }}
        >
          <div className={classes.alertBox} >
            <div className={classes.imgContent}>
              <img src={url} alt="Better Coffee" />
            </div>
            <div className={classes.txtContent}>
              <h3 className={classes.status}>{title}</h3>
              <p className={classes.message}>{description}</p>
            </div>
          </div>
        </Alert>
      </Snackbar>
    </div>
  );
};
export default withStyles(Styles)(AlterMessage);
