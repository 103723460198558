export const Styles = Theme => ({
    box: {
        width: "100%",
    },
    searchBox: {
        position: 'relative',
        "& input": {
            paddingRight: "50px",
        },
        "& img": {
            position: "absolute",
            right: "15px",
            top: "50%",
            transform: 'translate(-50%, -50%)',
        }
    },
    tabDesign: {
        margin: "20px 0 10px",
        borderBottom: '1px solid #D5D4DC',
        "&  span": {
            backgroundColor: '#FFC200',
        },
        "& button": {
            padding: "15px 0",
            minWidth: "auto",
            marginRight: "35px",
            minHeight: "auto",
            fontSize: "20px",
            fontWeight: "400",
            color: "#3F4444",
            lineHeight: "20px",
            fontFamily: "'Bebas Neue',serif",
            "&  span": {
                display: "none",
            },
        },
        "& [aria-selected='true']": {
            color: "#FFC200 !important",
        },
    },
    tabContainer: {
        padding: "10px 0 0 !important"
    },
    chipDesign: {
        "& div": {
            borderRadius: "54px",
            background: '#F1EEDB',
            padding: '10px',
            fontSize: "15px",
            fontWeight: "400",
            color: "#3F4444",
            lineHeight: "15px",
            fontFamily: "'Bebas Neue',serif",
            minWidth: "60px",
            cursor: "pointer",
        },
    },
    active: {
        background: '#FFC200 !important',
        color: "#fff !important",
    },
    boxOuter: {
        padding: "5rem 0 0",
        "& ul": {
            margin: "0",
        },
        "& li": {
            display: "inline-block",
            width: "20%",
            padding: "0 20px 5rem 0",
            "@media(max-width:1024px)": {
                width: "25%",
            },
            "@media(max-width:991px)": {
                width: "33.33%",
            },
            "@media(max-width:575px)": {
                width: "50%",
            },
            "&:nth-child(5n+5)": {
                paddingRight: "0",
                "@media(max-width:1024px)": {
                    paddingRight: "20px",
                },
            },
            "&:nth-child(3n+3)": {
                "@media(max-width:991px)": {
                    paddingRight: "0",
                },
                "@media(max-width:575px)": {
                    paddingRight: "20px",
                },
            },
            "&:nth-child(even)": {
                "@media(max-width:575px)": {
                    paddingRight: "0",
                },
            }
        }

    },
    outerInner: {
        margin: "auto",
        display: "flex",
        width: "100%",
        alignItems: "flex-start",
        height: "calc(100vh - 150px)",
        "@media(max-width:640px)": {
            display: "block",
        }
    },
    box: {
        width: "100%",
        height:"100%",
    }
})

