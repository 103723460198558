import * as React from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Style } from "@mui/icons-material";
import Propover from "../Propover/Propover";
// import StrConstant from "../../Common/StrConstant";
// import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyWalletData } from "../../redux/actions/myWallet/MyWalletActions";
// import CustomPropover from "../CustomPropover/CustomPropover";
const WalletCard = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { myWallet } = useSelector((state) => state?.getMyWallet);
  useEffect(() => {
    dispatch(getMyWalletData());
  }, []);
  useEffect(()=>{
localStorage.setItem("TotalCredit",myWallet?.data?.wallet_balance)
  },[myWallet])
  // console.log("myWallet?.data?.balance_expiry", myWallet?.data?.wallet_balance);
  return (
    <>
      <div className={classes.accordionContent}>
        <Accordion className={classes.box}>
          <AccordionSummary
            expandIcon={<img src="./images/arrow-close.svg" alt="close" />}
            className={Style.accordionHeading}
          >
            <div className={classes.topBox}>
              <h4>
                {myWallet?.data?.wallet_balance
                  ? myWallet?.data?.wallet_balance
                  : ""}{" "}
                Credits
              </h4>
              {myWallet?.data?.balance_expiry != "-" &&
                myWallet?.data?.wallet_balance != 0 ? (
                <div className={classes.dateBox}>
                  {myWallet?.data?.balance_expiry && (
                    <>
                      <p>Expiring {myWallet?.data?.balance_expiry}</p>
                      <Propover>
                        Your credits expire 12 months upon purchase. With 
                        credits that have been purchased on different dates, 
                        the expiry date shown here is based on your latest purchase.
                      </Propover>
                    </>
                  )}
                </div>
              ) : (
                <p>No Credits Available</p>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.listing}>
              {myWallet?.data?.transactions?.length >= 0 ? (
                <ul>
                  {myWallet?.data?.transactions?.map((data, index) => (
                    <li key={index}>
                      <div className={classes.lt}>
                        <h5>{data?.total_credits} Credits</h5>
                      </div>
                      <div className={classes.rt}>
                        <p>{data?.expiring_at}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
export default withStyles(Styles)(WalletCard);
