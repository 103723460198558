export const Styles = (Theme) => ({
  cardContainer: {
    background: "#fff",
    borderRadius: "10px",
    "@media(max-width:640px)": {
      background: "transparent",
    },
  },
  chooseBox: {
    width: "100%",
    display: "block",
    borderRadius: "10px",
    "& :nth-child(3n+3)": {
      marginRight: "0",
      marginBottom: "0",
    },
  },
  card: {
    background: "#FFFFFF",
    borderRadius: "10px",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    borderBottom: "1px solid #dddddd",
    width: "100%",
    // cursor: "pointer",
    "@media(max-width:640px)": {
      borderBottom: "0",
    },
  },
  ltBox: {
    width: "80%",
    "& h4": {
      marginBottom: "10px",
    },
    "& p": {
      marginBottom: "0",
    },
  },
  rtBox: {
    width: "20%",
    paddingLeft: "10px",
    textAlign: "right",
  },
  imgContent: {
    cursor: "pointer",
  },
  modalFlex: {
    display: "flex",
    "& :nth-child(2)": {
      marginLeft: "15px",
    },
  },
});
