export const Styles = Theme => ({
    textCheckbox: {
        display: "flex",
        alignItems: "center",
        margin: "0px 0px 10px !important",
        maxWidth: "fit-content",
        "& p": {
            padding: "0",
            margin: "0",
            color: "#3F4444",
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            cursor:"pointer",
            "& a": {
                fontWeight: "700",
                padding: "0 2px",
                fontSize: "14px",
                lineHeight: "14px",
            }
        },
        "& span": {
            padding: "0",
            margin: "0 5px 0 0",
            color: "#3F4444",
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
        },
    },
    addItems: {
        display: "flex",
        alignItems: "center",
        margin: "20px 0",
        "& img": {
            height: "15px",
            marginRight: "10px",
        }
    },
    dFlex: {
        display: "flex",
        alignItems:"center",
        justifyContent:"space-between",
        "@media(max-width:475px)":{
            display: "block",
        },
        "& > div:nth-child(2)": {
            marginRight: "20px",
            "@media(max-width:475px)":{
                marginRight: "0",
            },
        },
    },
})