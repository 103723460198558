export const Styles = Theme => ({
    btnBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "@media(max-width:640px)": {
            display: "block",
        },
        "& :nth-child(2)": {
            marginLeft: "10px",
            "@media(max-width:640px)": {
                margin: "auto",
            },
        },
        "& > div": {
            "@media(max-width:640px)": {
                float: "unset",
                margin: "auto",
            }
        },
    },
    successOrder:{
        "@media(max-width:640px)": {
            height: "calc(100vh - 160px)",
            display: "flex !important",
            flexDirection: "column",
            justifyContent: "space-between",
        }
    }
})