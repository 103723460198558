import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import YellowBtn from "../YellowBtn/YellowBtn";
import StrConstant from "../../Common/StrConstant";
import LogoContainer from "../LogoContainer/LogoContainer";
import { useNavigate } from "react-router";
const SuccessMessage = (props) => {
  const { classes } = props;
  let navigate = useNavigate();
  const buttonClick = () => {
    navigate("/my-dashboard");
  };
  return (
    <>
      <div className={classes.formOuter}>
        <div className={classes.formInner}>
          <div>
            <LogoContainer
              heading={StrConstant.Success.heading}
              goUrl={""}
              urlTxt={""}
            />
            <div className={classes.topBox}>
              <p>You have successfully created your account!</p>
            </div>
          </div>
          <div className={classes.customBtn}>
            <YellowBtn name="RETURN TO LOGIN" clickHandler={buttonClick} />
          </div>
        </div>
      </div>
    </>
  );
};
export default withStyles(Styles)(SuccessMessage);
