export const Styles = (Theme) => ({
  customBtn: {
    maxWidth: "250px",
    width: "100%",
    margin: "0",
    float: "right",
    "@media(max-width:640px)": {
      maxWidth: "100%",
      float: "unset",
      margin: "auto",
    },
    "& button": {
      padding: "15px 24px",
      background: "#FFC200",
      color: "#3F4444",
      fontSize: "20px",
      lineHeight: "20px",
      fontWeight: "400",
      fontFamily: "'Bebas Neue', serif",
      margin: "20px 0 0",
      letterSpacing: "0",
      whiteSpace: "nowrap",
      borderRadius: "32px",
      width: "100%",
      transition:"inherit",
      "@media(max-width:640px)": {
        padding: "12px 20px",
        margin: "0px 0 15px",
      },
      "&:hover": {
        background: "#FFC200",
      },
    },
  },
});
