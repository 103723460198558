export const Styles = Theme => ({
    accordionBox: {
        borderRadius: "10px !important",
        boxShadow: "none !important",
        padding: "20px",
        "&::before": {
            content: "''",
            display: "none",
        },
        "& > div": {
            padding: "0 !important",
            margin: "0 !important",
            alignItems: "flex-start",
        },
        "& > div > div": {
            padding: "0 !important",
            margin: "0 !important"
        }
    },
    topBox: {
        "& h4": {
            margin: "0",
        },
        "& p": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#3F4444BF",
        }
    },
    btBox: {
        "& p": {
            color: "#3F4444BF",
            marginBottom: "0",
        }
    },
    content: {
        padding: " 20px 0 0 !important",
        "& h6": {
            marginBottom: "5px",
        },
        "& a": {
            marginTop: "5px",
        }
    },
    topBox: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginBottom: "15px",
        paddingRight: "10px",
    },
    topInner: {
        width: "100%",
        paddingRight: '20px',
    },
    ltBox: {
        width: "fit-content",
        background: "#0000000D",
        padding: "6px 10px",
        borderRadius: "10px",
        marginRight: "15px",
        "& h4": {
            marginBottom: "0",
        },
        "& p": {
            marginBottom: "0",
            marginTop: "2px",
            fontSize: "12px",
            lineHeight: "16px",
            textAlign: "center",
        },
    },
    divider: {
        display: "block",
        width: "auto",
        height: "3px",
        background: "#ddd",
        borderRadius: "5px",
        margin: "auto",
    },
    rtBox: {
        width: "fit-content",
        "& h4": {
            marginBottom: "0",
        },
        "& p": {
            marginBottom: "0",
            fontSize: "12px",
            lineHeight: "16px",
        },
    },
    p20:{
        paddingBottom:"20px",
    }
})