import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { saveData } from "../../redux/actions/UserActions";
import { useEffect, useState } from "react";
import { getOutletList } from "../../redux/actions/outlet/OutletActions";
import NoDataFound from "../../Pages/NoDataFound/NoDataFound";

const Outlets = (props) => {
  const { classes, outletList, isVisible = false } = props;
  const navigate = useNavigate();
  const [expand, setexpand] = useState(false);
  const arraylist = [];
  const [outletid, setoutletid] = useState("");

  // console.log("outletcategory",outletCategory);
  const dispatch = useDispatch();

  // console.log("outletcategory",outletCategory);

  const selected_modes = localStorage.getItem("selected_modes");
  const selectionHandler = (id, outletName, openToday) => {
    if (openToday || isVisible === false) {
      console.log("outletid", id, outletName);
      dispatch(saveData("selected_outlet_id", id));
      dispatch(saveData("selected_outlet_name", outletName));
      navigate("/select-product");

      // dispatch(saveData("selected_outlet_id", id));
      // dispatch(saveData("selected_outlet_name", outletName));
    }
  };
  const { loading, outletCategory } = useSelector(
    (state) => state.getOutletCategory
  );
  useEffect(() => {
    // console.log("outletcategory", outletCategory);
  }, [selectionHandler, outletCategory]);
  // useEffect(() => {
  //   dispatch(getOutletList(selected_modes === "3" ? "delivery" : "pickup"));
  // }, []);
  return outletList?.data?.outlets?.length > 0 ? (
    <div className={classes.outletsCard}>
      {outletList?.data?.outlets?.map((outlets, index) => (
        <div
          key={index}
          // className={classes.cardConteiner}
        >
          <div
            className={`${classes.arrowcenterdiv} ${classes.card} ${
              !outlets?.open_today && isVisible ? classes.notActive : null
            }`}
          >
            <div style={{ width: "100%" }}>
              <div className={classes.mb10}>
                {!outlets?.open_today && isVisible && (
                  <div className={classes.bookmark}>closed</div>
                )}
                <h4 className={classes.heading}>{outlets?.outlet_name}</h4>
              </div>
              <ul>
                <li>
                  <div className={classes.outletsLeftBox}>
                    <div className={classes.ltBox}>
                      <div className={classes.imgContent}>
                        <img src="./images/location.svg" alt="Better Coffee" />
                      </div>
                    </div>
                    <div className={classes.rtBox}>
                      {/* <p>{outlets?.address}</p> */}
                      <p>
                        {` 
                            ${
                              outlets?.address_line1 === "-" || outlets?.address_line1 === ""
                                ? ""
                                : outlets?.address_line1
                            }
                            ${
                            outlets?.address_line2 === "-" || outlets?.address_line2 === ""
                              ? ""
                              : outlets?.address_line2
                          }
                          ${
                            outlets?.unit_code === "-" || outlets?.unit_code === "" ? "" : outlets?.unit_code
                          }
                          ${
                          outlets?.pin_code === "-" || outlets?.pin_code === "" ? "" : outlets?.pin_code
                        }`}
                      </p>
                    </div>
                  </div>
                  {/* <div className={classes.outletsRightBox}>
                  {!outlets?.open_today && isVisible ? null : (
                    <div className={classes.imgContent}>
                      <img
                        className={classes.imgPointer}
                        src="./images/arrow.svg"
                        alt="Better Coffee"
                        onClick={() =>
                          selectionHandler(
                            outlets?.outlet_id,
                            outlets?.outlet_name,
                            outlets?.open_today
                          )
                        }
                      />
                    </div>
                  )}
                </div> */}
                </li>
                <li className={classes.arrowcenter}>
                  {expand == true ? (
                    <>
                      {/* <div className={classes.ltBox}>
                  <div className={classes.imgContent}>
                    <img src="./images/watch.svg" alt="Better Coffee" />
                  </div>
                </div> */}
                      <div style={{ display: "flex" }}>
                        <div className={classes.ltBox}>
                          <div className={classes.imgContent}>
                            <img src="./images/watch.svg" alt="Better Coffee" />
                          </div>
                        </div>
                        <div className={classes.rtBox}>
                          {/* <p>
                    Open:{" "}
                    {outlets?.open_today ? outlets?.days_open : "Close today"}
                    <p></p>
                    {outlets?.days_close !== "" ? (
                      <p>Close: {outlets?.days_close}</p>
                    ) : (
                      ""
                    )}
                    <p>PH: Closed</p>
                  </p> */}
                          {/* //code */}
                          {outletid == outlets.outlet_id ? (
                            <>
                              <p>
                                {outlets?.schedule?.map((item) => {
                                  return (
                                    <div>
                                      {item.day_short != "Sun" && (
                                        <>
                                          {item.open != "" && (
                                            <p>
                                              <span
                                                className={classes.daysbold}
                                              >
                                                {item.day_short}
                                              </span>
                                              : {item.open} - {item.close}
                                              <br />
                                              {item?.break_start != "" && (
                                                <span>
                                                  (Closed for Break:{" "}
                                                  {item.break_start} -{" "}
                                                  {item.break_end})
                                                </span>
                                              )}
                                              <p></p>
                                            </p>
                                          )}
                                          {item.open == "" && (
                                            <p>
                                              <span
                                                className={classes.daysbold}
                                              >
                                                {item.day_short}
                                              </span>
                                              : Closed
                                              <p></p>
                                            </p>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  );
                                })}

                                {outlets?.schedule?.map((item) => {
                                  return (
                                    <div>
                                      {item.day_short == "Sun" && (
                                        <>
                                          {item.open == "" ? (
                                            <p>
                                              <span
                                                className={classes.daysbold}
                                              >
                                                {item.day_short}
                                              </span>
                                              : Closed
                                              <p></p>
                                            </p>
                                          ) : (
                                            <>
                                              <p>
                                                <span
                                                  className={classes.daysbold}
                                                >
                                                  {item.day_short}
                                                </span>
                                                : {item.open} - {item.close}
                                                <br />
                                                {item?.break_start != "" && (
                                                  <span>
                                                    (Closed for Break:{" "}
                                                    {item.break_start} -{" "}
                                                    {item.break_end})
                                                  </span>
                                                )}
                                                <p></p>
                                              </p>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  );
                                })}
                              </p>
                              <div
                                className={classes.viewbold}
                                onClick={() => setexpand(false)}
                              >
                                Hide Opening Hours
                              </div>
                            </>
                          ) : (
                            <div
                              className={classes.viewbold}
                              onClick={() => {
                                setexpand(true);
                                setoutletid(outlets.outlet_id);
                              }}
                            >
                              View Opening Hours
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <div className={classes.outletsRightBox}>
              
                  {!outlets?.open_today && isVisible ? null : (
                    <div className={classes.imgContent}>
                      <img
                        className={classes.imgPointer}
                        src="./images/arrow.svg"
                        alt="Better Coffee"
                        onClick={() =>
                          selectionHandler(
                            outlets?.outlet_id,
                            outlets?.outlet_name,
                            outlets?.open_today
                          )
                        }
                      />
                    </div>
                  )}
                </div> */}
                    </>
                  ) : (
                    <>
                      <div style={{ display: "flex" }}>
                        <div className={classes.ltBox}>
                          <div className={classes.imgContent}>
                            <img src="./images/watch.svg" alt="Better Coffee" />
                          </div>
                        </div>
                        <div
                          className={classes.viewbold}
                          onClick={() => {
                            setexpand(true);
                            // console.log("outletlist1", outlets.outlet_id);
                            setoutletid(outlets.outlet_id);
                          }}
                        >
                          View Opening Hours
                        </div>
                      </div>
                      {/* <div className={classes.outletsRightBox}>
                  {!outlets?.open_today && isVisible ? null : (
                    <div className={classes.imgContent}>
                      <img
                        className={classes.imgPointer}
                        src="./images/arrow.svg"
                        alt="Better Coffee"
                        onClick={() =>
                          selectionHandler(
                            outlets?.outlet_id,
                            outlets?.outlet_name,
                            outlets?.open_today
                          )
                        }
                      />
                    </div>
                  )}
                </div> */}
                    </>
                  )}
                </li>
              </ul>
            </div>
            <div className={classes.outletsRightBox}>
              {!outlets?.open_today && isVisible ? null : (
                <div className={classes.imgContent}>
                  <img
                    className={classes.imgPointer}
                    src="./images/arrow.svg"
                    alt="Better Coffee"
                    onClick={() =>
                      selectionHandler(
                        outlets?.outlet_id,
                        outlets?.outlet_name,
                        outlets?.open_today
                      )
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <div></div>
        </div>
      ))}
    </div>
  ) : (
    <NoDataFound />
  );
};

export default withStyles(Styles)(Outlets);
