import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstant from "../../Common/StrConstant";
import SignupForm from "../../Components/SignupForm/SignupForm";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
const Signup = (props) => {
  const { classes } = props;
  const [desktop, setDesktop] = useState(window.innerWidth > 768);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const mobileWidth = () => {
    setDesktop(window.innerWidth > 768);
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("resize", mobileWidth);
    return () => window.removeEventListener("resize", mobileWidth);
  });

  useEffect(() => {

    if (userInfo?.data?.user_data?.auth_token) {
      console.log("vikalp");
      navigate("/")
    }
  }, [userInfo])
  return (
    <>
      {desktop ? (
        <div className={classes.root}>
          <div className={classes.container}>
            <div className={classes.overlayContainer}>
              <div className={classes.overlayPanel}>
                <h1>{StrConstant.Main.heading} </h1>
                <p>{StrConstant.Main.description}</p>
              </div>
            </div>
            <div className={classes.formContainer}>
              <SignupForm />
            </div>
          </div>
        </div>
      ) : (
        <SignupForm />
      )}
    </>
  );
};

export default withStyles(Styles)(Signup);
