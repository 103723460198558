import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstant from "../../../Common/StrConstant";
import { useNavigate } from "react-router";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import Addresses from "../../../Components/Addresses/Addresses";
import { useDispatch, useSelector } from "react-redux";
import Loader from "react-spinner-loader";
import { ActionType } from "../../../redux/ActionTypes";
import { useEffect, useState } from "react";
// import Outlets from "../../../Components/Outlets/Outlets";
const ManageAddresses = (props) => {
  // const { classes } = props;
  // const { outletList } = useSelector((state) => state?.outletList);
  const { loading } = useSelector((state) => state?.userAddressList);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setView = () => {
    // if (localStorage.getItem("address_id")) {
    //   return <Outlets outletList={outletList} />;
    // } else {
    return <Addresses />;
    // }
  };
  const [show, setShow] = useState(setView);

  useEffect(() => {
    setShow(setView);
  }, [show]);
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() => {
              dispatch(
                { type: ActionType.CLEAR_RESPONSE },
                localStorage.removeItem("addressId"),
                navigate(-1)
              );
            }}
          />
          <h3>{StrConstant.ManageAddresses.heading}</h3>
        </div>
        <div>{show}</div>
      </OuterBox>
    </>
  );
};

export default withStyles(Styles)(ManageAddresses);
