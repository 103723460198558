export const Styles = Theme => ({
    notFountOuter: {
        height: "calc(100vh - 150px)",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent:"center",
        padding: "0 20px",
    },
    notFountInner:{
        maxWidth: "400px",
        textAlign: "center",
    },
    imgContent: {
        maxWidth: "300px",
        height: "auto",
        margin:"0 auto 40px",
        "@media(max-width:640px)":{
            maxWidth: "200px",
            margin:"0 auto 20px",
        },
        "& img":{
            height:"auto",
            width:"100%",
        }
    },
    desShow:{
        "@media(max-width:640px)":{
            display:"none",
        },
    },
    mobShow:{
        display:"none",
        "@media(max-width:640px)":{
            display:"block",
        },
    }
})