import { ActionType } from "../ActionTypes";
import serverURL from "../../APIHandler/serverURL";
import callAPI from "../../APIHandler/callAPI";
import { encryptValue, reloadHandler } from "../../Common/utils/utils";
import moment from "moment/moment";

export const signin = (email, password, isRemember) => async (dispatch) => {
  const param = {
    email: encryptValue(email),
    password: encryptValue(password),
  };
  dispatch({
    type: ActionType.USER_SIGNIN_REQUEST,
    payload: param,
  });
  try {
    const { data } = await callAPI.postRequest(serverURL.login, param);
    dispatch({ type: ActionType.USER_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
    sessionStorage.setItem("status", "active");

    // if (!isRemember)
    //   localStorage.setItem("expiry", moment(new Date()).add(30, "m").toDate());

    localStorage.setItem("isRemember", isRemember);
    localStorage.setItem("cart_id", data?.data?.user_data?.cart_id);
    if (data?.data?.user_data?.default_mode?.id !== null) {
      localStorage.setItem(
        "defaultMode",
        data?.data?.user_data?.default_mode?.title
      );
      // localStorage.setItem("modeId", data?.data?.user_data?.default_mode?.id);
      localStorage.setItem(
        "selected_modes",
        data?.data?.user_data?.default_mode?.id
      );
    }
    // document.location.href = "/my-dashboard";
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.USER_SIGNIN_FAILURE,
      payload:
        error.response && error.response.data
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resendEmailVerify = (sendEmail) => async (dispatch) => {
  dispatch({ type: ActionType.CLEAR_RESPONSE });

  dispatch({
    type: ActionType.RESEND_EMAIL_VERIFY_REQUEST,
    payload: {
      email: sendEmail,
    },
  });
  try {
    const { data } = await callAPI.postRequest(serverURL.resendEmailVerify, {
      email: sendEmail,
    });
    dispatch({ type: ActionType.RESEND_EMAIL_VERIFY_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.RESEND_EMAIL_VERIFY_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const registerUser = (userDetails) => async (dispatch) => {
  dispatch({
    type: ActionType.USER_REGISTER_REQUEST,
    payload: userDetails,
  });
  try {
    const { data } = await callAPI.postRequest(serverURL.register, userDetails);
    dispatch({ type: ActionType.USER_REGISTER_SUCCESS, payload: data });
    dispatch(saveData("email", userDetails?.email));
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.USER_REGISTER_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const userLogOut =
  (changed = false) =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionType.USER_LOGOUT_REQUEST,
    });
    const userInfoLocal = JSON.parse(localStorage.getItem("userInfo"));
    const {
      userSignin: { userInfo },
    } = getState();
    const key = userInfo
      ? userInfo?.data?.user_data?.auth_token
      : userInfoLocal?.data?.user_data?.auth_token;
    try {
      await callAPI.postRequest(
        serverURL.userLogout,
        {},
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${key}`,
          },
        }
      );
      dispatch({ type: ActionType.USER_LOGOUT_SUCCESS });
      localStorage.clear();
      localStorage.setItem("isChanged", changed);
      document.location.reload();
    } catch (error) {
      localStorage.clear();
      reloadHandler(error?.response?.status);
      // document.location.reload();
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({ type: ActionType.USER_LOGOUT_FAILURE, payload: message });
    }
  };

export const userSetPassword = (credentials) => async (dispatch) => {
  dispatch({
    type: ActionType.USER_SET_PASSWORD_REQUEST,
    payload: credentials,
  });
  try {
    const { data } = await callAPI.postRequest(
      serverURL.setPassword,
      credentials,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      }
    );
    dispatch({ type: ActionType.USER_SET_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.USER_SET_PASSWORD_FAILURE, payload: message });
  }
};

export const userChangePassword = (password) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.USER_CHANGE_PASSWORD_REQUEST,
    payload: password,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.putRequest(
      serverURL.changePassword,
      password,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.USER_CHANGE_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.USER_CHANGE_PASSWORD_FAILURE,
      payload: message,
    });
  }
};

export const userUpdateProfile =
  (userDetails, user_id) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.USER_UPDATE_PROFILE_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.patchRequest(
        serverURL.updateProfile.concat(user_id),
        userDetails,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({ type: ActionType.USER_UPDATE_PROFILE_SUCCESS, payload: data });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.USER_UPDATE_PROFILE_FAILURE,
        payload: message,
      });
    }
  };

export const userSendOtp = (mobile_phone) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.SEND_OTP_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  const user_id = userInfo?.data?.user_data?.user_id;
  try {
    const { data } = await callAPI.patchRequest(
      serverURL.sendOtp.concat(user_id),
      mobile_phone,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.SEND_OTP_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.SEND_OTP_FAILURE, payload: message });
  }
};

export const userVerifyOtp = (otp) => async (dispatch, getState) => {
  dispatch({ type: "CLEAR_OTP" });
  dispatch({
    type: ActionType.VERIFY_OTP_REQUEST,
    payload: otp,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(
      serverURL.verifyOtp,
      { otp },
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.VERIFY_OTP_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.VERIFY_OTP_FAILURE, payload: message });
  }
};

export const saveData = (name, data) => async (dispatch) => {
  dispatch({
    type: ActionType.SAVE_DATA_REQUEST,
    payload: data,
  });
  try {
    name && data
      ? localStorage.setItem(name, data) &&
        dispatch({ type: ActionType.SAVE_DATA_SUCCESS, payload: data })
      : dispatch({
          type: ActionType.SAVE_DATA_FAILURE,
          payload: "VALID NAME AND DATA SET REQUIRED",
        });
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.SAVE_DATA_FAILURE,
      payload: error,
    });
  }
};

export const verifyEmail = (email) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.VERIFY_EMAIL_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(
      serverURL.verifyEmail,
      { email: email },
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.VERIFY_EMAIL_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.VERIFY_EMAIL_FAILURE, payload: message });
  }
};

export const emailVerify = (vrToken) => async (dispatch) => {
  dispatch({
    type: ActionType.VERIFY_EMAIL_REQUEST,
    payload: { vrToken },
  });
  try {
    const { data } = await callAPI.getRequest(
      `${serverURL.emailVerify}${vrToken}`
    );
    dispatch({ type: ActionType.VERIFY_EMAIL_SUCCESS, payload: data });
    dispatch(saveData("password_token", data.data.user_data.password_token));
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch(saveData("email", error.response.data.data.email));
    dispatch({
      type: ActionType.VERIFY_EMAIL_FAILURE,
      payload:
        error.response &&
        (error.response.data.message ?? error.response.data.email)
          ? error.response.data
          : error.message,
    });
  }
};

export const userForgotPassword = (email) => async (dispatch) => {
  dispatch({
    type: ActionType.USER_FORGOT_PASSWORD_REQUEST,
  });
  try {
    const { data } = await callAPI.postRequest(
      `${serverURL.forgotPassword}`,
      email
    );
    dispatch({ type: ActionType.USER_FORGOT_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.USER_FORGOT_PASSWORD_FAILURE,
      payload: message,
    });
  }
};

export const getUserNotificationsAction =
  (currentPage) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.USER_NOTIFICATIONS_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        serverURL.userNotifications.concat(`?page=${currentPage}`),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({ type: ActionType.USER_NOTIFICATIONS_SUCCESS, payload: data });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.USER_NOTIFICATIONS_FAILURE,
        payload: message,
      });
    }
  };

export const socialLoginAction = (payload) => async (dispatch) => {
  localStorage.setItem("loading", true);
 
  dispatch({
    type: ActionType.USER_SOCIAL_LOGIN_REQUEST,
    payload,
  });
  try {
    const { data } = await callAPI.postRequest(serverURL.socialLogin, payload);
    dispatch({ type: ActionType.USER_SOCIAL_LOGIN_SUCCESS, payload: data });
    dispatch({ type: ActionType.USER_SIGNIN_SUCCESS, payload: data });
    localStorage.setItem("userInfo", JSON.stringify(data));
    localStorage.setItem("cart_id", data?.data?.user_data?.cart_id);
   
    window.location.reload();
  } catch (error) {
    reloadHandler(error?.response?.status);
    localStorage.setItem("loading", false);
    dispatch({
      type: ActionType.USER_SOCIAL_LOGIN_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSpendPolicyAction = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.SPEND_POLICY_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.getRequest(serverURL.getSpendPolicy, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });
    dispatch({ type: ActionType.SPEND_POLICY_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.SPEND_POLICY_SUCCESS,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const userResendOtpAction = () => async (dispatch, getState) => {
  dispatch({ type: ActionType.RESEND_OTP_VERIFY_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.getRequest(serverURL.resendOtp, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });
    dispatch({ type: ActionType.RESEND_OTP_VERIFY_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.RESEND_OTP_VERIFY_FAILURE, payload: message });
  }
};

// for multiple
export const readNotificationsAction = (page) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.READ_NOTIFICATIONS_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.getRequest(serverURL.readNotifications, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });
    dispatch({ type: ActionType.READ_NOTIFICATIONS_SUCCESS, payload: data });
    // dispatch(getUserNotificationsAction(page));
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.READ_NOTIFICATIONS_FAILURE,
      payload: message,
    });
  }
};
// for single
export const readNotificationAction =
  (id, page) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.READ_NOTIFICATION_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        serverURL.readNotifications.concat(`/${id}`),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({ type: ActionType.READ_NOTIFICATION_SUCCESS, payload: data });
      // dispatch(getUserNotificationsAction(page));
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.READ_NOTIFICATION_FAILURE,
        payload: message,
      });
    }
  };

export const getUserProfileAction = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GET_USER_PROFILE_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  const userInfoLocal = JSON.parse(localStorage.getItem("userInfo"));
  try {
    const { data } = await callAPI.getRequest(serverURL.getUserProfile, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${
          userInfoLocal?.data?.user_data?.auth_token ??
          userInfo?.data?.user_data?.auth_token
        }`,
      },
    });
    dispatch({ type: ActionType.GET_USER_PROFILE_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.GET_USER_PROFILE_FAILURE,
      payload: message,
    });
  }
};
