export const Styles = Theme => ({
    dFlex: {
        // display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        marginBottom: "10px",
        textAlign: "center",
        "@media(max-width:640px)": {
            textAlign: "left",
        },
        "& a": {
            fontSize: "16px",
            lineHeight: "24px",
            paddingLeft: "5px",
            "@media(max-width:640px)": {
                fontSize: "14px",
            },
        },
        "& p": {
            margin: "0",
            // fontSize: "18px",
        },
    },
    btTxt: {
        display: "flex",
        alignItems:"center",
        justifyContent: "center",
        width: "100%",
        padding: "10px 0",
        "& p": {
            margin: "0",
            paddingRight: "5px",
        },
        "& a": {
            color: "#3F4444BF !important",
            textDecoration: "underline",
        }
    },
    otpBox: {
        margin: "20px 0",
        "& > div": {
            justifyContent: "center",
        },
        "& input": {
            height: "55px",
            width: "55px !important",
            background: "#f1eedb !important",
            "@media(max-width:640px)": {
                background: "#fff !important",
            },
        }
    },
    space: {
        padding: "0 10px",
    },
    btnBox: {
        "& > div": {
            float: "unset",
            margin: "auto",

        }
    }
})