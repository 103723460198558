export const Styles = (Theme) => ({
  productBox: {
    position: "relative",
  },
  innerBox: {
    background: "#F1EEDB",
    borderRadius: "10px",
    padding: "15px",
    position: "relative",
    textDecoration: "none",
    display: "block",
    cursor: "pointer",
  },
  disable: {
    background: "#3F44441A",
    opacity: ".5",
  },
  topBox: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "60px",
    marginBottom: "15px",
    "@media(max-width:640px)": {
      minHeight: "60px",
    },
  },
  imgContent: {
    maxHeight: "100px",
    width: "100%",
    maxWidth: "60px",
    height: "auto",
    position: "absolute",
    right: "20px",
    top: "-30px",
    bottom: "0",
    objectFit: "contain",
    display: "flex",
    flexDirection: "column",
    justifyContent:"flex-end",
    flex: "1",
    "@media(max-width:640px)": {
      right: "10px",
    },
    "& img": {
      width: "100%",
      // marginTop: "auto",
    },
  },
  bottomBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    "& h5": {
      marginBottom: "5px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      lineClamp: "2",
      boxOrient: "vertical",
    },
    "& p": {
      marginBottom: "0",
    },
  },
  icon: {
    height: "20px",
    cursor: "pointer",
    width: "20px",
    color: "#111827",
    position: "absolute",
    top: "20px",
    left: "20px",
    zIndex: "99",
    "& :hover": {
      color: "#111827",
    },
  },
  borderIcon: {
    height: "20px",
    cursor: "pointer",
    width: "20px",
    color: "#FFCF27",
    position: "absolute",
    top: "20px",
    left: "20px",
    zIndex: "99",
    position: "absolute",
    top: "20px",
    left: "20px",
    zIndex: "99",
  },
});
