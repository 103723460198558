import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import OrderCardBox from "../../../Components/OrderCardBox/OrderCardBox";
import StrConstant from "../../../Common/StrConstant";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { Fragment, useEffect, useState } from "react";
import AlterMessage from "../../../Components/AlertMessage/AlertMessage";
import { useDispatch, useSelector } from "react-redux";
// import CommonSection from "../../../Components/CommonSection/CommonSection";
import { ActionType } from "../../../redux/ActionTypes";
// import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
// import { modeLinkHandler } from "../../../Common/utils/utils";
import Loader from "react-spinner-loader";
import Container from "@mui/material/Container";
import { viewOrderAction } from "../../../redux/actions/order/orderActions";
// import NoDataFound from "../../NoDataFound /NoDataFound";
import OrderStatus from "../../../Components/OrderStatus/OrderStatus";
// import ViewMore from "../../../Components/ViewMore/ViewMore";
import Opps from "../../../Components/Opps/Opps";
import Propover from "../../../Components/Propover/Propover";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useBreakpoint } from "../../../Hooks/useBreakpoints";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginLeft: "10px",
    backgroundColor: theme.palette.common.white,
    color: 'red',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    open: true,
  },
}));
const MyOrder = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const device = useBreakpoint()
  const navigate = useNavigate();
  const [isCancel, setIsCancel] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [alertSnack, setAlertSnack] = useState(false);
  // const [isCheckout, setIsCheckout] = useState(false);
  // const orderCheckout = useSelector((state) => state?.finalizeOrderCheckout);
  const orderCancel = useSelector((state) => state?.cancelOrder);
  // const setDefaultMode = localStorage.getItem("selected_modes");
  const { loading, viewOrder } = useSelector((state) => state?.viewOrder);
  const { editOrder } = useSelector((state) => state?.editOrder);
  const snackHandler = (event, reason) => {
    dispatch(
      { type: ActionType.CLEAR_RESPONSE },
      { type: ActionType.CLEAR_UPDATE },
      // setIsCancel(true),
      setIsCancel(false),
      // setIsCheckout(true),
      setIsCancel(false),
      setAlertSnack(false),
      setIsEdited(false)
    );
    // if (reason === "clickaway") return;
  };
  useEffect(() => {
    localStorage.removeItem("ordernumberno")
    localStorage.removeItem("totalcount")
    localStorage.removeItem("workingcount")
  }, [])
  // useEffect(() => {
  //   if (orderCheckout?.checkoutOrder?.success) return setIsCheckout(true);
  // }, [orderCheckout?.checkoutOrder?.success]);

  useEffect(() => {
    if (orderCancel?.cancelOrder?.success) setIsCancel(true);
  }, [orderCancel?.cancelOrder?.success]);

  useEffect(() => {
    if (editOrder?.success) setIsEdited(true);
  }, [editOrder]);

  //ScrollView Section
  const [orderData, setOrderData] = useState([]);
  // const [isComplete, setIsComplete] = useState(false);
  // const [page, setPage] = useState(1);

  // useEffect(() => {
  //   window.addEventListener("scroll", onScroll);
  //   return () => {
  //     window.removeEventListener("scroll", onScroll);
  //   };
  // });

  // useEffect(() => {
  //   if (viewOrder != null) {
  //     if (viewOrder?.data?.orders.length < 10) {
  //       setIsComplete(true);
  //     }
  //     if (orderData?.length > 0) {
  //       if (page > 1) {
  //         if (viewOrder?.data?.orders != undefined) {
  //           setOrderData([...orderData, ...viewOrder?.data?.orders]);
  //         } else {
  //           setIsComplete(true);
  //         }
  //       }
  //     } else {
  //       setOrderData(viewOrder?.data?.orders);
  //     }
  //   }
  // }, [viewOrder]);

  useEffect(() => {
    // if (viewOrder != null) {
    if (viewOrder?.data?.orders.length > 0) {
      setOrderData(viewOrder?.data?.orders);
    }
    if (viewOrder?.data?.orders.length === 0) {
      setOrderData([]);
    }
    // }
  }, [viewOrder]);

  // useEffect(() => {
  //   if (page === 1) {
  //     setOrderData([]);
  //   }
  //   // console.log("page", page);
  //   dispatch(viewOrderAction(page));
  // }, [page]);

  // const onScroll = () => {
  //   const winHeight = window.innerHeight;
  //   const srcHeight = document.documentElement.scrollHeight;
  //   const scrollTop =
  //     document.body.scrollTop || document.documentElement.scrollTop;
  //   const toBottom = srcHeight - winHeight - scrollTop;

  //   if (toBottom <= 100) {
  //     if (!isComplete) {
  //       getData();
  //     }
  //   }
  // };

  // const loadMoreHandler = () => {
  //   const nextPage = page + 1;
  //   setPage(nextPage);
  // };
  const [canclesucess, setcanclesucess] = useState('')
  const [iscanclesucess, setiscanclesucess] = useState(false)
  useEffect(() => {
    dispatch({ type: ActionType.CLEAR_CHECKOUT });
    dispatch(viewOrderAction());
  }, []);
  const titlev = () => {
    return <p style={{ color: "#3F4444B2", margin: "0", padding: "0", fontSize: "15px", fontWeight: "300", lineHeight: "1" }}>
      <div className={classes.tooltipInner}>Only orders within the next 60 days are shown. Please check your email for Order Confirmation for any orders beyond that.</div>
    </p>
  }
  console.log("device", orderCancel?.cancelOrder?.success)
  useEffect(() => {
    if (orderCancel?.cancelOrder?.success) {
      setcanclesucess(orderCancel?.cancelOrder?.message)
      setiscanclesucess(orderCancel?.cancelOrder?.success);
      dispatch({type:ActionType.CLEAR_RESPONSE})
    }
  
  }, [orderCancel?.cancelOrder?.success])

  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <AlterMessage
        closeHandler={snackHandler}
        description={"Enter credit"}
        title="Error"
        type="Error"
        open={alertSnack}
        url="./images/error.svg"
      />
      {iscanclesucess && isCancel && (
        <AlterMessage
          closeHandler={snackHandler}
          description={canclesucess}
          title={"Success"}
          type={"Success"}
          open={isCancel}
          url={"./images/success.svg"}
        />
      )}
      {/* {orderCheckout?.checkoutOrder?.success && isCheckout && (
        <AlterMessage
          closeHandler={snackHandler}
          description={orderCheckout?.checkoutOrder?.message}
          title={"Success"}
          type={"Success"}
          open={isCheckout}
          url={"./images/success.svg"}
        />
      )} */}
      {editOrder?.success && isEdited && (
        <AlterMessage
          description={editOrder?.message}
          url={"./images/success.svg"}
          closeHandler={snackHandler}
          title={"Success"}
          type={"Success"}
          open={isEdited}
        />
      )}

      <Container maxWidth="lg" className={classes.p0}>
        <div className={classes.outerInner}>
          <div className={`${classes.box} ${classes.p16}`}>
            <BackArrow
              clickHandler={() =>
                dispatch(
                  { type: ActionType.CLEAR_RESPONSE },
                  localStorage.removeItem("outletId"),
                  navigate("/my-dashboard")
                )
              }
            />
            <div className={classes.dFlex}>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                <h3 style={{ margin: 0, paddingRight: "5px" }}>{StrConstant.MyOrder.heading}</h3>
                {/* <div>
                  <LightTooltip title={titlev()} placement={(device == "sm" || device == "xs") ? "bottom-end" : "right"} arrow>
                    <img src="./images/i-icon.svg" alt="Better coffee" />
                  </LightTooltip>
                </div> */}
                {/* <Tooltip title="Only orders within the next 60 days are shown. Please check your email for Order Confirmation for any orders beyond that." placement="auto" arrow>
                  <img src="./images/i-icon.svg" alt="Better coffee" width='15px'/>
                </Tooltip> */}

                <Propover>
                  <p className={classes.txtColor}>
                    <span>Only orders within the next 60 days are shown. Please check your email for Order Confirmation for any orders beyond that.</span>
                  </p>
                </Propover>
              </div>
              <Link to="/order-history">View order history</Link>
            </div>
          </div>
          {loading == false && orderData.length === 0 ? (
            <>
              <Opps />
              <div className={classes.btnContent}>
                <OrderStatus
                  link={"/choose-mode"}
                  text={"Place Order"}
                  clickHandler={() => {
                    navigate("/choose-mode");
                  }}
                />
              </div>
            </>
          ) : (
            orderData?.map((calendar, i) => (
              <div key={i}>
                <div className={classes.labelBox}>
                  <h4>{calendar.month}</h4>
                </div>
                <div className={`${classes.box} ${classes.p16}`}>
                  <div className={classes.cardOuter}>
                    <ul key={Math.random()}>
                      {Object.keys(calendar?.orders).map((KeyOrders, index) => {
                        const childOrders = calendar?.orders[KeyOrders];
                        return (
                          <Fragment key={Math.random()}>
                            <li key={index}>
                              <div className={classes.dateBox}>
                                <h3>{childOrders?.date.split(" ")[0]}</h3>
                                <h6>{childOrders?.date.split(",")[1]}</h6>
                              </div>
                              <p className={classes.ltLine}>
                                <span></span>
                              </p>
                              {childOrders?.orders?.map((order, index) => (
                                <Fragment key={index}>
                                  {/* <p className={classes.ltLine}>
                                    <span></span>
                                  </p> */}
                                  <OrderCardBox
                                    data={order}
                                    isRecurring={true}
                                  />
                                  {/* <br /> */}
                                </Fragment>
                              ))}
                            </li>
                          </Fragment>
                        );
                      })}
                    </ul>
                  </div>
                  <div className={classes.btnContent}>
                    {/* {" "} */}
                    {/* <YellowBtn
                    name={"Place Order"}
                    clickHandler={() => {
                      navigate(modeLinkHandler(setDefaultMode));
                    }}
                  /> */}
                    <OrderStatus
                      // link={modeLinkHandler(setDefaultMode)}
                      link={"/choose-mode"}
                      text={"Place Order"}
                      clickHandler={() => {
                        // navigate(modeLinkHandler(setDefaultMode));
                        navigate("/choose-mode");
                      }}
                    />
                  </div>
                </div>
                {/* <div>
                {viewOrder?.data?.pagination?.lastPage === page ||
                viewOrder?.data?.pagination?.count === orderData.length ||
                orderData.length < 1 ? null : (
                  <ViewMore name={"Load More"} clickHandler={loadMoreHandler} />
                )}
              </div> */}
              </div>
            ))
          )}
        </div>
      </Container>
    </>
  );
};
export default withStyles(Styles)(MyOrder);
