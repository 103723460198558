export const Styles = (Theme) => ({
  mb20: {
    marginBottom: "15px",
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  formOuter: {
    "@media (max-width: 768px)": {
      height: "100%",
      display: "table",
      width: "100%",
      background: "#F7F7F7",
    },
  },
  formInner: {
    background: "rgba(255, 255, 255, 0.75)",
    borderRadius: "12px",
    padding: "2rem",
    maxWidth: "100%",
    width: "100%",
    height: "auto",
    margin: "0 auto",
    display: "block",
    boxSizing: "border-box",
    overflowY: "auto",
    maxHeight: "100vh",
    "@media (max-width: 768px)": {
      maxWidth: "100%",
      background: "#F7F7F7",
      maxHeight: "100%",
      overflowY: "inherit",
      borderRadius: "0",
      padding: "2rem 1.5rem",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-between",
    },
    "&::-webkit-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-moz-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-o-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-ms-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "& h2": {
      marginTop: "0",
    },
    "& div": {
      margin: "0",
    },
  },
  fromControl: {
    margin: "0 0 25px",
  },
  customBtn: {
    "& button": {
      padding: "12px 24px",
      background: "#FFC200",
      color: "#3F4444",
      fontSize: "20px",
      lineHeight: "20px",
      fontWeight: "400",
      fontFamily: "'Bebas Neue', serif",
      margin: "20px 0 0",
      letterSpacing: "0",
      whiteSpace: "nowrap",
      borderRadius: "32px",
      outline: "none",
      border: "none",
      width: "100%",
      "&:hover": {
        background: "#FFC200",
      },
      "@media (max-width: 640px)": {
        fontSize: "16px",
        lineHeight: "20px",
        padding: "12px 20px",
        margin: "0 0 20px",
      },
    },
  },
  passwordBox: {
    position: "relative",
    "& input": {
      padding: "12px 45px 12px 12px",
    },
    "& img": {
      position: "absolute",
      right: "10px",
      top: "50%",
      transform: "translate(-50%,-50%)",
    },
  },
  errorMessage: {
    fontSize: "14px",
    lineHeight: "19px",
    color: "#DF3F51",
    fontWeight: "400",
    fontFamily: "'Noto Serif', serif",
    margin: "5px 10px 0",
    "& a": {
      textDecorationColor: "#DF3F51 !important",
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "'Noto Serif', serif",
      display: "inline-block",
      cursor: "pointer",
      color: "#DF3F51 !important",
    },
  },
});
