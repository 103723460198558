import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import DarkGrayBtn from "../../../Components/DarkGrayBtn/DarkGrayBtn";
import { ActionType } from "../../../redux/ActionTypes";
import { useDispatch, useSelector } from "react-redux";
const OrderSuccessfully = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { viewOrderDetail } = useSelector((state) => state?.getOrderDetail);
  return (
    <>
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
        </div>
        <div className={classes.successOrder}>
          <h4>
            {viewOrderDetail?.data?.order_number} Your order review has been
            submitted!
          </h4>
          <div className={classes.btnBox}>
            <DarkGrayBtn
              name={"Return to My Orders"}
              clickHandler={() => navigate("/my-orders")}
            />
          </div>
        </div>
      </OuterBox>
    </>
  );
};
export default withStyles(Styles)(OrderSuccessfully);
