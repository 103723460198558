import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import FormLabel from "@mui/material/FormLabel";
import LogoContainer from "../LogoContainer/LogoContainer";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AlterMessage from "../AlertMessage/AlertMessage";
import { userForgotPassword } from "../../redux/actions/UserActions";
import { validateEmail, validateRequired } from "../../Common/Validations";
import StrConstant from "../../Common/StrConstant";
import { useNavigate } from "react-router";
import { ActionType } from "../../redux/ActionTypes";
import Loader from "react-spinner-loader";
import { Link } from "react-router-dom";

const ForgotForm = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [inValid, setInValid] = useState(false);
  const data = useSelector((state) => state?.forgotPassword);
  // const snackHandler = (event, reason) => {
  //   if (reason === "clickaway") return;
  //   setIsOpen(false);
  // };
  const forgotHandler = () => {
    if (validateRequired(email)) setInValid(true);
    else dispatch(userForgotPassword({ email }), setIsOpen(true));
  };
  useEffect(() => {
    if (data?.success != undefined) {
      localStorage.setItem("email", email);
      navigate("/forgot-success");
    }
  }, [data?.success]);
  useEffect(() => {
    dispatch({ type: ActionType.CLEAR_RESPONSE });
  }, []);

  // console.log("dataerror", data?.error?.includes("Google"));
  return (
    <>
      {/* {(data?.success || data?.error) && (
        <AlterMessage
          open={isOpen}
          closeHandler={snackHandler}
          type={data?.error ? "Error" : "Success"}
          title={data?.error ? "Error" : "Success"}
          url={data?.error ? "./images/error.svg" : "./images/success.svg"}
          description={
            data?.error ? data?.error : data?.forgotPassword.data.message
          }
        />
      )} */}
      {data?.loading && (
        <Loader
          show={data?.loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <div className={classes.formInner}>
        <div>
          <LogoContainer
            heading={StrConstant.Reset.heading}
            goUrl={"/login"}
            urlTxt={"Back to Login"}
          />
          <p>
            Enter your registered email address below to reset your password.
          </p>
          <form>
            <div className={classes.fromControl}>
              <FormLabel>Email Address</FormLabel>
              <div className={classes.passwordBox}>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  onChange={(email) => setEmail(email.target.value)}
                />
              </div>
              <>
                {data?.error &&
                data?.error?.email_verify != false &&
                data?.error !=
                  "This email is not registered. Please try again or create a new account." &&
                (data?.error?.includes("Google") ||
                  data?.error?.includes("google") ||
                  data?.error?.includes("Facebook") ||
                  data?.error?.includes("facebook") ||
                  data?.error?.includes("Social") ||
                  data?.error?.includes("social")) ? (
                  <p className={classes.errorMessage}>
                    You account is connected to Google or Facebook. Please use
                    the Google or Facebook button to log in.
                  </p>
                ) : (
                  ""
                )}
              </>

              <>
                {data?.error?.email_verify == false ? (
                  <p className={classes.errorMessage}>
                    {data?.error?.message}{" "}
                    <Link to={`/resend-email/${data?.error?.email}`}>
                    Resend verification email{" "}
                    </Link>
                  </p>
                ) : data?.error ==
                  "This email is not registered. Please try again or create a new account." ? (
                  <p className={classes.errorMessage}>
                    This email is not registered. Please try again or
                    <Link to={`/sign-up/`}>create a new account.</Link>
                  </p>
                ) : (
                  ""
                )}
              </>

              {inValid && email === "" ? (
                <p className={classes.errorMessage}>This field is required.</p>
              ) : (
                <div></div>
              )}
              {email !== "" && validateEmail(email) ? (
                <p className={classes.errorMessage}>
                  Information entered is invalid. Please try again.{" "}
                </p>
              ) : null}
            </div>
          </form>
        </div>
        <div className={classes.customBtn}>
          <Button onClick={() => forgotHandler()}>SUBMIT</Button>
        </div>
      </div>
    </>
  );
};
export default withStyles(Styles)(ForgotForm);
