import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import StrConstant from "../../../Common/StrConstant";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import DarkGrayBtn from "../../../Components/DarkGrayBtn/DarkGrayBtn";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import { ActionType } from "../../../redux/ActionTypes";
import { useDispatch, useSelector } from "react-redux";
// import { convertDDMMYYYY } from "../../../Common/utils/utils";
import { getMyWalletData } from "../../../redux/actions/myWallet/MyWalletActions";
import { cancelPlanAction } from "../../../redux/actions/planList/PlanListActions";
import AlterMessage from "../../../Components/AlertMessage/AlertMessage";
import moment from "moment";
const CancelSubscription = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModel, setShowModel] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { myWallet } = useSelector((state) => state?.getMyWallet);
  // const subscriptionId =
  //   myWallet?.data?.active_subscription?.subscription_id &&
  //   myWallet?.data?.upcoming_subscription?.subscription_id
  //     ? myWallet?.data?.upcoming_subscription?.subscription_id
  //     : myWallet?.data?.active_subscription?.subscription_id;
  //   const userInfo = useSelector((state) => state?.userSignin);
  const cancelSubscription = useSelector((state) => state?.cancelPlan);
  // console.log("cancelSubscription?.cancelPlan?.data?.success",cancelSubscription?.cancelPlan?.success);
  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    setIsOpen(false);
  };
  // back button
  const linkClickHandler = () => {
    dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1));
  };
  // modal Show
  const showPopup = () => {
    setShowModel(!showModel);
  };

  const SubscriptionModal = () => {
    setShowSubscriptionModal(!showSubscriptionModal);
    setShowModel(!showModel);
    dispatch(
      cancelPlanAction({
        subscription_id: myWallet?.data?.active_subscription?.subscription_id,
      })
    );
  };

  // modal Close
  const modalClose = () => {
    setShowModel(false);
  };
  useEffect(() => {
    dispatch(getMyWalletData());
  }, []);

  useEffect(() => {
    setIsOpen(true);
  }, [cancelSubscription?.error]);

  useEffect(() => {
    if (cancelSubscription?.cancelPlan?.success) {
      // localStorage.setItem("is_subscribed", false);
      navigate("/my-dashboard");
    }
  }, [cancelSubscription]);
  return (
    <>
      {cancelSubscription?.error && (
        <AlterMessage
          closeHandler={snackHandler}
          description={cancelSubscription?.error}
          title="Error"
          type="Error"
          open={isOpen}
          url="./images/error.svg"
        />
      )}
      <OuterBox>
        <div>
          <BackArrow clickHandler={linkClickHandler} />
          <h3>{StrConstant.CancelSubscription.heading}</h3>
        </div>
        <div>
          <p>{StrConstant.CancelSubscription.description1}</p>
          <p>{StrConstant.CancelSubscription.description2}</p>
          <p>
            Cancel by{" "}
            {moment(myWallet?.data?.active_subscription?.current_period_end)
              .subtract(1, "days")
              .format("D MMM YYYY")}{" "}
            to avoid being charged.
          </p>
          <div className={classes.btnBox}>
            {/* <YellowBtn name={"Confirm cancellation"} clickHandler={showPopup} /> */}
            <YellowBtn name={"Confirm"} clickHandler={showPopup} />
          </div>
        </div>
      </OuterBox>
      <CustomModal
        open={showModel}
        title={"Are you sure you  want to cancel your subscription? "}
        description={"We'd hate to see you go!"}
      >
        <div className={classes.modalFlex}>
          <DarkGrayBtn name="No" clickHandler={modalClose} />
          <YellowBtn name="Yes" clickHandler={SubscriptionModal} />
        </div>
      </CustomModal>
      {/* Subscription cancelled modal  */}
      {cancelSubscription?.success && (
        <CustomModal
          open={showSubscriptionModal}
          title={"Subscription cancelled"}
          description={"Your subscription will end immediately."}
        >
          <div className={classes.subscriptionModal}>
            <YellowBtn
              name="Back to My Subscription"
              clickHandler={() => navigate("/manage-subscription")}
            />
          </div>
        </CustomModal>
      )}
    </>
  );
};
export default withStyles(Styles)(CancelSubscription);
