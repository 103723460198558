import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
const StepperCustom = (props) => {
  const { classes, step = 0 } = props;
  const credit = localStorage.getItem("credit");
  // const gotCredit = useSelector((state) => state?.getOneTimeCredits?.success);
  return (
    <div className={classes.stepperWrapper}>
      <div
        className={`${classes.screenIndicator} ${
          step >= 1 ? classes.completed : null
        }`}
      >
        <span className={step >= 1 ? classes.check : classes.dots}></span>
        <span className={classes.txt}> {credit ? " Credit" : "Choose"}</span>
      </div>

      <div
        className={`${classes.screenIndicator} ${
          step >= 2 ? classes.completed : null
        }`}
      >
        <span className={step >= 2 ? classes.check : classes.dots}></span>
        <span className={classes.txt}>Payment</span>
      </div>

      <div
        className={`${classes.screenIndicator} ${
          step >= 3 ? classes.completed : null
        }`}
      >
        <span className={step >= 3 ? classes.check : classes.dots}></span>
        <span className={classes.txt}> Complete</span>
      </div>
    </div>
  );
};
export default withStyles(Styles)(StepperCustom);
