export const Styles = (Theme) => ({
  maxWidth: {
    maxWidth: "250px",
    overflow:"hidden"
  },
  ScheduleDelDropDown: {
    minWidth: "100px",
    "@media(max-width:375px)": {
      minWidth: "90px",
      maxWidth: "90px",
    },
    "& button": {
      background: "#F1EEDB !important",
      color: "#3f4444",
      fontSize: "16px",
      display: "flex",
      alignItems: "flex-start",
      textAlign: "left",
      padding: "0 15px",
      "@media(max-width:640px)": {
        padding: "0",
        background: "transparent",
      },
      "@media(max-width:375px)": {
        maxWidth: "90px",
      },
      "& img": {
        marginTop: "6px",
      },
    },
    "& li": {
      display: "block",
      "& p": {
        display: "block",
        marginBottom: "0",
      },
    },
    "& b": {
      textOverflow: "ellipsis",
      display: "-webkit-box",
      lineClamp: "1",
      boxOrient: "vertical",
      overflow: "hidden",
      textAlign: "left",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: "400",

    },
  },
  first: {
    fontSize: "14px",
    lineHeight: "16px",
    marginBottom: "2px",
    fontWeight: "400",
    textAlign: "left",
    whiteSpace: "nowrap",
    display: "inline-block",
    textTransform: "capitalize",
    "@media(max-width:575px)": {
      fontSize: "13px",
    },
  },
  headerMenu: {
    fontSize: "13px",
    fontWeight: "400",
    maxWidth: "fit-content",
    "@media(max-width:575px)": {
      fontSize: "12px",
    },
  },
  second: {
    display: "inline-block",
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "14px",
    "@media(max-width:575px)": {
      fontSize: "10px",
    },
  },
  modalFlex: {
    display: "flex",
    "& :nth-child(2)": {
      marginLeft: "15px",
    },
  },
});
