export const Styles = Theme => ({
    listingOuter: {
        // background: "#F1EEDB",
        // padding: "20px",
        margin: "25px 0",
        borderRadius: "10px",
    },
    heading:{
        background: "#F1EEDB",
        padding: "20px", 
        "& h4":{
            marginBottom:"0",
        },
    },
    dFlex: {
        display: "flex",
        width: "100%",
    },
    listingBox: {
        padding: "0 20px", 
        "& li": {
            "& h4": {
                marginBottom: "5px",
            },
            "& p": {
                marginBottom: "5px",
                textAlign: "right",
                lineHeight:"20px",
            },
            "& b": {
                display:"block",
                color:"#3F4444",
                fontSize:"14px",
                marginBottom: "5px",
                textAlign: "right",
                lineHeight:"20px",
            },
        }
    },
    lt: {
        width: "30%",
        paddingRight: "15px",
        "& h4": {
            marginBottom: "0",
        }
    },
    rt: {
        width: "70%",
        "& div": {
            marginLeft: "auto",
            textAlign: "right",
            maxWidth: "15px",
            "& img": {
                height: "auto",
                width: "100%",
            }
        }
    },
    btnBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
        "@media(max-width:640px)": {
            display: "block",
        },
        "& :nth-child(2)": {
            marginLeft: "10px",
            "@media(max-width:640px)": {
                margin: "auto",
            }
        },
        "& > div": {
            "@media(max-width:640px)": {
                float: "unset",
                margin: "auto",
            }
        },
    },
})
