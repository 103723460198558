import { ActionType } from "../../ActionTypes";
import serverURL from "../../../APIHandler/serverURL";
import callAPI from "../../../APIHandler/callAPI";
import { reloadHandler } from "../../../Common/utils/utils";
// import { encryptValue, decryptValue } from '../../../Common/utils/utils';

export const getPlanListDetail = (page) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GET_PLAN_LIST_DETAILS_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    // console.log("TRY--");
    const { data } = await callAPI.getRequest(`${serverURL.planList}?page=${page!=undefined?page:1}`, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });
    // console.log("Data:", data);
    return dispatch({
      type: ActionType.GET_PLAN_LIST_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    // console.log("CATCH:");
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.GET_PLAN_LIST_DETAILS_FAILURE,
      payload: message,
    });
  }
};

export const getBillingHistoryAction =
  (currentPage) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.BILLING_HISTORY_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    // console.log("TOKEN:", userInfo?.data?.user_data?.auth_token);
    try {
      const { data } = await callAPI.getRequest(
        serverURL.billingHistory.concat(`?page=${currentPage}`),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      //   console.log("Data:", data);
      return dispatch({
        type: ActionType.BILLING_HISTORY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      //   console.log("CATCH:");
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      return dispatch({
        type: ActionType.BILLING_HISTORY_FAILURE,
        payload: message,
      });
    }
  };

export const changePlanAction = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.CHANGE_PLAN_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(serverURL.changePlan, payload, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });
    return dispatch({ type: ActionType.CHANGE_PLAN_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({ type: ActionType.CHANGE_PLAN_FAILURE, payload: message });
  }
};

export const cancelPlanAction = (id) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.CANCEL_PLAN_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(serverURL.cancelPlan, id, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });
    return dispatch({ type: ActionType.CANCEL_PLAN_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({ type: ActionType.CANCEL_PLAN_FAILURE, payload: message });
  }
};
