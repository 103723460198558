import { withStyles } from '@mui/styles';
import { Styles } from './Style';
import StrConstant from '../../Common/StrConstant';
import ForgotForm from '../../Components/ForgotForm/ForgotForm';
import { useState, useEffect } from 'react';
const Forgot = (props) => {
    const { classes } = props
    const [desktop, setDesktop] = useState(window.innerWidth > 768);
    const mobileWidth = () => {
        setDesktop(window.innerWidth > 768)
    }
    useEffect(() => {
        window.addEventListener("resize", mobileWidth);
        return () => window.removeEventListener("resize", mobileWidth);
    })
   
    return (
        <>
            {desktop ? (<div className={classes.root}>
                <div className={classes.container}>
                    <div className={classes.overlayContainer}>
                        <div className={classes.overlayPanel}>
                            <h1>{StrConstant.Main.heading} </h1>
                            <p>{StrConstant.Main.description}</p>
                        </div>
                    </div>
                    <div className={classes.formContainer}>
                        <ForgotForm />
                    </div>
                </div>
            </div>) : (
                <ForgotForm />
            )}
        </>
    )
}

export default withStyles(Styles)(Forgot);

