export const Styles = (Theme) => ({
  p0: {
    "@media(max-width:640px)": {
      padding: "0 !important",
    },
  },
  p16: {
    "@media(max-width:640px)": {
      padding: "0 16px  !important",
    },
  },
  outerInner: {
    display: "table",
    width: "100%",
  },
  dFlex: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  labelBox: {
    background: "#F1EEDB",
    padding: "15px",
    "& h4": {
      margin: "0",
    },
  },
  cardOuter: {
    "& ul": {
      padding: "0",
      margin: "0",
    },
    "&  li": {
      // width: "33%",
      position: "relative",
      paddingBottom: "20px",
    },
  },
  box: {
    width: "100%",
    height: "100%",
  },
  btnContent: {
    paddingBottom: "30px",
    display: "table",
    width: "100%",
    marginTop: "20px",
  },
  ViewMore: {
    margin: "20px 0",
  }
});
