import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import StrConstant from "../../../../Common/StrConstant";
import OuterBox from "../../../../Components/OuterBox/OuterBox";
import YellowBtn from "../../../../Components/YellowBtn/YellowBtn";
import GrayBtn from "../../../../Components/GrayBtn/GrayBtn";
import { finalizeOrderCheckout } from "../../../../redux/actions/cart/cartActions";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "../../../../redux/ActionTypes";
import AlertMessage from "../../../../Components/AlertMessage/AlertMessage";
import DarkGrayBtn from "../../../.././Components/DarkGrayBtn/DarkGrayBtn";
import { modeLinkHandler } from "../../../../Common/utils/utils";
const PhoneNumberVerified = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const selected_modes = localStorage.getItem("selected_modes");
  const { checkoutOrder, error } = useSelector(
    (state) => state?.finalizeOrderCheckout
  );
  const ButtonClick = () => {
    dispatch(
      finalizeOrderCheckout(localStorage.getItem("checkoutData")),
      setIsOpen(true)
    );
  };

  useEffect(() => {
    if (checkoutOrder?.success) return navigate("/order-confirm");
  }, [checkoutOrder]);
  useEffect(()=>{
    dispatch({type:ActionType.CLEAR_CHECKOUT})
    dispatch({ type: ActionType.CLEAR_RESPONSE },setIsOpen(false))
  },[])
  return (
    <>
      {error && (
        <AlertMessage
          closeHandler={() =>
            dispatch({ type: ActionType.CLEAR_RESPONSE }, setIsOpen(false))
          }
          url={"./images/error.svg"}
          description={error}
          title={"Error"}
          type={"Error"}
          open={isOpen}
        />
      )}
      <OuterBox>
        <div>
          <h3 className={classes.m15}>
            {StrConstant.PhoneNumberVerified.heading}
          </h3>
        </div>
        <div>
          <div className={classes.outerInner}>
            <p>{StrConstant.PhoneNumberVerified.description}</p>
            <div className={classes.btnBox}>
              <DarkGrayBtn
                name={"Return to Cart"}
                clickHandler={() => navigate('/cart-detail')}
              />
              <YellowBtn name={"Submit Order"} clickHandler={ButtonClick} />
              {/* <GrayBtn name={"Review order"} /> */}
            </div>
          </div>
        </div>
      </OuterBox>
    </>
  );
};

export default withStyles(Styles)(PhoneNumberVerified);
