import React, { useEffect, useRef, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import StrConstant from "../../../Common/StrConstant";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import {
  userResendOtpAction,
  userVerifyOtp,
} from "../../../redux/actions/UserActions";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import AlterMessage from "../../../Components/AlertMessage/AlertMessage";
import { ActionType } from "../../../redux/ActionTypes";
import { CountdownTimer } from "../../../Views/CountdownTimer";
const VerifyOtp = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  let NOW = useRef(0);
  // const TWO_MIN_IN_MS = 120000;
  // const TimeAfterTowMins = NOW_IN_MS + TWO_MIN_IN_MS;
  const [disable, setDisable] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const numberToVerify = localStorage.getItem("numberToVerify");
  const { updateUser } = useSelector((state) => state?.updateProfile);
  const { error, verifyOtp } = useSelector((state) => state?.verifyOtp);
  useEffect(() => {
    if (error) return;
    setIsUpdateUser(true);
  }, []);
  useEffect(() => {
    if (verifyOtp?.success) {
      localStorage.removeItem("numberToVerify");
      navigate(-1);
    }
  }, [verifyOtp?.success]);

  const resendOtpHandle = () => {
    dispatch(
      userResendOtpAction(),
      (NOW.current = new Date().getTime() + 120000)
    );
    setDisable(true);
    setTimeout(() => setDisable(false), 120000);
  };
  return (
    <>
      {updateUser?.success && (
        <AlterMessage
          closeHandler={() =>
            dispatch(
              { type: ActionType.CLEAR_RESPONSE },
              setIsUpdateUser(false)
            )
          }
          description={updateUser?.message}
          title="Success"
          type="Success"
          open={isUpdateUser}
          url="./images/success.svg"
        />
      )}
      {error && (
        <AlterMessage
          closeHandler={() =>
            dispatch({ type: "CLEAR_OTP" }, setIsVerified(false))
          }
          description={error}
          title="Error"
          type="Error"
          open={isVerified}
          url="./images/error.svg"
        />
      )}
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <h3>{StrConstant.VerifyOtp.heading}</h3>
        </div>
        <div>
          <div className={classes.dFlex}>
            <h3>VERIFY YOUR PHONE NUMBER</h3>
            <p>{`${StrConstant.VerifyOtp.description} ${numberToVerify}`}.</p>
            <p>
              {StrConstant.VerifyOtp.secondDescription}
              <Link to="/profile">Edit your number</Link>
            </p>
          </div>
          <div className={classes.otpBox}>
            <OtpInput
              value={otp}
              numInputs={4}
              isInputNum={true}
              onChange={(otpVal) => setOtp(otpVal)}
              separator={<span className={classes.space}></span>}
            />
          </div>
          <div className={classes.btnBox}>
            <YellowBtn
              name={"Submit code"}
              clickHandler={() =>
                dispatch(userVerifyOtp(otp), setIsVerified(true))
              }
            />
          </div>
          {disable ? (
            <CountdownTimer targetDate={NOW.current} />
          ) : (
            <div className={classes.btTxt}>
              <p>Didn’t receive code? </p>
              <Link to="" onClick={resendOtpHandle}>
                Request again
              </Link>
            </div>
          )}
        </div>
      </OuterBox>
    </>
  );
};
export default withStyles(Styles)(VerifyOtp);
