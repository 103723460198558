import serverURL from "../../../APIHandler/serverURL";
import { ActionType } from "../../ActionTypes";
import callAPI from "../../../APIHandler/callAPI";
import { reloadHandler } from "../../../Common/utils/utils";
import { saveData } from "../UserActions";

export const getOutletList = (type) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GET_OUTLETS_LISTING_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.getRequest(
      serverURL.outletsList.concat(`?mode=${type}`),
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.GET_OUTLETS_LISTING_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.GET_OUTLETS_LISTING_FAILURE,
      payload: message,
    });
  }
};

export const getOutletCategory =
  (outletId, pageUrl) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_OUTLET_CATEGORY_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        pageUrl === "/menu"
          ? serverURL.getOutletCategory.concat("0")
          : serverURL.getCategories.concat(outletId),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );

      dispatch({ type: ActionType.GET_OUTLET_CATEGORY_SUCCESS, payload: data });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.GET_OUTLET_CATEGORY_FAILURE,
        payload: message,
      });
    }
  };

export const getOutlet =
  (selectedOutlet = false) =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_OUTLET_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        serverURL.getOutlet.concat(selectedOutlet ? `/${selectedOutlet}` : ""),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
     
      dispatch({ type: "CLEAR_OUTLET" });
      dispatch({ type: ActionType.GET_OUTLET_SUCCESS, payload: data });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.GET_OUTLET_FAILURE,
        payload: message,
      });
    }
  };



  export const getOutletid =
  (outletId) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_OUTLET_ID_CATEGORY_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        serverURL.getOutletid.concat(outletId),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      localStorage.setItem("selected_outlet_id", data?.data?.outlet_id) 
      localStorage.setItem("selected_outlet_name", data?.data?.outlet_name) 
      dispatch({ type: ActionType.GET_OUTLET_ID_CATEGORY_SUCCESS, payload: data });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.GET_OUTLET_ID_CATEGORY_FAILURE,
        payload: message,
      });
    }
  };


 