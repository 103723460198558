export const Styles = (Theme) => ({
  accordionOuter: {
    "&::after": {
      content: "''",
      display: "none"
    }
  },
  accordionBox: {
    padding: "20px",
  },
  cardInner: {
    background: "#FFFFFF",
    borderRadius: "15px !important",
    boxShadow: "none !important",
    position: "relative !important",
    overflow: "hidden",
    padding: "20px",
    cursor: "pointer",
    "& > div": {
      padding: "0",
      transition: ".5s ease",
      display: "block !important",
    },

  },
  cardInnerWithHover: {
    background: "#FFFFFF",
    borderRadius: "15px !important",
    boxShadow: "none !important",
    position: "relative !important",
    overflow: "hidden",
    padding: "20px",
    "& > div": {
      padding: "0",
      transition: ".5s ease",
      display: "block !important",
    },
    "&:hover": {
      background: "#F1EEDB !important",
      transition: ".5s ease",
    },
  },
  cardInnerActive: {
    background: "#F1EEDB !important",
    borderRadius: "20px !important",
    padding: "20px !important",
    cursor: "pointer",
    "@media(max-width:768px)": {
      padding: "0",
    },
  },
  boxContent: {
    display: "flex !important",
  },
  descriptionBox: {
    padding: "20px 0 0 0",
    minHeight:"120px",
    borderTop: "1px solid rgba(63, 68, 68, 0.15)",
    "& p": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      lineClamp: "4",
      boxOrient: "vertical",
    }
  },
  bookmark: {
    padding: "15px",
    fontSize: "18px",
    lineHeight: "18px",
    fontWeight: "400",
    fontFamily: "'Bebas Neue', serif",
    textDecoration: "none",
    background: "#FFC200",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "32px 0px 0px 32px",
    position: "absolute",
    right: "0",
    top: "15px",
    width: "40%",
    // maxWidth: "120px",
    "&:hover": {
      color: "#3F4444 !important",
    },
    "@media(max-width:768px)": {
      top: "-5px",
      right: "-20px",
      color: "#333539 !important",
    },
  },
  dFlex: {
    display: "flex",
    width: "auto",
    padding: "0 0 15px",
    justifyContent: "space-between",
    "& h4": {
      fontSize: "26px",
      lineHeight: "26px",
      padding: "0 2px",
      margin: "0",
      textAlign: "center",
    },
    "& span": {
      fontSize: "18px",
      lineHeight: "18px",
      fontFamily: "'Bebas Neue', serif",
    },
    "& p": {
      fontSize: "14px",
      lineHeight: "18px",
      margin: "0",
    },
  },
  topFirst: {
    marginBottom: "15px",
    width: "100%",
    minHeight: "40px",
    "& h3": {
      margin: "0",
      textAlign: "left",
      fontSize: "26px",
      lineHeight: "26px",
      "@media(max-width:768px)": {
        fontSize: "20px",
        lineHeight: "20px",
      },
    },
  },
  cardHeadline: {
    width: "70%",
    paddingRight: "10px",
  },
  ltHeadingTxt: {
    display: "flex",
    alignItems: "baseline",
    width: "50%",
  },
  rtHeadingTxt: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "end",
    position: "relative !important",
    width: "50%",
    padding: "0 10px",
    "& span": {
      "@media(max-width:768px)": {
        paddingRight: "20px",
      },
    },
  },
  profile: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "8px",
  },
  imgContent: {
    height: "48px",
    width: "48px",
    objectFit: "cover",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  txtContent: {
    paddingLeft: "10px",
    "& h5": {
      margin: "0 0 5px",
    },
    "& span": {
      fontSize: "12px",
      lineHeight: "12px",
      fontFamily: "'Noto Serif', serif",
      fontWeight: "400",
      color: "#3F4444",
    },
  },
  listBox: {
    "& li": {
      display: "flex !important",
      width: "100% !important",
      padding: "0 !important",
      paddingBottom: "10px !important",
      "& p": {
        margin: "0",
        fontSize: "14px",
        lineHeight: "18px",
        fontFamily: "'Noto Serif', serif",
      },
      "& > div": {
        width: "50%",
        "&:nth-child(2)": {
          paddingLeft: "10px",
          textAlign: "right",
        },
      },
    },
  },
  bottomBox: {
    "& small": {
      fontSize: "14px",
      lineHeight: "16px",
      fontFamily: "'Noto Serif', serif",
      color: "#3F4444",
    },
    "& p": {
      marginBottom: "0",
    },
  },
  accordionMob: {
    padding: "0px !important",
    "& > div": {
      flexDirection: "column",
      margin: "0 !important",
      "&::after": {
        content: "''",
        bottom: "18px",
      }
    }
  },
  noDescription:{
    padding: "0px !important",
    "& > div": {
      flexDirection: "column",
      margin: "0 !important",
      "&::after": {
        content: "''",
        display: 'none'
      },
      "& span":{
        padding:"0",
      }
    },
  }
});
