import React from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Button from "@mui/material/Button";
const YellowBtn = (props) => {
  const { classes, name, clickHandler, disable } = props;
  return (
    <div className={classes.customBtn}>
      <Button onClick={clickHandler} disabled={disable}>
        {name}
      </Button>
    </div>
  );
};
export default withStyles(Styles)(YellowBtn);
