export const Styles = Theme => ({
    m15: {
        "@media(max-width:991px)": {
            marginTop: "15px",
        }
    },
    outerInner: {
        "@media(max-width:640px)": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 150px)",
        }
    },
    btnBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "@media(max-width:640px)": {
            // display: "block",
            display:"flex",
            flexDirection:"column-reverse !important"
        },
        "& :nth-child(2)": {
            marginLeft: "10px",
            "@media(max-width:640px)": {
                margin: "auto",
                marginBottom: "0",
                maxWidth: '100%',
            },
            "& button": {
                "@media(max-width:640px)": {
                    marginBottom: "15px",
                }
            }
        },
        "& > div": {
            "@media(max-width:640px)": {
                float: "unset",
                margin: "auto",
            }
        },
    },

})