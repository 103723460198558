export const Styles = Theme => ({
    qtyBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& Button": {
            fontSize: "18px",
            lineHeight: "18px",
            color: "#3F4444BF",
            fontWeight: "400",
            background: "#fff",
        },

    },

    minesBtn: {
        "&:hover": {
            background: "#F1EEDB !important",
        },
        "&:active": {
            background: "##F1EEDB !important",
        }
    },

    plusBtn: {
        "&:hover": {
            background: "#F1EEDB !important",
        },
        "&:active": {
            background: "##F1EEDB !important",
        }
    },


    countNumber: {
        margin: "0 10px",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#3F4444BF",
        fontWeight: "400",
    }
})