export const Styles = (Theme) => ({
  pl: {
    paddingLeft: "25px",
  },
  p16: {
    "@media(max-width:640px)": {
      padding: "0 16px",
    },
  },
  p0: {
    "@media(max-width:640px)": {
      padding: "0 !important",
    },
  },
  m0: {
    marginBottom: "0 !important",
  },
  fontWeight700: {
    fontWeight: "700",
    color: "#3F4444",
    fontSize: "16px",
    lineHeight: "21px",
  },
  outer: {
    margin: "auto",
    display: "table",
    width: "100%",
    paddingBottom: "30px !important",
  },
  topBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  headingTop: {
    width: "50%",
    paddingLeft: "0px",
    display: "flex",
    alignItems: "center",
    marginRight: "15px",
    "& h4": {
      marginBottom: "0",
    },
  },
  number: {
    minWidth: 25,
    paddingRight: "5px",
  },
  lt: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    marginRight: "15px",
    "& h3": {
      marginBottom: "0",
    },
    "& h4": {
      marginBottom: "0",
    },
    "& p": {
      marginBottom: "0",
      // lineHeight: "normal",
    },
  },
  rt: {
    width: "50%",
    "& p": {
      marginBottom: "0",
      textAlign: "right",
    },
  },

  ltBox: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    marginRight: "5px",
    "& h3": {
      marginBottom: "0",
    },
  },
  rtBox: {
    width: "30%",
    "& p": {
      marginBottom: "0",
      textAlign: "right",
    },
  },
  imgBox: {
    maxHeight: "90px",
    maxWidth: "90px",
    height: "90px",
    width: "90px",
    borderRadius: "100%",
    background: "#FFC200",
    padding: "10px",
    objectFit: "cover",
    marginRight: "15px",
    position: "relative",
    "@media(max-width:640px)": {
      height: "60px",
      width: "60px",
      maxHeight: "60px",
      maxWidth: "60px",
    },
    "& img": {
      height: "100%",
      width: "100%",
      position: "absolute",
      left: "50%",
      top: "50%",
      objectFit: "contain",
      maxWidth: "65px",
      maxHeight: "65px",
      transform: "translate(-50%, -50%)",
      "@media(max-width:640px)": {
        maxHeight: "40px",
        maxWidth: "40px",
      },
    },
  },
  innerBox: {
    display: "flex",
    alignItems: "flex-start",
    margin: "20px 0",
    "@media(max-width:640px)": {
      display: "block",
      margin: "25px 0",
    },
  },
  headingBox: {
    width: "30%",
    paddingRight: "15px",
    "@media(max-width:640px)": {
      width: "100%",
      paddingRight: "0",
    },
    "& h4": {
      marginBottom: "0",
      width: "auto",
    },
  },
  boxContainer: {
    width: "70%",
    "@media(max-width:640px)": {
      width: "100%",
      marginTop: "15px",
    },
    "& ul": {
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
      margin: "0 0",
    },
    "& li": {
      padding: "0 20px 0 0",
      width: "50%",
      display: "inline-block",
    },
  },
  toggleBtnContent: {
    "& > div": {
      display: "flex",
      flexWrap: "wrap",
      "& button": {
        width: "calc((100% - 40px)/ 3)",
        "@media(max-width:991px)": {
          width: " calc((100% - 20px)/ 2)",
        },
        "&:nth-child(3n+3)": {
          marginRight: "0",
          "@media(max-width:991px)": {
            marginRight: "20px",
          },
        },
        "&:nth-child(even)": {
          "@media(max-width:991px)": {
            marginRight: "0",
          },
        },
      },
    },
    "& p": {
      marginBottom: "0",
      textTransform: "none",
    },
    "& h5": {
      marginBottom: "5px",
    },
  },
  cardBox: {
    width: "100%",
    height: "auto",
    padding: "15px",
    borderRadius: "10px",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& h5": {
      marginBottom: "0",
    },
    "& p": {
      marginBottom: "0",
    },
  },
  active: {
    background: "#FFC200",
  },
  quantityBox: {
    maxWidth: "200px",
  },
  textAreaBox: {
    marginBottom: "25px",
    "& textarea": {
      width: "100%",
      padding: "15px",
      borderRadius: "10px",
      background: "#fff",
      border: "none",
      minHeight:"60px",
    },
  },
  dFlex: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  noWrap: {
    display: "flex",
    width: "30%",
    alignItems: "flex-start",
    "@media(max-width:640px)": {
      width: "100%",
    },
    "& h4": {
      width: "auto",
    },
  },
  listingOuter: {
    background: "#F1EEDB",
    padding: "20px",
    margin: "25px 0 0",
    borderRadius: "10px",
    "@media(max-width:640px)": {
      margin: "25px 0 20px",
      borderRadius: "0",
    },
  },
  listingBox: {
    paddingLeft: "0px",
    "& li": {
      paddingLeft: "15px",
      color: "#333539",
      margin: "0 0 15px 0",
      fontSize: "26px",
      fontWeight: "400",
      lineheight: "26px",
      "& h4": {
        marginBottom: "5px",
      },
      "& p": {
        marginBottom: "5px",
      },
    },
  },
  listNumber: {
    marginRight: "20px",
  },
  btContent: {
    marginTop: "10px",
  },
  popoverContent: {
    "& span": {
      whiteSpace: "inherit !important",
    },
    "& br": {
      display: "none",
    },
    "& p": {
      marginBottom: "0",
      "& :nth-child(1)": {
        color: "#3F4444 !important",
        fontWeight: "700 !important",
      },
      "& :nth-child(3)": {
        color: "#3F4444 !important",
        fontWeight: "700 !important",
      },
    },
  },
});
