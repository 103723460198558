import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOutlet } from "../redux/actions/outlet/OutletActions";
import moment from "moment";
export const TimeSlot = (props) => {
  const { onSelection, onPrefill="" , onDate = null ,setonPrefill} = props;
  const [selectedSlot, setSelectedSlot] = useState(onPrefill);
  useEffect(() => {
    onSelection(selectedSlot);
  }, [selectedSlot]);

  // useEffect(() => {
  //   setSelectedSlot(onPrefill);
  // }, [onPrefill]);

  /* Start Time slot rendering  */
const url= window.location.href

  const currentDay = useRef(new Date());
  const dispatch = useDispatch();
  const [timeSlot, setTimeSlot] = useState([]);
  const { outlet } = useSelector((state) => state?.getOutlet);
  const outletId = localStorage.getItem("outletId"); //edit order
  const selected_outlet_id = localStorage.getItem("selected_outlet_id");
  // console.log("prefill",onPrefill,timeSlot)
  useMemo(() => {
    dispatch(getOutlet(outletId ? outletId : selected_outlet_id));
  }, []);

  useEffect(() => {
    setTimeSlotData();
  }, [outlet?.data?.outlet_id, onDate]);
 
  const setTimeSlotData = async () => {
    typeof onDate === "object"
      ? (currentDay.current = new Date())
      : (currentDay.current = moment(onDate, "YYYY-MM-DD hh:mm a")?._d);

    let OBSDate = "";
    let OBEDate = "";
    let OutletBreakStartDate = "";
    let OutletBreakEndDate = "";

    // console.log("MJ ", onDate);
    const currentSchedule = outlet?.data?.schedule?.find(
      (day, index) => index === currentDay.current?.getDay()
    );
    // console.log("MJ ", currentSchedule);

    if (currentSchedule?.open != "") {
      const curDate =
        currentDay.current?.getFullYear() +
        "-" +
        (currentDay.current?.getMonth() + 1) +
        "-" +
        currentDay.current?.getDate();

      const OSDate = curDate + " " + currentSchedule?.open;
      const OutletStartDate = moment(OSDate, "YYYY-MM-DD hh:mm a");

      if (
        currentSchedule?.break_start != "" &&
        currentSchedule?.break_end != ""
      ) {
        OBSDate = curDate + " " + currentSchedule?.break_start;
        OutletBreakStartDate = moment(OBSDate, "YYYY-MM-DD hh:mm a");

        OBEDate = curDate + " " + currentSchedule?.break_end;
        OutletBreakEndDate = moment(OBEDate, "YYYY-MM-DD hh:mm a");
      } else {
        OBSDate = curDate + " " + currentSchedule?.open;
        OutletBreakStartDate = moment(OBSDate, "YYYY-MM-DD hh:mm a");

        OBEDate = curDate + " " + currentSchedule?.open;
        OutletBreakEndDate = moment(OBEDate, "YYYY-MM-DD hh:mm a");
      }

      const OEDate = curDate + " " + currentSchedule?.close;
      const OutletEndDate = moment(OEDate, "YYYY-MM-DD hh:mm a");

      const CDate = moment().add(15, "minutes");
      const isToday = localStorage.getItem("isToday");
      // console.log("MJ isToday", isToday);
      let firstSlot = [];
      let secondSlot = [];
      if (isToday === "true") {
        // console.log("isToday*", isToday);

        if (OutletBreakStartDate.diff(CDate, "min") > 0) {
          if (OutletStartDate.diff(CDate, "min") > 0) {
            firstSlot = await intervalHandler(
              OutletStartDate,
              OutletBreakStartDate
            ); //start to break
          } else {
            firstSlot = await intervalHandler(CDate, OutletBreakStartDate); //start to break
          }
        }
        if (OutletEndDate.diff(CDate, "min") > 0) {
          if (OutletBreakEndDate.diff(CDate, "min") > 0) {
            secondSlot = await intervalHandler(
              OutletBreakEndDate,
              OutletEndDate
            ); //start to break
          } else {
            secondSlot = await intervalHandler(CDate, OutletEndDate); //start to break
          }
        }
      } else {
        firstSlot = await intervalHandler(
          OutletStartDate.format("YYYY-MM-DD hh:mm a"),
          OutletBreakStartDate.format("YYYY-MM-DD hh:mm a")
        ); //start to break
        secondSlot = await intervalHandler(
          OutletBreakEndDate.format("YYYY-MM-DD hh:mm a"),
          OutletEndDate.format("YYYY-MM-DD hh:mm a")
        ); //break to stop
      }

      let finalResult = [...firstSlot, ...secondSlot];
      
      setTimeSlot(finalResult);
      if (finalResult?.indexOf(onPrefill)==-1 && onPrefill!=="") {
      //   setSelectedSlot(selectedSlot);

      
      setonPrefill("")
      
      if(onPrefill==localStorage.getItem('timeslot')){
       
      setSelectedSlot(onPrefill);
      }
      }
    } else {
      setTimeSlot([]);
    
      setSelectedSlot("");
    }
  };

  useEffect(()=>{
    // console.log("onPrefill",onPrefill,"pr", selectedSlot,url,url?.indexOf('edit-order'))
    if(url?.indexOf("edit-order")>-1){
   if(onPrefill=="" && selectedSlot!==""){
    console.log("onPrefill",onPrefill,"pr", selectedSlot)
    setonPrefill(selectedSlot)
    setSelectedSlot(selectedSlot);
   
   }
  }
  },[onDate])
  const intervalHandler = async (startString, endString) => {
    // debugger
    let result = []; // timeSlot;
    const end = moment(endString, "YYYY-MM-DD hh:mm a");
    const start = moment(startString, "YYYY-MM-DD hh:mm a");
    end.subtract(15, "minutes");
    start.minutes(Math.ceil(start.minutes() / 15) * 15);
    const current = moment(start);
    while (current <= end) {
      let startTime = current.format("hh:mm A");
      current.add(15, "minutes");
      let endTime = current.format("hh:mm A");
      if (!result.includes(startTime + "-" + endTime)) {
        // ✅ only runs if value not in array
        result.push(startTime + "-" + endTime);
      }
    }
    return result;
    // setTimeSlot((v) => result);
  };

  useEffect(() => {
    // console.log("MJ timeSlot", timeSlot);
  }, [timeSlot]);
  /* End Time slot rendering  */
  return (
    <Select
      fullWidth
      displayEmpty
      name="timeSlot"
      value={selectedSlot}
      id="demo-simple-select-helper"
      labelId="demo-simple-select-helper-label"
      onChange={(event) => setSelectedSlot(event.target.value)}
      className="time-slot"
    >
      <MenuItem value={""} selected className="lightgray">
        Select Time Slot
      </MenuItem>
      {timeSlot?.map((element, index) => (
        <MenuItem key={index} value={element}>
          {element}
        </MenuItem>
      ))}
    </Select>
  );
};
