import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Stack from "@mui/material/Stack";
const CustomDatePicker = (props) => {
  // const minDate = new Date();
  const maxDate = new Date();
  const {
    classes,
    dateValue = "",
    maxFutureDate = "",
    allowedDays = null,
    onBinding,

    name = "",
    isPastDisable = false,
    isWeekDayDisable = false,
    allowAfter = 0,
    isFutureDisable = false,
    isBlank = false,
    editdata = null,
    selectmode=""
  } = props;
  console.log("allowafter", allowAfter,maxFutureDate)
  // minDate.setDate(minDate.getDate() + allowAfter);
  // console.log("editdata",editdata);
  const selected_modes=localStorage.getItem("selected_modes")
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");

  // const [defaultDate, setDefaultDate] = useState();
  const [maxFDate, setMaxFDate] = useState(new Date());
  const [isBlankDate, setIsBlankDate] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  // let defaultDate = new Date()
  // console.log("startmaxFutredate",maxFutureDate);
  useEffect(() => {
    if (value != "") {
      if (isBlankDate) {
        setIsBlankDate(false);
      }
      onBinding({ [name]: value });
    }
  }, [value]);

  useEffect(() => {
    if (dateValue != "") {
      setValue(new Date(dateValue));
    }
  }, [dateValue]);

  useEffect(() => {
    if (maxFDate.getDate() != new Date().getDate()) {

      const days = allowedDays;
      let affowdaysafter=""
      const newdate = new Date()
      let newdays = newdate.getDay() == 0 ? "Sunday" : newdate.getDay() == 1 ? "Monday" : newdate.getDay() == 2 ? "Tuesday" : newdate.getDay() == 3 ? "Wednesday" : newdate.getDay() == 4 ? "Thursday" : newdate.getDay() == 5 ? "Friday" : "Saturday"
      for(let i=0;i<days?.length;i++){
        if(days[i]?.indexOf(newdays)>-1){
         
          affowdaysafter="np"

        }
      }

    
      const date = maxFutureDate != "" ? new Date(maxFutureDate) : new Date();
      const workingDate = validateWorkingDays(date,  affowdaysafter==""?allowAfter-1: allowAfter);
      setMinDate(workingDate)
      const setdate=validateWorkingDays(new Date(editdata?.order_date),0)
      console.log("workingDate --- ",setdate,"hh", workingDate,days,affowdaysafter);
if(editdata!=null){


      setValue(setdate);
}else{
  setValue(workingDate)
}
    }
  }, [maxFDate]);

  useEffect(() => {
    if (isBlank) {
      setIsBlankDate(true);
    } else {
    }
  }, [isBlank]);

  useEffect(() => {
    if (maxFutureDate != "") {
      // console.log("workingDate -- ", maxFutureDate);
      const data = addMonthWRONG(new Date(maxFutureDate), 3);
      setMaxFDate(data);
      // if (value != "") {
      //   const workingDate = validateWorkingDays(new Date(maxFutureDate), allowAfter);
      //   setMinDate(workingDate)
      //   // console.log("workingDate --- ", workingDate);
      //   setValue(workingDate);
      // }

    }
  }, [maxFutureDate]);

  useEffect(() => {
    if (editdata!=null) {
      const createddate = new Date(editdata?.created_at);

      const days = allowedDays;
      // console.log("days",days)
      if (days != null) {

        const allowAfter = editdata?.order_mode == "Pick-Up" ? 1 : 4
        // console.log("allowafter",allowAfter)
        const workingDate = validateWorkingDays(createddate, allowAfter);
        const setdate=validateWorkingDays(new Date(editdata?.order_date),0)
        setValue(setdate)
        setMinDate(workingDate)
  
      
        console.log("workingDateddd",setdate,editdata,workingDate,allowAfter,editdata?.order_mode)
      }
    }
  }, [editdata]);
  useEffect(() => {
    if (allowAfter != 0) {
      const date = maxFutureDate != "" ? new Date(maxFutureDate) : new Date();
      console.log("workingDate date", date);
      const days = allowedDays;
      let affowdaysafter=""
      const newdate = new Date()
      let newdays = newdate.getDay() == 0 ? "Sunday" : newdate.getDay() == 1 ? "Monday" : newdate.getDay() == 2 ? "Tuesday" : newdate.getDay() == 3 ? "Wednesday" : newdate.getDay() == 4 ? "Thursday" : newdate.getDay() == 5 ? "Friday" : "Saturday"
      for(let i=0;i<days?.length;i++){
        if(days[i]?.indexOf(newdays)>-1){
         
          affowdaysafter="np"

        }
      }
     
      if (days != null) {
        console.log("workingDate allowAfter", affowdaysafter==""?allowAfter-1: allowAfter);

        const workingDate = validateWorkingDays(date,affowdaysafter==""?allowAfter-1: allowAfter);
        setMinDate(workingDate)
        // console.log("workingDate --- ", workingDate);
        setValue(workingDate);
      } else {
        const processDate = addDaysWRONG(date, allowAfter);
        const day = processDate.getDay();
        // setValue(new Date(processDate));
        // if (day === 0 || day === 6) {
        //   const setTempDate = addDaysWRONG(processDate, 2);
        //   setValue(new Date(setTempDate));
        // } else {
        setValue(new Date(processDate));
        // }
      }
    }
  }, [allowAfter]);

  const validateWorkingDays = (processDate, afterDay) => {
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let setDate = "";
    let countWorkingDay = 0;
    const days = allowedDays;
    for (let i = 0; i < 5; i++) {
      const preAddedDate = (i * 7);
      // console.log("workingDate preAddedDate", preAddedDate);
      weekdays.map((data, index) => {
        const setTempDate = addDaysWRONG(processDate, preAddedDate + index); //new DateObject().add(1 + index, "days");
        // console.log("workingDate setTempDate", setTempDate);
        const day = weekdays[setTempDate.getDay()];
        if (days?.indexOf(day) > -1) {
          if (countWorkingDay < afterDay) {
            countWorkingDay++
          } else {
            if (setDate === "") {
              setDate = setTempDate;

            }
          }
        }
      });
      if (setDate != "") {
        break;
      }

    }

    return setDate;
  }

  const addDaysWRONG = (date, days) => {
    const result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
  };

  const addMonthWRONG = (date, days) => {
    const result = new Date(date);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    result.setDate(day);
    result.setFullYear(date.getFullYear());
    if (month + days > 12) {
      result.setMonth(month + days - 12);
      result.setFullYear(year + 1);
    } else {
      result.setMonth(month + days);
      // result.setFullYear(year);
    }

    return result;
  };

  const dayNameHandler = () => {
    const date = new Date();
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = weekdays[date.getDay()];
    return today;
  };

  const disableWeekends = (date) => {
    let allowStatus = false;
    const days = allowedDays;
    if (days != null) {
      const weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const days = allowedDays;
      let isWeekend = [];
      weekdays.map((data, index) => {
        if (days != null && days?.indexOf(data) < 0) {
          isWeekend.push(index);
        }
      });
      if (isWeekend?.indexOf(date.getDay()) >= 0) {
        allowStatus = true;
      }
    } else {
      if (date.getDay() === 0 || date.getDay() === 6) {
        allowStatus = true;
      }
    }
    return allowStatus;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        {isFutureDisable && isPastDisable ? (
          <MobileDatePicker
            name={name}
            open={isOpen}
            okText={"Apply"}
            showToolbar={false}
            value={value}
            inputFormat={"dd MMM yyyy"}
            onClose={() => setIsOpen(false)}
            shouldDisableDate={isWeekDayDisable ? disableWeekends : null}
            onChange={(newValue) => setValue(newValue)}
            minDate={minDate}
            maxDate={maxFDate}
            // minDate={moment('28/09/2022').toDate()}
            // maxDate={maxDate ? maxDate : null}
            renderInput={(params) => (
              <div className={classes.datepickerDesign}>
                <TextField onClick={() => setIsOpen(true)} {...params} />{" "}
                <img
                  onClick={() => setIsOpen(true)}
                  src="images/calendar.svg"
                  alt="Better Coffee"
                // {...params}
                />
              </div>
            )}
          />
        ) : isPastDisable ? (
          <MobileDatePicker
            name={name}
            open={isOpen}
            okText={"Apply"}
            showToolbar={false}
            value={value}
            inputFormat={"dd MMM yyyy"}
            onClose={() => setIsOpen(false)}
            shouldDisableDate={isWeekDayDisable ? disableWeekends : null}
            onChange={(newValue) => {
              // console.log("newValue", newValue);
              setValue(newValue);
            }}
            minDate={minDate}
            // maxDate={isFutureDisable ? maxDate : null}
            // minDate={moment('28/09/2022').toDate()}
            // maxDate={maxDate ? maxDate : null}
            renderInput={(params) => (
              <div className={classes.datepickerDesign}>
                <TextField onClick={() => setIsOpen(true)} {...params} />{" "}
                <img
                  onClick={() => setIsOpen(true)}
                  src="images/calendar.svg"
                  alt="Better Coffee"
                // {...params}
                />
              </div>
            )}
          />
        ) : isFutureDisable ? (
          <MobileDatePicker
            name={name}
            open={isOpen}
            okText={"Apply"}
            showToolbar={false}
            value={value}
            inputFormat={"dd MMM yyyy"}
            onClose={() => setIsOpen(false)}
            shouldDisableDate={isWeekDayDisable ? disableWeekends : null}
            onChange={(newValue) => setValue(newValue)}
            // minDate={isPastDisable ? minDate : ""}
            maxDate={maxDate}
            // minDate={moment('28/09/2022').toDate()}
            // maxDate={maxDate ? maxDate : null}
            renderInput={(params) => (
              <div className={classes.datepickerDesign}>
                <TextField onClick={() => setIsOpen(true)} {...params} />{" "}
                <img
                  onClick={() => setIsOpen(true)}
                  src="images/calendar.svg"
                  alt="Better Coffee"
                // {...params}
                />
              </div>
            )}
          />
        ) : (
          <MobileDatePicker
            name={name}
            open={isOpen}
            okText={"Apply"}
            showToolbar={false}
            value={value}
            inputFormat={"dd MMM yyyy"}
            onClose={() => setIsOpen(false)}
            shouldDisableDate={isWeekDayDisable ? disableWeekends : null}
            onChange={(newValue) => setValue(newValue)}
            // minDate={isPastDisable ? minDate : ""}
            // maxDate={isFutureDisable ? maxDate : null}
            // minDate={moment('28/09/2022').toDate()}
            // maxDate={maxDate ? maxDate : null}
            renderInput={(params) => (
              <div className={classes.datepickerDesign}>
                <TextField onClick={() => setIsOpen(true)} {...params} />{" "}
                <img
                  onClick={() => setIsOpen(true)}
                  src="images/calendar.svg"
                  alt="Better Coffee"
                // {...params}
                />
              </div>
            )}
          />
        )}
        {/* <MobileDatePicker
          name={name}
          open={isOpen}
          okText={"Apply"}
          showToolbar={false}
          value={value}
          onClose={() => setIsOpen(false)}
          shouldDisableDate={isWeekDayDisable ? disableWeekends : null}
          onChange={(newValue) => setValue(newValue)}
          minDate={isPastDisable ? minDate : ""}
          // maxDate={isFutureDisable ? maxDate : null}
          // minDate={moment('28/09/2022').toDate()}
          // maxDate={maxDate ? maxDate : null}
          renderInput={(params) => (
            <div className={classes.datepickerDesign}>
              <TextField onClick={() => setIsOpen(true)}  {...params} />{" "}
              <img onClick={() => setIsOpen(true)} src="images/calendar.svg" alt="Better Coffee" {...params} />
            </div>
          )}
        /> */}
      </Stack>
    </LocalizationProvider>
  );
};
export default withStyles(Styles)(CustomDatePicker);
