import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import { useLocation, useNavigate } from "react-router";
import StrConstant from "../../../Common/StrConstant";
import AddPaymentForm from "../../../Components/AddPaymentForm/AddPaymentForm";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch } from "react-redux";
import { ActionType } from "../../../redux/ActionTypes";
import { useEffect, useState } from "react";
import Constant from "../../../Common/Constant";

const stripePromise = loadStripe(Constant.stripeKey);

const AddPayment = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const location = useLocation();
  // const [lastPage, setLastPage] = useState(-1);
  // useEffect(() => {
  //   if (location?.state !== null) {
  //     if (location?.state?.lastPage != undefined) {
  //       setLastPage(location?.state?.lastPage);
  //     }
  //   }
  // }, [location]);
  return (
    <>
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch(
                { type: ActionType.CLEAR_RESPONSE },
                // navigate(lastPage, {
                //   replace: true,
                //   state: {
                //     lastPage: lastPage,
                //   },
                // })
                navigate(-1)
              )
            }
          />
          <h3>{StrConstant.AddCard.heading}</h3>
        </div>
        <div>
          <div className={classes.description}></div>
          <Elements stripe={stripePromise}>
            <AddPaymentForm />
          </Elements>
        </div>
      </OuterBox>
    </>
  );
};

export default withStyles(Styles)(AddPayment);
