import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
const GroupToggleBtn = (props) => {
  const { classes, children } = props;
  return (
    <>
      <ToggleButtonGroup
        // exclusive
        // value={binding}
        // aria-label={binding}
        // onChange={toggleHandler}
        className={classes.toogleBtnBox}
      >
        {children}
      </ToggleButtonGroup>

    </>

  );
};

export default withStyles(Styles)(GroupToggleBtn);
