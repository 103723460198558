export const Styles = Theme => ({
    walletContainer: {
        "& > div": {
            "@media(max-width:640px)": {
                marginBottom: "20px",
            },
        },
        "& > div > div": {
            borderBottom: "1px solid #ddd",
            padding: "15px 20px !important",
            marginBottom:"15px",
            borderRadius: "10px !important",
            "@media(max-width:640px)": {
                borderBottom: "none",
                marginBottom:"0",
            },
        },
    },
    card: {
        width: "100%",
        padding: "0",
        "@media(max-width:640px)": {
            width: "100%",
            paddingRight: "0",
        },
    },
    innerBox: {
        background: "#FFFFFF",
        borderRadius: "10px",
        padding: "15px 20px",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        textDecoration: "none",
        width: "100%",
        marginBottom:"15px",
        "@media(max-width:640px)": {
            borderBottom: "0",
            borderRadius: "10px",
            marginBottom: "20px",
        }
    },
    border0: {
        borderBottom: "none !important",
        marginBottom: "0 !important",
    },
    ltBox: {
        width: "80%",
        "& h4": {
            marginBottom: "0",
        },
    },
    rtBox: {
        width: "20%",
        paddingLeft: "10px",
        textAlign: "right",
    },
    dFlex: {
        "@media(max-width:640px)": {
            display: "flex",
            "& :nth-child(2)": {
                marginRight: "20px",
            },

        },
    },
    dNone: {
        "@media(max-width:640px)": {
            display: "none"
        },
    },
    mobBorder0: {
        "@media(max-width:640px)": {
            border: "none",
        }
    },
    desktop: {
        "@media(max-width:640px)": {
            display: "none",
        }
    },
    mobile: {
        display: "none",
        "@media(max-width:640px)": {
            display: "block",
        },
        "& a":{
            textDecoration:"none",
        },
        "& h4":{
            marginBottom:"0",
        }
    },

})