export const Styles = Theme => ({
    fromControl: {
        margin: "20px 0 25px",
    },
    btnBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
        "@media(max-width:640px)": {
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
        },
        "& :nth-child(1)":{
            marginRight:"15px",
            "@media(max-width:640px)": {
                marginRight:"0px",
            }
        },
        "& > div": {
            "@media(max-width:640px)": {
                float: "unset",
                margin: "auto",
            }
        },
        "& a":{
            paddingRight:"10px",
        },
    },
    orderLink: {
        marginBottom: "20px",
    },
    ratingBox: {
        "& svg": {
            margin: "0 25px 0 0",
            fill: "#FFC200 !important",
        }
    },
    m0:{
        marginBottom:"0",
    },
    mb10:{
        marginBottom:"15px !important",
    }
})