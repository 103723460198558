export const Styles = Theme => ({
    customTooltip: {
        display: "flex",
        fontWeight: "700 !important",
        "& img": {
            marginLeft: "5px",
            width: "14px",
        },
    },
    tooltipText: {
        padding: "12px",
        maxWidth: "auto",
        fontSize: "14px !important",
        lineHeight: "16px !important",
        "@media (max-width: 768px)": {
            fontSize: "12px !important",
            lineHeight: "14px !important",
        },
        "&:after": {
            content: "''",
            position: "absolute",
            bottom: "-10px",
            left: "10%",
            marginLeft: "-5px",
            borderWidth: "5px",
            borderStyle: "solid",
            borderColor: "#fff transparent transparent transparent",
        },
        "& b": {
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: "700",
            fontFamily: "'Noto Serif', serif",
            color: "#3F4444",
            display: "block",
        },
        "& span": {
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            color: "#3F4444B2",
            display: "block",
            padding: "5px 0 0",
            display: "block",
        },
        "& ul": {
            margin: "0",
            paddingLeft: "20px",
        },
        "& li": {
            listStyle: "disc",
        }
    },
})