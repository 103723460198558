import { ActionType } from "../../ActionTypes";

export const getUserCartReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_USER_CART_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_USER_CART_SUCCESS:
      return { ...state, loading: false, userCart: action.payload };
    case ActionType.GET_USER_CART_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case "CLEAR_USER_CART":
      return [];
    default:
      return state;
  }
};

export const addProductToCartReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.ADD_PRODUCT_TO_CART_REQUEST:
      return { ...state, loading: true };
    case ActionType.ADD_PRODUCT_TO_CART_SUCCESS:
      return { ...state, loading: false, productCart: action.payload };
    case ActionType.ADD_PRODUCT_TO_CART_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
      case ActionType.CLEARADDPRODUCT:
        return {};
    default:
      return state;
  }
};

export const updatedProductToCartReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.UPDATED_PRODUCT_TO_CART_REQUEST:
      return { ...state, loading: true };
    case ActionType.UPDATED_PRODUCT_TO_CART_SUCCESS:
      return { ...state, loading: false, productCart: action.payload };
    case ActionType.UPDATED_PRODUCT_TO_CART_FAILURE:
      return { ...state, loading: false, error: action.payload };
      case ActionType.CLEAR_RESPONSE:
        return {};
      case ActionType.CLEARUPDATEDPRODUCT:
        return {};
    default:
      return state;
  }
};


export const removeProductFromCart = (state = {}, action) => {
  switch (action.type) {
    case ActionType.REMOVE_CART_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case ActionType.REMOVE_CART_PRODUCT_SUCCESS:
      return { ...state, loading: false, removeProduct: action.payload };
    case ActionType.REMOVE_CART_PRODUCT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    // case ActionType.CLEAR_RESPONSE:
    //   return {};
    default:
      return state;
  }
};

export const checkCouponReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.CHECK_COUPON_REQUEST:
      return { loading: true };
    case ActionType.CHECK_COUPON_SUCCESS:
      return { loading: false, success: true, status: action.payload };
    case ActionType.CHECK_COUPON_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_COUPON:
      return {};
    default:
      return state;
  }
};

export const applyCouponReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.APPLY_COUPON_REQUEST:
      return { loading: true };
    case ActionType.APPLY_COUPON_SUCCESS:
      return { loading: false, success: true, status: action.payload };
    case ActionType.APPLY_COUPON_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_COUPON:
      return {};
    default:
      return state;
  }
};

export const finalizeOrderCheckoutReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.FINALIZE_ORDER_CHECKOUT_REQUEST:
      return { ...state, loading: true };
    case ActionType.FINALIZE_ORDER_CHECKOUT_SUCCESS:
      return { ...state, loading: false, checkoutOrder: action.payload };
    case ActionType.FINALIZE_ORDER_CHECKOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.errMsg,
        status: action.payload.statusCode,
      };
    case ActionType.CLEAR_CHECKOUT:
      return {};
    default:
      return state;
  }
};

export const finalizeOrderVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.FINALIZE_ORDER_VERIFY_REQUEST:
      return { ...state, loading: true };
    case ActionType.FINALIZE_ORDER_VERIFY_SUCCESS:
      return { ...state, loading: false, verifyCart: action.payload };
    case ActionType.FINALIZE_ORDER_VERIFY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const futureScheduleReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_FUTURE_SCHEDULE_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_FUTURE_SCHEDULE_SUCCESS:
      return { ...state, loading: false, frequency: action.payload };
    case ActionType.GET_FUTURE_SCHEDULE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case "CLEAR_FUTURE_SCHEDULE":
      return {};
    default:
      return state;
  }
};

export const removeCouponReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.REMOVE_COUPON_REQUEST:
      return { loading: true };
    case ActionType.REMOVE_COUPON_SUCCESS:
      return { loading: false, success: true, status: action.payload };
    case ActionType.REMOVE_COUPON_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const validateCheckoutReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.VALIDATE_CHECKOUT_REQUEST:
      return { loading: true };
    case ActionType.VALIDATE_CHECKOUT_SUCCESS:
      return {
        loading: false,
        success: true,
        validate: action.payload,
      };
    case ActionType.VALIDATE_CHECKOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: action.payload.statusCode,


      };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const cartCounterReducer = (state = 0, action) => {
  switch (action.type) {
    case "CHANGE_CART":
      state = action?.count;
      return state;
    case "RESET_CART":
      state = 0;
      return state;
    default:
      return state;
  }
};

export const notificationCounterReducer = (state = 0, action) => {
  switch (action.type) {
    case "CHANGE_NOTIFICATION":
      state = action?.notification;
      return state;
    case "RESET_NOTIFICATION":
      state = 0;
      return state;
    default:
      return state;
  }
};

export const reOrdersReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.REORDER_CART_REQUEST:
      return { ...state, loading: true };
    case ActionType.REORDER_CART_SUCCESS:
      return { ...state, loading: false, reOrders: action.payload };
    case ActionType.REORDER_CART_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getcartdetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.CART_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ActionType.CART_DETAILS_SUCCESS:
      return { ...state, loading: false, reOrders: action.payload };
    case ActionType.CART_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
   case ActionType.CLEAR_CART_DETAILS:
   return {}
   
      default:
      return state;
  }
};

export const clearCartReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.CLEAR_CART_REQUEST:
      return { loading: true };
    case ActionType.CLEAR_CART_SUCCESS:
      return { loading: false, clearCart: action.payload };
    case ActionType.CLEAR_CART_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
