export const Styles = Theme => ({
    accordionContent: {
        width: "100%",
    },
    box: {
        padding: "20px",
        background: "#fff",
        width: "100%",
        "& > div": {
            boxShadow: "none !important",
            padding: "0",
            alignItems: "flex-start",
        },
        "& > div > div": {
            padding: "0 !important",
            margin: "0 !important",
        },
        "& h4": {
            marginBottom: "5px",
        },
        "& p": {
            marginBottom: "0",
        },
    },
    dateBox: {
        display: "flex",
        alignItems: "flex-start",
    },
    listing: {
        "& li": {
            display: "flex",
            alignItems: "flex-start",
        },
    },
    lt: {
        width: "50%",
        paddingRight: "10px",
    },
    rt: {
        width: "50%",
        "& p": {
            textAlign: "right",
        }
    },
})