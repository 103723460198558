export const Styles = (Theme) => ({
    datepickerDesign: {
        background: "#fff",
        borderRadius: "30px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        position: "relative",
        "&  div":{
            width:"100%",
        },
        "& input": {
            width: "auto",
            border: "none !important",
            textAlign: "left",
            width:"100%",
            padding:"15px 50px 15px 20px",
            color: localStorage.getItem("styleColordate")=="true"?"#3F4444":"#3F4444BF",
            fontSize: "16px !important",
            fontWeight: "400",
            lineHeight: "21px",
            fontFamily: '"Noto Serif", serif',
            "@media(max-width:375px)":{
                padding:"15px 30px 15px 20px",
            },
            "& :placeholder":{
                color: "#3F4444BF",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                fontFamily: '"Noto Serif", serif',
            }
        },
        "& p": {
            display: "none",
        },
        "& img": {
            height: "16px",
            position: "absolute",
            right: "25px",
            "@media(max-width:375px)":{
                height: "14px",
                right: "15px",  
            },
        },
    },
    endDateBox:{
        paddingRight:"50px",
    }
})