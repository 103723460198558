import React from "react";
export default {
  baseUrl: process.env.REACT_APP_BASEURL,
  encKey: process.env.REACT_APP_ENCKEY,
  stripeKey: process.env.REACT_APP_STRIPKEY,
  menus: [
    {
      label: "",
      route: "/my-dashboard",
      icon: <img src="" alt="Better cloud" />,
    },
    {
      label: "",
      route: "/my-dashboard",
      icon: <img src="./images/employees.svg" alt="Better cloud" />,
    },
    {
      label: "",
      route: "",
      icon: <img src="./images/spend.svg" alt="Better cloud" />,
    },
    {
      label: "",
      route: "",
      icon: <img src="./images/ballot.svg" alt="Better cloud" />,
    },
    {
      label: "",
      route: "",
      icon: <img src="./images/credits.svg" alt="Better cloud" />,
    },
    {
      label: "Company profile",
      route: "/Companyprofile",
      icon: <img src="./images/ballot.svg" alt="Better cloud" />,
    },
    {
      label: "",
      route: "",
      icon: <img src="./images/excalamationmarksqu.svg" alt="Better cloud" />,
    },
  ],
};
