import { withStyles } from '@mui/styles';
import { Styles } from './Style';
import StrConstant from '../../Common/StrConstant';
import OrderStatus from '../../Components/OrderStatus/OrderStatus';
import { modeLinkHandler } from '../../Common/utils/utils';
const NoDataFound = (props) => {
    const { classes } = props
    const setDefaultMode = localStorage.getItem("selected_modes");

    return (
        <>
            <div className={classes.notFountOuter}>
                <div className={classes.notFountInner}>
                    <div className={classes.imgContent}>
                        <img src="images/no-data.svg" alt="Better Coffee" />
                    </div>
                    <h3>{StrConstant.NoDataFound.heading}</h3>
                    <p>{StrConstant.NoDataFound.description}</p>
                </div>
            </div>
        </>
    )
}
export default withStyles(Styles)(NoDataFound);