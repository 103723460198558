export const Styles = Theme => ({
    pl: {
        paddingLeft: "25px !important",
    },
    p0: {
        "@media(max-width:640px)": {
            padding: "0 !important",
        }
    },
    m0: {
        margin: "0 !important",
    },
    p20: {
        padding: "20px !important",
    },
    pY20: {
        padding: "0 20px !important",
    },
    p16: {
        "@media(max-width:640px)": {
            padding: "0 16px !important",
        }
    },
    outer: {
        margin: "auto",
        display: "table",
        width: "100%",
        paddingBottom: "25px !important",
    },
    topBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "15px",
    },
    innerBox: {
        display: "flex",
        alignItems: "flex-start",
        margin: "0 0 20px 0",
        "@media(max-width:768px)": {
            display: "block",
            margin: "0 0 25px 0",
        },
    },
    boxContainer: {
        width: "70%",
        "@media(max-width:768px)": {
            width: "100%"
        },
    },
    headingBox: {
        width: "30%",
        "@media(max-width:768px)": {
            width: "100%"
        }
    },
    addressBox: {
        padding: "15px",
        borderRadius: "10px",
        background: "#fff",
        display: "flex",
        width: "100%",
        // minHeight:"120px",
        "& h4": {
            marginBottom: "5px",
        },
        "& p": {
            marginBottom: "0",
        },
    },
    pickupName: {
        padding: "15px",
        borderRadius: "10px",
        background: "#fff",
        display: "flex",
        minHeight: "50px",
        width: "100%",
        "& p": {
            marginBottom: "0",
        }
    },
    imgBox: {
        height: "15px",
        width: "15px",
        "& a": {
            display: "inline-block",
        },
        "& img": {
            height: "100%",
            width: "100%",
        },
    },
    lt: {
        width: "50%",
        display: "flex",
        paddingRight: "15px",
        "& h4": {
            marginBottom: "0",
        }
    },
    rt: {
        width: "50%",
        "& div": {
            marginLeft: "auto",
            textAlign: "right",
            maxWidth: "15px",
            "& img": {
                height: "auto",
                width: "100%",
            },
        },
        "& p": {
            textAlign: "right",
        }
    },
    leftBox: {
        width: "80%",
        paddingRight: "10px",
    },
    rightBox: {
        width: "20%",
        "& > div": {
            float: "right",
        },
    },
    dFlex: {
        display: "flex",
        width: "100%",
    },
    alignEnd: {
        alignItems: "flex-end",
    },
    listingOuter: {
        background: "#F1EEDB",
        padding: "20px",
        margin: "20px 0",
        borderRadius: "10px",
        "@media(max-width:640px)": {
            borderRadius: "0",
            margin: "25px 0",
        },
    },
    listingBox: {
        marginBottom: "0",
        "& li": {
            color: "#333539",
            // margin: "0 0 15px 0",
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "18px",
            "& h4": {
                marginBottom: "5px",
            },
            "& p": {
                marginBottom: "0",
                textAlign: "right",
            },
        }
    },
    ltBox: {
        width: "30%",
        paddingRight: "15px",
        display: "flex",
        "@media(max-width:640px)": {
            width: "60%",
        }

    },
    rtBox: {
        width: "70%",
        textAlign: "right",
        "@media(max-width:640px)": {
            width: "40%",
        }

    },
    selectBox: {
        width: "100%",
        "&  div": {
            border: "none !important",
            color: "#3F4444BF !important",
            fontSize: "16px !important",
            fontWeight: "400",
            lineHeight: "21px",
            fontFamily: '"Noto Serif", serif',
        },
        "&:hover": {
            border: "none !important",
        },
    },
    yourOrder: {
        margin: "20px 0",
        "& > div": {
            boxShadow: "none",
        },
        "& div": {
            margin: "0 !important",
            padding: "0",
            background: "#F1EEDB",
            borderRadius: "10px",
            "@media(max-width:640px)": {
                borderRadius: "0 !important",
            },
        },
        "& h4": {
            margin: "0",
        },
        "& h6": {
            margin: "0",
        }
    },
    toggleBtnContent: {
        "& > div": {
            width: "100%",
            display: "flex",
            flexBasis: "auto",
            "& button": {
                width: "32%",
                "&:nth-child(3n+3)": {
                    marginRight: "0",
                },
                "& h5": {
                    marginBottom: "5px",
                },
                "& span": {
                    fontSize: "14px",
                    color: "#3F4444BF",
                }
            },

        },
    },
    toggleBox: {
        "& button": {
            "&:nth-child(3n+3)": {
                marginRight: "15px !important",
            },
            "&:nth-child(7n+7)": {
                marginRight: "0 !important",
            },
        },
    },
    modalFlex: {
        "& > div": {
            float: "unset",
            margin: "auto",
            maxWidth: "100%",
        }
    },
    modalBtn: {
        display: "flex",
        "& :nth-child(1)": {
            marginRight: "10px",
        }
    },
    dateContainer: {
        "@media(max-width:640px)": {
            display: "flex",
        }
    },
    startDate: {
        "@media(max-width:640px)": {
            width: "50%",
            marginRight: "10px",
        }
    },
    endDate: {
        "@media(max-width:640px)": {
            width: "50%",
        }
    },
    orderTable: {
        width: "100%",
        paddingBottom: "15px",
        "& thead": {
            "& > tr": {
                background: "#E3DDD2",
            },
        },
    },
    btnBox: {
        padding: "20px !important",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        "& > div": {
            marginTop: "0",
        },
        "& button": {
            marginBottom: "0",
        }
    },
    orderSchedule: {
        padding: "0px 0 !important",
        backgroundColor: "#F1EEDB !important",
        borderRadius: "10px !important",
        "@media(max-width:640px)": {
            borderRadius: "0px !important",
        },
        "& div": {
            margin: "0 !important",
            padding: "0",
        },
        "& > div": {
            margin: "0 !important",
            padding: "0 !important",
            "& > div": {
                "&::after": {
                    right: "20px !important",
                    top: "20px !important",
                },
            },
        },
    },
    number: {
        paddingRight: "5px",
        minWidth: "25px",
    },
    scheduleBox: {
        padding: "0 20px 20px !important",
        "& p": {
            marginBottom: "0",
        }
    },
    orderHeading: {
        padding: "20px 45px 20px 20px !important",
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
        "@media(max-width:640px)": {
            flexDirection: "column",
        },
        "& h4": {
            margin: "0",
            lineHeight: "26px",
            whiteSpace: "nowrap",
        }
    },
    promocode: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& p": {
            marginBottom: "0 !important",
        }
    },
    promocodeDel: {
        fontSize: "12px",
        lineHeight: "20px",
        height: "18px",
        width: "18px",
        background: "#FFC200 !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "100%",
        marginLeft: "5px",
        cursor: "pointer",
        fontWeight: "700",
        "& svg": {
            height: "14px",
        }
    },
    redTxt: {
        color: "#ff5722"
    },
    viewbutton: {
        width: "30%",
        display: "inline-block",
        "@media(max-width:640px)": {
            width: "100%"
        },
    },
    rightTxt: {
        "@media(max-width:640px)": {
            textAlign: "right !important",
        },
    },
    RedrightTxt: {
        color: "#ff5722",
        "@media(max-width:640px)": {
            textAlign: "right !important",
        },
    },
    centerTxt: {
        "@media(max-width:640px)": {
            textAlign: "center !important",
        },
    },
    centerRedTxt: {
        color: "#ff5722",
        "@media(max-width:640px)": {
            textAlign: "center !important",
        },
    },
    ltPanel: {
        width: "60%",
        paddingRight: "10px",
        "& h4": {
            marginBottom: "0",
        }
    },
    rtPanel: {
        width: "40%",
        "& p": {
            marginBottom: "0",
            textAlign: "right",
        }
    },
    fontWeight700: {
        fontWeight: "700",
        color: "#3F4444",
    },
    infoIcon: {
        display: "flex",
        alignItems: "center",
        "& SVG": {
            width: "25px",
            height: "25px",
            color: "#ff5722",
            display: "inline-block",
            margin: "0 5px !important",
            "@media(max-width:640px)": {
                margin: "0 5px 0 0 !important",
            },
        },
        "& span": {
            fontSize: "13px",
            color: "#ff5722",
            fontWeight: "700",
        }
    },

    infoIconTable: {
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        "@media(max-width:640px)": {
            justifyContent: "flex-end",
        },
        "& SVG": {
            width: "25px",
            height: "25px",
            color: "#ff5722",
            display: "inline-block",
            margin: "0 0 0 5px !important",
        },
        "& span": {
            fontSize: "13px",
            color: "#ff5722",
            fontWeight: "700",
        }
    },
    orderHighlighted: {
        "& td": {
            fontSize: "13px",
            color: "#ff5722",
            fontWeight: "700",
        }
    },
    m0: {
        marginBottom: "0 !important",
    },
    m30: {
        marginBottom: "30px",
    }
})