import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import StrConstant from "../../../Common/StrConstant";
import React, { lazy, Suspense, useState } from "react";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import DarkGrayBtn from "../../../Components/DarkGrayBtn/DarkGrayBtn";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import Addresses from "../../../Components/Addresses/Addresses";
import Outlets from "../../../Components/Outlets/Outlets";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOutletList } from "../../../redux/actions/outlet/OutletActions";
import { ActionType } from "../../../redux/ActionTypes";
import Loader from "react-spinner-loader";
import ScheduleDeliverynodata from './SchdeuleDeliveryNodata'
const ScheduleDelivery = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getoutletidsuccess = useSelector(state => state?.getOutletid)
  // const [showModel, setShowModel] = useState(false);
  // const Loader = lazy(() => import("react-spinner-loader"));
  const selected_modes = localStorage.getItem("selected_modes");
  const { loading, outletList } = useSelector((state) => state?.outletList);
  const [showOutlet, setShowOutlet] = useState(false);

  const setView = () => {
    // console.log("Loaded or not", localStorage.getItem("address_id"));

    if (localStorage.getItem("address_id") && localStorage.getItem("selected_outlet_id") && getoutletidsuccess?.success == true &&getoutletidsuccess?.outletCategoryid?.data!=null) {

      navigate("/select-product");
      // setShowOutlet(true);
      // return <Outlets outletList={outletList} />;
    }else if( getoutletidsuccess?.success == true &&getoutletidsuccess?.outletCategoryid?.data==null){
      return navigate("/delivery")
    }
     else {
      setShowOutlet(false);
      return <Addresses />;
    }
  };
// console.log("getoutletidsuccess",getoutletidsuccess?.getoutletidsuccess?.data)
  useEffect(() => {
    dispatch({ type: ActionType.CLEAR_OUTLET_ID })
  }, [])
  const [show, setShow] = useState(setView);
  useEffect(() => {
    setShow(setView);
  }, [show, getoutletidsuccess]);

  useEffect(() => {
    localStorage.removeItem("address_id");
    dispatch(getOutletList(selected_modes === "3" ? "delivery" : "pickup"));
  }, []);

  /* MJ CODE START  */

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    // return false;
    if (localStorage.getItem("address_id") === "") {
      navigate(-1);
    } else {
      localStorage.setItem("address_id", "");
      return false;
    }
  };
  useEffect(() => {
    // console.log("synthetic event", window.location);

    window.history.pushState(null, null, window.location.hash);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(()=>{
    dispatch({type:ActionType.GET_OUTLET_CATEGORY_SUCCESS})
  },[])

  /* MJ CODE END  */

  return (
    <>

      {getoutletidsuccess?.loading == true && (
        <Loader
          show={getoutletidsuccess?.loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}

      <Suspense>

        {loading && (
          <Loader
            show={loading}
            type="body"
            stack="vertical"
            message="Loading..."
          />
        )}
      </Suspense>

      <OuterBox>
        <div>
          <BackArrow clickHandler={(e) => onBackButtonEvent(e)} />
          <h3>
            {!showOutlet
              ? StrConstant.ScheduleDelivery.heading
              : StrConstant.ScheduleDeliveryFrom.heading}
          </h3>
        </div>
        <div>
          <div className={classes.addItems}>{show}</div>
        </div>
        {/* <CustomModal
          open={showModel}
          title={"your address is not within delivery zone"}
        >
          <div className={classes.modalFlex}>
            <DarkGrayBtn
              name={"Add new address"}
              clickHandler={() => navigate("/add-address")}
            />
            <YellowBtn
              name={"Edit Address"}
              clickHandler={() => navigate("/add-address")}
            />
          </div>
        </CustomModal> */}
      </OuterBox>
    </>
  );
};
export default withStyles(Styles)(ScheduleDelivery);
