import { modeLinkHandler } from "../Common/utils/utils";

export const useInsufficient = (currentWalletBalance, totalPayable) => {
  // console.log("totalPayable:", totalPayable);
  // console.log("currentWalletBalance:", currentWalletBalance);
  if (localStorage.getItem("insufficient") === "true") {
    if (parseFloat(currentWalletBalance) < parseFloat(totalPayable))
      return "/purchase-credits";
    else return true;
  }

  return modeLinkHandler(localStorage.getItem("selected_modes"));
};
