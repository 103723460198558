export const Styles = (Theme) => ({
    pr10:{
        paddingRight:"10px",
    },
    logoContent: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        margin: "0 0 25px",
    },
    logo: {
        width: "120px",
        margin: "0 0 25px",
        display: "none",
        "@media (max-width: 768px)": {
            display: "none",
        }
    },
    haveAccount: {
        paddingLeft: "5px",
    },
    m0:{
        margin:"0 0 10px !important",
    }
})