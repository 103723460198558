import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstant from "../../Common/StrConstant";
import { useState, useEffect } from "react";
import ResendEmail from "../../Components/ResendEmail/ResendEmail";
const Verify = (props) => {
  const { classes } = props;
  const [desktop, setDesktop] = useState(window.innerWidth > 768);
  const mobileWidth = () => {
    setDesktop(window.innerWidth > 768);
  };
  useEffect(() => {
    window.addEventListener("resize", mobileWidth);
    return () => window.removeEventListener("resize", mobileWidth);
  });
  return (
    <>
      {desktop ? (
        <div className={classes.root}>
          <div className={classes.container}>
            <div className={classes.overlayContainer}>
              <div className={classes.overlayPanel}>
                <h1>{StrConstant.Main.heading} </h1>
                <p>{StrConstant.Main.description}</p>
              </div>
            </div>
            <div className={classes.formContainer}>
              <ResendEmail />
            </div>
          </div>
        </div>
      ) : (
        <ResendEmail />
      )}
    </>
  );
};

export default withStyles(Styles)(Verify);
