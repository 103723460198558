import { useMemo, useRef } from "react";
import { useBreakpoint } from "./useBreakpoints";

export const useImpactCard = () => {
  const point = useBreakpoint();
  const imgArray = useRef([]);
  //*public image url
  const desktop = [
    "./images/communityDesktop.svg",
    "./images/peopleDesktop.svg",
    "./images/futureDesktop.svg",
  ];
  const tablet = [
    "./images/communityTab.svg",
    "./images/futureTab.svg",
    "./images/peopleTab.svg",
  ];
  const mobile = [
    "./images/communityMobile.svg",
    "./images/futureMobile.svg",
    "./images/peopleMobile.svg",
  ];
  // ?for every unique brkPnt
  useMemo(() => {
    if (point === "lg") return (imgArray.current = desktop);
    else if (point === "md") return (imgArray.current = tablet);
    else if (point === "sm" || point === "xs")
      return (imgArray.current = mobile);
  }, [point]);
  // !output
  return imgArray.current;
};
