import { Styles } from "./Style";
import { Link } from "react-router-dom";
import { withStyles } from "@mui/styles";
import YellowBtn from "../YellowBtn/YellowBtn";
import React, { Fragment, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomModal from "../CustomModal/CustomModal";
import DarkGrayBtn from "../DarkGrayBtn/DarkGrayBtn";
import { useDispatch, useSelector } from "react-redux";
// import AlterMessage from "../../Components/AlertMessage/AlertMessage";
import {
  cardsDefaultAction,
  deleteCard,
} from "../../redux/actions/credit/CreditActions";
// import { ActionType } from "../../redux/ActionTypes";
const CardPayment = (props) => {
  const dispatch = useDispatch();
  const { classes, cardList, setting = false, onSelection } = props;
  const [imageUrl, setImageUrl] = useState(null);
  const [showModel, setShowModel] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { cards } = useSelector((state) => state?.cardList);
  const { userProfile } = useSelector((state) => state?.getUserProfile);
  const userInfo=JSON.parse(localStorage.getItem("userInfo"));
  const selectionHandler = (id) => {
    // localStorage.setItem("cart_id", id);
    setSelectedId(id);
    onSelection(id);
  };
  useEffect(() => {
    const temp = cardList?.find((element) => element?.is_primary);
    setSelectedId(temp?.card_id);
  }, [cards]);
  useEffect(() => {
    if (selectedId === null) return;
    onSelection(selectedId);
    const temp = cards?.data?.cards.filter(
      (fetch) => fetch.card_id === selectedId
    );
    setImageUrl(temp[0]?.brand);
  }, [selectedId]);
  return (
    <>
      {cardList?.length === 0 ? (
        <div className={classes.box}>
          <h4>no card</h4>
        </div>
      ) : (
        cardList?.map((card, index) => (
          <div
            key={index}
            className={
              selectedId === card?.card_id ? classes.boxActive : classes.box
            }
            onClick={() => selectionHandler(card?.card_id)} /////////////////////
          >
            <div className={classes.cardBox}>
              {/*this was a link */}
              <div className={classes.imgBox}>
                <img
                  src={`./images/${
                    card?.brand === "American Express"
                      ? "american-express"
                      : card?.brand
                  }.svg`}
                  alt="Better Coffee"
                />
              </div>
              <div className={classes.cardOuter}>
                <div className={classes.cardInner}>
                  <p>{card?.brand}</p>
                  <p>
                    <span>
                      <b>....</b>
                      {card.last4}
                    </span>
                    <span>{card?.is_primary ? "(Primary)" : null}</span>
                  </p>
                </div>
                <div className={classes.setDefault}>
                  {/* lol */}
                  {userInfo?.data?.user_data?.is_subscribed===false||
                  !card?.is_primary ? (
                    <Link to="" onClick={() => setShowModel((pre) => !pre)}>
                      <DeleteIcon />
                    </Link>
                  ) : null}
                  {setting && !card?.is_primary ? (
                    <Link
                      to=""
                      onClick={() =>
                        dispatch(
                          cardsDefaultAction({
                            card_id: card?.card_id,
                          })
                        )
                      }
                    >
                      Set as default
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))
      )}
      <CustomModal
        open={showModel}
        title={"Are you sure you want to delete this card?"}
        description={""}
      >
        <div className={`${classes.box} ${classes.cartDetailBox}`}>
          <div className={classes.margin2rem}>
            <div className={classes.imgBox}>
              <img
                src={`./images/${
                  imageUrl === "American Express"
                    ? "american-express"
                    : imageUrl
                }.svg`}
                alt="Better Coffee"
              />
            </div>
            <div className={classes.description}>
              <p>
                {cards?.data?.cards.map((fetch) =>
                  fetch.card_id === selectedId ? (
                    <Fragment key={Math.random()}>{fetch.brand}</Fragment>
                  ) : null
                )}
              </p>
              <p>
                <span>
                  <b>....</b>
                  {cards?.data?.cards.map((fetch) =>
                    fetch.card_id === selectedId ? (
                      <Fragment key={Math.random()}>{fetch.last4}</Fragment>
                    ) : null
                  )}
                </span>
                <span>
                  {" "}
                  {cards?.data?.cards.map((fetch) =>
                 fetch.card_id === selectedId?  ( fetch?.is_primary? "(Primary)" : null):null
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={classes.modalFlex}>
          <DarkGrayBtn
            name={"Cancel"}
            clickHandler={() => setShowModel(false)}
          />
          <YellowBtn
            name={"Delete"}
            clickHandler={() =>
              dispatch(
                deleteCard(selectedId),
                localStorage.setItem("cart_id", null),
                setSelectedId(null),
                setShowModel(false),
                selectionHandler(null)
              )
            }
          />
        </div>
      </CustomModal>
    </>
  );
};
export default withStyles(Styles)(CardPayment);
