export const Styles = (Theme) => ({
  p0: {
    "@media(max-width:640px)": {
      padding: "0 !important",
    },
  },
  outerInner: {
    "@media(max-width:640px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      padding:"0 20px",
    },
  },
  cardConteiner: {
    background: "#fff",
    borderRadius: "10px",
    width: "100%",
    "@media(max-width:991px)": {
      background: "transparent",
    },
  },
  listingBox: {
    paddingLeft: "0",
    "@media(max-width:640px)": {
      padding: "0 20px 0 20px",
    },
    "& li": {
      color: "#333539",
      margin: "0 0 15px 0",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "20px",
      "& h4": {
        marginBottom: "5px",
      },
      "& p": {
        marginBottom: "5px",
      },
    },
  },

  dFlex: {
    display: "flex",
    width: "100%",
  },
  imgContent: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
  },

  lt: {
    width: "50%",
    marginRight: "15px",
    "& h4": {
      marginBottom: "5px !important",
    },
    "& h5": {
      marginBottom: "5px !important",
    },
    "& p": {
      display: "block",
    },
  },
  rt: {
    width: "50%",
    "& p": {
      marginBottom: "5px",
      textAlign: "right",
      wordBreak: "break-all",
      fontWeight: "700",
      color: "#3F4444",
    },
  },

  imgBox: {
    height: "35px",
    width: "35px",
    margin: "0 0 0 10px",
    "& img": {
      height: "100%",
      width: "100%",
      cursor: "pointer",
    },
  },

  needBox: {
    display: "flex",
    alignItems: "center",
    "& a": {
      "@media(max-width:640px)": {
        padding: "20px !important",
        borderRadius: "10px",
        width: "100%",
        margin: "15px 0 0",
      },
    },
    "& img": {
      paddingRight: "6px",
    },
  },
  subTotal: {
    padding: "0",
    margin: "25px 0 0",
    "@media(max-width:640px)": {
      background: "#F1EEDB",
      padding: "20px",
      margin: "25px 0",
    },
    lt: {
      "& p": {
        fontSize: "12px !important",
        lineHeight: "16px !important",
      },
    },
  },
  text: {
    "@media(max-width:640px)": {
      fontSize: "12px !important",
      lineHeight: "16px !important",
    },
  },
  bottomBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    "@media(max-width:640px)": {
      display: "block",
      padding: "0 20px",
    },
    "& button": {
      marginTop: "20px",
      marginBottom: "0",
    },
  },
  modalFlex: {
    display: "flex",
    "& :nth-child(1)": {
      marginRight: "15px",
    },
  },
  btnContent: {
    "& :nth-child(1)": {
      marginLeft: "10px",
      "@media(max-width:640px)": {
        margin: "15px 0 0 0",
      },
    },
  },
  outerBox: {
    display: "flex",
    alignItems: "center",
    height: "calc(100vh - 75px)",
    "@media(max-width:991px)": {
      display: "table",
      margin: "0  0 20px",
      height: "auto",
      width: "100%",
    },
    "& > :nth-child(1)": {
      width: "30%",
      marginRight: "10px",
      "@media(max-width:991px)": {
        width: "100%",
        marginRight: "0",
      },
      "@media(max-width:640px)": {
        padding: "0 20px",
      },
    },
    "& > :nth-child(2)": {
      width: "70%",
      borderRadius: "10px",
      maxHeight: "calc(100vh - 150px)",
      overflowY: "auto",
      background: "#fff",
      padding: "40px 25px 40px 40px",
      "@media(max-width:991px)": {
        width: "100%",
        maxHeight: "100%",
        overflowY: "inherit",
        margin: "15px 0 0",
        display: "table",
        maxHeight:"calc(100vh - 220px)",
      },
      "@media(max-width:640px)": {
        background: "transparent",
        padding: "0",
        maxHeight:"100%",
        height:"calc(100vh - 260px)",
      },
      "&::-webkit-scrollbar": {
        appearance: "none",
      },
      "&::-moz-scrollbar": {
        appearance: "none",
      },
      "&::-o-scrollbar": {
        appearance: "none",
      },
      "&::-ms-scrollbar": {
        appearance: "none",
      },
    },
  },
  countBox: {
    display: "flex",
    alignItems: "flex-start",
  },
  count: {
    width: "auto",
    minWidth: "25px",
    paddingRight: "5px",
  },
  cartRight: {
    width: "100%",
  },
  cartListing: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
});
