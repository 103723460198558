import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import StrConstant from "../../../Common/StrConstant";
import Outlets from "../../../Components/Outlets/Outlets";
import { useDispatch, useSelector } from "react-redux";
import { lazy, Suspense, useEffect } from "react";
import { getOutletList } from "../../../redux/actions/outlet/OutletActions";
import { ActionType } from "../../../redux/ActionTypes";

const SchedulePickUp = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Loader = lazy(() => import("react-spinner-loader"));
  const selected_modes = localStorage.getItem("selected_modes");
  const { loading, outletList } = useSelector((state) => state?.outletList);
  useEffect(() => {
    dispatch(getOutletList(selected_modes === "3" ? "delivery" : "pickup"));
  }, []);
  useEffect(() => {
    dispatch({type:ActionType.GET_OUTLET_CATEGORY_SUCCESS})
    localStorage.removeItem("selected_outlet_name")
  }, [])
  return (
    <>
      <Suspense>
        {loading && (
          <Loader
            show={loading}
            type="body"
            stack="vertical"
            message="Loading..."
          />
        )}
      </Suspense>
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() => {
              navigate(-1);
            }}
          />
          <h3>{StrConstant.SchedulePickUp.heading}</h3>
        </div>
        <>{outletList ? <Outlets outletList={outletList} /> : null}</>
      </OuterBox>
    </>
  );
};

export default withStyles(Styles)(SchedulePickUp);
