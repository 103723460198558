export const Styles = Theme => ({
    outerInner: {
        maxWidth: "1000px",
        margin: "auto",
        display: "flex",
        width: "100%",
        alignItems: "center",
        height: "calc(100vh - 150px)",
        "@media(max-width:640px)": {
            display: "block",
        }
    },
    description: {
        width: "30%",
        "@media(max-width:640px)": {
            width: "100%",
        }
    },
    fromControl: {
        margin: "20px 0 25px",
    },
    contentBox: {
        width: "70%",
        height: "auto",
        boxShadow: "0px 0px 10px 0px #ccc",
        background: "#fff",
        padding: "40px 30px",
        maxHeight: "600px",
        overflowY: "auto",
        margin:"50px 0",
        "@media(max-width:640px)": {
            width: "100%",
            paddingLeft: "0",
            boxShadow: "none",
            padding: "0",
            background: "transparent",
            maxHeight: "0",
            overflowY: "inherit",
            margin:"0 0",
        },
        "&::-webkit-scrollbar": {
            appearance: "none",
        },
        "&::-moz-scrollbar": {
            appearance: "none",
            width: "0",
        },
        "&::-o-scrollbar": {
            appearance: "none",
            width: "0",
        },
        "&::-ms-scrollbar": {
            appearance: "none",
            width: "0",
        },
    },
    passwordBox: {
        position: "relative",
        "& input": {
            padding: "12px 45px 12px 12px",
        },
        "& img": {
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translate(-50%,-50%)",
        }
    },
    btnBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
        "@media(max-width:640px)": {
            display: "block",
        },
        "& :nth-child(2)": {
            marginLeft: "10px",
            "@media(max-width:640px)": {
                margin: "auto",
            }
        },
        "& > div": {
            "@media(max-width:640px)": {
                float: "unset",
                margin: "auto",
            }
        },
    },
    modalFlex: {
        display: "flex",
        "& div:nth-child(2)": {
            marginLeft: "15px",
        },
    },
})