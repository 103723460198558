export const Styles = (Theme) => ({
  promoBox: {
    width: "100%",
    // margin: "20px 0",
  },
  promoInput: {
    width: "100%",
    position: "relative",
    marginBottom: '20px',
    "& input": {
      padding: "24px 210px 24px 20px",
      minHeight: "50px",
      "@media (max-width: 640px)": {
        padding: "24px 105px 24px 20px",
      },
    },
    "& button": {
      padding: "15px 24px",
      background: "#FFC200",
      color: "#3F4444",
      fontSize: "20px",
      lineHeight: "20px",
      fontWeight: "400",
      fontFamily: "'Bebas Neue', serif",
      letterSpacing: "0",
      whiteSpace: "nowrap",
      borderRadius: "32px",
      width: "100%",
      position: "absolute",
      right: "0",
      top: "0",
      maxWidth: "200px",
      minHeight: "50px",
      "@media(max-width:640px)": {
        maxWidth: "100px",
      },
      "&:hover": {
        background: "#FFC200",
      },
    },
  },
  errorMsg: {
    color: "red",
    textAlign: "left !important",
    marginTop: "2px",
    paddingLeft: "5px",
    fontSize: "13px",
  },
  successMsg: {
    color: "green",
    textAlign: "left !important",
    marginTop: "2px",
    paddingLeft: "5px",
    fontSize: "13px",
  },
  dFlex: {
    display: "flex",
    width: "100%",
  },
  ltBox: {
    width: "30%",
    paddingRight: "15px",
    display: "flex",
    "@media(max-width:640px)": {
      width: "60%",
    },
  },
  rtBox: {
    width: "70%",
    textAlign: "right",
    "@media(max-width:640px)": {
      width: "40%",
    },
  },
  promoCode: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& p": {
      marginBottom: "0 !important",
    },
  },
  promoCodeDel: {
    fontSize: "12px",
    lineHeight: "20px",
    height: "18px",
    width: "18px",
    background: "#FFC200 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    marginLeft: "5px",
    cursor: "pointer",
    fontWeight: "700",
    "& svg": {
      height: "14px",
    },
  },
});
