
import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Styles } from './Style';
import StrConstant from '../../../../Common/StrConstant';
import OuterBox from '../../../../Components/OuterBox/OuterBox';
import BackArrow from '../../../../Components/BackArrow/BackArrow';
import { useNavigate } from 'react-router';
const SubmissionFeedback = (props) => {
    const { classes, } = props
    let navigate = useNavigate();
    const buttonClick = () => {
        navigate('/');
    }
    return (
        <>
            <OuterBox>
                <div>
                    <BackArrow clickHandler={() => {  navigate(-1);}}/>
                    <h3>{StrConstant.SubmissionFeedback.heading}</h3>
                </div>
                <div>
                    <p>{StrConstant.SubmissionFeedback.description1}</p>
                    <p>{StrConstant.SubmissionFeedback.description2}</p>
                    <p>{StrConstant.SubmissionFeedback.description3}</p>
                </div>
            </OuterBox>
        </>
    )
}
export default withStyles(Styles)(SubmissionFeedback);