import { useEffect, useState } from "react";
import { Styles } from "./Style.js";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Constant from "../../Common/Constant.js";
import StrConstant from "../../Common/StrConstant.js";
import Loader from "react-spinner-loader";
import { encryptValue } from "../../Common/utils/utils.js";
import { socialLoginAction } from "../../redux/actions/UserActions.js";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
const SocialBtn = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const windowHref = window.location.hash;
  const loading = localStorage.getItem('loading')
  // const [loading,setloading]=useState(false)
  const REDIRECT_URI = Constant.baseUrl + "/#/sign-up";
  const { userInfo } = useSelector((state) => state?.userSocialLogin);
  // const REDIRECT_URI = "https://www.bettrbarcus.hipster-dev.com/login#/sign-up";
  useEffect(() => {
    if (userInfo?.data?.user_data?.auth_token) return navigate("/");
  }, [userInfo]);
  useEffect(() => {
    localStorage.setItem("loading", false)
  }, [])
  console.log("userInfo", loading)

  return (
    <>
      {loading == "true" && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <LoginSocialFacebook
        appId={StrConstant.SocialLogin.REACT_APP_FB_APP_ID || ""}
        fieldsProfile={
          "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
        }
        redirect_uri={REDIRECT_URI}
        onResolve={({ provider, data }) => {
          const payload = {
            provider: encryptValue(provider),
            provider_id: encryptValue(data?.accessToken),
            email: encryptValue(data?.email),
            first_name: encryptValue(data?.first_name),
            last_name: encryptValue(data?.last_name),
          };
          // setloading(true)

          dispatch(socialLoginAction(payload));
        }}
        onReject={(err) => {
          // console.log(err);
        }}
      >
        <div className={classes.btnContent}>
          <Button>
            <img src="./images/fackbook-logo.svg" alt="Better Coffee" />
            <span>
              Sign {windowHref === "#/login" ? "in" : "up"} with Facebook
            </span>
          </Button>
        </div>
      </LoginSocialFacebook>

      <LoginSocialGoogle
        client_id={StrConstant.SocialLogin.REACT_APP_GG_APP_ID || ""}
        redirect_uri={REDIRECT_URI}
        scope="openid profile email"
        discoveryDocs="claims_supported"
        access_type="offline"
        onResolve={({ provider, data }) => {
          const payload = {
            provider: encryptValue(provider),
            provider_id: encryptValue(data?.access_token),
            email: encryptValue(data?.email),
            first_name: encryptValue(data?.given_name),
            last_name: encryptValue(data?.family_name),
          };
          dispatch(socialLoginAction(payload));
        }}
        onReject={(err) => {
          // console.log(err);
        }}
      >
        <div className={classes.btnContent}>
          <Button>
            <img src="./images/google-logo.svg" alt="Better Coffee" />
            <span>
              Sign {windowHref === "#/login" ? "in" : "up"} with Google
            </span>
          </Button>
        </div>
      </LoginSocialGoogle>
    </>
  );
};
export default withStyles(Styles)(SocialBtn);
