export const Styles = Theme => ({
  root: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      backgroundColor: "#FFC200",
      backgroundPosition: "center",
      backgroundImage: "url('/images/bg.png')",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
  },
  container: {
      backgroundColor: "transparent",
      position: "relative",
      width: "1240px",
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
      padding: "0 20px",
      justifyContent: "center",
      margin: "auto",
  },
  overlayContainer: {
      width: "60%",
      height: "auto",
      "@media (max-width: 1024px)": {
          width: "55%",
      },
      "@media (max-width: 991px)": {
          width: "50%",
      },
      "@media (max-width: 840px)": {
          width: "45%",
      },
      "& h1": {
          fontWeight: "400",
          margin: 0,
          color: '#F1EEDB',
          fontSize: '64px',
          letterSpacing: '-0.02em',
          lineHeight: '64px',
          textTransform: "uppercase",
          fontFamily: "'Bebas Neue', serif",
          "@media (max-width: 1024px)": {
              fontSize: '55px',
              lineHeight: '55px',
          },
          "@media (max-width: 991px)": {
              fontSize: '45px',
              lineHeight: '45px',
          },
          "@media (max-width: 767px)": {
              fontSize: '35px',
              lineHeight: '35px',
          },
      },
      "& p": {
          fontSize: "18px",
          lineHeight: "24px",
          letterSpacing: "0.5px",
          fontWeight: "700",
          margin: "20px 0 0",
          color: '#FFFFFF',
          fontFamily: "'Noto Serif', serif",
          "@media (max-width: 991px)": {
              fontSize: '16px',
              lineHeight: '20px',
              marginTop: "10px",
          }
      },
  },

  formContainer: {
      width: "40%",
      height: "auto",
      "@media (max-width: 1024px)": {
          width: "45%",
      },
      "@media (max-width: 991px)": {
          width: "50%",
      },
      "@media (max-width: 840px)": {
          width: "55%",
      },
  },
  overlayPanel: {
      width: "100%",
      paddingRight: "1rem",
      boxSizing: "border-box",
      maxWidth: "600px",
  },
})
