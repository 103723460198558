import { ActionType } from "../../ActionTypes";

export const planListReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_PLAN_LIST_DETAILS_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_PLAN_LIST_DETAILS_SUCCESS:
      return { ...state, loading: false, planDetail: action.payload };
    case ActionType.GET_PLAN_LIST_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getBillingHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.BILLING_HISTORY_REQUEST:
      return { ...state, loading: true };
    case ActionType.BILLING_HISTORY_SUCCESS:
      return { ...state, loading: false, subscriptionList: action.payload };
    case ActionType.BILLING_HISTORY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const changePlanReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.CHANGE_PLAN_REQUEST:
      return { ...state, loading: true };
    case ActionType.CHANGE_PLAN_SUCCESS:
      return { ...state, loading: false, changePlan: action.payload };
    case ActionType.CHANGE_PLAN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const cancelSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.CANCEL_PLAN_REQUEST:
      return { ...state, loading: true };
    case ActionType.CANCEL_PLAN_SUCCESS:
      return { ...state, loading: false, cancelPlan: action.payload };
    case ActionType.CANCEL_PLAN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};
