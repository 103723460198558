import React from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import StrConstant from "../../../../Common/StrConstant";
import OuterBox from "../../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../../Components/BackArrow/BackArrow";
import YellowBtn from "../../../../Components/YellowBtn/YellowBtn";
import GrayBtn from "../../../../Components/GrayBtn/GrayBtn";
import { ActionType } from "../../../../redux/ActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { useImpactCard } from "../../../../Hooks/useImpactCard";
import DarkGrayBtn from "../../../../Components/DarkGrayBtn/DarkGrayBtn";
const OrderConfirm = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const impactCard = useImpactCard();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
   const numberToVerify=localStorage.getItem("numberToVerify")
   const insufficient=localStorage.getItem("insufficient")
  const { checkoutOrder } = useSelector(
    (state) => state?.finalizeOrderCheckout
  );
  const linkClickHandler = () => {
    dispatch(
      { type: ActionType.CLEAR_RESPONSE },
      { type: ActionType.CLEAR_CHECKOUT },
      navigate("/my-dashboard")
    );
  };

  const ViewOrderClick = () => {
    dispatch({ type: ActionType.CLEAR_CHECKOUT }, navigate("/my-orders"));
  };

  const GoToHome = () => {
    dispatch({ type: ActionType.CLEAR_CHECKOUT }, navigate("/my-dashboard"));
  };
  return (
    <>
      <OuterBox>
        <div>
          <BackArrow clickHandler={linkClickHandler} />
          <h3>{StrConstant.OrderConfirm.heading}</h3>
        </div>
        <div>
          <p>
            {/* Your order(s) <b> {checkoutOrder?.data?.order_number}</b> is confirmed! */}
            Your order(s) has been received! Check your email for more details on your order status.
          </p>
          <div className={classes.orderFlex}>
            <div className={classes.orderInfo}>
              <div className={classes.content}>
                {/* <h4>Did you know?</h4> */}
                <div>
                  {impactCard.length > 0 && (
                    <div className={classes.imageContent}>
                      <img
                        src={
                          impactCard[
                          Math.floor(Math.random() * impactCard.length)
                          ]
                        }
                        alt="Better cloud"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={`${classes.btnBox} ${classes.mobileview}`}>
              <DarkGrayBtn
                name={"Return to my dashboard"}
                clickHandler={GoToHome}
              />
              <YellowBtn
                name={"View my orders"}
                clickHandler={ViewOrderClick}
              />

            </div>
          </div>
        </div>
      </OuterBox>
    </>
  );
};
export default withStyles(Styles)(OrderConfirm);
