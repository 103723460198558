import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Button from "@mui/material/Button";
const BackArrow = (props) => {
  const { classes, clickHandler } = props;
  return (
    <>
      <div className={classes.back}>
        <Button onClick={clickHandler} disableRipple disableFocusRipple>
          <img src="./images/long-arrow-left.svg" alt="Better Coffee" />
          <span>Go Back </span>
        </Button>
      </div>
    </>
  );
};

export default withStyles(Styles)(BackArrow);
