export const Styles = Theme => ({
    stepperWrapper: {
        margin: "20px auto 50px",
        maxWidth: "500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        "@media(max-width:768px)": {
            maxWidth: "350px",
        },
        "@media(max-width:425px)": {
            maxWidth: "250px",
        },
        "& > :before": {
            content: "''",
            top: "50%",
            left: "0",
            width: "100%",
            height: "3px",
            backgroundColor: "#ffc200",
            position: "absolute",
            zIndex: "-2",
        },
    },
    dots: {
        width: "12px",
        height: "12px",
        background: "#ffc200",
        borderRadius: "100%",
        "@media (max-width: 768px)": {
            width: "12px",
            height: "12px",
        },
        "@media(max-width:425px)": {
            width: "10px",
            height: "10px",
        },
    },
    stepperTxt: {
        margin: "0px auto 50px",
        maxWidth: "800px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "@media(max-width:991px)": {
            maxWidth: "450px",
        },
        "@media(max-width:768px)": {
            maxWidth: "350px",
        },
    },
    stepperBox: {
        // position: "relative",
        maxWidth: "45px",
    },
    txt: {
        fontSize: "14px",
        lineHeight: "14px",
        fontWeight: "700",
        fontFamily: "'Noto Serif', serif",
        color: "#3F4444",
        background: "transparent !important",
        paddingTop: "5px",
        display: "block",
        position: "absolute",
        bottom: "-22px",
    },
    screenIndicator: {
        borderRadius: "50%",
        border: "3px solid rgb(255 194 0)",
        backgroundColor: "white",
        height: "40px",
        width: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "@media (max-width: 768px)": {
            height: "35px",
            width: "35px",
        },
    },

    completed: {
        borderColor: "#ffc200",
        background: "#ffc200",
    },
    check: {
        height: "15px !important",
        width: "8px !important",
        transform: "rotate(52deg)",
        border: "3px solid  #3f4444",
        borderLeft: "0",
        borderTop: "0",
        borderRadius: "0 !important",
        marginTop:"-2px",
    }
})