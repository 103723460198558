export const Styles = Theme => ({
    fromControl: {
        margin: "0 0 25px",
    },
    btnBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "@media(max-width:640px)": {
            flexDirection: "column-reverse",
            justifyContent: "center",
        },
        "& > div": {
            margin: "0",
            "@media(max-width:640px)": {
                margin: "15px auto",
            },
            "& button": {
                "@media(max-width:640px)": {
                    margin: "0",
                }
            }
        }
    },
    passwordBox: {
        position: "relative",
        "& input": {
            padding: "12px 45px 12px 12px",
        },
        "& span": {
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translate(-50%,-50%)",
        }
    },
    numberField: {
        position: "relative",
        "& input": {
            paddingRight: "40px",
        },
        "& img": {
            position: "absolute",
            right: "15px",
            top: "50%",
            transform: "translate(-10%, -50%)",
        }
    },
    modalFlex: {
        display: "flex",
        justifyContent: "center",
        "@media(max-width:375px)": {
            display: "block",
        },
        "& > :nth-child(2)": {
            marginLeft: "15px",
            "@media(max-width:375px)": {
                marginLeft: "0",
            }
        },
        "& button": {
            "@media(max-width:375px)": {
                margin: "10px 0",
            }
        }
    },
    passwordField: {
        position: "relative",
        "& input": {
            paddingRight: "40px",
        },
        "& span": {
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translate(-50%,-50%)",
            "& svg": {
                height: "20px",
                width: "18px",
                color: "#3F444480",
                cursor: "pointer",
            },
        },
    },
})