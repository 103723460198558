import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstant from "../../Common/StrConstant";
import { useState, useEffect } from "react";
// import AlterMessage from "../../Components/AlertMessage/AlertMessage";
// import { useDispatch, useSelector } from "react-redux";
import ForgotSuccessMessage from "../../Components/ForgotSuccessMessage/ForgotSuccessMessage";
// import { ActionType } from "../../redux/ActionTypes";
const Success = (props) => {
  const { classes } = props;
  // const dispatch = useDispatch();

  const [desktop, setDesktop] = useState(window.innerWidth > 768);
  // const data = useSelector((state) => state?.forgotPassword);
  // const [isOpen, setIsOpen] = useState(false);
  const mobileWidth = () => {
    setDesktop(window.innerWidth > 768);
  };
  // const snackHandler = (event, reason) => {
  //   if (reason === "clickaway") return;
  //   dispatch({ type: ActionType.CLEAR_RESPONSE }, setIsOpen(false));
  // };
  useEffect(() => {
    window.addEventListener("resize", mobileWidth);
    return () => window.removeEventListener("resize", mobileWidth);
  });
  // useEffect(() => {
  //   setIsOpen(true);
  // }, [data?.success]);

  // useEffect(() => {
  //   dispatch({ type: ActionType.CLEAR_RESPONSE });
  // }, [])
  return (
    <>
      {/* {data?.success && (
        <AlterMessage
          open={isOpen}
          closeHandler={snackHandler}
          type={"Success"}
          title={"Success"}
          url={"./images/success.svg"}
          description={data?.forgotPassword?.data?.message}
        />
      )} */}
      {desktop ? (
        <div className={classes.root}>
          <div className={classes.container}>
            <div className={classes.overlayContainer}>
              <div className={classes.overlayPanel}>
                <h1>{StrConstant.Main.heading} </h1>
                <p>{StrConstant.Main.description}</p>
              </div>
            </div>
            <div className={classes.formContainer}>
              <ForgotSuccessMessage />
            </div>
          </div>
        </div>
      ) : (
        <ForgotSuccessMessage />
      )}
    </>
  );
};

export default withStyles(Styles)(Success);
