import http from "./http-common";
const tempHeader = {
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Credentials": true,
  },
};
const postRequest = (url, data, header = tempHeader) =>
  http.post(url, data, header);
const putRequest = (url, data, header = tempHeader) =>
  http.put(url, data, header);

const getRequest = (url, data, header = tempHeader) =>
  http.get(url, data, header);

const patchRequest = (url, data, header = tempHeader) =>
  http.patch(url, data, header);

const deleteRequest = (url, data, header = tempHeader) =>
  http.delete(url, data, header);

const setFunc = {
  postRequest,
  putRequest,
  getRequest,
  deleteRequest,
  patchRequest,
};
export default setFunc;
