import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const MultiGroupToggler = (props) => {
  const { classes, children, onSelected, setOnSelected } = props;
  const [formats, setFormats] = useState(onSelected);
  console.log("daysselected ",onSelected);
  const handleChange = (event, newBinding) => {
    setFormats(newBinding);
    setOnSelected(newBinding);
  };
  const arr=["Thu", "Fri","Sat","Sun"]
  return (
    <ToggleButtonGroup
      className={classes.toogleBtnBox}
      value={formats}
      onChange={handleChange}
     
    >
      {children}
    </ToggleButtonGroup>
  );
};

export default withStyles(Styles)(MultiGroupToggler);
