const StrConstant = {
  Login: {
    heading: "login",
  },
  SignUp: {
    heading: "Sign Up",
    propoverTitle: "Password has to:",
    propoverDescription1: "Be at least 8 characters",
    propoverDescription2:
      "Include at least one uppercase and one lowercase letter",
    propoverDescription3: "Include number(s)",
    propoverDescription4: "Include special characters (eg. ~!@$%^&*()_+|)",
  },
  Verify: {
    heading: "Verify Your Email",
  },
  VerifyEmail: {
    heading: "Verify Email",
  },
  VerifiedEmail: {
    heading: "YOUR EMAIL HAS BEEN VERIFIED",
  },
  AlreadyVerifiedEmail: {
    heading: "YOU'RE GOOD TO GO!",
  },
  Reset: {
    heading: "RESET YOUR PASSWORD",
  },
  Success: {
    heading: "YOUR EMAIL HAS BEEN VERIFIED",
  },
  SetPassword: {
    heading: "Set Password",
  },
  Main: {
    heading: "ORDER BETTR",
    description: "Refuel with us—order, receive, enjoy!",
  },
  AddPayment: {
    heading: "add payment method",
  },
  ChoosePaymentMethod: {
    heading: "Choose payment method",
  },
  ChoosePlan: {
    heading: "Choose your plan",
    description: "Save more by subscribing to a monthly recurring credit plan!",
    btTxt: {
      description: "Not a fan of commitment? ",
      LinkTxt: "Purchase one-time credits instead",
    },
    subBtTxt: {
      description: "Are you a fan of commitment?",
      LinkTxt: "Purchase subscription instead",
    },
  },
  GetOneTimeCredits: {
    heading: "Get one-time credits",
    description: "Enjoy the flexibility of getting only the credits you need.",
    btTxt: {
      description: "Want more savings?",
      LinkTxt: "Subscribe to a monthly recurring credit plan instead",
    },
    propoverTitle: "Lorem ipsum dolor sit amet",
    propoverDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam gravida augue condimentum ligula ornare dictum. ",
  },
  OneOfPurchaseCredits: {
    heading: "Purchase of credits",
    secondHeading: "Payment details",
  },
  PurchaseComplete: {
    heading: "CREDIT PURCHASE SUCCESS",
    // description:
    //   "You're one step closer to fueling your coffee addiction! Let's get started.",
    description:"Place an order and start getting caffeinated!"
      },
  PurchaseCredits: {
    heading: "Purchase of credits",
    secondHeading: "Payment details",
  },
  OneOffGetOneTimeCredits: {
    heading: "Get one-time credits",
    description: "Save more by subscribing to a monthly recurring credit plan!",
  },
  ChooseMode: {
    heading: "Choose Mode",
  },
  PickupToday: {
    heading: "Pick-up Today",
  },
  SchedulePickUp: {
    heading: "Schedule Pick-up",
  },
  ScheduleDelivery: {
    heading: "Delivery To",
  },
  ScheduleDeliveryFrom: {
    heading: "Delivery From",
  },
  Home: {
    heading: "My Dashboard",
    propoverTitle: "",
    propoverDescription: "",
  },
  MyOrder: {
    heading: "My Orders",
  },
  ViewDetails: {
    heading: "Order",
  },
  Profile: {
    heading: "Profile",
  },
  VerifyOtp: {
    heading: "Verify your phone number",
    description:
      "A confirmation code has been sent to ",
    secondDescription: " Entered the wrong number?",
    SuccessTitle: "Profile Updated",
    SuccessDescription: "Your profile information updated successfully.",
  },
  MyAccount: {
    heading: "My Account",
  },
  ManagePaymentMethods: {
    heading: "Manage Payment Methods",
  },
  AddCard: {
    heading: "Add Card",
  },
  ManageAddresses: {
    heading: "Manage Addresses",
  },
  AddAddresses: {
    heading: "Add Address",
  },
  ChangePassword: {
    heading: "Change Password",
  },
  ForgotPassword: {
    heading: "Change Password",
    propoverTitle:
      "Password of at least 8 characters and meet the following conditions:",
    propoverDescription: "Mix of uppercase and lowercase letters",
    propoverDescription: "Include number(s).",
    propoverDescription: "Include special characters (eg. ~!@$%^&*()_+|)",
  },
  CartDetail: {
    heading: "Cart",
  },
  OrderReview: {
    heading: "Finalise your order",
  },
  OrderReviewVerifyOtp: {
    heading: "Verify your phone number",
    description:
      "Please complete a one-time verification of your mobile number to submit the order.",
  },
  EditPhoneNumber: {
    heading: "One step closer to your treats",
    description:
      "Please complete a one-time verification of your mobile number to submit the order.",
  },
  PhoneNumberVerified: {
    heading: "Phone number verified",
    description: "You can proceed to submit your first order!",
  },
  OrderConfirm: {
    heading: "ORDER RECEIVED",
  },
  DeleteAccount: {
    heading: "Delete account",
    description1: "This action cannot be undone.",
    description2:
      "Keep in mind that once the account is deleted, drink orders will be suspended, you will not be able to make new orders and retrieve order information. ",
    description3:
      "All your available credits will also be forfeited. Any auto-renewable subscription plan will be cancelled.",
    description4:
      "Since there is no turning back, before we do anything wrong, we want to make sure it is you.",
  },
  AccountDeletionSuccess: {
    heading: "account deletion success",
    description1:
      "Your account has been deactivated from the site and will be permenantly deleted automatically after 30 days..",
    description2:
      "If you login to your account within the next 30 days, the account will be reactivated.",
    description3:
      "Thank you for using Bettr Cloud Bar services! Is there anything you would like to share?",
  },
  SubmissionFeedback: {
    heading: "Submission of feedback",
    description1: "Thank you for your feedback.",
    description2: "You will be redirected to BettrCloudBar.sg shortly.",
  },
  MyWallet: {
    heading: "My Wallet",
  },
  BillingHistory: {
    heading: "Billing History",
  },
  CreditTransactions: {
    heading: "Credit Transactions",
  },
  WalletCard: {
    propoverTitle: "Lorem Ipsum",
    propoverDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  ManageSubscription: {
    heading: "Manage my subscription",
    description: "Update your plan at any time! Changes to your plan are immediate; your billing cycle will be reset, and you'll get your credits instantly.",
  },
  CancelSubscription: {
    heading: "Cancel your subscription",
    description1:
      "Your subscription cancellation will take effect immediately.",
    description2:
      "All upcoming orders will continue to be fulfilled, and you will still be able to use any remaining credits before they expire.",
  },
  PickupFinalizeOrder: {
    heading: "Finalise your order",
  },
  EditOrder: {
    heading: "Edit Order",
  },
  EditSummay: {
    heading: "Edit Summay",
  },
  SuccessfullyOrder: {
    heading: "Successfully Order",
  },
  OrderRating: {
    heading: "Review order",
    description: "Tell us how your order went!",
  },
  Notifications: {
    heading: "Notifications",
  },
  NotFound: {
    heading: "404 Opps. Page was not found ",
    disDesktop: "This page doesn’t exist or was removed!.",
    disMobile:
      "Browse our menu and save your favourites for quick access!(Placeholder)",
  },
  RestrictedPage: {
    heading: "This page is restricted ",
    discriptionDesktop: "This page is not allowed to access!.",
    discriptionMobile:
      "Browse our menu and save your favourites for quick access!(Placeholder)",
  },
  NoDataFound: {
    heading: "No data found",
    description: "Sorry, there's no coffee here...",
  },
  Opps: {
    heading: "OOPS!",
    description: "There's nothing to show here.",
  },
  CancleOrder: {
    heading: "has been cancelled.",
  },
  Message: {
    Success: {
      type: "Success",
      url: "./images/success.svg",
      // title: "Success",
      // description: "Your request has been submitted, an email will be sent to you shortly.",
    },
    Info: {
      type: "Info",
      url: "./images/Info.svg",
      // title: "Info",
      // description: "Your request has been submitted, an email will be sent to you shortly.",
    },
    Error: {
      type: "Error",
      url: "./images/error.svg",
      // title: "Issue/unsuccessful",
      // description: "Your request has not been submitted due to... ",
    },
  },
  CardBox: {
    staticDetails: {
      image: "images/Ellipse.svg",
      name: "Julien",
      subHeading: "Likes to try different coffee each day",
      product: {
        item1: "Latte",
        item2: "Extra Chocolate",
      },
      price: {
        amount1: 13,
        amount2: 12,
      },
      description:
        "Here’s how some coffee drinkers use their credits to create a routine that works for them.",
    },
  },
  SocialLogin: {
    // REACT_APP_FB_APP_ID: "400816795302662",
    REACT_APP_FB_APP_ID: "879466807113783",
    REACT_APP_GG_APP_ID:
      "204488953040-jk6qfejjpolon0avepm49rq4555mtdjs.apps.googleusercontent.com",
  },
};
export default StrConstant;
