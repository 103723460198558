import React from 'react';
import { withStyles } from '@mui/styles';
import { Styles } from './Style';
import Button from '@mui/material/Button';
const GrayBtn = (props) => {
    const { classes, name, clickHandler} = props
    return (
        <>
            <div className={classes.grayBtn}>
                <Button onClick={clickHandler}>{name}</Button>
            </div>
        </>
    )
}
export default withStyles(Styles)(GrayBtn);
