import { Styles } from "./Style";
import { Link } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Loader from "react-spinner-loader";
import { useNavigate } from "react-router";
import { useEffect, useMemo, useState } from "react";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import StrConstant from "../../../../Common/StrConstant";
import { ActionType } from "../../../../redux/ActionTypes";
import { modeLinkHandler } from "../../../../Common/utils/utils";
import BackArrow from "../../../../Components/BackArrow/BackArrow";
import YellowBtn from "../../../../Components/YellowBtn/YellowBtn";
import DarkGrayBtn from "../../../../Components/DarkGrayBtn/DarkGrayBtn";
import DrakGrayBtn from "../../../../Components/DarkGrayBtn/DarkGrayBtn";
import {
  cartCounterAction,
  getUserCart,
  getcartdetailsaction,
  removeCouponAction,
  removeProductFromCart,
} from "../../../../redux/actions/cart/cartActions";
import CustomModal from "../../../../Components/CustomModal/CustomModal";
import AlterMessage from "../../../../Components/AlertMessage/AlertMessage";
import { getOutlet } from "../../../../redux/actions/outlet/OutletActions";
const CartDetail = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart_id = localStorage.getItem("cart_id");
  const [showModel, setShowModel] = useState(false);
  const selected_modes = localStorage.getItem("selected_modes");
  const { reOrders } = useSelector((state) => state?.reOrders);
  // const { removeProduct } = useSelector((state) => state?.deleteProduct);
  const { loading, userCart } = useSelector((state) => state?.getUserCart);
  const [alertSnack, setAlertSnack] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  const [cartItemId, setCartItemId] = useState(null);
  const addProduct = useSelector((state) => state?.addProductToCart);
  const [addProducts, setaddproducts] = useState('')

  const cartIdentifier = localStorage.getItem("cart_id");
  const skip = localStorage.getItem("skip");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const getcarddet = useSelector(state => state?.getcarddetails)

  const linkClickHandler = () => {
    dispatch(
      { type: ActionType.CLEAR_RESPONSE },
      navigate(skip === "YES" ? "/select-product" : -1),
      localStorage.removeItem("skip")
    );
  };


  useEffect(() => {

    localStorage.removeItem("daysOpenB2B")
    localStorage.removeItem("daysOpen")
  }, [])

  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    dispatch(
      { type: ActionType.CLEAR_RESPONSE },
      localStorage.setItem("cartListSnack", false),
      setAlertSnack(false)
      // setIsOpen(false)
    );
  };
  useEffect(() => {
    if (!selected_modes) {
      localStorage.setItem("defaultMode", userInfo?.data?.user_data?.default_mode?.title)
      localStorage.setItem(
        "selected_modes",
        userInfo?.data?.user_data?.default_mode?.id
      );
    }
  }, [])
  const deleteHandler = (id) => {
    setShowModel(true);
    setCartItemId(id);
  };
  useEffect(() => {
    localStorage.removeItem("outletId");
  }, [])

  const confirmDeleteHandler = () => {
    cartItemId &&
      dispatch(
        removeProductFromCart(cartIdentifier, cartItemId)
        // ,setIsOpen(true)
      );
    userCart?.data?.coupon_used !== "" &&
      dispatch(
        removeCouponAction({
          cart_id,
          coupon_code: userCart?.data?.coupon_used,
        })
      );
    setShowModel(false);
  };

  useEffect(() => {
    if (localStorage.getItem("cartListSnack") !== "false") {
      if (addProduct?.productCart?.success) {

        setaddproducts(addProduct?.productCart?.message)
        setAlertSnack(true);
        dispatch({ type: ActionType.CLEARADDPRODUCT })
      }
    }
  }, [addProduct?.productCart?.success]);

  useEffect(() => {
    localStorage.setItem(
      "selected_outlet_id",
      userCart?.data?.outlet?.outlet_id
    );
    localStorage.setItem("address_id", userCart?.data?.address_id);
    dispatch(cartCounterAction(userCart?.data?.cart_items.length));
  }, [userCart]);

  useEffect(() => {
    if (reOrders?.success)
      dispatch(getUserCart(reOrders?.data?.cart_identifier));
  }, [reOrders?.data?.cart_identifier]);

  useEffect(() => {
    if (userCart?.data?.outlet_weekly_calendar?.days_open != undefined)
      localStorage.setItem(
        "daysOpen",
        JSON.stringify(userCart?.data?.outlet_weekly_calendar?.days_open)
      );
  }, [userCart]);

  useEffect(() => {
    if (userCart?.success) {
      if (selected_modes === "1") {
        localStorage.setItem("isToday", true);
      } else localStorage.setItem("isToday", false);
    }
  }, [userCart]);

  useEffect(() => {
    localStorage.removeItem("numberToVerify")
  }, [])
  useEffect(() => {
    dispatch(getUserCart());
    dispatch(getOutlet(localStorage.getItem("selected_outlet_id")));
    dispatch(
      { type: ActionType.CLEAR_CHECKOUT },
      localStorage.removeItem("is_subscribed_process"),
      localStorage.removeItem("insufficient")
    );
  }, []);

  const editHandler = (data) => {
    console.log("vikalp", data)
    dispatch(getcartdetailsaction(cartIdentifier, data))
  }

  useEffect(() => {

    if (getcarddet?.reOrders?.success) {
 
      localStorage.setItem('itemId',getcarddet?.reOrders?.data?.outlet_item_id)
      navigate('/item-modifier/id')
      dispatch({ type: ActionType.CLEAR_CART_DETAILS })
      window.location.reload()
    }
  }, [getcarddet])
useEffect(()=>{
dispatch({type:ActionType.CLEARUPDATEDPRODUCT})
},[])
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {/* {addProduct?.productCart?.success && (
        <AlterMessage
          closeHandler={snackHandler}
          description={addProducts}
          title="Success"
          type="Success"
          open={alertSnack}
          url="./images/success.svg"
        />
      )} */}
      {/* {removeProduct?.success && (
        <AlterMessage
          closeHandler={snackHandler}
          description={removeProduct?.message}
          title="Success"
          type="Success"
          open={isOpen}
          url="./images/success.svg"
        />
      )} */}

      <Container maxWidth="lg" className={classes.p0}>
        <div className={classes.outerBox}>
          <div>
            <BackArrow clickHandler={linkClickHandler} />
            <h3>{StrConstant.CartDetail.heading}</h3>
          </div>
          <div>
            {userCart?.data?.cart_items.length > 0 ? (
              <div className={classes.cardConteiner}>
                <div className={classes.listingOuter}>
                  <ul className={classes.listingBox}>
                    {userCart?.data?.cart_items?.map((data, index) => (
                      <li key={index}>
                        <div className={classes.cartItem}>
                          <div className={classes.cartListing}>
                            <div className={classes.count}>
                              <h5>{data?.item_quantity}</h5>
                            </div>
                            <div className={classes.cartRight}>
                              <div className={classes.dFlex}>
                                {/* <h5 className={classes.count}>
                            {data?.item_quantity}
                          </h5> */}
                                <div
                                  className={`${classes.lt} ${classes.countBox}`}
                                >
                                  <h5>
                                    {data?.item_name}{" "}
                                    {/* <span>
                                {data?.item_quantity > 1
                                  ? ` x ${data?.item_quantity}`
                                  : ""}
                              </span> */}
                                  </h5>
                                </div>
                                <div className={classes.rt}>
                                  <p>{data?.item_total} Credits</p>
                                </div>
                              </div>
                              <div className={classes.dFlex}>
                                <div className={classes.lt}>
                                  {data?.hide_size === 0 && (
                                    <p>{data?.item_size}</p>
                                  )}

                                  {data?.modifiers?.map((modiferData) => (
                                    <p key={Math.random()}>
                                      {/* {modiferData?.modifier_group}
                                {" - "} */}
                                      {modiferData?.modifier_name}
                                    </p>
                                  ))}
                                  <p>
                                    {data?.item_comment != ""
                                      ? '"' + data?.item_comment + '"'
                                      : ""}
                                  </p>
                                </div>
                                <div className={classes.rt}>
                                  <div className={classes.imgContent}>
                                    <div
                                      className={classes.imgBox}
                                      onClick={() =>
                                        editHandler(data?.cart_item_id)
                                      }
                                    >
                                      <img
                                        src="./images/edit.svg"
                                        alt="better Coffee"
                                      />
                                    </div>
                                    <div
                                      className={classes.imgBox}
                                      onClick={() =>
                                        deleteHandler(data?.cart_item_id)
                                      }
                                    >
                                      <img
                                        src="./images/trash.svg"
                                        alt="better Coffee"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                {userCart?.data && userCart?.data?.cart_items.length !== 0 ? (
                  <>
                    <div className={classes.needBox}>
                      <Link to="/select-product">
                        <img
                          src="./images/arrow-left.svg"
                          alt="Better Coffee"
                        />
                        Add Items
                      </Link>
                    </div>
                    <div className={classes.subTotal}>
                      <div className={classes.dFlex}>
                        <div className={classes.lt}>
                          <h5>Subtotal</h5>
                        </div>
                        <div className={classes.rt}>
                          <p>{userCart?.data?.grand_total} Credits</p>
                        </div>
                      </div>
                      {selected_modes === "3" && (
                        <div className={classes.dFlex}>
                          <div className={classes.lt}>
                            <p className={classes.text}>
                              Delivery fees will be calculated at the next step
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className={classes.bottomBox}>
                      {" "}
                      <YellowBtn
                        name={"NEXT"}
                        clickHandler={() => navigate("/order-review")}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            ) : (
              !loading && (
                <div className={classes.outerInner}>
                  <div>
                    <p className="margin-bottom-0">
                      Your cart is currently empty.
                    </p>
                    <p className="margin-bottom-0">
                      Add some delicious treats?
                    </p>
                  </div>
                  <div className={classes.btnContent}>
                    <YellowBtn
                      name={"PLACE NEW ORDER"}
                      clickHandler={() =>
                        // navigate(modeLinkHandler(selected_modes))
                        navigate("/choose-mode")
                      }
                    />
                    <DarkGrayBtn
                      name={"Return to my dashboard"}
                      clickHandler={() => navigate("/my-dashboard")}
                    />

                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </Container>

      <CustomModal open={showModel} title={"Are you sure you want to delete?"}>
        <div className={`${classes.box} ${classes.modalContent}`}>
          <div className={classes.modalFlex}>
            <DrakGrayBtn
              name="Cancel"
              clickHandler={() => setShowModel(false)}
            />
            <YellowBtn name={"Delete"} clickHandler={confirmDeleteHandler} />
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default withStyles(Styles)(CartDetail);
