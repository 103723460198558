export const Styles = Theme => ({
    btnContent: {
        margin:"10px 0 0",
        "& button": {
            background: "#fff",
            padding: "12px 15px",
            display: "flex",
            boxShadow: "0px 1px 1px 0px #0000002B",
            margin:"0px 0 0",
            borderRadius: "0",
            width:"100%",
            position: "relative",
            "&:hover":{
                background: "#fff",
            },
            "& img":{
                 textAlign:"left",
                 paddingRight:"10px",
                 position: "absolute",
                 left: "15px",
            },
            "& span":{
                color: "#3F4444",
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: "400",
                fontFamily: "'Noto Serif', serif",
            },
        }
    },
    m0:{
        marginBottom:"0 !important"
    }
})