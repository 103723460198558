import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import YellowBtn from "../YellowBtn/YellowBtn";
import { useDispatch, useSelector } from "react-redux";
import StrConstant from "../../Common/StrConstant";
import LogoContainer from "../LogoContainer/LogoContainer";
import React, { useEffect, useState } from "react";
import { resendEmailVerify } from "../../redux/actions/UserActions";
import Loader from "react-spinner-loader";
import AlterMessage from "../AlertMessage/AlertMessage";
import { CircularProgress } from "@mui/material";
import { CountdownTimer } from "../../Views/CountdownTimer";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { ActionType } from "../../redux/ActionTypes";
const ResendEmail = (props) => {
  const { classes } = props;
  const { email } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [isOpen, setIsOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const TWO_MIN_IN_MS = 120000;
  const NOW_IN_MS = new Date().getTime();
  const TimeAfterTowMins = NOW_IN_MS + TWO_MIN_IN_MS;
  const data = useSelector((state) => state?.resendEmailVerify);
  const { loading, error, success, userIn } = useSelector(
    (state) => state.userRegister
  );
  const [resendagain,setresendagain]=useState(false);
  const resendEmailHandle = () => {
    dispatch(resendEmailVerify(email));
    setDisable(true);
    setresendagain(true);
    setTimeout(() => setDisable(false), 120000);
  };
  // const snackHandler = (event, reason) => {
  //   if (reason === "clickaway") return;
  //   setIsOpen(false);
  //   dispatch({ type: ActionType.CLEAR_RESPONSE });
  // };
  // useEffect(() => {
  //   setIsOpen(true);
  // }, [success]);

  useEffect(() => {
    if (data?.error) return setIsVerified(true);
  }, [data]);

  useEffect(() => {
    dispatch({ type: ActionType.CLEAR_RESPONSE });
  }, []);
  console.log("isVerified",isVerified,data?.error)
  return (
    <>
      {data?.loading && (
        <Loader
          show={data?.loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {loading && (
        <Loader
          show={data?.loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {/* {data?.success && (
        <AlterMessage
          closeHandler={snackHandler}
          description={userIn?.message}
          title={"Success"}
          type={"Success"}
          open={isOpen}
          url={"./images/success.svg"}
        />
      )} */}
      {/* {data?.error && (
        <AlterMessage
          closeHandler={snackHandler}
          url={"./images/error.svg"}
          description={data?.error}
          title={"Error"}
          type={"Error"}
          open={isOpen}
        />
      )} */}
      {
        <CircularProgress
          variant="determinate"
          value={true}
          className={classes.circularProgress}
        />
      }
      <div className={classes.formOuter}>
        <div className={classes.formInner}>
          <div className={classes.topBox}>
            <div className={classes.back}>
              <Link to="/login">
                <img
                  src="./images/long-arrow-left.svg"
                  alt="Better Coffee"
                  onClick={() => dispatch({ type: ActionType.CLEAR_RESPONSE })}
                />
              </Link>
            </div>
            <LogoContainer
              heading={
                isVerified
                  ? StrConstant.AlreadyVerifiedEmail.heading
                  : StrConstant.Verify.heading
              }
              goUrl={""}
              urlTxt={""}
            />
            {/* <h2 className={classes.mb20}>Verify your email</h2> */}
            <p>
              {isVerified ? (
                <>Let's get your coffee fix.</>
              ) : (
                <>
                  A verification link has been sent to <b>{email}</b>. Click on
                  the verification link in your email and proceed from there.
                </>
              )}
            </p>
          </div>
          <div className={classes.btBox}>
            <div className={classes.btnTxt}>
              {isVerified ? (
                <YellowBtn
                  disable={false}
                  name={"Login"}
                  clickHandler={() => navigate("/login")}
                />
              ) : (
                <YellowBtn
                  disable={disable}
                  name={resendagain==true?"Resend Again":"Resend Link"}
                  clickHandler={resendEmailHandle}
                />
              )}
            </div>
            {!isVerified && disable ? (
              <CountdownTimer targetDate={TimeAfterTowMins} />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default withStyles(Styles)(ResendEmail);
