import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import StrConstant from "../../../Common/StrConstant";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import Outlets from "../../../Components/Outlets/Outlets";
import { lazy, Suspense, useEffect } from "react";
import { getOutletList } from "../../../redux/actions/outlet/OutletActions";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "../../../redux/ActionTypes";
const PickupToday = (props) => {
  const { classes, isVisible } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Loader = lazy(() => import("react-spinner-loader"));
  const selected_modes = localStorage.getItem("selected_modes");
  const { loading, outletList } = useSelector((state) => state?.outletList);
  useEffect(() => {
    dispatch({type:ActionType.GET_OUTLET_CATEGORY_SUCCESS})
    dispatch(getOutletList(selected_modes === "3" ? "delivery" : "pickup"));
  }, []);
  return (
    <>
      <Suspense>
        {loading && (
          <Loader
            show={loading}
            type="body"
            stack="vertical"
            message="Loading..."
          />
        )}
      </Suspense>
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <h3>{StrConstant.PickupToday.heading}</h3>
        </div>
        {outletList ? (
          <Outlets outletList={outletList} isVisible={isVisible} />
        ) : null}
      </OuterBox>
    </>
  );
};

export default withStyles(Styles)(PickupToday);
