import { ActionType } from "../ActionTypes";

export const userSigninReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_SIGNIN_REQUEST:
      return { ...state, loading: true };
    case ActionType.USER_SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        status: true,
      };
    case ActionType.USER_SIGNIN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionType.CLEAR_ERROR:
      return {};

    default:
      return state;
  }
};

export const resendEmailVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.RESEND_EMAIL_VERIFY_REQUEST:
      return { loading: true };
    case ActionType.RESEND_EMAIL_VERIFY_SUCCESS:
      return { loading: false, success: true, resendEmail: action.payload };
    case ActionType.RESEND_EMAIL_VERIFY_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_REGISTER_REQUEST:
      return { loading: true };
    case ActionType.USER_REGISTER_SUCCESS:
      return { loading: false, userIn: action.payload, success: true };
    case ActionType.USER_REGISTER_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_ERROR:
      return {};
      case ActionType.CLEAR_DATA_ERROR:
      return {};
    default:
      return state;
  }
};

export const userSignOutReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_LOGOUT_REQUEST:
      return { ...state, loading: true };
    case ActionType.USER_LOGOUT_SUCCESS:
      return { ...state, loading: false, success: true };
    case ActionType.USER_LOGOUT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userSetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_SET_PASSWORD_REQUEST:
      return { loading: true };
    case ActionType.USER_SET_PASSWORD_SUCCESS:
      return { loading: false, success: true, setpassMesg: action.payload };
    case ActionType.USER_SET_PASSWORD_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userChangePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_CHANGE_PASSWORD_REQUEST:
      return { loading: true };
    case ActionType.USER_CHANGE_PASSWORD_SUCCESS:
      return { loading: false, success: true, changePassword: action.payload };
    case ActionType.USER_CHANGE_PASSWORD_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case ActionType.USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, updateUser: action.payload };
    case ActionType.USER_UPDATE_PROFILE_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const userSendOtpReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.SEND_OTP_REQUEST:
      return { loading: true };
    case ActionType.SEND_OTP_SUCCESS:
      return { loading: false, success: true, sendOtp: action.payload };
    case ActionType.SEND_OTP_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const userVerifyOtpReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.VERIFY_OTP_REQUEST:
      return { loading: true };
    case ActionType.VERIFY_OTP_SUCCESS:
      return { loading: false, success: true, verifyOtp: action.payload };
    case ActionType.VERIFY_OTP_FAILURE:
      return { loading: false, error: action.payload };
    case "CLEAR_OTP":
      return {};
    default:
      return state;
  }
};

export const saveDataReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.SAVE_DATA_REQUEST:
      return { loading: true };
    case ActionType.SAVE_DATA_SUCCESS:
      return { loading: false, success: true, savedData: action.payload };
    case ActionType.SAVE_DATA_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const VerifyEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.VERIFY_EMAIL_REQUEST:
      return { loading: true };
    case ActionType.VERIFY_EMAIL_SUCCESS:
      return { loading: false, success: true, verifyEmail: action.payload };
    case ActionType.VERIFY_EMAIL_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const emailVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.EMAIL_VERIFY_REQUEST:
      return { loading: true };
    case ActionType.EMAIL_VERIFY_SUCCESS:
      return { loading: false, success: true, emailVerify: action.payload };
    case ActionType.EMAIL_VERIFY_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const forgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_FORGOT_PASSWORD_REQUEST:
      return { loading: true };
    case ActionType.USER_FORGOT_PASSWORD_SUCCESS:
      return { loading: false, success: true, forgotPassword: action.payload };
    case ActionType.USER_FORGOT_PASSWORD_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const getUserNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_NOTIFICATIONS_REQUEST:
      return { loading: true };
    case ActionType.USER_NOTIFICATIONS_SUCCESS:
      return {
        loading: false,
        success: true,
        notificationList: action.payload,
      };
    case ActionType.USER_NOTIFICATIONS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userSocialLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_SOCIAL_LOGIN_REQUEST:
      return { ...state, loading: true };
    case ActionType.USER_SOCIAL_LOGIN_SUCCESS:
      return { ...state, loading: false, userInfo: action.payload };
    case ActionType.USER_SOCIAL_LOGIN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userSpendPolicyReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.SPEND_POLICY_REQUEST:
      return { ...state, loading: true };
    case ActionType.SPEND_POLICY_SUCCESS:
      return { ...state, loading: false, spendPolicy: action.payload };
    case ActionType.SPEND_POLICY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userResendOtpReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.RESEND_OTP_VERIFY_REQUEST:
      return { ...state, loading: true };
    case ActionType.RESEND_OTP_VERIFY_SUCCESS:
      return { ...state, loading: false, resendOtp: action.payload };
    case ActionType.RESEND_OTP_VERIFY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// for multiple
export const readNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.READ_NOTIFICATIONS_REQUEST:
      return { loading: true };
    case ActionType.READ_NOTIFICATIONS_SUCCESS:
      return {
        loading: false,
        success: true,
        markAllNotification: action.payload,
      };
    case ActionType.READ_NOTIFICATIONS_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};
// for  single
export const readNotificationReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.READ_NOTIFICATION_REQUEST:
      return { loading: true };
    case ActionType.READ_NOTIFICATION_SUCCESS:
      return {
        loading: false,
        success: true,
        singleNotification: action.payload,
      };
    case ActionType.READ_NOTIFICATION_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const getUserProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_USER_PROFILE_REQUEST:
      return { loading: true };
    case ActionType.GET_USER_PROFILE_SUCCESS:
      return { loading: false, success: true, userProfile: action.payload };
    case ActionType.GET_USER_PROFILE_FAILURE:
      return { loading: false, error: action.payload };
    case "USE_LOGGED_OUT":
      return {};
    default:
      return state;
  }
};
