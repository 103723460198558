export const Styles = (Theme) => ({
  mb20: {
    marginBottom: "20px",
  },
  formOuter: {
    "@media (max-width: 768px)": {
      height: "100%",
      display: "table",
      width: "100%",
      background: "#F7F7F7",
    },
  },
  formInner: {
    background: "rgba(255, 255, 255, 0.75)",
    borderRadius: "12px",
    padding: "2rem",
    width: "100%",
    height: "auto",
    margin: "0 auto",
    display: "block",
    boxSizing: "border-box",
    overflowY: "auto",
    maxHeight: "calc(100vh - 100px)",
    "@media (max-width: 768px)": {
        background: "#F7F7F7",
        maxHeight: "100%",
        overflowY: "auto",
        borderRadius: "0",
        padding: "2rem 1.5rem",
    },
    "@media (max-width: 575px)": {
        padding: "2rem 1rem",
    },
    "&::-webkit-scrollbar": {
        appearance: "none",
        width: "0",
    },
    "&::-moz-scrollbar": {
      appearance: "none",
      width: "0",
  },
  "&::-o-scrollbar": {
      appearance: "none",
      width: "0",
  },
  "&::-ms-scrollbar": {
      appearance: "none",
      width: "0",
  },
    "& h2": {
        marginTop: "0",
    },
},
  fromControl: {
    margin: "0 0 25px",
  },
  textCheckbox: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    "& p": {
        padding: "0",
        marginBottom: "0",
        color: "#3F4444",
        fontSize: "13px",
        lineHeight: "16px",
        fontWeight: "400",
        fontFamily: "'Noto Serif', serif",
        cursor:"pointer",
    },
    "& span": {
        "& :nth-child(2)": {
            background: "#fff",
        }
    },
    "& a": {
      fontWeight: "700",
      padding: "0 2px",
      fontSize: "13px",
      lineHeight: "16px",
      color: "#3F4444",
    },
},
  labelTxt: {
    display: "block",
  },
  passLabel: {
    display: "flex",
  },
  continueTxt: {
    position: "relative",
    margin: "0 0 30px",
    "& p": {
      color: "#3F4444",
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: "400",
      margin: "0 0",
      fontFamily: "'Noto Serif', serif",
      textAlign: "center",
      "&:before": {
        content: "''",
        height: "2",
        width: "30%",
        background: "#3F4444BF",
        display: "table",
        left: "0",
        position: "absolute",
        top: "5",
      },
      "&:after": {
        content: "''",
        height: "2",
        width: "30%",
        background: "#3F4444BF",
        display: "table",
        right: "0",
        position: "absolute",
        top: "5",
      },
    },
  },
  dFlex: {
    display: "flex",
    "& :nth-child(2)": {
      marginLeft: "20px",
    },
  },
  wid50: {
    width: "50%",
  },
  btnTxt: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "30px 0 0",
    "& > div": {
        maxWidth: "100%",
    },
    "& button": {
        margin: "0",
    }
},
  haveTxt: {
    display: "flex",
    justifyContent: "center",
    padding: "40px 0",
    "& p": {
      color: "#3F4444",
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: "400",
      margin: "0",
      fontFamily: "'Noto Serif', serif",
      paddingRight: "5px",
    },
    "& a": {
      color: "#3F4444",
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: "700",
      fontFamily: "'Noto Serif', serif",
      textDecoration: "underline",
    },
  },
  passwordField: {
    position: "relative",
    "& input": {
      paddingRight: "40px",
    },
    "& span": {
      position: "absolute",
      right: "10px",
      top: "50%",
      transform: "translate(-50%,-50%)",
      "& svg": {
        height: "20px",
        width: "18px",
        color: "#3F444480",
        cursor: "pointer",
      },
    },
  },
});
