import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import OrderStatus from "../../../Components/OrderStatus/OrderStatus";
import FormLabel from "@mui/material/FormLabel";
import StrConstant from "../../../Common/StrConstant";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../../../redux/actions/UserActions";
import Loader from "react-spinner-loader";
import { ActionType } from "../../../redux/ActionTypes";
// import { modeLinkHandler } from "../../../Common/utils/utils";
const fields = {
  email: "",
  password: "",
};
const ChangePassword = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, loading } = userSignin;
  const [formFields, setFormFields] = useState(fields);
  // const setDefaultMode = localStorage.getItem("selected_modes");

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setFormFields((pre) => ({ ...pre, [name]: value }));
  };
  const changePasswordHandler = () => {
    dispatch(signin(formFields.email, formFields.password));
    if (userInfo) navigate(`/change-password`);
  };
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <h3>{StrConstant.ChangePassword.heading}</h3>
        </div>
        <div>
          <div className={classes.fromControl}>
            <FormLabel>Email </FormLabel>
            <input
              type="email"
              value={formFields.email}
              name="email"
              onChange={(e) => inputHandler(e)}
              placeholder="Enter  Email"
            />
          </div>
          <div className={classes.fromControl}>
            <FormLabel>Password</FormLabel>
            <input
              type="password"
              value={formFields.password}
              name="password"
              onChange={(e) => inputHandler(e)}
              placeholder="Enter Password"
            />
          </div>
          <OrderStatus
            //  link={modeLinkHandler(setDefaultMode)}
            link={"/choose-mode"}
            text={"Place order"}
          />
          <div className={classes.btnBox}>
            <YellowBtn
              name={"change password"}
              clickHandler={changePasswordHandler}
            />
          </div>
        </div>
      </OuterBox>
    </>
  );
};
export default withStyles(Styles)(ChangePassword);
