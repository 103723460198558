import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import StepperCustom from "../../../Components/StepperCustom/StepperCustom";
import NoExpandedBox from "../../../Components/NoExpandedBox/NoExpandedBox";
import CardPayment from "../../../Components/CardPayment/CardPayment";
import StrConstant from "../../../Common/StrConstant";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardsList,
  purchaseCredits,
} from "../../../redux/actions/credit/CreditActions";
import { lazy, Suspense, useEffect, useState } from "react";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import CommonSection from "../../../Components/CommonSection/CommonSection";
import { ActionType } from "../../../redux/ActionTypes";
import moment from "moment";

const PurchaseCredits = (props) => {
  const { classes } = props;
  const selectedPlan = localStorage.getItem("selectedPlan");
  const AlterMessage = lazy(() =>
    import("../../../Components/AlertMessage/AlertMessage")
  );
  const Loader = lazy(() => import("react-spinner-loader"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const plansData = useSelector((state) => state?.planDetail);
  const { cards } = useSelector((state) => state?.cardList);
  const data = useSelector((state) => state?.purchaseCredits);
  const [isOpen, setIsOpen] = useState(false);
  const [card_id, setCard_id] = useState(null);
  const [validation, setValidation] = useState(false);
  const currentDate = moment(Date.now());
  const selectionHandler = (selection) => setCard_id(selection);
  const isplanfree = localStorage.getItem("isplanfree")
  const submitHandler = () => {
    if (card_id && cards?.data !== []) {
      setIsOpen(true);
      const payload = {
        card_id,
        stripe_plan_id:
          plansData?.planDetail?.data?.plans[selectedPlan]?.stripe_plan_id,
      };
      dispatch(
        purchaseCredits(payload),
        localStorage.setItem("is_subscribed_process", true)
      );
    } else {
      setValidation(true);
    }
  };
  console.log("selectedPlan", selectedPlan)
  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    setIsOpen(false);
    setValidation(false);
  };
  useEffect(() => {
    dispatch(getCardsList());
  }, []);

  useEffect(() => {
    if (data?.success) {
      dispatch({ type: ActionType.CLEAR_RESPONSE });
      return navigate("/purchase-complete");
    }
  }, [data]);
  return (
    <>
      <Suspense>
        {data?.loading && (
          <Loader
            show={data?.loading}
            type="body"
            stack="vertical"
            message="Loading..."
          />
        )}
        {/* {data?.messages?.success && isOpen && (
          <AlterMessage
            closeHandler={snackHandler}
            description={data?.messages?.success}
            title={"Success"}
            type={"Success"}
            open={isOpen}
            url={"./images/success.svg"}
          />
        )} */}
        {data?.error && isOpen && (
          <AlterMessage
            closeHandler={snackHandler}
            description={data?.error}
            title={"Error"}
            type={"Error"}
            open={isOpen}
            url={"./images/error.svg"}
          />
        )}
        {validation && (
          <AlterMessage
            description={"Please add a card"}
            closeHandler={snackHandler}
            url={"./images/error.svg"}
            title={"Error"}
            type={"Error"}
            open={validation}
          />
        )}
      </Suspense>
      <CommonSection>
        <div className={classes.outerInner}>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <StepperCustom step={2} />
          <div className={classes.lt}>
            <h3>{StrConstant.PurchaseCredits.heading}</h3>
            <NoExpandedBox index={selectedPlan} />
          </div>
          <div className={classes.heading}>
            <h3>{StrConstant.PurchaseCredits.secondHeading}</h3>
            <Link to={`/add-card`} className={classes.addItems}>
              <img src="./images/plus.svg" alt="Better Coffee" />
              <span
                onClick={() => dispatch({ type: ActionType.CLEAR_RESPONSE })}
              >
                Add payment method
              </span>
            </Link>
          </div>
          <div className={classes.boxContainer}>
            <div className={classes.ltBox}>
              {cards ? (
                cards?.data?.cards ? (
                  <CardPayment
                    cardList={cards?.data?.cards}
                    onSelection={selectionHandler}
                  />
                ) : null
              ) : null}
            </div>
            <div className={classes.totalBox}>

              <div className={classes.totalBoxInner}>
                <div >
                  <small>
                    {isplanfree == 1 && "Enjoy your first month free!"}
                  </small>
                </div>
                <div style={{ display: "flex" }}>

                  <div className={classes.lt}>
                    {/* <br /> */}
                    <h4>
                      Total
                      <span>:</span>
                    </h4>
                  </div>
                  {cards?.data !== []
                    ? plansData?.planDetail?.data?.plans.map((list, i) =>
                      i == selectedPlan ? (
                        <div key={i} className={classes.rt}>
                         {isplanfree==1 ?<>
                         <span style={{display:"flex"}}>

                          <strike>
                          <h4> 
                          S${list.amount}
                          
                          </h4>
                          </strike>
                          <h4>&nbsp; S$0</h4>
                         </span>
                         </>:
                         <h4> S${list.amount}</h4>
                         } 
                        </div>
                      ) : null
                    )
                    : null}
                </div>
              </div>
              {/* {validation && <p className="errorMessage">Please add a card</p>} */}
            </div>
          </div>
        </div>
        <div>

          <div className={classes.desContent}>
            <small>
              *This is a recurring plan and your next billing date is{" "}
              {currentDate.add(1, "months").format("D MMM YYYY")}. Please cancel
              before {currentDate.subtract(1, "days").format("D MMM YYYY")} to
              avoid getting charged.
            </small>
          </div>
          <div className={classes.btnBox}>
            {/* <YellowBtn name={"Confirm and Pay"} clickHandler={submitHandler} /> */}
            <YellowBtn name={"Confirm"} clickHandler={submitHandler} />
          </div>
        </div>
      </CommonSection>
    </>
  );
};
export default withStyles(Styles)(PurchaseCredits);
