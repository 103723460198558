export const Styles = (theme) => ({
    alertSuccess: {
        "& > div > div": {
            backgroundColor: "#fff",
            margin: "0 10px",
            boxShadow: "0px 5px 10px 5px rgba(0, 0, 0, 0.05)",
            borderLeft: "4px solid #3CB44B",
            borderRadius: "5px",
            padding: "0 10px",
        },
    },
    alertInfo: {
        "& > div > div": {
            backgroundColor: "#fff",
            margin: "0 10px",
            boxShadow: "0px 5px 10px 5px rgba(0, 0, 0, 0.05)",
            borderLeft: "4px solid #3699FF",
            borderRadius: "5px",
            padding: "0 10px",
        },
    },
    alertUnSuccess: {
        "& > div > div": {
            backgroundColor: "#fff",
            margin: "0 10px",
            boxShadow: "0px 5px 10px 5px rgba(0, 0, 0, 0.05)",
            borderLeft: "4px solid #FF4C25",
            borderRadius: "5px",
            padding: "0 10px",
        },
    },
    alertInner: {
        overflow: "hidden",
        "& > div": {
            "&:nth-child(1)": {
                display: "none",
            },
        },
        "& svg": {
            color: "#3F4444",
            height: "15px",
            width: "15px",
        },
    },
    alertBox: {
        display: "flex",
        alignItems: "center",
        minWidth: "300px",
        "@media(max-width:768px)": {
            minWidth: "auto",
        },
        "& button": {
            padding: "5px !important",
            minWidth: "auto !important",
            position: "absolute",
            right: "20px",
            top: "12px",
            height: "15px",
            width: "15px",
            "& svg": {
                color: "#000",
                height: "20px",
            }
        }
    },
    imgContent: {
        maxHeight: "30px",
        maxWidth: "30px",
        objectFit: "cover",
        width: "10%",
        "& img": {
            height: "auto",
            width: "auto",
        },
    },
    txtContent: {
        padding: "0 5px",
        width: "100%",
        minWidth: "200px !important",
        maxWidth: "500px",
        "& h3": {
            color: "#3F4444",
            fontSize: "15px",
            lineHeight: "15px",
            fontWeight: "500",
            fontFamily: "'Bebas Neue', serif",
            margin: "5px 0",
            textAlign: "left !important",
        },
        "& p": {
            color: "#3F444480",
            fontSize: "13px",
            lineHeight: "15px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            margin: "5px 0 0",
            // textTransform: "lowercase",
            textAlign: "left !important",
            "&::first-letter": {
                textTransform: "capitalize",
            },
            "@media(max-width:640px)": {
                fontSize: "12px",
                lineHeight: "14px",
            },
        },
    },
})