export const Styles = (Theme) => ({
  pdLt: {
    marginLeft: "20px",
    "@media (max-width: 640px)": {
      marginLeft: "0",
    },
  },
  noWrap: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "@media (max-width: 640px)": {
      display: "block",
    },
  },
  flexDirectionChange: {
    "& ul": {
      display: "none",
      "@media (max-width: 480px)": {
        display: "block",
      },
    },
  },
  cardOuter: {
    margin: "0",
    "& li": {
      width: "33.33%",
      display: "inline-block",
      paddingRight: "20px",
      "&:nth-child(3n+3)": {
        paddingRight: "0",
      },
      "@media(max-width:375px)": {
        width: "100%",
        paddingRight: "0",
        display: "block",
      },
    },
  },
  card: {
    background: "#FFFFFF",
    borderRadius: "10px",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    width: "100%",
    justifyContent: "space-between",
    minHeight: "150px",
  },
  cardInner: {
    width:"90%",
    "& button": {
      margin: "10px 0 0",
    },
    "& p": {
      marginBottom: "0",
    },
  },
  ltBox: {
    width: "90%",
    "@media(max-width:640px)": {
      width: "80%",
    },
    "& h4": {
      marginBottom: "10px",
    },
    "& p": {
      marginBottom: "0",
    },
  },
  rtBox: {
    width: "10%",
    paddingLeft: "10px",
    textAlign: "right",
    "@media(max-width:640px)": {
      width: "20%",
    }
  },
  btnContent: {
    "& > div ": {
      float: "left",
      width: "auto",
      "& button": {
        margin: "10px 0 0",
      }
    },
  },
  fontWeightBold: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#3F4444",
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
  }
});
