import * as React from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Style } from "@mui/icons-material";
import Propover from "../Propover/Propover";
import StrConstant from "../../Common/StrConstant";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyWalletData } from "../../redux/actions/myWallet/MyWalletActions";
import {
  convertCycleDDMMYYYY,
  convertDDMMYYYY,
} from "../../Common/utils/utils";
const WalletCardSubscription = (props) => {
  const { classes, data } = props;
  const dispatch = useDispatch();
  const { myWallet } = useSelector((state) => state?.getMyWallet);
  // console.log("myWallet", myWallet);
  useEffect(() => {
    dispatch(getMyWalletData());
  }, []);
  console.log("wallet",myWallet?.data?.active_subscription?.plan_name)
  return (
    <>
      <div className={classes.accordionContent}>
        <Accordion className={classes.box}>
          <AccordionSummary
            expandIcon={<img src="./images/arrow-close.svg" />}
            className={Style.accordionHeading}
          >
            <div className={classes.topBox}>
              <h4>My Subscription</h4>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {myWallet?.data?.active_subscription?.plan_name ? (
              <div className={classes.listing}>
                {/* <div className={classes.dateBox}>
                  {myWallet?.data?.active_subscription?.current_period_end && (
                    <p>
                      Expiring On{" "}
                      {myWallet?.data?.active_subscription
                        ?.current_period_end != ""
                        ? convertDDMMYYYY(
                            myWallet?.data?.active_subscription?.current_period_end.split(
                              " "
                            )[0]
                          )
                        : ""}
                    </p>
                  )}
                </div> */}
                <ul>
                  <li>
                    <div className={classes.lt}>
                      <h5>{myWallet?.data?.active_subscription?.plan_name}</h5>
                    </div>
                    <div className={classes.rt}>
                      <p>
                        {myWallet?.data?.active_subscription?.credits} Credits
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className={classes.lt}>
                      <h5>Amount Payable</h5>
                    </div>
                    <div className={classes.rt}>
                      <p>S${myWallet?.data?.active_subscription?.amount}/mth</p>
                    </div>
                  </li>
                  <li>
                    <div className={classes.lt}>
                      <h5>Current Cycle</h5>
                    </div>
                    <div className={classes.rt}>
                      <p>{`${
                        myWallet?.data?.active_subscription
                          ?.current_period_start != ""
                          ? convertCycleDDMMYYYY(
                              myWallet?.data?.active_subscription
                                ?.current_period_start
                            )
                          : ""
                      } - ${
                        myWallet?.data?.active_subscription
                          ?.current_period_end != ""
                          ? convertDDMMYYYY(
                              myWallet?.data?.active_subscription
                                ?.current_period_end
                            )
                          : ""
                      }`}</p>
                    </div>
                  </li>
                  <li>
                    <div className={classes.lt}>
                      <h5>Next Payment</h5>
                    </div>
                    <div className={classes.rt}>
                      <p>
                        {myWallet?.data?.active_subscription
                          ?.current_period_end != ""
                          ? convertDDMMYYYY(
                              myWallet?.data?.active_subscription
                                ?.current_period_end
                            )
                          : ""}
                      </p>
                    </div>
                  </li>
                </ul>
                <Link to="/manage-subscription">Manage subscription</Link>
              </div>
            ) : (
              <>
           
              <p>You do not have an active subscription</p>
              <Link to="/choose-plan" >Choose your plan</Link>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
export default withStyles(Styles)(WalletCardSubscription);
