import { ActionType } from "../../ActionTypes";
import serverURL from "../../../APIHandler/serverURL";
import callAPI from "../../../APIHandler/callAPI";
import { saveData } from "../UserActions";
import { reloadHandler } from "../../../Common/utils/utils";

export const getProductDetail =
  (outlet_item_id, outlet_id) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_OUTLET_ITEM_DETAILS_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        serverURL.getProductDetail.concat(
          `${outlet_id}?item=${outlet_item_id}`
        ),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({
        type: ActionType.GET_OUTLET_ITEM_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      return dispatch({
        type: ActionType.GET_OUTLET_ITEM_DETAILS_FAILURE,
        payload: message,
      });
    }
  };
export const getProductList =
  (outlet_id, pageUrl, search, category, page = 1) =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_PRODUCT_LIST_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        pageUrl === "/menu"
          ? serverURL.getProductList.concat(
              `${
                outlet_id === "undefined" ? 0 : 0
              }?search_by=${search}&category_id=${
                category === 0 || category === "0" ? "" : category
              }&page=${page}`
            )
          : pageUrl === "/select-product"
          ? serverURL.getUserOutlet.concat(
              `${
                outlet_id === "undefined" ? 0 : outlet_id
              }?search_by=${search}&category_id=${
                category === 0 || category === "0" ? "" : category
              }&page=${page}`
            )
          : null,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({ type: ActionType.GET_PRODUCT_LIST_SUCCESS, payload: data });
      // pageUrl !== "/menu" && dispatch(getFavoritesListAction(outlet_id));
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      return dispatch({
        type: ActionType.GET_PRODUCT_LIST_FAILURE,
        payload: message,
      });
    }
  };
export const getFavoritesListAction =
  (outletID, page, search) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_FAVORITES_LIST_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        serverURL.getFavoritesList.concat(
          `/${outletID}?page=${page ? page : "1"}/${search}`
        ),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({ type: ActionType.GET_FAVORITES_LIST_SUCCESS, payload: data });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      return dispatch({
        type: ActionType.GET_FAVORITES_LIST_FAILURE,
        payload: message,
      });
    }
  };

export const toggleFavoritesAction =
  (outlet_item_id) => async (dispatch, getState) => {
    // console.log("🚀 ~ cartId", outlet_item_id);
    dispatch({
      type: ActionType.TOGGLE_FAVORITES_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    // const selectedOutlet = localStorage.getItem("selected_outlet_id")
    //   ? localStorage.getItem("selected_outlet_id")
    //   : 0;
    // const currentPage = localStorage.getItem("currentPage");
    try {
      const { data } = await callAPI.postRequest(
        serverURL.toggleFavorites,
        { outlet_item_id: outlet_item_id },
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({ type: ActionType.TOGGLE_FAVORITES_SUCCESS, payload: data });
      // dispatch(getProductList(selectedOutlet, currentPage, "", ""));
      dispatch(saveData("itemId", outlet_item_id));
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      return dispatch({
        type: ActionType.TOGGLE_FAVORITES_FAILURE,
        payload: message,
      });
    }
  };
