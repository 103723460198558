import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import Loader from "react-spinner-loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StrConstant from "../../../Common/StrConstant";
import { ActionType } from "../../../redux/ActionTypes";
import { useLocation, useNavigate } from "react-router";
import StepperCustom from "../../../Components/StepperCustom/StepperCustom";
import DarkGrayBtn from "../../../Components/DarkGrayBtn/DarkGrayBtn";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import CommonSection from "../../../Components/CommonSection/CommonSection";
import { useImpactCard } from "../../../Hooks/useImpactCard";
import {
  finalizeOrderCheckout,
  getUserCart,
} from "../../../redux/actions/cart/cartActions";
import { useInsufficient } from "../../../Hooks/useInsufficient";
import GrayBtn from "../../../Components/GrayBtn/GrayBtn";
const PurchaseComplete = (props) => {
  const { classes } = props;
  // const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const impactCard = useImpactCard();
  const { userCart } = useSelector((state) => state?.getUserCart);
  const { error } = useSelector((state) => state?.finalizeOrderCheckout);
  //* should pass (current balance from cart,amount from total payable)
 const insufficient=localStorage.getItem('insufficient')
  const nextTo = useInsufficient(
    userCart?.data?.wallet_balance,
    localStorage.getItem("totalPayable")
  );
  const [loader, setLoader] = useState(false);
  // const setDefaultMode = localStorage.getItem("selected_modes");
  // const { validate, error } = useSelector((state) => state?.validateCheckout);
  const { checkoutOrder } = useSelector(
    (state) => state?.finalizeOrderCheckout
  );
  const payload = JSON.parse(localStorage.getItem("checkoutData"));

  const functionOne = () => {
    localStorage.removeItem("credit");
    navigate(localStorage.getItem("insufficient") === "true"?"/cart-detail":'/my-dashboard');
  };

  const orderHandler = () => {
    nextTo === true
      ? dispatch(finalizeOrderCheckout(payload))
      : navigate(nextTo);
  };

  useEffect(() => {
    if (checkoutOrder?.success) {
      setLoader(true);
      navigate("/order-confirm");
      localStorage.removeItem("checkoutData");
      localStorage.removeItem("is_subscribed_process");
    }
  }, [checkoutOrder]);

  useEffect(() => {
    if (error === "Customer's Mobile number not verified.")
      navigate("/edit-phone-number");
  }, [error]);

  // useEffect(() => {
  //   if (validate?.success)
  //     dispatch(
  //       finalizeOrderCheckout(localStorage.getItem("checkoutData")),
  //       setLoader(true),
  //       localStorage.removeItem("insufficient"),
  //       localStorage.removeItem("checkoutData")
  //     );
  // }, [validate]);

  useEffect(() => {
    dispatch(
      getUserCart(
        localStorage.getItem("cart_id") ? localStorage.getItem("cart_id") : ""
      )
    );
    localStorage.removeItem("selectedPlan");
  }, []);
  // console.log(location?.state?.pageState);
  return (
    <>
      <Loader show={loader} type="body" stack="vertical" message="Loading..." />
      <CommonSection>
        <div className={classes.outerInner}>
          <div className={classes.box}>
            <BackArrow
              clickHandler={() =>
                dispatch(
                  { type: ActionType.CLEAR_RESPONSE },
                  localStorage.removeItem("credit"),
                  navigate("/my-dashboard")
                )
              }
            />
            <StepperCustom step={3} />
            <div className={classes.description}>
              <h3>
                {localStorage.getItem("insufficient") === "true"
                  ? "CREDIT PURCHASE SUCCESS"
                  : null}
                {/* {localStorage.getItem("insufficient") === "true"
                  ? "top-up completed "
                  : null} */}
                {localStorage.getItem("is_subscribed_process")
                  ? "CREDIT SUBSCRIPTION SUCCESS"
                  : null}
                {localStorage.getItem("is_subscribed_process") ||
                  localStorage.getItem("insufficient")
                  ? null
                  : "CREDIT PURCHASE SUCCESS"}
              </h3>
              <span>
                {localStorage.getItem("insufficient") === "true"
                  ? "Please click submit to confirm your order."
                  : null}
                {localStorage.getItem("is_subscribed_process")
                  ? "Place an order and start getting caffeinated!"
                  : null}
                {localStorage.getItem("is_subscribed_process") ||
                  localStorage.getItem("insufficient")
                  ? null
                  : StrConstant.PurchaseComplete.description}
              </span>
            </div>
            <div>
              {impactCard.length === 0 ? null : (
                <div className={classes.imageContent}>
                  <img
                    src={
                      impactCard[Math.floor(Math.random() * impactCard.length)]
                    }
                    alt="Better cloud"
                  />
                </div>
              )}
            </div>
            {/* {location?.state === null ? ( */}
            <div className={`${classes.btndFlex} ${classes.mobilepurchase}`}>
              <DarkGrayBtn
                name={localStorage.getItem("insufficient") === "true"?"Return to cart":"Return to my dashboard"}
                clickHandler={functionOne}
              />
              <YellowBtn
                name={
                  localStorage.getItem("insufficient") === "true"
                    ? "Submit Order"
                    : "Place Order"
                }
                clickHandler={orderHandler}
              />
            </div>
            {/* ) : null} */}
          </div>
        </div>
      </CommonSection>
    </>
  );
};

export default withStyles(Styles)(PurchaseComplete);
