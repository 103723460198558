export const Styles = Theme => ({
    modalContent: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        minWidth: "380px",
        "@media(max-width:640px)": {
            minWidth: "350px",
        },
        "@media(max-width:375px)": {
            minWidth: "300px",
        },
    },
    modalInner: {
        maxWidth: "400",
        width: "100%",
        background: "#fff",
        padding: "30px",
        borderRadius: "10px",
        display: "table",
        width: "100%",
        "@media(max-width:640px)": {
            padding: "20px",
        },
        "& button": {
            margin: "0 !important",
        },
    },
    title: {
        width: "100%",
        "& h3": {
            fontSize: "30px",
            lineHeight: "30px",
            marginBottom: "10px",
            "@media(max-width:640px)": {
                fontSize: "25px",
                lineHeight: "25px",
            }
        },
        "& p": {
            "@media(max-width:640px)": {
                fontSize: "14px",
                lineHeight: "19px",
            }
        }
    },
})