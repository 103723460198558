import { Styles } from "./Style";
import Box from "@mui/material/Box";
import Navbar from "../Navbar/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ScheduleDeliveryDropDown from "../ScheduleDeliveryDropDown/ScheduleDeliveryDropDown";
import { Button } from "@mui/material";
import { ActionType } from "../../redux/ActionTypes";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import { useBreakpoint } from "../../Hooks/useBreakpoints";
import { getMyWalletData } from "../../redux/actions/myWallet/MyWalletActions";
const Header = (props) => {
  const location = useLocation();

  const { classes, isVisible = false, page = null } = props;
  const device = useBreakpoint();
  const dispatch = useDispatch();
  const selected_modes = localStorage.getItem("selected_modes");
  const cartCounter = useSelector((state) => state?.cartCounter);
  const notificationCounter = useSelector(
    (state) => state?.notificationCounter
  );
  const { myWallet } = useSelector((state) => state?.getMyWallet);
  const [state, setState] = useState({
    right: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    
    if (window.history.length == 1) {
      sessionStorage.setItem("status", "active");
    }

    // if (localStorage.getItem("isRemember") === "true") {
    // } else if (
    //   localStorage.getItem("isRemember") === "false" &&
    //   localStorage.getItem("expiry") &&
    //   new Date(localStorage.getItem("expiry")) < new Date()
    // ) {
    //   localStorage.clear();
    //   dispatch({ type: ActionType.CLEAR_ERROR });
    //   navigate("/login");
    // }

    if (localStorage.getItem("isRemember") === "true") {
    } else if (
      localStorage.getItem("isRemember") === "false" &&
      sessionStorage.getItem("status") !== "active"
    ) {
      localStorage.clear();
      dispatch({ type: ActionType.CLEAR_ERROR });
     
      navigate("/login");
    }
  });
  useEffect(()=>{
    localStorage.setItem("TotalCredit",myWallet?.data?.wallet_balance)
      },[myWallet])
useEffect(()=>{
  dispatch(getMyWalletData());
},[])
  const toggleDrawer = (anchor, open) => (event) => {
    localStorage.removeItem("addressId");
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      className={classes.sidebarBox}
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 375 }}
      role="presentation"
    >
      <Navbar
        cancelHandler={toggleDrawer(anchor, false)}
        isAnimation={state?.right}
        page={page}
      />
    </Box>
  );
  // useEffect(() => {
  //   dispatch({ type: ActionType.CLEAR_RESPONSE });
  // });
  const url=window.location.href
  useEffect(()=>{
    if(url?.indexOf("profile")>0||url?.indexOf("my-account")>0||url?.indexOf("edit-order")>0||url?.indexOf("view-details")>0){
      localStorage.setItem("styleColordate",true)
    }else{
      localStorage.setItem("styleColordate",false)
    }
  })

  return (
    <div className={classes.headerOuter}>
      <Container maxWidth="lg">
        <div className={classes.headerBox}>
          {device !== "sm" ? (
            <div className={classes.logoContainer}>
              <div className={classes.logoBox}>
                <Link
                  to="/my-dashboard"
                  onClick={() => localStorage.removeItem("addressId")}
                >
                  <img src="./images/logo-header.png" alt="Better Coffee" />
                </Link>
              </div>
              {isVisible &&
                (selected_modes === "1" ||
                  selected_modes === "2" ||
                  selected_modes === "3") && <ScheduleDeliveryDropDown />}
            </div>
          ) : (
            isVisible &&
            (selected_modes === "1" ||
              selected_modes === "2" ||
              selected_modes === "3") && <ScheduleDeliveryDropDown />
          )}

          {process.env.REACT_APP_STAGING_MODE === "true" ? (
            <Chip label="Staging" variant="" className={classes.staging} />
          ) : null}
          <div className={classes.iconBox}>
            <Button>
              <Link
                to="/notifications"
                onClick={() => localStorage.removeItem("addressId")}
                className={`${classes.iconImgContent} ${ location.pathname === "/notifications"  && classes.CurrentItem}`}
              >
                <img src="./images/bell.svg" alt="Better Coffee" />
                {notificationCounter === 0 ? null : (
                  <span className={classes.count}>{notificationCounter}</span>
                )}
              </Link>
            </Button>
            <Button>
              <Link
                to="/cart-detail"
                onClick={() => localStorage.removeItem("addressId")}
                className={`${classes.iconImgContent} ${ location.pathname === "/cart-detail"  && classes.CurrentItem}`}

              >
                <img src="./images/shopping-cart.svg" alt="Better Coffee" />
                {cartCounter === 0 ? null : (
                  <span className={classes.count}>{cartCounter}</span>
                )}
              </Link>
            </Button>
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Button>
                  <Link
                    to=""
                    onClick={toggleDrawer(anchor, true)}
                    className={classes.iconImgContent}
                  >
                    {<img src="./images/toggle.svg" alt="better coffee" />}
                    {/* {<MenuIcon />} */}
                  </Link>
                </Button>
                <SwipeableDrawer
                  anchor={anchor}
                  open={state[anchor]}
                  transitionDuration={800}
                  onClose={toggleDrawer(anchor, false)}
                  onOpen={toggleDrawer(anchor, true)}
                >
                  {list(anchor)}
                </SwipeableDrawer>
              </React.Fragment>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};
export default withStyles(Styles)(Header);
