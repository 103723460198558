export const Styles = Theme => ({
    box: {
        width: "100%",
    },
    needTxt: {
        display: "flex",
        alignItems: "center",
        "& a": {
            padding: "0 2px",
        },
        "& img": {
            paddingLeft: "2px",
        },
        "& p": {
            color: "#3F4444",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "700",
        },
    },
    btxt: {
        display: "flex",
        justifyContent: "flex-start",
        padding: "10px 0 0",
        "@media(max-width:640px)": {
            padding: "10px 0 0",
            justifyContent: "center",
            textAlign:"center",
        },
        "& a": {
            // paddingLeft: "5px",
            display:"initial",
            
        },
    },
    dFlex: {
        display: "flex",
        alignItems: "center",
        margin: "10px 0 10px",
        "& img": {
            marginLeft: "5px",
        },
        "& label":{
            marginBottom:"0",
        }
    },
    inputBox: {
        marginBottom: "20px",
        width: "75%",
        "@media(max-width:991px)": {
            width: "70%",
        },
        "@media(max-width:640px)": {
            width: "100%",
        },
        "& input": {
            paddingRight: "100px",
        },
        "& span": {
            position: "absolute",
            right: "25px",
            top: '50%',
            transform: 'translate(0, -50%)',
        }
    },
    inputContent: {
        position: 'relative',
        "& span":{
            color: "#3F444480",
        }
    },
    boxContent: {
        display: "flex",
        alignItems: "flex-start",
        "@media(max-width:640px)": {
            display: "block",
        },
        "& button": {
            margin: "0",
            "@media(max-width:640px)": {
                margin: "auto",
            },
        }
    },
    btnBox: {
        width: "25%",
        paddingLeft: "10px",
        "@media(max-width:991px)": {
            width: "30%",
        },
        "@media(max-width:640px)": {
            width: "100%",
            paddingLeft: "0",
            marginTop:"4rem",
        },
    },
    modalFlex: {
        display: "flex",
        "& :nth-child(2)": {
          marginLeft: "15px",
        },
      },
      dFlex: {
        display: "flex",
        width: "100%",
    },
    ltPanel: {
        width: "60%",
        paddingRight: "10px",
        "& h4": {
            marginBottom: "0",
        }
    },
    rtPanel: {
        width: "40%",
        "& p": {
            marginBottom: "0",
            textAlign: "right",
        }
    },
})