export const Styles = (Theme) => ({
  p0: {
    padding: "0 20px !important",
    "@media(max-width:640px)": {
      padding: "0 0 !important",
    }
  },
  outerInner: {
    margin: "auto",
    display: "flex",
    width: "100%",
    alignItems: "center",
    height: "calc(100vh - 75px)",
    "@media(max-width:991px)": {
      display: "table",
      height: "100%",
    },
  },
  description: {
    width: "30%",
    marginRight: "10px",
    "@media(max-width:991px)": {
      textAlign: "left",
      padding: "0px 20px",
      width: "100%",
    },
    "@media(max-width:640px)": {
      padding: "0px 16px",
    },
    "& button": {
      margin: "20px 0 15px",
      "@media(max-width:991px)": {
        margin: "20px 0",
      },
    },
  },
  contentBox: {
    width: "70%",
    height: "auto",
    background: "#fff",
    maxHeight: "calc(100vh - 150px)",
    overflowY: "auto",
    padding: "40px 20px 40px 30px",
    borderRadius: "10px",
    "@media(max-width:991px)": {
      width: "100%",
      maxHeight: "100%",
      overflowY: "inherit",
      boxShadow: "none",
      // padding: "20px 20px 30px 30px",
    },
    "@media(max-width:640px)": {
      padding: "0px 0px 30px",
      background: "transparent !important",
    },
    "&::-webkit-scrollbar": {
      appearance: "none",
    },
    "&::-moz-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-o-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-ms-scrollbar": {
      appearance: "none",
      width: "0",
    },
  },
  maxWidth: {
    // maxWidth: "991px",
    // margin: "auto",
    // "@media(max-width:991px)": {
    //     maxWidth: "none",
    // },
  },
  pLt5: {
    paddingLeft: "5px",
    display: "inline-block",
    lineHeight: "18px",
    
  },
  paraNotWrap: {
    display: "flex",
    alignItems: "flex-start",
    "& p":{
      lineHeight:"15px",
    },
    "& h6":{
      whiteSpace: "nowrap",
    }
  },
  fontWeight700: {
    fontWeight: "700",
    color: "#3F4444",
  },
  dFlex: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    "@media(max-width:640px)": {
      padding: "0 16px",
    },
  },
  labelBox: {
    background: "#F1EEDB",
    padding: "15px",
    "& h4": {
      margin: "0",
    },
  },
  listBox: {
    "@media(max-width:640px)": {
      padding: "0 16px",
    },
    "& ul": {
      marginBottom: "0",
      marginTop: "18px",
      padding:'0 15px',
      "@media(max-width:640px)": {
        padding: "0 0",
      },
    },
    "& li": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
  },
  lt: {
    width: "30%",
    "@media(max-width:640px)": {
      width: "40%",
    },
    "& h6":{
      lineHeight:"18px",
    },
    "& p":{
      lineHeight:"16px",
    }
  },
  rt: {
    width: "70%",
    paddingLeft: "15px",
    textAlign: "right",
    "@media(max-width:640px)": {
      width: "60%",
    },
    "& p":{
      lineHeight:"16px",
      marginBottom:"15px",
    }
  },
  number:{
    minWidth:'25px',
    paddingRight: '5px',
   },
  leftBox:{
    width: "70%",
    "@media(max-width:640px)": {
      width: "60%",
    },
    "& h6":{
      lineHeight:"18px",
    },
    "& p":{
      lineHeight:"16px",
      paddingLeft:"25px",
    }
  },
  rightBx: {
    width: "30%",
    paddingLeft: "15px",
    textAlign: "right",
    "@media(max-width:640px)": {
      width: "40%",
    },
    "& p":{
      lineHeight:"16px",
      marginBottom:"15px",
    }
  },
  ltBox:{
    display: "flex",
  },
  totalBox: {
    // padding: "15px 15px 0",
    padding: "15px 0 0",
    borderTop: "1px solid #000",
    "@media(max-width:640px)": {
      padding: "15px 0 0",
    },
  },
  btnFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "@media(max-width:640px)": {
      width: "100%",
      padding: "0 16px 0px",
      flexDirection: "column-reverse",
      justifyContent: "center",
    },
    "& > div": {
      margin: "0 0 0 15px",
      "@media(max-width:640px)": {
        margin: "0",
      },
      "& button": {
        marginTop: "0",
      },
      "@media(max-width:640px)": {
        margin: "auto",
      },
    },
    "& a": {
      "@media(max-width:640px)": {
        margin: "10px 0 20px",
      },
    },
  },
  btTxt: {
    padding: "0 30px",
    "@media(max-width:991px)": {
      width: "100%",
      padding: "0px 20px",
    },
    "@media(max-width:640px)": {
      padding: "0px 16px 15px",
    },
    "& p": {
      margin: "0 0 15px 0",
      "& a": {
        display: "initial",
      },
    },
  },
  modalFlex: {
    display: "flex",
    "& div:nth-child(2)": {
      marginLeft: "15px",
    },
  },
  btnContent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media(max-width:640px)": {
      flexDirection: "column-reverse",
    },
  },
  Bottom: {
    paddingBottom: "20px",
  },
});
