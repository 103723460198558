export const Styles = Theme => ({
    grayBtn: {
        maxWidth: "250px",
        width: "100%",
        margin:"0",
        float:"right",
        "@media(max-width:640px)":{
            maxWidth: "100%",
            float:"unset",
            margin:"auto",
        },
        "& button": {
            padding: "15px 24px",
            background: "rgba(63, 68, 68, 0.1)",
            color: "#3F444480",
            fontSize: "20px",
            lineHeight: "20px",
            fontWeight: "400",
            fontFamily: "'Bebas Neue', serif",
            margin: "20px 0 0px",
            letterSpacing: "0",
            whiteSpace: "nowrap",
            borderRadius: "32px",
            width: "100%",
            transition: "inherit",
            "@media(max-width:640px)":{
                padding: "12px 20px",
                margin: "0px 0 15px",
            },
            "&:hover": {
                color: "#3F444480",
                background: "rgba(63, 68, 68, 0.1)",
            },
        },
    }
})
