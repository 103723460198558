import { combineReducers } from "redux";
import {
  userSigninReducer,
  resendEmailVerifyReducer,
  userRegisterReducer,
  userSignOutReducer,
  userSetPasswordReducer,
  userChangePasswordReducer,
  userUpdateProfileReducer,
  userSendOtpReducer,
  userVerifyOtpReducer,
  saveDataReducer,
  VerifyEmailReducer,
  emailVerifyReducer,
  forgotPasswordReducer,
  getUserNotificationsReducer,
  userSocialLoginReducer,
  userSpendPolicyReducer,
  userResendOtpReducer,
  readNotificationReducer, // for single
  readNotificationsReducer, // for multiple
  getUserProfileReducer,
} from "./reducers/UserReducers";
import {
  dashboardReducer,
  configurationReducer,
} from "../redux/reducers/dashboard/DashboardReducers";
import {
  planListReducer,
  getBillingHistoryReducer,
  changePlanReducer,
  cancelSubscriptionReducer,
} from "../redux/reducers/planList/PlanListReducers";
import {
  addCardReducer,
  cardsListReducer,
  deleteCardReducer,
  getOneTimeCreditsReducer,
  purchaseCreditsReducer,
  creditTransactionsReducer,
  cardsDefaultReducer,
} from "./reducers/credit/CreditReducers";
import {
  getUserAddressListReducer,
  addUserAddressReducer,
  updateUserAddressReducer,
  deleteUserAddressReducer,
  setDefaultUserAddressReducer,
  deliveryFeeCalculateReducer,
  checkPostalCodeReducer,
} from "./reducers/userAddress/UserAddressReducers";
import {
  modesListReducer,
  setModesReducer,
} from "./reducers/chooseModes/chooseModesReducers";
import {
  outletListReducer,
  outletCategoryReducer,
  getOutletReducer,
  getOutletidReducer
} from "./reducers/outlet/OutletReducers";

import {
  getProductListReducer,
  getProductDetailReducer,
  getFavoritesListReducer,
  toggleFavoritesReducer,
} from "../redux/reducers/product/ProductReducers";
import {
  addProductToCartReducer,
  getUserCartReducer,
  removeProductFromCart,
  checkCouponReducer,
  applyCouponReducer,
  finalizeOrderCheckoutReducer,
  finalizeOrderVerifyReducer,
  futureScheduleReducer,
  removeCouponReducer,
  validateCheckoutReducer,
  cartCounterReducer,
  notificationCounterReducer,
  reOrdersReducer,
  clearCartReducer,
  getcartdetailsReducer,
  updatedProductToCartReducer,
} from "./reducers/cart/cartReducers";
import {
  viewOrdersReducer,
  viewOrderDetailReducer,
  editOrderReducer,
  cancelOrderReducer,
  viewRecurringOrderReducer,
  viewOrderHistoryReducer,
  orderRatingReducer,
  ordernumberReducer,
} from "./reducers/order/orderReducers";
import { getMyWalletReducer } from "./reducers/myWallet/MyWalletReducers";
import  addOutletitemslice  from "./addOutletitem";
const rootReducer = combineReducers({
  userSignin: userSigninReducer,
  resendEmailVerify: resendEmailVerifyReducer,
  userRegister: userRegisterReducer,
  userLogout: userSignOutReducer,
  dashboardDetail: dashboardReducer,
  planDetail: planListReducer,
  setPassword: userSetPasswordReducer,
  changePassword: userChangePasswordReducer,
  updateProfile: userUpdateProfileReducer,
  addCard: addCardReducer,
  cardList: cardsListReducer,
  deleteCard: deleteCardReducer,
  getOneTimeCredits: getOneTimeCreditsReducer,
  modesList: modesListReducer,
  setModes: setModesReducer,
  userAddressList: getUserAddressListReducer,
  addAddress: addUserAddressReducer,
  purchaseCredits: purchaseCreditsReducer,
  updateUserAddress: updateUserAddressReducer,
  deleteUserAddress: deleteUserAddressReducer,
  setDefaultUserAddress: setDefaultUserAddressReducer,
  outletList: outletListReducer,
  getProductList: getProductListReducer,
  getProductDetail: getProductDetailReducer,
  getOutletCategory: outletCategoryReducer,
  getOutletid:getOutletidReducer,
  addProductToCart: addProductToCartReducer,
  updatedProductToCart:updatedProductToCartReducer,
  getUserCart: getUserCartReducer,
  finalizeOrderCheckout: finalizeOrderCheckoutReducer,
  sendOtp: userSendOtpReducer,
  verifyOtp: userVerifyOtpReducer,
  saveData: saveDataReducer,
  deleteProduct: removeProductFromCart,
  viewOrder: viewOrdersReducer,
  checkCoupon: checkCouponReducer,
  applyCoupon: applyCouponReducer,
  verifyEmail: VerifyEmailReducer,
  emailVerify: emailVerifyReducer,
  forgotPassword: forgotPasswordReducer,
  getMyWallet: getMyWalletReducer,
  calculateDeliveryFee: deliveryFeeCalculateReducer,
  notificationList: getUserNotificationsReducer,
  configuration: configurationReducer,
  finalizeOrderVerify: finalizeOrderVerifyReducer,
  getOrderDetail: viewOrderDetailReducer,
  editOrder: editOrderReducer,
  userSocialLogin: userSocialLoginReducer,
  spendPolicy: userSpendPolicyReducer,
  favoritesList: getFavoritesListReducer,
  favoritesProductList: toggleFavoritesReducer,
  checkPostalCode: checkPostalCodeReducer,
  billingHistory: getBillingHistoryReducer,
  creditTransaction: creditTransactionsReducer,
  resendOtp: userResendOtpReducer,
  getOutlet: getOutletReducer,
  futureSchedule: futureScheduleReducer,
  changePlan: changePlanReducer,
  cancelOrder: cancelOrderReducer,
  readNotifications: readNotificationsReducer, // for multiple
  readNotification: readNotificationReducer, // for single
  getUserProfile: getUserProfileReducer,
  cancelPlan: cancelSubscriptionReducer,
  cardsDefault: cardsDefaultReducer,
  viewRecurringOrder: viewRecurringOrderReducer,
  viewOrderHistory: viewOrderHistoryReducer,
  removeCoupon: removeCouponReducer,
  validateCheckout: validateCheckoutReducer,
  cartCounter: cartCounterReducer,
  notificationCounter: notificationCounterReducer,
  orderRating: orderRatingReducer,
  ordernumber: ordernumberReducer,
  reOrders: reOrdersReducer,
  clearCart: clearCartReducer,
  outletitemid:addOutletitemslice,
  getcarddetails:getcartdetailsReducer,
});
export default rootReducer;
