import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import FormLabel from "@mui/material/FormLabel";
import StepperCustom from "../../../Components/StepperCustom/StepperCustom";
import StrConstant from "../../../Common/StrConstant";
import CommonSection from "../../../Components/CommonSection/CommonSection";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { ActionType } from "../../../redux/ActionTypes";
import { useDispatch } from "react-redux";
const OneOffGetOneTimeCredits = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <CommonSection>
        <div className={classes.box}>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <div className={classes.contentBox}>
            <StepperCustom />
            <div className={classes.description}>
              <h3>{StrConstant.OneOffGetOneTimeCredits.heading}</h3>
              <p>{StrConstant.OneOffGetOneTimeCredits.description}</p>
            </div>
            {/* <div className={classes.btnBox}>
              <YellowBtn name={"Proceed to checkout"} />
            </div> */}
            <div className={classes.txtBox}>
              <span>Need a </span>
              <Link to="/menu">peek into the menu</Link>{" "}
              <span>to decide on quantity? </span>
              <Link to="/menu">
                {" "}
                <img src="./images/arrowRight.svg" alt="Better Coffee" />
              </Link>
            </div>
            <div className={classes.boxContent}>
              <div className={classes.inputBox}>
                <FormLabel>Number of credits to purchase</FormLabel>
                <div className={classes.inputContent}>
                  <input type="" name="" placeholder="Enter card number" />
                  <span>= S$ 20.00</span>
                </div>
              </div>
              <div className={classes.btnBox}>
                <YellowBtn name={"Proceed to checkout"} />
              </div>
            </div>
            {/* <FormControlLabel className={classes.textCheckbox} control={<CustomCheckbox />} label="Make this a gift" />
                <div className={classes.fromControl}>
                    <FormLabel>Recipient email address*</FormLabel>
                    <input type="" name="" placeholder="Enter email address" />
                </div>
                <div className={classes.fromControl}>
                    <FormLabel>message for recipient</FormLabel>
                    <textarea aria-label="minimum height" rows={5} placeholder="Enter message for recipient"></textarea>
                </div>*/}
          </div>
        </div>
      </CommonSection>
    </>
  );
};

export default withStyles(Styles)(OneOffGetOneTimeCredits);
