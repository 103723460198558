import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
const ViewMore = (props) => {
    const { classes, name, clickHandler } = props;
    return (
        <>
        <div className={classes.viewMore}>
                <Button onClick={clickHandler}>{name}</Button>
            </div> 
        </>
    )
}
export default withStyles(Styles)(ViewMore);