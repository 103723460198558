export const Styles = (Theme) => ({
  outer: {
    width: "100%",
    height: "auto",
    "@media(max-width:640px)": {
      margin: "0 0 !important",
      padding: "0 0 !important",
    },
  },
  p20: {
    "@media(max-width:640px)": {
      padding: "0 16px",
    },
  },
  btText: {
    display: "flex",
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "16px",
    },
  },
  outerBox: {
    height: "calc(100vh - 120px)",
    "@media(max-width:640px)": {
        height: "calc(100vh - 150px)",
    },
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  contentBox: {
    width: "100%",
  },
  billingTable: {
    width: "100%",
    marginBottom: "20px",
    "& thead": {
      "& > tr": {
        background: "#F1EEDB",
      },
    },
    "& span": {
      color: "#DF3F51",
      lineHeight: "18px",
      fontSize: "16px",
      display: "block",
      "@media(max-width:640px)": {
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
  },
  btText: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    padding: "20px 0 20px",
    "@media(max-width:640px)": {
      padding: "10px 10px",
      display: "block",
      textAlign: "left",
      padding: "20px 16px 20px",
    },
    "& p": {
      marginBottom: "0",
      paddingRight: "5px",
    },
  },
  rightTxt: {
    "@media(max-width:640px)": {
      textAlign: "right !important",
    },
  },
});
