import { ActionType } from "../../ActionTypes";
import serverURL from "../../../APIHandler/serverURL";
import callAPI from "../../../APIHandler/callAPI";
import { reloadHandler } from "../../../Common/utils/utils";
export const getAddressDetailList = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GET_ADDRESS_DETAIL_LIST_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.getRequest(serverURL.getAddressList, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Origin": "http://localhost:3000",
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });
    return dispatch({
      type: ActionType.GET_ADDRESS_DETAIL_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.GET_ADDRESS_DETAIL_LIST_FAILURE,
      payload: message,
    });
  }
};

export const addAddressDetail = (address) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.ADD_ADDRESS_DETAIL_REQUEST,
    payload: address,
  });
  dispatch({type:ActionType.CLEAR_RESPONSE})
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(serverURL.addAddress, address, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });

    dispatch({ type: ActionType.ADD_ADDRESS_DETAIL_SUCCESS, payload: data });
    dispatch(getAddressDetailList());
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.ADD_ADDRESS_DETAIL_FAILURE, payload: message });
  }
};

export const deleteAddressDetail = (id) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.DELETE_ADDRESS_DETAIL_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.deleteRequest(
      serverURL.deleteAddress.concat(id),
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.DELETE_ADDRESS_DETAIL_SUCCESS, payload: data });
    dispatch(getAddressDetailList());
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.DELETE_ADDRESS_DETAIL_FAILURE,
      payload: message,
    });
  }
};

export const updateAddressDetail =
  (id, payload) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.UPDATE_ADDRESS_DETAIL_REQUEST,
      payload,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.patchRequest(
        serverURL.updateAddress.concat(id),
        payload,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({
        type: ActionType.UPDATE_ADDRESS_DETAIL_SUCCESS,
        payload: data,
      });
      dispatch(getAddressDetailList());
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.UPDATE_ADDRESS_DETAIL_FAILURE,
        payload: message,
      });
    }
  };

export const setDefaultAddressDetail = (id) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.SET_DEFAULT_ADDRESS_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.getRequest(
      serverURL.setDefaultAddress.concat(id),
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({
      type: ActionType.SET_DEFAULT_ADDRESS_SUCCESS,
      payload: data,
    });
    dispatch(getAddressDetailList());
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.SET_DEFAULT_ADDRESS_FAILURE,
      payload: message,
    });
  }
};

export const calculateDeliveryFee =
  (addressId) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.DELIVERY_FEE_CALCULATE_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.postRequest(
        serverURL.calDeliveryFee,
        addressId,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({
        type: ActionType.DELIVERY_FEE_CALCULATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.DELIVERY_FEE_CALCULATE_FAILURE,
        payload: message,
      });
    }
  };

export const checkPostalCodeAction =
  (payload) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.CHECK_POSTAL_CODE_REQUEST,
      payload,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.postRequest(
        serverURL.checkPostalCode,
        payload,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({ type: ActionType.CHECK_POSTAL_CODE_SUCCESS, payload: data });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.CHECK_POSTAL_CODE_FAILURE,
        payload: message,
      });
    }
  };
