import * as moment from "moment";
import CryptoJS from "crypto-js";
import constant from "../Constant";

export const encryptValue = (normalString) => {
  var key = CryptoJS.enc.Utf8.parse(
    constant.encKey.substring(constant.encKey.length - 16)
  );
  var iv = CryptoJS.enc.Utf8.parse(
    constant.encKey.substring(constant.encKey.length - 16)
  );
  var encrypted = CryptoJS.AES.encrypt(normalString, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

export const decryptValue = (normalString) => {
  var key = CryptoJS.enc.Utf8.parse(
    constant.encKey.substring(constant.encKey.length - 16)
  );
  var iv = CryptoJS.enc.Utf8.parse(
    constant.encKey.substring(constant.encKey.length - 16)
  );
  // Decrypt
  var bytes = CryptoJS.AES.decrypt(normalString.toString(), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText.toString();
};

export const convertDDMMYYY = (response) => {
  const yourDate = new Date(response)?.toString();
  const dmyDate = moment(yourDate)?.format("DD MMM YYYY hh:mm A");
  return dmyDate;
};

export const convertDDMMYYYY = (response) => {
  // console.log("yourDate", response);
  // const yourDate = new Date(response).toString();
  const dmyDate = moment(response)?.format("DD MMM YYYY");
  return dmyDate;
};

export const convertCycleDDMMYYYY = (response) => {
  // const yourDate = new Date(response).toString();
  const dmyDate = moment(response)?.format("DD MMM");
  return dmyDate;
};

export const debounce = (func, wait, immediate) => {
  // console.log("debounced");
  let timeout;
  return function executedFunction() {
    let context = this;
    let args = arguments;
    let later = () => {
      timeout = null;
      if (!immediate) func?.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func?.apply(context, args);
  };
};

export const serverDateFormat = (date) => {
  const dd = String(date?.getDate())?.padStart(2, "0");
  const mm = String(date?.getMonth() + 1)?.padStart(2, "0"); //January is 0!
  const yyyy = date?.getFullYear();
  date = dd + "/" + mm + "/" + yyyy;
  return date;
};

export const modeLinkHandler = (setDefaultMode) => {
  if (setDefaultMode == 1) return "/pick-up-today";
  else if (setDefaultMode == 2) return "/schedule-pick-up";
  else if (setDefaultMode == 3) return "/schedule-delivery";
  else return "/choose-mode";
};

export const supplyDateFormat = (date) => {
  const dd = String(date?.getDate())?.padStart(2, "0");
  const mm = String(date?.getMonth() + 1)?.padStart(2, "0"); //January is 0!
  const yyyy = date?.getFullYear();
  date = yyyy + "-" + mm + "-" + dd;
  return date;
};

export const reloadHandler = (access) => {
  // console.log("access", access);
  if (access === 401) {
    localStorage.removeItem("userInfo");
    window.location.reload();
  }
};

export const onPopState = (handler) => {
  window.onpopstate = handler;
};

export const displayAmountFormat=(value)=>{
  return typeof value =="number" ? (value?.toString()?.includes(".0")?value?.toString()?.split(".")[0]:value):(value?.includes(".0")?value?.split(".")[0]:value)

  }
