import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuth } from "../Hooks/useAuth";
import Header from "../Components/Header/Header";
import { Navigate, useLocation } from "react-router-dom";
const PrivateRoutes = ({ children, isVisible }) => {
  const location = useLocation();
  const orderId = localStorage.getItem("orderId");
  const { loggedIn, company_employee, customer } = useAuth();
  const { userInfo } = useSelector((state) => state.userSignin);
  const restrictArray = [
    "/choose-plan",
    "/purchase-credits",
    "/manage-payment-methods",
  ];
  const canExist = [
    "/my-orders",
    `/edit-order/${orderId}`,
    `/view-details/${orderId}`,
  ];

  useMemo(() => {
    if (loggedIn) {
      if (location.pathname === "/sign-up")
        return <Navigate to="/login" replace state={{ from: location }} />;
    }
    if (company_employee) {
      if (restrictArray?.indexOf(location.pathname) >= 0)
        return <Navigate to="/restricted" replace state={{ from: location }} />;
    }
    if (canExist?.indexOf(location.pathname) >= 4) {
      localStorage.removeItem("outletId");
    }
  }, [loggedIn]);

  location?.pathname === "/choose-mode" &&
    localStorage.setItem("chooseInit", location?.pathname);

  return loggedIn ? (
    <>
      <div className="headerOuter">
        <Header isVisible={isVisible} />
      </div>
      <div className="paddingTop">{children}</div>
    </>
  ) : (
    <Navigate
      to={
        customer
          ? userInfo?.data?.user_data?.login_count === 1
            ? "/choose-plan"
            : "/"
          : "/login"
      }
      replace
      state={{ from: location }}
    />
  );
};

export default PrivateRoutes;
