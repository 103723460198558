export const Styles = Theme => ({
    mLt: {
        marginLeft: "20px",
        "@media (max-width: 640px)": {
            marginLeft: "0",
        }
    },
    mb0: {
        marginBottom: "0",
    },
    mt20:{
        marginTop:"20px",
        "@media (max-width: 640px)": {
            marginTop:"0px",
        }
    },
    noWrap: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        "@media (max-width: 640px)": {
            display: "block",
        }
    },
    flexDirectionChange: {
        "& ul": {
            display: "none",
            // "@media (max-width: 480px)": {
            //     display:"block",
            // }
        },
    },
    cardOuter: {
        margin: "0",
        "& li": {
            width: "33.33%",
            display: "inline-block",
            paddingRight: "20px",
            "&:nth-child(3n+3)": {
                paddingRight: "0",
            },
            "@media(max-width:375px)": {
                width: "100%",
                paddingRight: "0",
                display: "block",
            }
        }
    },
    card: {
        background: "#FFFFFF",
        borderRadius: "10px",
        padding: "20px",
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
        width: "100%",
        justifyContent: "space-between",
        minHeight: "150px",
        maxHeight: "150px",
    },
    cardInner: {
        "& button": {
            margin: "10px 0 0",
        },
        "& p": {
            marginBottom: "0",
        }
    },
    ltBox: {
        width: "90%",
        "& h4": {
            marginBottom: "10px",
        },
        "& p": {
            marginBottom: "0",
        }
    },
    rtBox: {
        width: "10%",
        paddingLeft: "10px",
        textAlign: "right",
    },
    dFlex: {
        display: "flex",
        alignItems: "flex-start",
        // justifyContent: "space-between",
    },
    fontWeightBold: {
        fontSize: "16px",
        fontWeight: "700",
        color: "#3F4444",
    },
    viewMenu: {
        position: "relative",
        "& h4": {
            marginBottom: "0",
        }
    },
    myAccount: {
        "& h4": {
            marginBottom: "0",
        }
    },
    bannerImg: {
        height: "250px",
        width: "100%",
        objectFit: "cover",
        overflow: "hidden",
        borderRadius: "15px",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        }
    },
    btnContent: {
        display: "flex",
        flexDirection: "flex-end"
    }
})

