import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import FormLabel from "@mui/material/FormLabel";
import { useNavigate } from "react-router";
import StrConstant from "../../../../Common/StrConstant";
import OuterBox from "../../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../../Components/BackArrow/BackArrow";
import YellowBtn from "../../../../Components/YellowBtn/YellowBtn";
import { ActionType } from "../../../../redux/ActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Loader from "react-spinner-loader";
import AlertMessage from "../../../../Components/AlertMessage/AlertMessage";
import { userUpdateProfile } from "../../../../redux/actions/UserActions";
import { validatePhone } from "../../../../Common/Validations";
const EditPhoneNumber = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inValid, setInValid] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [phone_number, setPhone_number] = useState("");
  const { userInfo } = useSelector((state) => state?.userSignin);
  const { loading, updateUser, error } = useSelector(
    (state) => state?.updateProfile
  );
  const userData = userInfo?.data?.user_data;
  useEffect(() => {
    if (updateUser?.success) navigate("/verify-phone-number-otp");
  }, [updateUser?.message]);

  useEffect(()=>{
    dispatch({ type: ActionType.CLEAR_RESPONSE });
  },[])
  const handleButtonClick = () => {
    if (validatePhone(phone_number)) return setInValid(true);
    else
      return dispatch(
        userUpdateProfile({ phone_number }, userData?.user_id),
        localStorage.setItem("numberToVerify", phone_number),
        setIsOpen(true)
      );
  };
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {error && (
        <AlertMessage
          closeHandler={() =>
            dispatch({ type: ActionType.CLEAR_RESPONSE }, setIsOpen(false))
          }
          url={"./images/error.svg"}
          description={error}
          title={"Error"}
          type={"Error"}
          open={isOpen}
        />
      )}
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch(
                { type: ActionType.CLEAR_CHECKOUT },
                { type: ActionType.CLEAR_RESPONSE },
                navigate(-1)
              )
            }
          />
          <h3>{StrConstant.EditPhoneNumber.heading}</h3>
        </div>
        <div>
          <p>{StrConstant.EditPhoneNumber.description}</p>
          <div className={classes.boxFlex}>
            <div className={classes.fromControl}>
              <FormLabel>Contact number</FormLabel>
              <div className={classes.numberField}>
                <input
                  type="tel"
                  name="contact"
                  value={phone_number}
                  placeholder="Enter your contact number"
                  onChange={(event) => setPhone_number(event.target.value)}
                  onInput={(event) => {
                    if (event?.target?.value != "") {
                      event.target.value = Math.max(
                        0,
                        parseInt(event.target.value)
                      )
                        .toString()
                        .slice(0, 8);
                    }
                  }}
                />
                {inValid && phone_number === "" ? (
                  <p className="errorMessage">This field is required.</p>
                ) : (
                  <div></div>
                )}
                {phone_number !== "" && validatePhone(phone_number) ? (
                  <p className="errorMessage">
                    Information entered is invalid. Please try again.{" "}
                  </p>
                ) : null}
              </div>
            </div>
            <div className={classes.btnBox}>
              <YellowBtn
                name={"NEXT"}
                clickHandler={() => {
                  handleButtonClick();
                }}
              />
            </div>
          </div>
        </div>
      </OuterBox>
    </>
  );
};
export default withStyles(Styles)(EditPhoneNumber);
