import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  background: "#3F44441A",
  "@media(max-width:640px)": {
    background: "#fff",
  },
  // 'input:hover ~ &': {
  //   backgroundColor: theme.palette.mode === '#ffc200' ? '#30404d' : '#ebf1f5',
  // },
  "input:disabled ~ &": {
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#ffc200 !important",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: "url(./images/check.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#ffc200",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}
const CustomCheckbox = (props) => {
  const { isDefaultSelected = false, onChange } = props;
  const [isChecked, setIsChecked] = useState(isDefaultSelected);
  const handleOnChange = () => {
    setIsChecked((pre) => !pre);
    // console.log(`checked: ${!isChecked}`);
    onChange(!isChecked, props?.name);
  };
  return <BpCheckbox checked={isChecked} onChange={handleOnChange} />;
};
export default CustomCheckbox;
