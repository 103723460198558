import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import YellowBtn from "../YellowBtn/YellowBtn";
// import StrConstant from "../../Common/StrConstant";
import LogoContainer from "../LogoContainer/LogoContainer";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userForgotPassword } from "../../redux/actions/UserActions";
import { CountdownTimer } from "../../Views/CountdownTimer";
import { useState } from "react";
const SuccessMessage = (props) => {
  const { classes } = props;
  const TWO_MIN_IN_MS = 120000;
  const NOW_IN_MS = new Date().getTime();
  const TimeAfterTowMins = NOW_IN_MS + TWO_MIN_IN_MS;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const email = localStorage.getItem("email");
  const buttonClick = () => {
    navigate("/login");
  };
  const resendPasswordHandle = () => {
    dispatch(userForgotPassword({ email }));
    setDisable(true);
    setTimeout(() => setDisable(false), 120000);
  };
  return (
    <>
      <div className={classes.formOuter}>
        <div className={classes.formInner}>
          <div>
            <LogoContainer heading={"Email sent"} goUrl={""} urlTxt={""} />
            <div className={classes.topBox}>
              <p>
                An email with instructions to reset your password has been sent
                to your registered email account.
              </p>
              <p>
                If you do not receive the email within the next few minutes,
                please check your account's Junk/Spam folder.{" "}
              </p>
              {disable ? (
                <CountdownTimer targetDate={TimeAfterTowMins} />
              ) : (
                <p>
                  Didn't receive the email?{" "}
                  <Link to="" onClick={resendPasswordHandle}>
                    Resend again
                  </Link>{" "}
                </p>
              )}
            </div>
          </div>
          <div className={classes.customBtn}>
            <YellowBtn name="RETURN TO LOGIN" clickHandler={buttonClick} />
          </div>
        </div>
      </div>
    </>
  );
};
export default withStyles(Styles)(SuccessMessage);
