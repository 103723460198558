export const Styles = (Theme) => ({
  formOuter: {
    "@media (max-width: 768px)": {
      height: "auto",
      display: "table",
      width: "100%",
      background: "#F7F7F7",
    },
  },
  formInner: {
    background: "rgba(255, 255, 255, 0.75)",
    borderRadius: "12px",
    padding: "2rem",
    maxWidth: "420px",
    width: "100%",
    height: "auto",
    margin: "0 auto",
    display: "block",
    boxSizing: "border-box",
    overflowY: "auto",
    maxHeight: "100vh",
    "@media (max-width: 768px)": {
      maxWidth: "100%",
      background: "#F7F7F7",
      maxHeight: "100%",
      overflowY: "inherit",
      borderRadius: "0",
      padding: "2rem 1.5rem",
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      justifyContent: "space-between",
    },
    "&::-webkit-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-moz-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-o-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "&::-ms-scrollbar": {
      appearance: "none",
      width: "0",
    },
    "& h2": {
      marginTop: "0",
    },
    "& div": {
      margin: "0",
    }
  },
  customBtn: {
    "& > div": {
      maxWidth: "100%",
      float: "unset",
    },
    "& button": {
      padding: "12px 24px",
      background: "#FFC200",
      color: "#3F4444",
      fontSize: "20px",
      lineHeight: "20px",
      fontWeight: "400",
      fontFamily: "'Bebas Neue', serif",
      margin: "20px 0 0",
      letterSpacing: "0",
      whiteSpace: "nowrap",
      borderRadius: "32px",
      outline: "none",
      border: "none",
      width: "100%",
      "&:hover": {
        background: "#FFC200",
      },
      "@media (max-width: 640px)": {
        padding: "12px 20px",
        margin: "0px 0 15px",
        fontSize: "16px",
        lineHeight: "20px",
      }
    },
  },
});
