export const Styles = Theme => ({
    outerInner: {
        maxWidth: "1000px",
        margin: "auto",
        display: "flex",
        width: "100%",
        alignItems: "center",
        height: "calc(100vh - 150px)",
        "@media(max-width:640px)": {
            display: "block",
        }
    },
    borderBottom0: {
        // borderBottom: "none !important",
    },
    card: {
        "& a": {
            background: "#FFFFFF",
            padding: "15px 20px",
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
            borderBottom: "1px solid #ddd",
            borderRadius:"20px",
            textDecoration: "none",
            width: "100%",
            "@media(max-width:640px)": {
                borderBottom: "0",
                padding: "15px 20px",
                borderRadius: "10px",
            },
        }
    },
    ltBox: {
        width: "80%",
        "& h4": {
            marginBottom: "0",
        },
    },
    rtBox: {
        width: "20%",
        paddingLeft: "10px",
        textAlign: "right",
    },
    fromControl: {
        margin: "0 0 25px",
    },
    modalFlex: {
        display: "flex",
        "& > :nth-child(2)": {
            marginLeft: "15px",
        },
        "& button": {
            margin: "10px 0 0"
        }
    },
    txtLink: {
        textAlign: "left",
        display: "inherit",
        "@media(max-width:375px)": {
            textAlign: "center",
        }
    }
})