import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { Link } from "react-router-dom";
import StrConstant from "../../../Common/StrConstant";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import CustomCheckbox from "../../../Components/CustomCheckbox/CustomCheckbox";
import CardPayment from "../../../Components/CardPayment/CardPayment";
import { useNavigate } from "react-router";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { useDispatch } from "react-redux";
import { ActionType } from "../../../redux/ActionTypes";
const ChoosePaymentMethod = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <OuterBox>
        <div>
          <BackArrow clickHandler={() => dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))} />
          <h3>{StrConstant.ChoosePaymentMethod.heading}</h3>
        </div>
        <div>
          <div className={classes.dFlex}>
            {/* <CardPayment />
            <CardPayment /> */}
          </div>
          <div className={classes.dFlex}>
            <label className={classes.textCheckbox}>
              <CustomCheckbox />
              <p>Set as default payment method</p>{" "}
            </label>
            <Link to="/add-card" className={classes.addItems}>
              <img src="./images/plus.svg" alt="Better Coffee" />
              <span>Add payment method</span>
            </Link>
          </div>
          <YellowBtn name={"Confirm"} clickHandler={() => navigate("/purchase-credits")} />
        </div>
      </OuterBox>
    </>
  );
};

export default withStyles(Styles)(ChoosePaymentMethod);
