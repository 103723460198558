import { withStyles } from '@mui/styles';
import { Styles } from './Style';
import StrConstant from '../../Common/StrConstant';
import { Link } from 'react-router-dom';
const NotFound = (props) => {
    const { classes } = props
    return (
        <>
            <div className={classes.notFountOuter}>
                <div className={classes.notFountInner}>
                    <h2>PAGE NOT FOUND</h2>
                    <p className={classes.description}>Sorry, there's no coffee here...</p>
                    <Link to="/" className={classes.returnBtn}>Return to Home</Link>
                </div>
            </div>
        </>
    )
}
export default withStyles(Styles)(NotFound);