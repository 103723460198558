import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstant from "../../../Common/StrConstant";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import { creditTransactionsAction } from "../../../redux/actions/credit/CreditActions";
import OrderStatus from "../../../Components/OrderStatus/OrderStatus";
import { ActionType } from "../../../redux/ActionTypes";
import { modeLinkHandler } from "../../../Common/utils/utils";
import Loader from "react-spinner-loader";
import ViewMore from "../../../Components/ViewMore/ViewMore";
import Opps from "../../../Components/Opps/Opps";
const CreditTransactions = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setDefaultMode = localStorage.getItem("selected_modes");
  const creditTransactions = useSelector((state) => state?.creditTransaction);
  const loading = useSelector((state) => state?.creditTransaction);

  useEffect(() => {
    dispatch(creditTransactionsAction(page));
  }, []);

  //ScrollView Section
  const [creditData, setCreditData] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [page, setPage] = useState(1);

  // useEffect(() => {
  //   window.addEventListener("scroll", onScroll);
  //   return () => {
  //     window.removeEventListener("scroll", onScroll);
  //   };
  // });

  useEffect(() => {
    if (creditTransactions?.creditTransac != null) {
      if (creditTransactions?.creditTransac?.data?.transactions.length < 10) {
        setIsComplete(true);
      }
      if (creditData?.length > 0) {
        if (page > 1) {
          if (
            creditTransactions?.creditTransac?.data?.transactions != undefined
          ) {
            setCreditData([
              ...creditData,
              ...creditTransactions?.creditTransac?.data?.transactions,
            ]);
          } else {
            setIsComplete(true);
          }
        }
      } else {
        setCreditData(creditTransactions?.creditTransac?.data?.transactions);
      }
    }
  }, [creditTransactions?.creditTransac]);

  useEffect(() => {
    if (page === 1) {
      setCreditData([]);
    }
    // console.log("page", page);
    if(page>1){
    dispatch(creditTransactionsAction(page));
    }
  }, [page]);

  // const onScroll = () => {
  //   const winHeight = window.innerHeight;
  //   const srcHeight = document.documentElement.scrollHeight;
  //   const scrollTop =
  //     document.body.scrollTop || document.documentElement.scrollTop;
  //   const toBottom = srcHeight - winHeight - scrollTop;

  //   if (toBottom <= 100) {
  //     if (!isComplete) {
  //       getData();
  //     }
  //   }
  // };

  // function getData() {
  //   if (loading?.loading) return;
  //   const nextPage = page + 1;
  //   setPage(nextPage);
  // }
  const loadMoreHandler = () => {
    const nextPage = page + 1;
    setPage(nextPage);
  };

  const moveToCustomMenu = (url) => {
    window.open(url, "_blank");
  };
  // console.log("creditData", creditData?.length == 0);
  return (
    <>
      {loading?.loading && page === 1 && (
        <Loader
          show={loading?.loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <Container maxWidth="lg" className={classes.outer}>
        <div className={classes.outerBox}>
          <div>
            <div className={classes.p20}>
              <BackArrow
                clickHandler={() =>
                  dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
                }
              />
              <h3>{StrConstant.CreditTransactions.heading}</h3>
            </div>
            {creditData?.length == 0 &&loading?.loading==false ? (
              <Opps />
            ) : (
              <table
                className={classes.billingTable}
                border="0"
                cellSpacing={0}
                cellPadding={0}
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th className={classes.rightTxt}>Credits</th>
                  </tr>
                </thead>
                <tbody>
                  {creditData?.length != 0 &&
                    creditData?.map((data, index) => (
                      <tr key={index}>
                        <td>{data?.date_created}</td>
                        <td>
                          {`${
                            data?.description === "Confirmation" ||
                            data?.description === "Cancellation"
                              ? "Order #" + data?.order_number
                              : data?.description=="Top Up"?"Credit Purchase":data?.description
                          }`}
                          {data?.description === "Confirmation" ? (
                            <p>{data?.description}</p>
                          ) : null}
                          {data?.description === "Cancellation" ? (
                            <span>{data?.description}</span>
                          ) : null}
                        </td>
                        <td className={classes.rightTxt}>
                          {data?.description === "Top Up" ||
                          data?.description === "Purchased Subscription" ||
                          data?.description === "Cancellation" ||
                          data?.description === "Credit Allocation" ||
                          data?.description === "Credit credited"||
                          data?.description === "Subscription Renewed" ||
                          data?.description ==="Subscription Purchase"
                            ? "+"
                            : null}
                          {data?.description === "Confirmation" ||
                          data?.description === "Company Deduction" ||
                          data?.description === "Company debited" ||
                          data?.description === "Company debitted" 
                            ? "-"
                            : null}
                          {data?.amount}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            <div>
              {creditTransactions?.creditTransac?.data?.pagination?.lastPage ===
                page ||
              creditTransactions?.creditTransac?.data?.pagination?.count ===
                creditData.length ||
              creditData.length < 1 ? null : (
                <ViewMore name={"Load More"} clickHandler={loadMoreHandler} />
              )}
            </div>
          </div>
          <div className={classes.btText}>
            <p>Something not right?</p>{" "}
            <p>
              Contact our{" "}
              <Link
                to=""
                onClick={() =>
                  moveToCustomMenu("https://www.order.bettr.coffee/contact-us/")
                }
              >
                {" "}
                customer service.
              </Link>
            </p>
          </div>
          <OrderStatus
            // link={modeLinkHandler(setDefaultMode)}
            link={"/choose-mode"}
            text={"Place order "}
          />
        </div>
      </Container>
    </>
  );
};
export default withStyles(Styles)(CreditTransactions);
