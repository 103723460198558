import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstant from "../../Common/StrConstant";
import LoginForm from "../../Components/LoginForm/LoginForm";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const Login = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const userSignIn = useSelector((state) => state.userSignin);
  const { userInfo } = userSignIn;
  // console.log("LOGINCOUNT:", userInfo?.data?.user_data?.user_id);

  const [desktop, setDesktop] = useState(window.innerWidth > 768);
  const mobileWidth = () => {
    setDesktop(window.innerWidth > 768);
  };
  useEffect(() => {
    if (userInfo?.data?.user_data?.user_id > 0) {
      navigate("/my-dashboard");
    }
  }, []);
  useEffect(() => {
    window.addEventListener("resize", mobileWidth);
    return () => window.removeEventListener("resize", mobileWidth);
  });
  return (
    <>
      {desktop ? (
        <div className={classes.root}>
          <div className={classes.container}>
            <div className={classes.overlayContainer}>
              <div className={classes.overlayPanel}>
                <h1>{StrConstant.Main.heading} </h1>
                <p>{StrConstant.Main.description}</p>
              </div>
            </div>
            <div className={classes.formContainer}>
              <LoginForm />
            </div>
          </div>
        </div>
      ) : (
        <LoginForm />
      )}
    </>
  );
};

export default withStyles(Styles)(Login);
