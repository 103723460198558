import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import Loader from "react-spinner-loader";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { ToggleButton } from "@mui/material";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { ActionType } from "../../../../redux/ActionTypes";
import BackArrow from "../../../../Components/BackArrow/BackArrow";
import YellowBtn from "../../../../Components/YellowBtn/YellowBtn";
import QuantityBox from "../../../../Components/QuantityBox/QuantityBox";
import AlterMessage from "../../../../Components/AlertMessage/AlertMessage";
import CustomPropover from "../../../../Components/CustomPropover/CustomPropover";
import { getProductDetail } from "../../../../redux/actions/product/ProductActions";
import GroupToggleBtn from "../../../../Components/GroupToggleBtn/GroupToggleBtn";
import { addProductToCart, updatedProductToCart } from "../../../../redux/actions/cart/cartActions";
const ItemModifier = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const itemId = localStorage.getItem("itemId");
  const exportCount = (count) => setCount(count);
  const item_id = localStorage.getItem("item_id");
  const address_id = localStorage.getItem("address_id");
  const [item_comment, setItem_comment] = useState("");
  const [selectedSize, setSelectedSize] = useState({ size: "", price: "" });
  const outlet_id = localStorage.getItem("selected_outlet_id");
  const [selectedModifier, setSelectedModifier] = useState([]);
  const [isUpdated, setIsUpdated] = useState([]);
  const [urlmatch,seturlmatch]=useState(false)
   const getcartdetailss=JSON.parse(localStorage.getItem('getcartdetails'))
  // const [selectedData, setSelectedData] = useState();
  
  const cart_id=localStorage.getItem('cart_id')
  const [grandTotal, setGrandTotal] = useState(0);
  const selectedMode = localStorage.getItem("selected_modes");
  const { error, productCart } = useSelector(
    (state) => state?.addProductToCart
  );
  const  updatedproductCart  = useSelector(
    (state) => state?.updatedProductToCart
  );
  const url=window.location.href
console.log("href",url)
const productDetail = useSelector(
  (state) => state?.getProductDetail?.productList
);
// useEffect(() => {
    
//   dispatch(getProductDetail(itemId, outlet_id));
// }, []);
useEffect(()=>{
if(url?.includes("item-modifier/id")){
  console.log("url",url?.includes("item-modifier/id")) 
  
  seturlmatch(true)
}else{
 console.log("url",url?.includes("item-modifier/id")) 
  seturlmatch(false)
}
},[url])
 
  const listLoading = useSelector((state) => state?.getProductDetail);
  const regularPrice = (
    productDetail?.data?.size[1]?.price - productDetail?.data?.size[0]?.price
  ).toFixed(1);

 
useEffect(()=>{
 
dispatch(getProductDetail(urlmatch?getcartdetailss?.outlet_item_id: itemId,urlmatch?getcartdetailss?.outlet_id: outlet_id));
},[urlmatch])
  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    dispatch(
      { type: ActionType.CLEAR_RESPONSE },
      setIsOpen(false),
      setDisable(false)
    );
  };







  const selectedOptions = (
    index,
    modifierId,
    optionId,
    optionPrice,
    optionName
  ) => {
    // setSelectedModifierOne(optionName);
    const data = selectedModifier !== null ? selectedModifier : [];
    const isExist = data.filter((sd) => {
      return sd.masterID === modifierId;
    });
    if (isExist?.length <= 0) {
      data.push({
        masterID: modifierId,
        modifier_id: optionId,
        option_id: optionId,
        option_name: optionName,
        modifier_price: optionPrice,
      });
      setSelectedModifier(data);
    } else {
      const pos = data?.findIndex((fd) => {
        return fd.masterID === modifierId;
      });
      data[pos] = {
        masterID: modifierId,
        modifier_id: optionId,
        option_id: optionId,
        option_name: optionName,
        modifier_price: optionPrice,
      };
      setSelectedModifier(data);
    }
    const isUpdate = isUpdated;
    isUpdate[index] = optionId;
    setIsUpdated({ ...isUpdate });

    calculateGrandTotal();
    // console.log("selectedModifier:", selectedModifier);
  };

  const calculateGrandTotal = () => {
    let total = selectedSize.price * count;
    selectedModifier.map((data, index) => {
      total +=
        (parseInt(data?.modifier_price) === data?.modifier_price
          ? parseInt(parseFloat(data?.modifier_price) * parseFloat(count))
          : parseFloat(
            parseFloat(data?.modifier_price) * parseFloat(count)
          ));
    });
    setGrandTotal(
      parseInt(total) === total ? total : parseFloat(total).toFixed(1)
    );
  };

  const addToCartHandler = async () => {
    setIsOpen(true);
    const payload = {
      address_id: selectedMode === "3" ? address_id : null,
      outlet_id,
      item_id,
      item_price: selectedSize.price === "" ? 0 : selectedSize.price,
      item_comment,
      item_quantity: count,
      modifier: selectedModifier?.length > 0 ? selectedModifier : {},
    };
    dispatch(
      addProductToCart(payload),
      setDisable(true),
      localStorage.setItem("skip", "YES")
    );
  };

const updateTocarthandler= async()=>{
  setIsOpen(true);
  const payload = {
    cart_id:cart_id,
    cart_item_id:getcartdetailss?.cart_item_id,
    address_id: selectedMode === "3" ? address_id : null,
    outlet_id:getcartdetailss?.outlet_id,
    item_id:getcartdetailss?.item_id,
    item_price: selectedSize.price === "" ? 0 : selectedSize.price,
    item_comment,
    item_quantity: count,
    modifier: selectedModifier?.length > 0 ? selectedModifier : {},
  };
  
  dispatch(
    updatedProductToCart(payload),
    setDisable(true),
    localStorage.setItem("skip", "YES")
  );
}

  useEffect(() => {
    let defaultModifier = [];
    // console.log("productDetail -- ", productDetail);
    //const isUpdate = isUpdated;
    /**
     * Initial selected modifier
     * @param {Object} productDetail all product details
     */
    if (productDetail?.data?.modifier?.length > 0) {
      productDetail?.data?.modifier?.map((modifier, index) => {
        const data = defaultModifier;
        const isExist = data.filter((sd) => {
          return sd.masterID === modifier?.modifier_id;
        });

        const modifierStatus = modifier?.modifier_status === 1;

        const optionStatus = modifier?.modifier_option?.filter(
          (options) => options?.status === true
        );
        // console.log("optionStatus", optionStatus);
        const status = modifierStatus && optionStatus.length > 0 ? true : false;

        if (isExist?.length <= 0 && status) {
          if(urlmatch){
            data.push({
              masterID: getcartdetailss?.modifiers[index]?.masterID,
              modifier_id:getcartdetailss?.modifiers[index]?.modifier_id,
              option_id: getcartdetailss?.modifiers[index]?.option_id ,
              option_name: getcartdetailss?.modifiers[index]?.option_name,
              modifier_price:
                getcartdetailss?.modifiers[index]?.modifier_price,
            });
          }else{
            data.push({
              masterID: modifier?.modifier_id,
              modifier_id:
                optionStatus.length > 0 ? optionStatus[0].modifier_option_id : 0,
              option_id:
                optionStatus.length > 0 ? optionStatus[0].modifier_option_id : 0,
              option_name: optionStatus[0].name,
              modifier_price:
                optionStatus.length > 0 ? optionStatus[0].price : "",
            });
          }
        

         
          setSelectedModifier(data);

          const isUpdate = isUpdated;
          isUpdate[index] =
            modifier?.modifier_option.length > 0
              ? modifier?.modifier_option[0].modifier_option_id
              : 0;
          setIsUpdated({ ...isUpdate });
        }
        // console.log("data", data);
      });
    } else {
      setSelectedModifier(defaultModifier);
    }

    let prize = "";
    let size = "";
    if (productDetail?.data?.size[0]?.price != 0) {
      prize = productDetail?.data?.size[0]?.price;
      size = productDetail?.data?.size[0]?.size;
    } else if (productDetail?.data?.size[1]?.price != 0) {
      prize = productDetail?.data?.size[1]?.price;
      size = productDetail?.data?.size[1]?.size;
    }

    setSelectedSize(
      urlmatch==true? 
      {
        size:getcartdetailss?.item_size,
        price:getcartdetailss?.unit_price
        //  getcartdetailss?.item_size==productDetail?.data?.size[0]?.size ?productDetail?.data?.size[0]?.price:productDetail?.data?.size[1]?.price 
      }
      :
      productDetail?.success
        ? {
          size: size,
          price: prize,
        }
        : { size: "", price: "" }
    );
    calculateGrandTotal();
  }, [productDetail]);

  useEffect(() => {
    if (selectedSize?.size !== "") {
      calculateGrandTotal();
    }
  }, [selectedSize]);

  useEffect(() => {
    if (count > 0) {
      calculateGrandTotal();
    }
  }, [count]);

  useEffect(() => {
    if (productCart?.success||updatedproductCart?.productCart?.success) {
      navigate("/cart-detail");
     
      
    }
  }, [productCart,updatedproductCart]);
// console.log("updatedproductCart",updatedproductCart?.productCart?.success)
  const renderModifier = () => {
    return (
      <>
        {listLoading?.loading && (
          <Loader
            show={listLoading?.loading}
            type="body"
            stack="vertical"
            message="Loading..."
          />
        )}
        {productDetail?.data?.modifier?.length > 0 &&
          productDetail?.data?.modifier?.map((modifier, index) => {
            const modifierStatus = modifier?.modifier_status === 1;
            const optionStatus = modifier?.modifier_option?.filter(
              (options) => options?.status === true
            );
            const status =
              modifierStatus && optionStatus.length > 0 ? true : false;
            // console.log("modifierStatus", status);
            return (
              status && (
                <div
                  className={`${classes.innerBox} ${classes.m0}`}
                  key={index}
                >
                  <div className={classes.headingBox}>
                    <div className={classes.dFlex}>
                      <h4>
                        {modifier?.modifier_status === 0
                          ? null
                          : modifier?.modifier_name.split("+")[0]}
                      </h4>
                      {modifier?.modifier_description !== "" && (
                        <CustomPropover className={classes.customPropover}>
                          <div
                            className={classes.popoverContent}
                            dangerouslySetInnerHTML={{
                              __html: modifier?.modifier_description,
                            }}
                          ></div>
                        </CustomPropover>
                      )}
                    </div>
                  </div>
                  <div className={classes.boxContainer}>
                    <div className={classes.toggleBtnContent}>
                      {/* options?.name==="Regular"&& options?.price!==0&& */}
                      <GroupToggleBtn>
                        {modifier?.modifier_option?.length > 0 &&
                          modifier?.modifier_option?.map(
                            (options) =>
                              options?.status && (
                                <ToggleButton
                                  key={Math.random()}
                                  selected={
                                    selectedModifier !== null
                                      ? selectedModifier.filter((sd) => {
                                        return (
                                          sd.masterID ===
                                          modifier?.modifier_id &&
                                          sd.option_id ===
                                          options?.modifier_option_id
                                        );
                                      }) <= 0
                                        ? false
                                        : true
                                      : false
                                  }
                                  value={options?.modifier_option_id}
                                  onClick={() =>
                                    selectedOptions(
                                      index,
                                      modifier?.modifier_id,
                                      options?.modifier_option_id,
                                      options?.price,
                                      options?.name
                                    )
                                  }
                                >
                                  <h5>{options?.name}</h5>
                                  <p>
                                    {options?.price > 0
                                      ? "+" + options?.price + " Credits"
                                      : "-"}
                                  </p>
                                </ToggleButton>
                              )
                          )}
                      </GroupToggleBtn>
                    </div>
                  </div>
                </div>
              )
            );
          })}
      </>
    );
  };
  useEffect(()=>{
    setItem_comment(urlmatch?getcartdetailss?.item_comment:"")
  },[urlmatch])

  return (
    <>
      {listLoading?.loading && (
        <Loader
          type="body"
          stack="vertical"
          message="Loading..."
          show={listLoading?.loading}
        />
      )}
      {error && isOpen && (
        <AlterMessage
          open={isOpen}
          type={"Error"}
          title={"Error"}
          url={"./images/error.svg"}
          closeHandler={snackHandler}
          description={error}
        />
      )}
      <Container maxWidth="lg" className={classes.p0}>
        <div className={classes.outer}>
          <div>
            <div className={classes.p16}>
              <BackArrow
                clickHandler={() =>
                  dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
                }
              />
              <div className={classes.topBox}>
                <div className={classes.ltBox}>
                  {productDetail?.data?.image_url != "" && (
                    <div>
                      <div className={classes.imgBox}>
                        <img
                          src={urlmatch==true?getcartdetailss?.item_image: productDetail?.data?.image_url}
                          alt="Better coffee"
                        />
                      </div>
                    </div>
                  )}
                  <h3>{urlmatch==true?getcartdetailss?.item_name: productDetail?.data?.item_name}</h3>
                </div>
                <div className={classes.rtBox}>
                  <p className={classes.fontWeight700}>
                    {
                    productDetail?.data?.size[0]?.price != 0
                      ? productDetail?.data?.size[0]?.price
                      : productDetail?.data?.size[1]?.price}{" "}
                    Credits
                  </p>
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: productDetail?.data?.item_description,
                }}
              ></div>
              {productDetail?.data?.hide_size !== 0 ? null : (
                <div className={`${classes.innerBox} ${classes.m0}`}>
                  <div className={classes.headingBox}>
                    <h4>Size</h4>
                  </div>
                  <div className={classes.boxContainer}>
                    <div className={classes.toggleBtnContent}>
                      <GroupToggleBtn>
                        {productDetail?.data?.size?.map((size, index) => {
                          const isDisplay =
                            productDetail?.data?.size[0]?.price != 0
                              ? productDetail?.data?.size[0]?.price
                              : productDetail?.data?.size[1]?.price;
                          return (size?.size === "Regular" ||
                            size?.size === "Small") &&
                            size?.price === 0 ? null : (
                            <ToggleButton
                              value={size?.price}
                              key={index}
                              selected={selectedSize.size === size?.size}
                              onClick={() =>
                                setSelectedSize({
                                  size: size?.size,
                                  price: size?.price,
                                })
                              }
                            >
                              <h5>{size?.size}</h5>
                              <p>
                                {size?.price > 0
                                  ? size?.size === "Small"
                                    ? "-"
                                    : isDisplay === size?.price
                                      ? "-"
                                      : "+" +
                                      parseFloat(regularPrice) +
                                      " Credits"
                                  : "-"}
                              </p>
                            </ToggleButton>
                          );
                        })}
                      </GroupToggleBtn>
                    </div>
                  </div>
                </div>
              )}
              {renderModifier()}
              <div className={classes.innerBox}>
                <div className={classes.headingBox}>
                  {" "}
                  <h4>Quantity</h4>
                </div>
                <div className={classes.boxContainer}>
                  <div className={`${classes.cardBox} ${classes.quantityBox}`}>
                    <QuantityBox exportCount={exportCount} urlmatch={urlmatch}/>
                  </div>
                  {/* {count > 90 ? (
                    <p className="errorMessage">This field is invalid.</p>
                  ) : null} */}
                </div>
              </div>
              <div className={classes.commentBox}>
                <h4>Comments</h4>
                <div className={classes.textAreaBox}>
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={1}
                    value={item_comment}
                    placeholder="Please note comments are fulfilled at our discretion"
                    type="text"
                    onChange={(event) => setItem_comment(event.target.value)}
                    maxLength={25}
                  />
                </div>
              </div>
            </div>
            <div className={classes.listingOuter}>
              <h4>Your Selection</h4>
              <div className={classes.listingBox}>
                <div className={classes.dFlex}>
                  <div className={classes.headingTop}>
                    <h5 className={classes.number}>{count} </h5>
                    <h5>{urlmatch==true? getcartdetailss?.item_name: productDetail?.data?.item_name}</h5>
                  </div>
                  <div className={classes.rt}>
                    <p className={classes.fontWeight700}>
                      {grandTotal} Credits
                    </p>
                  </div>
                </div>

                {productDetail?.data?.hide_size != 1 && (
                  <div className={classes.dFlex}>
                    <div className={`${classes.lt} ${classes.pl}`}>
                      <p>{urlmatch==true?getcartdetailss?.item_size: selectedSize.size}</p>
                    </div>
                    {/* <div className={classes.rt}>
                      <p>
                        {selectedSize.price
                          ? `${
                              parseInt(selectedSize.price) ===
                              selectedSize.price
                                ? parseInt(
                                    parseFloat(selectedSize.price) *
                                      parseFloat(count)
                                  )
                                : parseFloat(
                                    parseFloat(selectedSize.price) *
                                      parseFloat(count)
                                  ).toFixed(1)
                            } Credits`
                          : "-"}{" "}
                      </p>
                    </div> */}
                  </div>
                )}
                {selectedModifier !== null &&
                  selectedModifier.map((modifier, index) => (
                    <div className={classes.dFlex} key={index}>
                      <div className={`${classes.lt} ${classes.pl}`}>
                        <p>{modifier?.option_name}</p>
                      </div>
                      {/* <div className={classes.rt}>
                        <p>
                          {modifier?.modifier_price != 0
                            ? `${
                                parseInt(modifier?.modifier_price) ===
                                modifier?.modifier_price
                                  ? parseInt(
                                      parseFloat(modifier?.modifier_price) *
                                        parseFloat(count)
                                    )
                                  : parseFloat(
                                      parseFloat(modifier?.modifier_price) *
                                        parseFloat(count)
                                    ).toFixed(1)
                              } Credits`
                            : "-"}
                        </p>
                      </div> */}
                    </div>
                  ))}
                <div className={classes.dFlex}>
                  <div className={`${classes.lt} ${classes.pl}`}>
                    {item_comment ? <p>{`"${item_comment}"`}</p> : null}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.p16}>
              <YellowBtn
                disable={disable}
                name={urlmatch?"updated to cart":"Add to cart"}
                clickHandler={urlmatch?updateTocarthandler: addToCartHandler}
              />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default withStyles(Styles)(ItemModifier);
