export const Styles = (Theme) => ({
    multiDatePic: {
        background: "#fff",
        borderRadius: "30px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        position: "relative",
        "& > div:first-child": {
            width: "100%"
        },
        "& input": {
            width: "auto",
            border: "none !important",
            textAlign: "left",
            color:"#3F4444BF",
            width: "100%",
            padding: "15px 50px 15px 20px",
            borderRadius: "32px",
            minHeight: "50px",
            "@media(max-width:375px)": {
                padding: "15px 30px 15px 20px",
            },
        },
        "& p": {
            display: "none",
        },
        "& img": {
            height: "16px",
            position: "absolute",
            right: "25px",
            "@media(max-width:375px)": {
                height: "14px",
                right: "15px",
            },
        },
    },
    button: {
        padding: "15px 24px",
        color: "#3F4444",
        fontSize: "20px",
        lineHeight: "20px",
        fontWeight: "400",
        fontFamily: "'Bebas Neue', serif",
        margin: "20px 0 15px",
        letterSpacing: "0",
        whiteSpace: "nowrap",
        borderRadius: "32px",
        width: "100%",
    },
    btnContent: {
        display: "flex",
        justifyContent: "space-between",
        padding:"20px 10px",
        borderTop: "1px solid #DDDDDD",
    },
    yellowBtn: {
        width: "50%",
        padding: "12px 20px",
        color: "#3F4444",
        fontSize: "20px",
        lineHeight: "20px",
        fontWeight: "400",
        fontFamily: "'Bebas Neue', serif",
        margin: "0 0 0 10px",
        letterSpacing: "0",
        whiteSpace: "nowrap",
        borderRadius: "32px",
        width: "100%",
        background: "#FFC200",
        border: "none",
        "&:hover": {
            background: "#FFC200",
        },
    },
    grayBtn: {
        width: "50%",
        padding: "12px 20px",
        color: "#3F4444",
        fontSize: "20px",
        lineHeight: "20px",
        fontWeight: "400",
        fontFamily: "'Bebas Neue', serif",
        margin: "0",
        letterSpacing: "0",
        whiteSpace: "nowrap",
        borderRadius: "32px",
        width: "100%",
        background: "#E3DDD2",
        border: "none",
        "&:hover": {
            background: "#E3DDD2",
        },
    }
})