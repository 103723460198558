import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import StrConstant from "../../../Common/StrConstant";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { useDispatch, useSelector } from "react-redux";
import AlterMessage from "../../../Components/AlertMessage/AlertMessage";
import { useEffect, useState } from "react";
import {
  getModesList,
  setMode,
} from "../../../redux/actions/chooseModes/ChooseModesActions";
import Loader from "react-spinner-loader";
import { ActionType } from "../../../redux/ActionTypes";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import DarkGrayBtn from "../../../Components/DarkGrayBtn/DarkGrayBtn";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import { modeLinkHandler } from "../../../Common/utils/utils";
import { getSpendPolicyAction } from "../../../redux/actions/UserActions";
import { getMyWalletData } from "../../../redux/actions/myWallet/MyWalletActions";

const ChooseMode = (props) => {
  const { classes } = props;
  const currentDay = new Date();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const spendPolicy = useSelector((state) => state?.spendPolicy);
  const cartCounter = useSelector((state) => state?.cartCounter);
  const { loading, cards } = useSelector((state) => state?.modesList);
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const today = weekdays[currentDay.getDay()];
  const isAllowed =
    spendPolicy?.spendPolicy?.data?.spend_policy?.allowed_days?.indexOf(today);
  const [alertSnack, setAlertSnack] = useState(false);

  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    setAlertSnack(false);
  };

  const clickHandler = (id) => {
    setSelectedId(id);
    if (localStorage.getItem("selected_modes") != id && cartCounter !== 0)
      setShowModel(true);
    else changeHandler(id);
  };

  const changeHandler = (id = localStorage.getItem("selected_modes")) => {
    const payload = {
      default_mode: id,
    };
    localStorage.setItem("selected_modes", id);
    dispatch(setMode(payload));
    localStorage.getItem("selected_modes") != id && cartCounter !== 0
      ? dispatch({ type: "RESET_CART" }, navigate(modeLinkHandler(id)))
      : navigate(modeLinkHandler(id));
  };

  const modalHandler = () => {
    dispatch({ type: "RESET_CART" });
    dispatch(
      setMode({
        default_mode: selectedId,
      })
    );
    navigate(modeLinkHandler(selectedId));
  };
  useEffect(() => {
    localStorage.removeItem("chooseInit");
    localStorage.removeItem("address_id");
    dispatch(getModesList());
    if (
      JSON.parse(localStorage.getItem("userInfo"))?.data?.user_data
        ?.role_name === "company_employee"
    ) {
      dispatch(getSpendPolicyAction());
    }
  }, []);
  useEffect(()=>{
    dispatch(getMyWalletData());
  },[])
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <AlterMessage
        closeHandler={snackHandler}
        description={"Enter credit"}
        title="Error"
        type="Error"
        open={alertSnack}
        url="./images/error.svg"
      />
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <h3>{StrConstant.ChooseMode.heading}</h3>
        </div>
        <div>
          {cards?.data?.map((list) =>
            isAllowed ? (
              isAllowed >= 0 || list.id !== 1 ? (
                <div className={classes.chooseBox} key={list.id}>
                  <div className={classes.card}>
                    <div className={classes.ltBox}>
                      <h4>{list.title}</h4>
                      <p>{list.description}</p>
                    </div>
                    <div className={classes.rtBox}>
                      <img
                        onClick={() => clickHandler(list?.id)}
                        className={classes.imgContent}
                        src="./images/arrow.svg"
                        alt="Better Coffee"
                      />
                    </div>
                  </div>
                </div>
              ) : null
            ) : (
              <div className={classes.chooseBox} key={list.id}>
                <div className={classes.card}>
                  <div className={classes.ltBox}>
                    <h4>{list.title}</h4>
                    <p>{list.description}</p>
                  </div>
                  <div className={classes.rtBox}>
                    <img
                      onClick={() => clickHandler(list?.id)}
                      className={classes.imgContent}
                      src="./images/arrow.svg"
                      alt="Better Coffee"
                    />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </OuterBox>
      <CustomModal
        open={showModel}
        title={"TO PROCEED, YOUR CART WILL BE CLEARED."}
        description={""}
      >
        <div className={classes.modalFlex}>
          <DarkGrayBtn
            name={"Cancel"}
            clickHandler={() => setShowModel(false)}
          />
          <YellowBtn name={"Confirm"} clickHandler={modalHandler} />
        </div>
      </CustomModal>
    </>
  );
};
export default withStyles(Styles)(ChooseMode);
