import { Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Styles } from "./Style";
const QuantityBox = (props) => {
  const { classes ,urlmatch} = props;
 console.log("url",urlmatch)
 const getcartdetails=JSON.parse(localStorage.getItem('getcartdetails'))
  const [count, setCount] = useState(1);
  useEffect(() => {
    props.exportCount(count);
  }, [count, props]);
useEffect(()=>{
if(urlmatch==true ){
  setCount(getcartdetails?.item_quantity ?getcartdetails?.item_quantity:1)
}
else{
  setCount(1)
}
},[urlmatch])

  return (
    <div className={classes.qtyBox}>
      <Button
        onClick={() => (count > 1 ? setCount((pre) => pre - 1) : null)}
        className={classes.minesBtn}
      >
        -
      </Button>
      <div className={classes.countNumber}>{count === null ? 1 : count}</div>
      <Button
        onClick={() => (count < 90 ? setCount((pre) => pre + 1) : null)}
        className={classes.plusBtn}
      >
        +
      </Button>
    </div>
  );
};
export default withStyles(Styles)(QuantityBox);
