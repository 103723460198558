import serverURL from "../../../APIHandler/serverURL";
import callAPI from "../../../APIHandler/callAPI";
import { ActionType } from "../../ActionTypes";
import { saveData } from "../UserActions";
import { getOutlet } from "../outlet/OutletActions";
import { reloadHandler } from "../../../Common/utils/utils";

export const getUserCart =
  (cartId = false) =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionType.GET_USER_CART_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        serverURL.getUserCart.concat(cartId ? `/${cartId}` : ""),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      const splitspendpolicy=data?.data?.spend_policy?.map((item)=>(
        item.slice(0,3)
      ))
      dispatch(
        { type: ActionType.GET_USER_CART_SUCCESS, payload: data },
        localStorage.setItem("cart_id", data?.data?.cart_identifier),
        localStorage.setItem(
          "spend_policy_days",
          JSON.stringify(splitspendpolicy)
        )
      );
      // console.log("data", data?.data?.spend_policy);
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      return dispatch({
        type: ActionType.GET_USER_CART_FAILURE,
        payload: message,
      });
    }
  };

export const addProductToCart = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.ADD_PRODUCT_TO_CART_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(
      serverURL.addProductToCart,
      payload,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch(
      { type: ActionType.ADD_PRODUCT_TO_CART_SUCCESS, payload: data },
      localStorage.setItem(
        "cart_id",
        JSON.stringify(data?.data?.cart_identifier)
      )
    );
    dispatch(getUserCart());
    dispatch(saveData("cartListSnack", true));
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.ADD_PRODUCT_TO_CART_FAILURE,
      payload: message,
    });
  }
};


export const updatedProductToCart = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.UPDATED_PRODUCT_TO_CART_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(
      serverURL.updatededitcard,
      payload,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch(
      { type: ActionType.UPDATED_PRODUCT_TO_CART_SUCCESS, payload: data },
      localStorage.setItem(
        "cart_id",
        JSON.stringify(data?.data?.cart_identifier)
      )
    );
    dispatch(getUserCart());
    dispatch(saveData("cartListSnack", true));
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.UPDATED_PRODUCT_TO_CART_FAILURE,
      payload: message,
    });
  }
};

export const removeProductFromCart =
  (cartId, cartItem) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.REMOVE_CART_PRODUCT_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.deleteRequest(
        serverURL.deleteProduct.concat(`${cartId}/${cartItem}`),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({ type: ActionType.REMOVE_CART_PRODUCT_SUCCESS, payload: data });
      dispatch({ type: "RESET_NOTIFICATION" });
      dispatch(getUserCart());
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      return dispatch({
        type: ActionType.REMOVE_CART_PRODUCT_FAILURE,
        payload: message,
      });
    }
  };

export const checkCoupon = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.CHECK_COUPON_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(serverURL.checkCoupon, payload, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });

    dispatch({ type: ActionType.CHECK_COUPON_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.CHECK_COUPON_FAILURE,
      payload: message,
    });
  }
};

export const applyCoupon = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.APPLY_COUPON_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(serverURL.applyCoupon, payload, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });
    dispatch({ type: ActionType.APPLY_COUPON_SUCCESS, payload: data });
    dispatch(getUserCart(localStorage.getItem("cart_id")));
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.APPLY_COUPON_FAILURE,
      payload: message,
    });
  }
};

export const finalizeOrderCheckout =
  (payload) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.FINALIZE_ORDER_CHECKOUT_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.postRequest(
        serverURL.finalizeOrderCheckout,
        payload,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch(
        {
          type: ActionType.FINALIZE_ORDER_CHECKOUT_SUCCESS,
          payload: data,
        
        },
        localStorage.setItem("cart_id", "")
      );
      localStorage.removeItem("is_subscribed_process");
      dispatch({ type: "RESET_CART" });
      dispatch(
        { type: "CLEAR_OUTLET" },
        localStorage.removeItem("selected_outlet_id")
      );
      dispatch(getOutlet());
    } catch (error) {
      reloadHandler(error?.response?.status);
      const statusCode = error?.response?.status;
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.FINALIZE_ORDER_CHECKOUT_FAILURE,
        payload: { errMsg: message, statusCode: statusCode },
      });
    }
  };

export const finalizeOrderVerify = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.FINALIZE_ORDER_VERIFY_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(
      serverURL.finalizeOrderVerify,
      payload,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({
      type: ActionType.FINALIZE_ORDER_VERIFY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.FINALIZE_ORDER_VERIFY_FAILURE,
      payload: message,
    });
  }
};

export const futureScheduleAction = (payload) => async (dispatch, getState) => {
  dispatch({ type: ActionType.CLEAR_RESPONSE });
  dispatch({
    type: ActionType.GET_FUTURE_SCHEDULE_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(
      serverURL.getFutureSchedule,
      payload,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({
      type: ActionType.GET_FUTURE_SCHEDULE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.GET_FUTURE_SCHEDULE_FAILURE,
      payload: message,
    });
  }
};

export const removeCouponAction = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.REMOVE_COUPON_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(
      serverURL.removeCoupon,
      payload,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.REMOVE_COUPON_SUCCESS, payload: data });
    dispatch(getUserCart(payload?.cart_id));
    dispatch({ type: ActionType.CLEAR_COUPON });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.REMOVE_COUPON_FAILURE,
      payload: message,
    });
  }
};

export const validateCheckoutAction =
  (payload, type = null) =>
  async (dispatch, getState) => {
    dispatch({
      type: ActionType.VALIDATE_CHECKOUT_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      localStorage.setItem("checkoutData", JSON.stringify(payload));
      const { data } = await callAPI.postRequest(
        serverURL.validateCheckout,
        payload,
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({
        type: ActionType.VALIDATE_CHECKOUT_SUCCESS,
        payload: { ...data, type: type },
      });
      // localStorage.setItem("checkoutData", JSON.stringify(payload));
    } catch (error) {
      reloadHandler(error?.response?.status);
console.log("validateCheckout",error?.response?.status)
      const statusCode = error?.response?.status;
   
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      return dispatch({
        type: ActionType.VALIDATE_CHECKOUT_FAILURE,
        payload: { message: message, type: type,statusCode: error?.response?.status },
      });
    }
  };

export const cartCounterAction = (cartCount) => async (dispatch) => {
  try {
    dispatch({ type: "CHANGE_CART", count: cartCount });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    // console.log(message);
  }
};

export const notificationCounterAction =
  (notificationCounter) => async (dispatch) => {
    try {
      dispatch({
        type: "CHANGE_NOTIFICATION",
        notification: notificationCounter,
      });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      // console.log(message);
    }
  };

export const reOrderCartAction = (orderId) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.REORDER_CART_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.getRequest(
      serverURL.reOrders.concat(orderId),
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch(
      { type: ActionType.REORDER_CART_SUCCESS, payload: data },
      localStorage.removeItem("cart_id")
    );
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.REORDER_CART_FAILURE,
      payload: message,
    });
  }
};

export const clearCartAction = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.CLEAR_CART_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    // .concat(localStorage.getItem("cart_id"))
    const { data } = await callAPI.getRequest(serverURL.cartClear, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });
    dispatch(
      { type: ActionType.CLEAR_CART_SUCCESS, payload: data },
      localStorage.removeItem("cart_id")
    );
    dispatch({ type: "RESET_CART" });
    dispatch({ type: "CLEAR_USER_CART" });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.CLEAR_CART_FAILURE,
      payload: message,
    });
  }
};


export const getcartdetailsaction = (cartId, cartItem) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.CART_DETAILS_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    // .concat(localStorage.getItem("cart_id"))
    const { data } = await callAPI.getRequest(serverURL.getcartdetails.concat(`${cartId}/${cartItem}`)
      , {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });

    console.log("vikalp",data)
    localStorage.setItem('getcartdetails',JSON.stringify( data.data))
    dispatch(
      { type: ActionType.CART_DETAILS_SUCCESS, payload: data },
   
    );
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
    error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  return dispatch({
    type: ActionType.CART_DETAILS_FAILURE,
    payload: message,
  });
  }
};