import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getOutlet } from "../../redux/actions/outlet/OutletActions.js";
import CustomModal from "../CustomModal/CustomModal.js";
import DarkGrayBtn from "../DarkGrayBtn/DarkGrayBtn.js";
import YellowBtn from "../YellowBtn/YellowBtn.js";
import { modeLinkHandler } from "../../Common/utils/utils.js";
import { clearCartAction } from "../../redux/actions/cart/cartActions.js";
const ScheduleDeliveryDropDown = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mode, setMode] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModel, setShowModel] = useState(false);
  const [showModelmode, setShowModelmode] = useState(false);
  const cartCounter = useSelector((state) => state?.cartCounter);
  const { userInfo } = useSelector((state) => state?.userAddressList);
  const defaultMode = localStorage.getItem("defaultMode");
  const getChangedMode = useSelector((state) => state?.setModes);
  const outlet = useSelector((state) => state?.getOutlet);
  const getCurrentDefaultMode = localStorage.getItem("defaultMode");
  const selected_outlet_id = localStorage.getItem("selected_outlet_id");
  const address_id = localStorage.getItem("address_id");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

 const [outletName,setOutletName]=useState("");

  const navigationHandler = () => {
    if (cartCounter !== 0) {
      dispatch(
        clearCartAction(),
        setAnchorEl(null),
        setShowModel(false),
        localStorage.removeItem("address_id")
      );
    } else {
      setAnchorEl(null);
      setShowModel(false);
    }
    return navigate(modeLinkHandler(localStorage.getItem("selected_modes")));
  };

  const navigationHandlermode = () => {
    if (cartCounter !== 0) {
      dispatch(
        clearCartAction(),
        setAnchorEl(null),
        setShowModelmode(false),
        localStorage.removeItem("address_id")
      );
    } else {
      setAnchorEl(null);
      setShowModelmode(false);
    }
    return  navigate("/choose-mode");
    // navigate(modeLinkHandler(localStorage.getItem("selected_modes")));
  };


  const handleModeChange = () => {
    setAnchorEl(null);
    // setShowModel(false);
    // navigate("/choose-mode");
    if (cartCounter !== 0) {
      setShowModelmode(true);
    } else {
      navigate("/choose-mode");
    }

  };

  const handleModal = (address) => {
    setAnchorEl(null);
    if (cartCounter !== 0) {
      setShowModel(true);
    } else {
      navigationHandler();
    }
  };

  useEffect(() => {
    setMode(getCurrentDefaultMode);
  }, [getChangedMode?.cards?.success, getCurrentDefaultMode]);

  useEffect(()=>{
    if(window.location.hash!="#/order-review")
        setOutletName(localStorage?.getItem("selected_outlet_name"))
     else{
      setOutletName(outlet?.outlet?.data?.outlet_name)
     }        
  },[outlet])

  useEffect(() => {
    if (
      selected_outlet_id === "1" ||
      selected_outlet_id === "2" ||
      selected_outlet_id === "3"
    )
      dispatch(getOutlet(selected_outlet_id));
  }, [selected_outlet_id]);

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      marginTop: theme.spacing(1),
      minWidth: 280,
      marginTop: 22,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow: "none",
      "& .MuiMenu-list": {
        padding: "10px 0",
      },
      "& .MuiMenuItem-root": {
        display: "block !important",
        "& .MuiSvgIcon-root": {
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "& p": {
          marginBottom: "0 !important",
        },
        // "&:active": {
        //   backgroundColor: alpha(
        //     theme.palette.primary.main,
        //     theme.palette.action.selectedOpacity
        //   ),
        // },
      },
    },
  }));

  useMemo(() => {
    setSelectedAddress(
      userInfo?.data?.address?.find(
        (address) => address?.address_id == address_id
      )
    );
  }, [userInfo, address_id]);

  return (
    <React.Fragment>
      <div className={classes.ScheduleDelDropDown}>
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          disableElevation
          disableRipple
          onClick={handleClick}
          endIcon={<img src="./images/arrow-db.svg" alt="Better" />}
        >
          <div className={classes.maxWidth}>
            <p className={classes.first}>{mode ? mode : ""}</p>
            {mode === "Schedule Pick-Up" || mode === "Pick-Up Today" ? (
              <b>
                { outletName}
              </b>
            ) : mode === "Schedule Delivery" ? (
              selectedAddress === null ? (
                <b className={classes.second}>No address is selected</b>
              ) : (
                <b className={classes.second}>
                  {`${
                    selectedAddress?.address_tag === "Custom"
                      ? selectedAddress?.custom_address_tag
                      : selectedAddress?.address_tag
                      ? selectedAddress?.address_tag
                      : ""
                  }`}
                </b>
              )
            ) : null}
          </div>
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem disableRipple>
            {mode ? (
              <>
                <p>{defaultMode}</p>
                {mode !== "Schedule Delivery" ? (
                  <p>
                    {localStorage.getItem("selected_outlet_name")
                      ? localStorage.getItem("selected_outlet_name")
                      : ""}
                  </p>
                ) : (
                  `${
                    selectedAddress?.address_tag === "Custom"
                      ? selectedAddress?.custom_address_tag
                      : selectedAddress?.address_tag
                      ? selectedAddress?.address_tag
                      : ""
                  }`
                )}
              </>
            ) : null}
          </MenuItem>
          <MenuItem onClick={() => handleModal(true)} disableRipple>
            Change location
          </MenuItem>
          <MenuItem onClick={() => handleModeChange()} disableRipple>
            Change order mode
          </MenuItem>
        </StyledMenu>
      </div>
      <CustomModal
        open={showModel}
        title={"TO PROCEED, YOUR CART WILL BE CLEARED."}
        description={""}
      >
        <div className={classes.modalFlex}>
          <DarkGrayBtn
            name={"Cancel"}
            clickHandler={() => setShowModel(false)}
          />
          <YellowBtn name={"Confirm"} clickHandler={navigationHandler} />
        </div>
      </CustomModal>
      <CustomModal
        open={showModelmode}
        title={"TO PROCEED, YOUR CART WILL BE CLEARED."}
        description={""}
      >
        <div className={classes.modalFlex}>
          <DarkGrayBtn
            name={"Cancel"}
            clickHandler={() => setShowModelmode(false)}
          />
          <YellowBtn name={"Confirm"} clickHandler={navigationHandlermode} />
        </div>
      </CustomModal>
      </React.Fragment>
  );
};
export default withStyles(Styles)(ScheduleDeliveryDropDown);
