import { Styles } from "./Style";
import { Link } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Loader from "react-spinner-loader";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import StarRating from "react-svg-star-rating";
import FormLabel from "@mui/material/FormLabel";
import StrConstant from "../../../Common/StrConstant";
import { useDispatch, useSelector } from "react-redux";
import { ActionType } from "../../../redux/ActionTypes";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import AlterMessage from "../../../Components/AlertMessage/AlertMessage";
import { orderRatingAction } from "../../../redux/actions/order/orderActions";

const OrderRating = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [quality_of_order, setOrder] = useState(null);
  const [comment, setComment] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [service_quality, setQuality] = useState(null);
  const [iniValid, setIniValid] = useState(false);
  const { viewOrderDetail } = useSelector((state) => state?.getOrderDetail);
  const { loading, orderRating, error } = useSelector(
    (state) => state?.orderRating
  );

  const submitReview = () => {
    setIniValid(false);
    const payload = {
      id: viewOrderDetail?.data?.order_id,
      quality_of_order,
      service_quality,
      comment,
    };
    // console.log("payload", payload);
    if (quality_of_order === null || service_quality === null)
      return setIniValid(true);
    else return dispatch(orderRatingAction(payload), setIsOpen(true));
  };

  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    dispatch({ type: ActionType.CLEAR_RESPONSE }, setIsOpen(false));
  };
  useEffect(() => {
    // console.log("orderRating", orderRating);
    if (orderRating?.success) return navigate("/order-successfully");
  }, [orderRating]);
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {error && (
        <AlterMessage
          closeHandler={snackHandler}
          url={"./images/error.svg"}
          description={error}
          title={"Error"}
          type={"Error"}
          open={isOpen}
        />
      )}
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <h3>{StrConstant.OrderRating.heading} {viewOrderDetail?.data?.order_number}</h3>
        </div>
        <div>
          <p>{StrConstant.OrderRating.description}</p>
        
          <div className={classes.fromControl}>
            <FormLabel>Order Quality</FormLabel>
            <div className={classes.ratingBox}>
              {" "}
              <StarRating
                innerRadius={28}
                hoverColor={"#FFC200"}
                handleOnClick={(rating) => setOrder(rating)}
                setQuality
              />
            </div>
            {iniValid && quality_of_order <= 0 && (
              <p className="errorMessage">This field is required.</p>
            )}
          </div>
          <div className={classes.fromControl}>
            <FormLabel>Service Quality</FormLabel>
            <div className={classes.ratingBox}>
              {" "}
              <StarRating
                innerRadius={28}
                hoverColor={"#FFC200"}
                handleOnClick={(rating) => setQuality(rating)}
              />
            </div>
            {iniValid && service_quality <= 0 && (
              <p className="errorMessage">This field is required.</p>
            )}
          </div>
          <div className={classes.fromControl}>
            <FormLabel>Comments</FormLabel>
            <textarea
              rows={5}
              aria-label="minimum height"
              placeholder="Enter your comments"
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>
          <div className={classes.btnBox}>
            <YellowBtn name={"SUBMIT"} clickHandler={submitReview} />
          </div>
        </div>
      </OuterBox>
    </>
  );
};

export default withStyles(Styles)(OrderRating);
