import { useSelector } from "react-redux";

export const useAuth = () => {
  const userSignIn = useSelector((state) => state?.userSignin);
  const { userInfo } = userSignIn;
  const loggedIn = userInfo || localStorage.getItem("userInfo") ? true : false;
  const company_employee =
    userInfo?.data?.user_data?.role_name === "company_employee" ? true : false;
  const customer =
    userInfo?.data?.user_data?.role_name === "customer" ? true : false;
  return { loggedIn, company_employee, customer };
};
