import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import StrConstant from "../../../Common/StrConstant";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import DarkGrayBtn from "../../../Components/DarkGrayBtn/DarkGrayBtn";
import { ActionType } from "../../../redux/ActionTypes";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
const SuccessfullyOrder = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { editOrder } = useSelector((state) => state?.editOrder);
  const [edit,setedit]=useState()
  console.log("editOrder",editOrder)
  useEffect(()=>{
if(editOrder?.success){
  localStorage.setItem("editorderId",editOrder?.data?.order_number)
  setedit(editOrder)
dispatch({type:ActionType.CLEAR_UPDATE})

}
  },[editOrder])
  return (
    <>
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_UPDATE }, navigate(-2))
            }
          />
          {/* <h3>{StrConstant.SuccessfullyOrder.heading}</h3> */}
        </div>
        <div className={classes.successOrder}>
          <h4>
            Order {edit?.data?.order_number ?edit?.data?.order_number:localStorage.getItem("editorderId")} has been Successfully Updated
          </h4>
          <div className={classes.btnBox}>
            <DarkGrayBtn
              name={"Return to My Orders"}
              clickHandler={() =>
                dispatch(
                  { type: ActionType.CLEAR_UPDATE },
                  navigate("/my-orders")
                )
              }
            />
          </div>
        </div>
      </OuterBox>
    </>
  );
};
export default withStyles(Styles)(SuccessfullyOrder);
