export const Styles = Theme => ({
    p0: {
        "@media(max-width:640px)": {
            padding: "0 !important",
        },
    },
    p16: {
        "@media(max-width:640px)": {
            padding: "0 16px  !important",
        },
    },
    outerInner: {
        display: "table",
        width: "100%",
    },
    dFlex: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    labelBox: {
        background: "#F1EEDB",
        padding: "15px",
        "& h4": {
            margin: "0",
        }
    },
    ltLine: {
        border: "1px solid #F1EEDB",
        position: "absolute",
        left: "60px",
        height: "100%",
        width: "1px",
        top: "0",
        "@media (max-width: 640px)": {
            left: "55px",
        },
        "& span": {
            height: "14px",
            width: "14px",
            border: "3px solid #FFC200",
            borderRadius: "100%",
            position: "absolute",
            left: "-7px",
            top: "28px",
        },
    },
    cardOuter: {
        "& ul": {
            padding: "0",
            margin: "0"
        },
        "&  li": {
            // width: "33%",
            position: "relative",
            padding: "20px 0 0 80px",
            "@media (max-width: 640px)": {
                padding: "20px 0 0 80px",
            },
        },
        "& div:last-child":{
           paddingBottom:"0",
        }
    },
    dateBox: {
        position: "absolute",
        left: "10px",
        width: "auto",
        "& h3": {
            borderBottom: "2px solid #3F4444",
            marginBottom: "5px",
        },
    },
    box: {
        width: "100%",
        height: "100%",
    },
    btnContent: {
        paddingBottom: "30px",
        display: "table",
        width: "100%",
        marginTop: "20px",
    },
    txtColor:{
        marginBottom:"0 !important",
        "& span":{
            fontSize:'14px',
            fontWeight:"400",
            color:"#3f4444 !important",
            lineHeigt:"20px",
            letterSpacing:".2",
        }
    }
})