import React, { useMemo } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ProductBox from "../../../Components/ProductBox/ProductBox";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFavoritesListAction,
  getProductList,
  toggleFavoritesAction,
} from "../../../redux/actions/product/ProductActions";
import { getOutletCategory } from "../../../redux/actions/outlet/OutletActions";
import Loader from "react-spinner-loader";
import { saveData } from "../../../redux/actions/UserActions";
// import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import { modeLinkHandler } from "../../../Common/utils/utils";
import { useNavigate } from "react-router";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { ActionType } from "../../../redux/ActionTypes";
import OrderStatus from "../../../Components/OrderStatus/OrderStatus";
import ViewMore from "../../../Components/ViewMore/ViewMore";
import { Container } from "@mui/system";
import Opps from "../../../Components/Opps/Opps";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { removeCouponAction } from "../../../redux/actions/cart/cartActions";
const ProductList = (props) => {
  const { classes, onProp, onPage, onTypingEvent } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [isComplete, setIsComplete] = useState(false);
  // const [value, setValue] = useState("0");
  const [search, setSearch] = useState("");
  const cart_id = localStorage.getItem("cart_id");
  const [productData, setProductData] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [favoritesData, setFavoritesData] = useState([]);
  const [closeIcon, setCloseIcon] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("0");
  const { userCart } = useSelector((state) => state?.getUserCart);
  const [selectedSubCategory, setSelectedSubCategory] = useState("0");
  const { productList } = useSelector((state) => state?.getProductList);

  const getProductListLoader = useSelector((state) => state?.getProductList);
  const { favoritesList } = useSelector((state) => state?.favoritesList);
  const favoritesListdata = useSelector((state) => state?.favoritesList);
  const { toggleFavorites } = useSelector(
    (state) => state?.favoritesProductList
  );
  const setDefaultMode = localStorage.getItem("selected_modes");
  localStorage.setItem("currentPage", onPage ? onPage : onProp);
  const selectedOutlet =
    localStorage.getItem("selected_outlet_id") &&
      localStorage.getItem("selected_outlet_id") != "undefined"
      ? localStorage.getItem("selected_outlet_id")
      : 0;
  const { loading, outletCategory } = useSelector(
    (state) => state.getOutletCategory
  );


  const [value, setValue] = useState((outletCategory != undefined && outletCategory?.data?.length == 1) ? outletCategory?.data[0].category_id : "0");

  const prepareProductCall = () => {
    let cateID = "";
    if (selectedSubCategory !== "0") {
      cateID = selectedSubCategory;
    } else {
      cateID = selectedCategory;
    }
    dispatch(
      getProductList(
        selectedOutlet,
        onPage ? onPage : onProp,
        search,
        cateID,
        page
      )
    );
  };

  const inintialTabHAndler = () => {
    setValue((outletCategory != undefined && outletCategory?.data?.length == 1) ? outletCategory?.data[0].category_id : "0")

    if ((outletCategory !== undefined && outletCategory?.data?.length == 1)) {
      categoryChangeHandler(outletCategory?.data[0].category_id, outletCategory?.data[0].child_categories)
    }
  }
  // lol
  useEffect(() => {
    inintialTabHAndler()
  }, [outletCategory])
  const handleSearch = (event) => {
    if (event?.which === 13) {
      // console.log("dsfsdfgjhsd");
      setIsComplete(false);
      if (value === "My Favourites") {
        setValue("0");
        categoryChangeHandler("0", []);
      }
      if (page === 1) {
        setProductData([]);
        prepareProductCall();
      } else {
        setPage(1);
      }
    }
  };
  const searchClickHandler = () => {
    setIsComplete(false);
    if (value === "My Favourites") {
      setValue("0");
      categoryChangeHandler("0", []);
    }
    
    if (page === 1) {
      setProductData([]);
      prepareProductCall();
    } else {
      setPage(1);
    }
  };
  const [count,setcount]=useState(0)
  const categoryChangeHandler = (categoryID, subCategory) => {
    setPage(1);
    selectedCategory !== categoryID &&
      selectedSubCategory !== subCategory &&
      setProductData([]);

    setIsComplete(false);
    if (categoryID === "") {
      setSelectedSubCategory(subCategory);
    } else {
      
      setProductData([])
      console.log("productlistvv",productData,subCategory)
    //   if(productData.length==0){
    //   prepareProductCall()
    // }
    setcount(count+1)
      setSelectedCategory(categoryID);
      setSubCategory(subCategory);
      setSelectedSubCategory("0");
    }
  };
  useEffect(() => {
    dispatch(getOutletCategory(selectedOutlet, onPage ? onPage : onProp));
  }, [selectedOutlet]);

  const loadFavourites = () => {
    onPage !== "/menu" &&
      dispatch(getFavoritesListAction(selectedOutlet, page));
  };
  useEffect(() => {
    if (page === 1) {
      if (value === "My Favourites") {
        setFavoritesData([]);
        loadFavourites();
      } else {
        prepareProductCall();
      }
    } else {
      setPage(1);
    }
  }, [outletCategory, selectedCategory, selectedSubCategory, value,count]);

  useEffect(() => {
    dispatch(
      { type: ActionType.CLEAR_RESPONSE },
      localStorage.setItem("cartListSnack", false)
    );
    dispatch(
      getProductList(
        selectedOutlet,
        onPage ? onPage : onProp,
        search,
        "",
        page
      ),
      getOutletCategory(selectedOutlet, onPage ? onPage : onProp)
    );
  }, []);

  useEffect(() => {
    cart_id &&
      userCart?.data?.coupon_used !== "" &&
      dispatch(
        removeCouponAction({
          cart_id,
          coupon_code: userCart?.data?.coupon_used,
        })
      );
    // console.log("cart_id", cart_id);
    // console.log("userCart?.data?.coupon_used", userCart?.data?.coupon_used);
  }, [userCart]);

  const handleFavUnFav = (ItemID, isFavorite) => {
    dispatch(toggleFavoritesAction(ItemID));
    if (value === "My Favourites") {
      const filterData = favoritesData.findIndex((sd) => {
        return sd.item_id === ItemID;
      });
      const updatedObj = favoritesData;
      updatedObj.splice(filterData, 1);
      setFavoritesData(updatedObj);
    } else {
      const itemData = productData.findIndex((sd) => {
        return sd.item_id === ItemID;
      });
      const updatedObj = productData;
      updatedObj[itemData].is_favorite = isFavorite != 1 ? 1 : 0;
      setProductData(updatedObj);
    }
  };

  // Scroll View
  // useEffect(() => {
  //   window.addEventListener("scroll", onScroll);
  //   return () => {
  //     window.removeEventListener("scroll", onScroll);
  //   };
  // });

  useEffect(() => {

    console.log("productlist", productData, productList);
    if (productList != null) {
      if (productList?.data?.items.length < 10) {
        setIsComplete(true);
      }
      if (productData.length > 0) {
        if (page > 1) {
          let arr = []
          // let samedata;
          for (let i = 0; i < productList?.data?.items?.length; i++) {
            const item_names = productList?.data?.items[i]?.item_name
            console.log("item_name", item_names);
            let samedata = productData?.filter((item) => item?.item_name == item_names)
            if (samedata.length === 0 || samedata.length < 1) {
              arr.push(productList?.data?.items[i])
            }
          }
          setProductData([...productData, ...arr]);
          console.log("arrr", arr)
          // let product = [...productData];
          // setProductData(product.concat(arr));
        }
      } else {
        if (productList?.data?.items.length > 9 && page === 1) {
          // setPage(2);
        }
        setProductData(productList?.data?.items);
      }
    }
  }, [productList]);

  useEffect(() => {
    if (favoritesList != null) {
      // setFavoritesData(favoritesList?.data?.favorites);
      if (favoritesData.length > 0) {
        if (page > 1) {
          setFavoritesData([
            ...favoritesData,
            ...favoritesList?.data?.favorites,
          ]);
        }
      } else {
        setFavoritesData(favoritesList?.data?.favorites);
      }
    }
  }, [favoritesList]);

  useEffect(() => {
    if (page === 1) {
      if (value === "My Favourites") {
        setFavoritesData([]);
      } else {
        setProductData([]);
      }
    }
    if (value === "My Favourites") {
      loadFavourites();
    } else {
      prepareProductCall();
    }
  }, [page]);

  // const onScroll = () => {
  //   const winHeight = window.innerHeight;
  //   const srcHeight = document.documentElement.scrollHeight;
  //   const scrollTop =
  //     document.body.scrollTop || document.documentElement.scrollTop;
  //   const toBottom = srcHeight - winHeight - scrollTop;

  //   if (toBottom <= 100) {
  //     if (!isComplete) {
  //       getData();
  //     }
  //   }
  // };

  // function getData() {
  //   if (getProductListLoader?.loading) return;
  //   const nextPage = page + 1;
  //   // setPage(nextPage);
  // }

  const loadMoreHandler = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    // const handleChange = (value) => {
    //   onTypingEvent(value);
    //   setValue(value);
    //   if (value != "") {
    //     setCloseIcon(true);
    //   } else {
    //     setCloseIcon(false);
    //   }
    // };
  };
  // search close icon add
  const handleChange = (value) => {
    setSearch(value);
    if (value != "") {
      setCloseIcon(true);
    } else {
      setCloseIcon(false);
    }
  };

  useEffect(() => {
    if (!closeIcon) {
      setIsComplete(false);
      if (page === 1) {
        setProductData([]);
        prepareProductCall();
      } else {
        setPage(1);
      }
    }
  }, [closeIcon]);

  const browseHandler = (toAll) => {
    if (toAll === "0") {
      setValue(toAll);
      categoryChangeHandler("0", []);
    } else {
      // console.log("value:", toAll);
    }
  };

  const handleSearchClose = (value) => {
    setSearch(value);
    setCloseIcon(false);
    // setIsComplete(false);
    // if (page === 1) {
    //   setProductData([]);
    //   prepareProductCall();
    // } else {
    //   setPage(1);
    // }
  };
  const [timerloading, settimerloading] = useState(false)
  useEffect(() => {
    settimerloading(true)
    if (loading == false) {

      setTimeout(() => {
        settimerloading(false)
      }, 1000)
    }
  }, [loading])
  // console.log("loading",getProductListLoader?.loading,toggleFavorites?.loading )
  return (
    <>
      {timerloading && (
        <Loader
          show={timerloading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {getProductListLoader?.loading && page === 1 && (
        <Loader
          show={getProductListLoader?.loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <Container maxWidth="lg">
        <div className={classes.outerInner}>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <div className={classes.box}>
            <div className={classes.searchBox}>
              <input
                type="text"
                value={search}
                onKeyDown={handleSearch}
                placeholder="Find a drink or snack"
                onChange={(event) => handleChange(event.target.value)}
              />
              <img
                src="./images/search.svg"
                alt="Better Coffee"
                onClick={searchClickHandler}
              />
              {closeIcon != "" && (
                <Button
                  disableRipple
                  className="closeIcon"
                  onClick={() => handleSearchClose("")}
                >
                  <CloseIcon />
                </Button>
              )}
            </div>
            <TabContext value={String(value)}>
              <div className={classes.tabDesign}>
                <TabList
                  value={String(value)}
                  onChange={(event, newValue) => setValue(newValue)}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs example"
                >
                  {outletCategory?.data.length > 1 && (

                    <Tab
                      label="All"
                      value="0"
                      onClick={() => {
                        categoryChangeHandler("0", [])
                      }}
                    />

                  )}
                  {outletCategory?.data.map((data, index) => (
                    <Tab
                      key={index}
                      label={data?.category_name}
                      value={String(data?.category_id)}
                      onClick={() =>
                        categoryChangeHandler(
                          data?.category_id,
                          data?.child_categories
                        )
                      }
                    />
                  ))}
                  {onPage !== "/menu" && (
                    <Tab label="My Favourites" value="My Favourites" />
                  )}
                </TabList>
              </div>
              <TabPanel
                value={String(selectedCategory)}
                className={classes.tabContainer}
              >
                {subCategory && (
                  <div className={classes.chipDesign}>
                    {subCategory &&
                      subCategory?.map((subcat, index) => (
                        <Stack key={index} direction="row">
                          <Chip
                            label={subcat?.category_name}
                            className={
                              selectedSubCategory === subcat?.category_id
                                ? classes.active
                                : null
                            }
                            onClick={() =>
                              categoryChangeHandler("", subcat?.category_id)
                            }
                          ></Chip>
                        </Stack>
                      ))}
                  </div>
                )}
                {/* <div className={classes.chipDesign}>
                  {subCategory &&
                    subCategory?.map((subcat, index) => (
                      <Stack key={index} direction="row">
                        <Chip
                          label={subcat?.category_name}
                          className={classes.active}
                          onClick={() =>
                            categoryChangeHandler("", subcat?.category_id)
                          }
                        ></Chip>
                      </Stack>
                    ))}
                </div> */}
                <div className={classes.boxOuter}>
                  {

                    getProductListLoader?.loading == false &&
                    toggleFavorites?.loading == false &&
                    productData.length === 0 && (
                      <div className={classes.oppsBox}>
                        <Opps />
                      </div>
                    )}
                  <ul>
                    {productData.map((data, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          dispatch(saveData("item_id", data?.item_id))
                        }
                      >
                        <ProductBox
                          onPage={onPage}
                          url={data?.image_url}
                          inStock={data?.in_stock}
                          creditsNo={data?.item_price}
                          itemId={data?.outlet_item_id}
                          coffeeName={data?.item_name}
                          isFavorite={data?.is_favorite}
                          sameOutlet={data?.same_outlet}
                          regularPrice={data?.regular_price}
                          favItemID={data?.item_id}
                          favUnfavClicked={handleFavUnFav}
                        />
                      </li>
                    ))}
                  </ul>
                  {onPage == "/menu" && (
                    <div className={classes.btnContent}>
                      {" "}
                      {/* <YellowBtn
                      name={"Place Order"}
                      clickHandler={() => {
                        navigate(modeLinkHandler(setDefaultMode));
                      }}
                    /> */}
                      <OrderStatus
                        // link={modeLinkHandler(setDefaultMode)}
                        link={"/choose-mode"}
                        text={"Place order"}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>
              {onPage !== "/menu" && (
                <TabPanel
                  value="My Favourites"
                  className={classes.tabContainer}
                >
                  <div className={classes.chipDesign}></div>
                  <div className={classes.boxOuter}>
                    {favoritesListdata?.loading == false && favoritesData?.length === 0 && favoritesList?.data?.favorites?.length === 0 && (
                      <div className={classes.oppsBox}>
                        <Opps
                          description={
                            "There's nothing to show here. Start saving your favourites now!"
                          }
                          isBrowse={true}
                          onBrowse={browseHandler}
                        />
                      </div>
                    )}
                    <ul>
                      {favoritesData?.map((data, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            dispatch(saveData("item_id", data?.item_id))
                          }
                        >
                          <ProductBox
                            url={
                              data?.image_url?.length > 0 ? data?.image_url : ""
                            }
                            inStock={data?.in_stock}
                            coffeeName={data?.item_name}
                            creditsNo={data?.item_price}
                            regularPrice={data?.regular_price}
                            itemId={data?.outlet_item_id}
                            isFavorite={data?.is_favorite}
                            isDisable={data?.same_outlet}
                            sameOutlet={data?.same_outlet}
                            favItemID={data?.item_id}
                            favUnfavClicked={handleFavUnFav}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </TabPanel>
              )}
            </TabContext>
          </div>
          <div>
            {value === "My Favourites" ? (
              favoritesList?.data?.pagination?.lastPage === page ||
                favoritesList?.data?.pagination?.count === favoritesData.length ||
                favoritesData.length < 1 ? null : (
                <ViewMore name={"Load More"} clickHandler={loadMoreHandler} />
              )
            ) : productList?.data?.pagination?.lastPage === page ||
              productList?.data?.pagination?.count === productData.length ||
              productData.length < 1 ? null : (
              <ViewMore name={"Load More"} clickHandler={loadMoreHandler} />
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default withStyles(Styles)(ProductList);
