import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#fff",
  border: "1.5px solid rgba(63, 68, 68, 0.5)",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    border: "1.5px solid #FFC200",
    outlineOffset: 2,
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  border: "4px solid #FFC200",
  backgroundColor: "#000",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    content: '""',
  },
});
// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
const CommonRadio = (props) => {
  const { radioData = [], name = "", onBinding } = props;
  const [selection, setSelection] = useState("");
  useEffect(() => {
    onBinding(selection);
  }, [selection]);
  return (
    <FormControl>
      <RadioGroup
        name={name}
        // defaultValue="female"
        aria-labelledby="customized-radios"
      >
        {radioData.map((data, index) => (
          <FormControlLabel
            key={index}
            label={data.name}
            value={data.value}
            control={<BpRadio />}
            onChange={() => setSelection(data.value)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
export default CommonRadio;
