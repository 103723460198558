
import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Styles } from './Style';
import FormLabel from "@mui/material/FormLabel";
import StrConstant from '../../../../Common/StrConstant';
import OuterBox from '../../../../Components/OuterBox/OuterBox';
import DarkGrayBtn from '../../../../Components/DarkGrayBtn/DarkGrayBtn'
import { useNavigate } from 'react-router';
import YellowBtn from '../../../../Components/YellowBtn/YellowBtn';
import BackArrow from "../../../../Components/BackArrow/BackArrow";
const AccountDeletionSuccess = (props) => {
    const { classes, } = props
    let navigate = useNavigate();
    const buttonClick = () => {
        navigate('/');
    }
    const successMessage = () => {
        navigate('/submission-feedback');
    }
    return (
        <>
            <OuterBox>
                <div>
                    <BackArrow
                        clickHandler={() => {
                            navigate(-1);
                        }}
                    />
                    <h3 >{StrConstant.AccountDeletionSuccess.heading}</h3>
                </div>
                <div>
                    <p>{StrConstant.AccountDeletionSuccess.description1}</p>
                    <p>{StrConstant.AccountDeletionSuccess.description2}</p>
                    <p>{StrConstant.AccountDeletionSuccess.description3}</p>
                    <div className={classes.fromControl}>
                        <FormLabel>Feedback</FormLabel>
                        <div className={classes.passwordBox}>
                            <textarea type="text" name="" rows={4} placeholder="Enter Feedback"></textarea>
                        </div>
                    </div>
                    <div className={classes.btnBox}>
                        <YellowBtn name={"Submit"} clickHandler={successMessage} />
                        <DarkGrayBtn name={"Return to bettrcloudbar.sg"} clickHandler={buttonClick} />
                    </div>
                </div>
            </OuterBox>
        </>
    )
}
export default withStyles(Styles)(AccountDeletionSuccess);