export const Styles = Theme => ({
    formOuter: {
        "@media (max-width: 768px)": {
            height: "auto",
            display: "table",
            width: "100%",
            height: "100%",
            background: "#F7F7F7",
        },
    },
    formInner: {
        background: "rgba(255, 255, 255, 0.75)",
        borderRadius: "12px",
        padding: "2rem",
        maxWidth: "420px",
        width: "100%",
        height: "auto",
        margin: "0 auto",
        display: "block",
        boxSizing: "border-box",
        overflowY: "auto",
        maxHeight: "100vh",
        "@media (max-width: 768px)": {
            maxWidth: "100%",
            background: "#F7F7F7",
            maxHeight: "100%",
            overflowY: "inherit",
            borderRadius: "0",
            padding: "1rem 1.5rem",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
        },
        "&::-webkit-scrollbar": {
            appearance: "none",
            width: "0",
        },
        "&::-moz-scrollbar": {
            appearance: "none",
            width: "0",
        },
        "&::-o-scrollbar": {
            appearance: "none",
            width: "0",
        },
        "&::-ms-scrollbar": {
            appearance: "none",
            width: "0",
        },
        "& h2": {
            marginTop: "0",
        },
    },
    btxt: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        padding: "10px 0",
        "& p": {
            color: "#3F4444",
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "400",
            margin: "0 0 5px",
            fontFamily: "'Noto Serif', serif",
        },
        "& a": {
            color: "#3F4444",
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "700",
            fontFamily: "'Noto Serif', serif",
            textDecoration: "underline",
        }
    },
    back: {
        "& a": {
            maxWidth: "80px",
            height: "auto",
            margin: "20px 0",
            "& img": {
                height: "auto",
                width: "100%",
            }
        }
    },
    topBox: {
        "& b": {
            color: "#3F4444",
        },
        "& div": {
                margin: "0",
        }
    },
    btnTxt: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& > div": {
            maxWidth: "100%",
        },
    },
    circularProgress: {
        width: "auto !important",
        height: "auto !important",
    }
})

