import * as React from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import { Button } from '@mui/material';
const CustomModal = (props) => {
  const { classes, open, children, title, description, modalClose } = props;
  return (
    <>
      <React.Fragment>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          onClose={modalClose}
        >
          <Fade in={open}>
            <div className={classes.modalContent}>
              {/* <Button onClick={modalClose}>close</Button> */}
              <div className={classes.modalInner}>
                <div className={classes.title}>
                  <h3>{title}</h3> <p>{description}</p>
                </div>
                {children}
              </div>
            </div>
          </Fade>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default withStyles(Styles)(CustomModal);
