import React from "react";
import Header from "../Components/Header/Header";

const PublicRoutes = ({ children }) => {
  return (
    <>
      <div className="headerOuter">
        <Header page={"/menu"} />
      </div>
      <div className="paddingTop">{children}</div>
    </>
  );
};

export default PublicRoutes;
