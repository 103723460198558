import {
  validateEmail,
  validateName,
  validatePassword,
} from "../../Common/Validations";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import FormLabel from "@mui/material/FormLabel";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import YellowBtn from "../YellowBtn/YellowBtn";
import SocialBtn from "../SocialBtn/SocialBtn";
import { Link, useNavigate } from "react-router-dom";
import Propover from "../../Components/Propover/Propover";
import StrConstant from "../../Common/StrConstant";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../redux/actions/UserActions";
import Loader from "react-spinner-loader";
import AlterMessage from "../AlertMessage/AlertMessage";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LogoContainer from "../LogoContainer/LogoContainer";
import { encryptValue } from "../../Common/utils/utils";
const fields = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  accepted_term_condition: false,
  promotion_check: false,
  inValid: false,
};
const SignupForm = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [formFields, setFormFields] = useState(fields);
  const [showPassword, setShowPassword] = useState(false);
  const { loading, error, success } = useSelector(
    (state) => state.userRegister
  );
  const checkBoxHandler = (check, name) => {
    setFormFields((pre) => ({ ...pre, [name]: check }));
  };
  const inputHandler = (e) => {
    const { name, value } = e.target;
    setFormFields((pre) => ({ ...pre, [name]: value }));
  };
  const submitHandler = () => {
    if (
      validateName(formFields.first_name) ||
      validateEmail(formFields.email) ||
      validatePassword(formFields.password) ||
      formFields.last_name === "" ||
      formFields.accepted_term_condition === false
    )
      return setFormFields((pre) => ({ ...pre, inValid: true }));
    else {
      let payload = {
        first_name: encryptValue(formFields?.first_name),
        last_name: encryptValue(formFields?.last_name),
        email: encryptValue(formFields?.email),
        password: encryptValue(formFields?.password),
        accepted_term_condition: formFields?.accepted_term_condition,
        promotion_check: formFields?.promotion_check,
      };
      formFields.inValid && console.count("inValid:");
      setIsOpen(true);
      dispatch(registerUser(payload));
    }
  };

  const handleSubmit = (event) => {
    if (event?.which === 13) {
      submitHandler();
    }
  };

  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    setFormFields((pre) => ({ ...pre, inValid: false }));
    setIsOpen(false);
  };
  // custom link
  const moveToCustomMenu = (url) => {
    // window.location.href = url;
    window.open(url, "_blank");
    // cancelHandler();
  };
  useEffect(() => {
    if (success) {
      navigate(`/resend-email/${formFields.email}`);
    }
  }, [success]);
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {success && (
        <AlterMessage
          closeHandler={snackHandler}
          description={success}
          title={"Success"}
          type={"Success"}
          open={isOpen}
          url={"./images/success.svg"}
        />
      )}
      {error && (
        <AlterMessage
          closeHandler={snackHandler}
          description={error}
          title={success ? "Success" : "Error"}
          type={success ? "Success" : "Error"}
          open={isOpen}
          url={success ? "./images/success.svg" : "./images/error.svg"}
        />
      )}
      <form>
        <div className={classes.formOuter}>
          <div className={classes.formInner}>
            <LogoContainer
              heading={StrConstant.SignUp.heading}
              goUrl={"/login"}
              urlTxt={"Back to Login"}
            />
            <div className={classes.fromControl}>
              <FormLabel>
                Name <span className="req">*</span>
              </FormLabel>
              <div className={classes.dFlex}>
                <div className={classes.wid50}>
                  {" "}
                  <input
                    type="text"
                    name="first_name"
                    placeholder="First name"
                    onKeyDown={handleSubmit}
                    value={formFields.first_name}
                    onChange={(e) => inputHandler(e)}
                  />
                  {formFields.inValid && formFields.first_name === "" ? (
                    <p className="errorMessage">This field is required.</p>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className={classes.wid50}>
                  {" "}
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                    onKeyDown={handleSubmit}
                    value={formFields.last_name}
                    onChange={(e) => inputHandler(e)}
                  />
                  {formFields.inValid && formFields.last_name === "" ? (
                    <p className="errorMessage">This field is required.</p>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.fromControl}>
              <FormLabel>
                Email address<span className="req">*</span>
              </FormLabel>
              <input
                type="email"
                name="email"
                value={formFields.email}
                onKeyDown={handleSubmit}
                placeholder="Enter your email address"
                onChange={(e) => inputHandler(e)}
              />
              {formFields.inValid && formFields.email === "" ? (
                <p className="errorMessage">This field is required.</p>
              ) : (
                <div></div>
              )}
              {formFields.email !== "" && validateEmail(formFields.email) ? (
                <p className="errorMessage">
                  Information entered is invalid. Please try again.{" "}
                </p>
              ) : null}
            </div>
            <div className={classes.fromControl}>
              <FormLabel className={classes.passLabel}>
                Password <span className="req">*</span>
                <Propover>
                  <b>{StrConstant.SignUp.propoverTitle}</b>
                  <ul>
                    <li>
                      <span>{StrConstant.SignUp.propoverDescription1}</span>
                    </li>
                    <li>
                      <span>{StrConstant.SignUp.propoverDescription2}</span>
                    </li>
                    <li>
                      <span>{StrConstant.SignUp.propoverDescription3}</span>
                    </li>
                    <li>
                      <span>{StrConstant.SignUp.propoverDescription4}</span>
                    </li>
                  </ul>
                </Propover>
              </FormLabel>
              <div className={classes.passwordField}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onKeyDown={handleSubmit}
                  value={formFields.password}
                  placeholder="Create your password"
                  onChange={(e) => inputHandler(e)}
                />
                <span onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </span>
              </div>
              {formFields.inValid && formFields.password === "" ? (
                <p className="errorMessage">This field is required.</p>
              ) : (
                <div></div>
              )}
              {formFields.password !== "" &&
              validatePassword(formFields.password) ? (
                <p className="errorMessage">
                  Your password must be at least 8 characters, with at least one number, one special character, and have a mixture of uppercase and lowercase letters.
                  {/* Information entered is invalid. Please try again. */}
                </p>
              ) : null}
            </div>

            <label className={classes.textCheckbox}>
              <CustomCheckbox
                onChange={checkBoxHandler}
                name={"accepted_term_condition"}
              />
              <p className={classes.labelTxt}>
                I agree with Bettr Group's{" "}
                <Link
                  to=""
                  onClick={() =>
                    moveToCustomMenu(
                      "https://www.order.bettr.coffee/terms-of-service"
                    )
                  }
                >
                  Terms of Use
                </Link>{" "}
                &
                <Link
                  to=""
                  onClick={() =>
                    moveToCustomMenu(
                      "https://www.order.bettr.coffee/privacy-policy"
                    )
                  }
                >
                  Privacy Policy.
                </Link>
              </p>
            </label>

            <label className={classes.textCheckbox}>
              <CustomCheckbox
                onChange={checkBoxHandler}
                name={"promotion_check"}
              />
              <p className={classes.labelTxt}>
                I'd like to be kept up to date on news and exclusive offers.
              </p>
            </label>
            {formFields.inValid &&
              formFields.accepted_term_condition === false && (
                <p className="errorMessage">
                  You must agree to the <b>Terms of Use</b> &{" "}
                  <b>Privacy Policy</b> to proceed.
                </p>
                // <p className="errorMessage">This field is required.</p>
              )}
            <div className={classes.btnTxt} onClick={(e) => submitHandler(e)}>
              <YellowBtn name={"Next"} />
            </div>
            <div className={classes.haveTxt}>
              <p>Have an account?</p>
              <Link to="/login">Login.</Link>
            </div>

            <div className={classes.continueTxt}>
              <p>Or continue with</p>
            </div>
            <SocialBtn />
          </div>
        </div>
      </form>
    </>
  );
};
export default withStyles(Styles)(SignupForm);
