import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstant from "../../Common/StrConstant";
import YellowBtn from "../../Components/YellowBtn/YellowBtn";
import { useNavigate } from "react-router";
const NotFound = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  return (
    <div className={classes.notFountOuter}>
      <div className={classes.notFountInner}>
        <div className={classes.imgContent}>
          <img src="images/404.svg" alt="Better Coffee" />
        </div>
        <h3>{StrConstant.RestrictedPage.heading}</h3>
        <p className={classes.desShow}>
          {StrConstant.RestrictedPage.disDesktop}
        </p>
        <p className={classes.mobShow}>
          {StrConstant.RestrictedPage.disMobile}
        </p>
        <YellowBtn
          name={"home"}
          clickHandler={() => {
            navigate("/my-dashboard");
          }}
        />
      </div>
    </div>
  );
};
export default withStyles(Styles)(NotFound);
