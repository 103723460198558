import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import { toggleFavoritesAction } from "../../redux/actions/product/ProductActions";
import { saveData } from "../../redux/actions/UserActions";
// import { modeLinkHandler } from "../../Common/utils/utils";
const ProductBox = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const id = localStorage.getItem("selected_modes");
  const {
    classes,
    url = "",
    coffeeName,
    creditsNo,
    itemId,
    isFavorite = 1,
    onPage,
    regularPrice,
    // sameOutlet = false,
    inStock,
    favUnfavClicked = false,
    favItemID = 0,
  } = props;
  // const setDefaultMode = localStorage.getItem("selected_modes");
  // const linkHandler = () => {
  //   if (id === "1") return "/item-modifier";
  //   else if (id === "2") return "/item-modifier";
  //   else if (id === "3") return "/item-modifier";
  //   else return "/choose-mode";
  // };
  const handleItemClick = (itemID) => {
    if (inStock === 1) {
      dispatch(saveData("itemId", itemID));
      navigate((onPage === "/menu" && "/choose-mode") || "/item-modifier");
    }
  };
  const handleFavUnFav = (ItemID, isFavorite) => {
    favUnfavClicked(ItemID, isFavorite);
  };
  return (
    <div className={classes.productBox}>
      {onPage !== "/menu" && (
        <div onClick={() => handleFavUnFav(favItemID, isFavorite)}>
          {isFavorite === 1 || isFavorite ? (
            <FavoriteIcon className={classes.borderIcon} />
          ) : (
            <FavoriteBorderIcon className={classes.icon} />
          )}
        </div>
      )}
      <div
        className={`${classes.innerBox} ${inStock === 0 && classes.disable}`}
        onClick={() => handleItemClick(itemId)}
      >
        <div className={classes.topBox}>
          <div className={classes.imgContent}>
            {url !== "" && <img src={url} alt={coffeeName} />}
          </div>
        </div>
        <div className={classes.bottomBox}>
          <h5>{coffeeName}</h5>
          <p>{`${creditsNo === 0 ? regularPrice : creditsNo}`} Credits</p>
        </div>
      </div>
    </div>
  );
};

export default withStyles(Styles)(ProductBox);
