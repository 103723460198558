import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import YellowBtn from "../YellowBtn/YellowBtn";
import DrakGrayBtn from "../DarkGrayBtn/DarkGrayBtn";
import CustomModal from "..//CustomModal/CustomModal";
import {
  getUserProfileAction,
  userLogOut,
} from "../../redux/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useAuth } from "../../Hooks/useAuth";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ActionType } from "../../redux/ActionTypes";
import { styled, alpha } from "@mui/material/styles";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Navbar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { company_employee } = useAuth();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { classes, cancelHandler, page = null } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [showModel, setShowModel] = useState(false);
  const { userProfile } = useSelector((state) => state?.getUserProfile);
  const open = Boolean(anchorEl);
  const openProfile = Boolean(anchorE2);
  const moveToMenu = (url) => {
    navigate(url);
    cancelHandler();
  };
  const moveToCustomMenu = (url) => {
    // window.location.href = url
    window.open(url, "_blank");
    // cancelHandler();
  };
  useEffect(() => {
    dispatch({ type: ActionType.CLEAR_RESPONSE });
    localStorage.removeItem("addressId");
  }, [anchorEl]);

  useEffect(() => {
    dispatch({ type: ActionType.CLEAR_RESPONSE });
    localStorage.removeItem("addressId");
  }, [anchorE2]);

  const handleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorE2(null);
  };
  useEffect(() => {
    if (page !== "/menu") dispatch(getUserProfileAction());
  }, []);
  // console.log("userInfo", userInfo,userInfo.data.user_data.first_name);
  return (
    <>
      <div className={classes.navbarOuter}>
        <div className={classes.navbarInner}>
          <div className={classes.topBox}>
            <Link to="" onClick={cancelHandler} className={classes.close}>
              <img
                src="./images/close.png"
                alt="better coffee"
                className={classes.closeIcon}
              />
              <img
                src="./images/yellow-close.png"
                alt="better coffee"
                className={classes.closeYellow}
              />
            </Link>
          </div>
          <div className={classes.navbar}>
            <div className={classes.logoContent}>
              <Link
                to="/my-dashboard"
                onClick={() => moveToMenu("/my-dashboard")}
              >
                <img src="./images/logo.png" alt="better coffee" />
              </Link>
            </div>
            <ul>
              {/* <li disableRipple>
                <span onClick={() => moveToMenu("/my-dashboard")}>Home</span>
              </li> */}
              <li>
                <span onClick={() => moveToMenu("/menu")}>Menu</span>
              </li>
              {company_employee ? null : (
                <>
                  <li>
                    <span onClick={() => moveToMenu("/purchase-credits")}>
                      Buy Credits
                    </span>
                  </li>
                  <li>
                    <span
                      onClick={() =>
                        moveToMenu(
                          `${
                            userProfile?.data?.is_subscribed === true
                              ? "/manage-subscription"
                              : "/choose-plan"
                          }`
                        )
                      }
                    >
                      Subscription
                    </span>
                  </li>
                </>
              )}
              <li>
                <span onClick={() => moveToMenu("/my-dashboard")}>
                  My Dashboard
                </span>
              </li>
              <li className="dnone">
                <div className={classes.customDropDown}>
                  <Button
                    id="fade-button"
                    aria-controls={open ? "fade-menu" : null}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : null}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    disableRipple
                  >
                    <span>My Account</span>
                    {/* <KeyboardArrowDownIcon /> */}
                    <img src="./images/arrow-close.svg" alt="better coffee" />
                  </Button>
                  <Menu
                    id="fade-menu"
                    disableRipple
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    TransitionComponent={Fade}
                    className={classes.dropdownItems}
                  >
                    <MenuItem disableRipple onClick={() => setAnchorEl(null)}>
                      <span onClick={() => moveToMenu("/profile")}>
                        Profile
                      </span>
                    </MenuItem>
                    {company_employee ? null : (
                      <MenuItem disableRipple onClick={() => setAnchorEl(null)}>
                        <span
                          onClick={() => moveToMenu("/manage-payment-methods")}
                        >
                          Manage payment methods
                        </span>
                      </MenuItem>
                    )}
                    <MenuItem disableRipple onClick={() => setAnchorEl(null)}>
                      <span onClick={() => moveToMenu("/manage-addresses")}>
                        Manage addresses
                      </span>
                    </MenuItem>
                  </Menu>
                </div>
              </li>
              <li className="dnone">
                <span onClick={() => moveToMenu("/menu")}>Bettr’s Menu</span>
              </li>
              {company_employee ? null : (
                <li className="dnone">
                  <span
                    onClick={() =>
                      moveToMenu(
                        `${
                          userProfile?.data?.is_subscribed === true
                            ? "/choose-plan"
                            : "/manage-subscription"
                        }`
                      )
                    }
                  >
                    Plans
                  </span>
                </li>
              )}
              <li className="dnone">
                <ul className={classes.secondList}>
                  <li disableRipple>
                    <span
                      onClick={() =>
                        moveToCustomMenu(
                          "https://www.order.bettr.coffee/how-it-works"
                        )
                      }
                    >
                      How It Works
                    </span>
                  </li>
                  <li disableRipple>
                    <span
                      onClick={() =>
                        moveToCustomMenu("https://www.order.bettr.coffee/faqs")
                      }
                    >
                      FAQs
                    </span>
                  </li>
                  <li disableRipple>
                    <span
                      onClick={() =>
                        moveToCustomMenu(
                          "https://www.order.bettr.coffee/contact-us"
                        )
                      }
                    >
                      Contact Us
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <ul className={classes.secondList}>
                  <li disableRipple>
                    <span
                      onClick={() =>
                        moveToCustomMenu(
                          "https://www.order.bettr.coffee/our-impact-story"
                        )
                      }
                    >
                      Our Impact
                    </span>
                  </li>
                  <li disableRipple>
                    <span
                      onClick={() =>
                        moveToCustomMenu("https://www.order.bettr.coffee/faqs")
                      }
                    >
                      FAQs
                    </span>
                  </li>
                  <li disableRipple>
                    <span
                      onClick={() =>
                        moveToCustomMenu(
                          "https://www.order.bettr.coffee/contact-us/"
                        )
                      }
                    >
                      Get In Touch
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.btBox}>
          <div className={classes.btLeft}>
            <p className={classes.userName}>
              {/* {userProfile?.data?.first_name || ""} */}
              {userInfo?.data?.user_data?.first_name}
            </p>
            <span></span>
          </div>
          {userInfo === null ? null : (
            <div className={classes.btRight}>
              <div
                className={`${classes.customDropDown} ${classes.bottomDropDown}`}
              >
                <Button
                  id="profileMenu-button"
                  aria-controls={openProfile ? "profileMenu-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openProfile ? "true" : undefined}
                  // variant="contained"
                  disableElevation
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </Button>

                {/* <Button
                id="bottomProfile"
                aria-controls={openProfile ? "profileMenu" : undefined}
                aria-haspopup="true"
                aria-expanded={openProfile ? "true" : undefined}
                onClick={(event) => setAnchorE2(event.currentTarget)}
              >
                <MoreVertIcon />
              </Button> */}
                {/* <Menu
                className={classes.profileSelect}
                id="profileMenu"
                MenuListProps={{
                  "aria-labelledby": "bottomProfile",
                }}
                anchorE2={anchorE2}
                open={openProfile}
                onClose={() => setAnchorE2(undefined)}
                anchorOrigin={{
                  // vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  // vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => setAnchorE2(null)}>
                  <span onClick={() => moveToMenu("/change-password")}>
                    Change password
                  </span>
                </MenuItem>
                <MenuItem onClick={() => setShowModel(!showModel)}>
                  <span>Logout</span>
                </MenuItem>
              </Menu> */}

                <StyledMenu
                  id="profileMenu-menu"
                  MenuListProps={{
                    "aria-labelledby": "profileMenu-button",
                  }}
                  anchorEl={anchorE2}
                  open={openProfile}
                  onClose={handleClose}
                >
                  {/* {userInfo?.data?.user_data?.provider === "" ||
                  userInfo?.data?.user_data?.provider == undefined ? ( */}
                 {(userInfo?.data?.user_data?.provider=="google"|| userInfo?.data?.user_data?.provider=="facebook")
                 ? null: 
                 <MenuItem
                 onClick={() => {
                   setAnchorE2(null);
                   moveToMenu("/change-password");
                 }}
                 disableRipple
               >
                 <span>Change password</span>
               </MenuItem>
                 }
                 
                  {/* ) : null */}
                  <MenuItem
                    onClick={() => setShowModel(!showModel)}
                    disableRipple
                  >
                    <span>Logout</span>
                  </MenuItem>
                </StyledMenu>
              </div>
            </div>
          )}
        </div>
      </div>
      <CustomModal
        open={showModel}
        title={"Are you sure you want to log out?"}
        description={"You'll be missed, see you again soon!"}
      >
        <div className={`${classes.box} ${classes.modalContent}`}>
          <div className={classes.modalFlex}>
            <DrakGrayBtn
              name="Cancel"
              clickHandler={() => setShowModel(false)}
            />
            <YellowBtn
              name={"Log out"}
              clickHandler={() => dispatch(userLogOut(), navigate("/login"))}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default withStyles(Styles)(Navbar);
