import { ActionType } from "../../ActionTypes";

export const modesListReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.MODES_LIST_REQUEST:
      return { ...state, loading: true };
    case ActionType.MODES_LIST_SUCCESS:
      return { ...state, loading: false, success: true, cards: action.payload };
    case ActionType.MODES_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const setModesReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.SET_MODE_REQUEST:
      return { ...state, loading: true };
    case ActionType.SET_MODE_SUCCESS:
      return { ...state, loading: false, success: true, cards: action.payload };
    case ActionType.SET_MODE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case "CLEAR_MODE":
      return {};
    default:
      return state;
  }
};
