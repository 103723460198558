import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { useNavigate } from "react-router";
import StrConstant from "../../../Common/StrConstant";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import DarkGrayBtn from "../../../Components/DarkGrayBtn/DarkGrayBtn";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import CommonSection from "../../../Components/CommonSection/CommonSection";
import { ActionType } from "../../../redux/ActionTypes";
import { useDispatch } from "react-redux";
const EditSummary = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const buttonClick = () => {
    navigate("/view-details");
  };
  const successUpdate = () => {
    navigate("/successfully-order");
  };
  return (
    <>
      <CommonSection>
        <div className={classes.outer}>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <h3>{StrConstant.EditSummay.heading}</h3>
        </div>
        <div className={classes.listingOuter}>
          <div className={classes.heading}>
            <h4>Delivery</h4>
          </div>
          <ul className={classes.listingBox}>
            <li className={classes.dFlex}>
              <div className={classes.lt}>
                <h5>Date</h5>
              </div>
              <div className={classes.rt}>
                <p>30 Apr 2022, Thursday</p>
              </div>
            </li>
            <li className={classes.dFlex}>
              <div className={classes.lt}>
                <h5>Time slot</h5>
              </div>
              <div className={classes.rt}>
                <p>12:00 - 14:00</p>
              </div>
            </li>
            <li className={classes.dFlex}>
              <div className={classes.lt}>
                <h5>Address</h5>
              </div>
              <div className={classes.rt}>
                <b>Office</b>
                <p>53 Tanjong Pagar Road #33-02 Singapore 234567</p>
              </div>
            </li>
          </ul>
        </div>
        <div className={classes.btnBox}>
          <YellowBtn name={"Submit Changes"} clickHandler={successUpdate} />
          <DarkGrayBtn
            name={"Discard changes and return"}
            clickHandler={buttonClick}
          />
        </div>
      </CommonSection>
    </>
  );
};

export default withStyles(Styles)(EditSummary);
