export const Styles = (Theme) => ({
  fromControl: {
    margin: "20px 0 25px",
  },
  btnBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    "@media(max-width:640px)": {
      justifyContent: "center",
    },
    "& > div": {
      "@media(max-width:640px)": {
        float: "unset",
        margin: "auto",
      },
    },
  },
  orderLink: {
    marginBottom: "20px",
  },
  ratingBox: {
    "& span": {
      "@media(max-width:640px)": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        "& :last-child": {
          marginRight: "0",
        },
      },
    },
    "& svg": {
      margin: "0 25px 0 0",
      fill: "#FFC200 !important",
    },
  },
});
