import { ActionType } from "../../ActionTypes";

export const getUserAddressListReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_ADDRESS_DETAIL_LIST_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_ADDRESS_DETAIL_LIST_SUCCESS:
      return { ...state, loading: false, userInfo: action.payload };
    case ActionType.GET_ADDRESS_DETAIL_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addUserAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.ADD_ADDRESS_DETAIL_REQUEST:
      return { ...state, loading: true };
    case ActionType.ADD_ADDRESS_DETAIL_SUCCESS:
      return { ...state, loading: false, address: action.payload };
    case ActionType.ADD_ADDRESS_DETAIL_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const updateUserAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.UPDATE_ADDRESS_DETAIL_REQUEST:
      return { ...state, loading: true };
    case ActionType.UPDATE_ADDRESS_DETAIL_SUCCESS:
      return { ...state, loading: false, userInfo: action.payload };
    case ActionType.UPDATE_ADDRESS_DETAIL_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const deleteUserAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.DELETE_ADDRESS_DETAIL_REQUEST:
      return { ...state, loading: true };
    case ActionType.DELETE_ADDRESS_DETAIL_SUCCESS:
      return { ...state, loading: false, userInfo: action.payload };
    case ActionType.DELETE_ADDRESS_DETAIL_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const setDefaultUserAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.SET_DEFAULT_ADDRESS_REQUEST:
      return { ...state, loading: true };
    case ActionType.SET_DEFAULT_ADDRESS_SUCCESS:
      return { ...state, loading: false, userInfo: action.payload };
    case ActionType.SET_DEFAULT_ADDRESS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deliveryFeeCalculateReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.DELIVERY_FEE_CALCULATE_REQUEST:
      return { ...state, loading: true };
    case ActionType.DELIVERY_FEE_CALCULATE_SUCCESS:
      return { ...state, loading: false, deliveryFee: action.payload };
    case ActionType.DELIVERY_FEE_CALCULATE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const checkPostalCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.CHECK_POSTAL_CODE_REQUEST:
      return { ...state, loading: true };
    case ActionType.CHECK_POSTAL_CODE_SUCCESS:
      return { ...state, loading: false, deliveryFee: action.payload };
    case ActionType.CHECK_POSTAL_CODE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};
