import { ActionType } from "../../ActionTypes";

export const viewOrdersReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.VIEW_ORDERS_REQUEST:
      return { ...state, loading: true };
    case ActionType.VIEW_ORDERS_SUCCESS:
      return { ...state, loading: false, viewOrder: action.payload };
    case ActionType.VIEW_ORDERS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const viewOrderDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_ORDER_DETAIL_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_ORDER_DETAIL_SUCCESS:
      return { ...state, loading: false, viewOrderDetail: action.payload };
    case ActionType.GET_ORDER_DETAIL_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case "CLEAR_DETAILS":
      return {};
    default:
      return state;
  }
};

export const editOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.EDIT_ORDER_REQUEST:
      return { ...state, loading: true };
    case ActionType.EDIT_ORDER_SUCCESS:
      return { ...state, loading: false, editOrder: action.payload };
    case ActionType.EDIT_ORDER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionType.CLEAR_UPDATE:
      return {};
    default:
      return state;
  }
};
export const cancelOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.CANCEL_ORDER_REQUEST:
      return { ...state, loading: true };
    case ActionType.CANCEL_ORDER_SUCCESS:
      return { ...state, loading: false, cancelOrder: action.payload };
    case ActionType.CANCEL_ORDER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const viewRecurringOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.VIEW_RECURRING_ORDER_REQUEST:
      return { ...state, loading: true };
    case ActionType.VIEW_RECURRING_ORDER_SUCCESS:
      return { ...state, loading: false, viewRecurringOrder: action.payload };
    case ActionType.VIEW_RECURRING_ORDER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const viewOrderHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.VIEW_ORDERS_HISTORY_REQUEST:
      return { ...state, loading: true };
    case ActionType.VIEW_ORDERS_HISTORY_SUCCESS:
      return { ...state, loading: false, viewOrderHistory: action.payload };
    case ActionType.VIEW_ORDERS_HISTORY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const orderRatingReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.ORDERS_RATING_REQUEST:
      return { ...state, loading: true };
    case ActionType.ORDERS_RATING_SUCCESS:
      return { ...state, loading: false, orderRating: action.payload };
    case ActionType.ORDERS_RATING_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};


export const ordernumberReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.ORDERS_NUMBER_REQUEST:
      return { ...state, loading: true };
    case ActionType.ORDERS_NUMBER_SUCCESS:
      return { ...state, loading: false, orderRating: action.payload };
    case ActionType.ORDERS_NUMBER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionType.CLEAR_NUMBER_RESPONSE:
      return {};
    default:
      return state;
  }
};
