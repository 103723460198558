import React from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import StrConstant from "../../../Common/StrConstant";
import OuterBox from "../../../Components/OuterBox/OuterBox";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import WalletCard from "../../../Components/WalletCard/WalletCard";
import WalletCardSubscription from "../../../Components/WalletCardSubscription/WalletCardSubscription";
import OrderStatus from "../../../Components/OrderStatus/OrderStatus";
import { useDispatch, useSelector } from "react-redux";
// import { modeLinkHandler } from "../../../Common/utils/utils";
import { ActionType } from "../../../redux/ActionTypes";
import Loader from "react-spinner-loader";
const MyWallet = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state?.userSignin);
  const { loading } = useSelector((state) => state?.getMyWallet);
  // const setDefaultMode = localStorage.getItem("selected_modes");
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      <OuterBox>
        <div>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <h3>{StrConstant.MyWallet.heading}</h3>
        </div>
        <div>
          <div className={classes.walletContainer}>
            <WalletCard />
            {userInfo?.userInfo?.data?.user_data?.role_name ===
            "company_employee" ? null : (
              <WalletCardSubscription title={"My Subscription"} />
            )}
          </div>
          <div className={classes.card}>
            {userInfo?.userInfo?.data?.user_data?.role_name ===
            "company_employee" ? null : (
              <div className={classes.innerBox}>
                <div className={classes.ltBox}>
                  <h4>Buy Credits</h4>
                </div>
                <div className={classes.rtBox}>
                  <div className={classes.imgContent}>
                    <Link to="/purchase-credits">
                      <img src="./images/arrow.svg" alt="Better Coffee" />
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={classes.dFlex}>
            {/* <div className={`${classes.card} ${classes.width50}`}> */}
            <div className={`${classes.innerBox} ${classes.desktop}`}>
              <div className={classes.ltBox}>
                <h4>Credit transactions</h4>
              </div>
              <div className={classes.rtBox}>
                <div className={classes.imgContent}>
                  <Link
                    to="/credit-transactions"
                    className={classes.mobBorder0}
                  >
                    <img src="./images/arrow.svg" alt="Better Coffee" />
                  </Link>
                </div>
              </div>
            </div>
            <div className={`${classes.innerBox} ${classes.mobile}`}>
              <Link to="/credit-transactions" className={classes.mobBorder0}>
                <h4>Credit transactions</h4>
              </Link>
            </div>
            {/* </div> */}
            {/* <div className={`${classes.card} ${classes.width50}`}> */}

            {userInfo?.userInfo?.data?.user_data?.role_name ===
            "company_employee" ? null : (
              <div className={`${classes.innerBox} ${classes.desktop}`}>
                <div className={classes.ltBox}>
                  <h4>billing history</h4>
                </div>
                <div className={`${classes.rtBox} ${classes.dNone}`}>
                  <div className={classes.imgContent}>
                    <Link to="/billing-history" className={classes.border0}>
                      <img src="./images/arrow.svg" alt="Better Coffee" />
                    </Link>
                  </div>
                </div>
              </div>
            )}

            <div className={`${classes.innerBox} ${classes.mobile}`}>
              <Link to="/billing-history" className={classes.border0}>
                <h4>billing history</h4>
              </Link>
            </div>
          </div>
          {/* </div> */}
          <OrderStatus
            // link={modeLinkHandler(setDefaultMode)}
            link={"/choose-mode"}
            text={"Place order"}
          />
        </div>
      </OuterBox>
    </>
  );
};
export default withStyles(Styles)(MyWallet);
