import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import { Link } from "react-router-dom";
import CardBox from "../../../Components/CardBox/CardBox";
import StrConstant from "../../../Common/StrConstant";
import CommonSection from "../../../Components/CommonSection/CommonSection";
import BackArrow from "../../../Components/BackArrow/BackArrow";
import YellowBtn from "../../../Components/YellowBtn/YellowBtn";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCardsList } from "../../../redux/actions/credit/CreditActions";
import {
  getPlanListDetail,
  changePlanAction,
} from "../../../redux/actions/planList/PlanListActions";
import { getMyWalletData } from "../../../redux/actions/myWallet/MyWalletActions";
import { ActionType } from "../../../redux/ActionTypes";
import moment from "moment";
import { convertDDMMYYYY } from "../../../Common/utils/utils";
import Loader from "react-spinner-loader";
import AlterMessage from "../../../Components/AlertMessage/AlertMessage";
import ViewMore from "../../../Components/ViewMore/ViewMore";
const ManageSubscription = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [stripePlanId, setStripePlanId] = useState(null);
  const loading = useSelector((state) => state?.planDetail);
  // const userInfo = useSelector((state) => state?.userSignin);
  const plansData = useSelector((state) => state?.planDetail);
  const changePlan = useSelector((state) => state?.changePlan);
  const { myWallet } = useSelector((state) => state?.getMyWallet);
 const walletdetails=localStorage.getItem("walletdetails")
  const subscriptionId = myWallet?.data?.active_subscription?.subscription_id;
  const [page, setpage] = useState(1);
  const [totalPlan, setTotalPlan] = useState([])

  const snackHandler = (event, reason) => {
    // if (reason === "clickaway") return;
    dispatch(
      { type: ActionType.CLEAR_RESPONSE },
      setIsOpen(false),
      setShowModel(false)
    );
  };
  const updatePlanHandler = () => {
    if (subscriptionId === null) return setIsOpen(true);
    else {
      const payload = {
        subscription_id: subscriptionId,
        stripe_plan_id: stripePlanId,
      };
      dispatch(changePlanAction(payload));
    }
  };
  const selectionHandler = (plans) => {
    if (myWallet?.data?.active_subscription?.id != plans?.plan_id) {
      setSelectedPlan(plans?.plan_id);
      setStripePlanId(plans?.stripe_plan_id);
    }
  };
  useEffect(() => {
    const walletdetailsdata=walletdetails?JSON.parse(walletdetails):""
    console.log("wallet", walletdetailsdata)
    if ( (Object.keys(walletdetailsdata?.data?.active_subscription)?.length === 0)||walletdetailsdata?.data?.active_subscription?.plan_name == "" ) {
      navigate("/choose-plan")
    }
  })
  useEffect(() => {
    dispatch(getCardsList());
    dispatch(getPlanListDetail());
    dispatch(getMyWalletData());
  }, []);
  useEffect(() => {
    let startdate = moment();
    startdate = startdate.subtract(1, "days");
    startdate = startdate.format("D MMM YYYY");
  }, []);
  useEffect(() => {
    if (changePlan?.error) setIsOpen(true);
  }, [changePlan]);
  useEffect(() => {
    if (changePlan?.changePlan?.success) return setShowModel(true);
  }, [changePlan?.changePlan?.success]);
  // console.log(plansData?.planDetail?.data?.plans);
  useEffect(() => {
    dispatch(getPlanListDetail(page));
  }, [page])

  useEffect(() => {
    // const plansData=plansData?.planDetail?.data?.plans?
    if (plansData?.planDetail?.data?.plans != null && plansData?.planDetail?.data?.plans != undefined) {
      if (totalPlan?.length > 0) {

        if (page > 1) {
          if (plansData?.planDetail?.data?.pagination?.currentPage == page) {
            console.log("totalplans", plansData?.planDetail?.data?.plans)
            setTotalPlan([...totalPlan, ...plansData?.planDetail?.data?.plans])
          }

        }
      }
      else {
        console.log("totalPlan", plansData?.planDetail?.data?.plans)
        setTotalPlan(plansData?.planDetail?.data?.plans)

      }
    }
  }, [plansData])

  const handleMoreplan = () => {
    setpage(page + 1);
  }

  return (
    <>
      {(loading?.loading || changePlan?.loading) && (
        <Loader
          show={loading?.loading || changePlan?.loading}
          message="Loading..."
          stack="vertical"
          type="body"
        />
      )}
      {isOpen && (
        <AlterMessage
          closeHandler={snackHandler}
          description={changePlan?.error}
          title="Error"
          type="Error"
          open={isOpen}
          url="./images/error.svg"
        />
      )}
      <CommonSection>
        <div className={classes.description}>
          <BackArrow
            clickHandler={() =>
              dispatch({ type: ActionType.CLEAR_RESPONSE }, navigate(-1))
            }
          />
          <h3>{StrConstant.ManageSubscription.heading}</h3>
          <p>{StrConstant.ManageSubscription.description}</p>
          <p>
            For ad hoc credit requirements,{" "}
            <Link to="/purchase-credits">
              purchase one - time credits instead
            </Link>
            .
          </p>
        </div>
        <div className={classes.cardOuter}>
          <ul>
            {totalPlan?.map((plans, index) => (
              <li key={index} onClick={() => selectionHandler(plans)}>
                <CardBox
                  amount={plans?.amount}
                  credit={plans?.credits}
                  feature={plans?.feature}
                  planName={plans?.plan_name}
                  placeholder={plans?.placeholder}
                  // isUpcoming={
                  //   myWallet?.data?.upcoming_subscription?.id === plans?.plan_id
                  //     ? true
                  //     : false
                  // }
                  isNewPlan={false}
                  isCurrent={
                    myWallet?.data?.active_subscription?.id === plans?.plan_id
                      ? true
                      : false
                  }
                  isSelected={selectedPlan === plans?.plan_id ? true : false}
                />
              </li>
            ))}
          </ul>
        </div>
       <div>

        {plansData?.planDetail?.data?.pagination?.lastPage > page &&
            <ViewMore name={"Load More"} clickHandler={handleMoreplan} />
            
          }
          </div>
        {myWallet?.data?.active_subscription?.stripe_status == "trialing" ? (
          <p>

            You are currently on the free trial, which will end {" "}
            {convertDDMMYYYY(
              myWallet?.data?.active_subscription?.trial_ends_at
            )}.
          </p>
        ) : (
          <p>
            Your current cycle ends on{" "}
            {convertDDMMYYYY(
              myWallet?.data?.active_subscription?.current_period_end
            )}
            . For any changes, update your plan by{" "}
            {moment(myWallet?.data?.active_subscription?.current_period_end)
              .subtract(1, "days")
              .format("D MMM YYYY")}
            .
          </p>
        )}

        <div className={classes.btBox}>
          <Link to="/cancel-subscription">Cancel subscription</Link>
          <YellowBtn
            // name={"Update plan"}
            name={"UPDATE SUBSCRIPTION"}
            disable={
              myWallet?.data?.upcoming_subscription?.id &&
                myWallet?.data?.active_subscription?.id
                ? true
                : false
            }
            clickHandler={updatePlanHandler}
          />
        </div>
      </CommonSection>
      <CustomModal
        open={showModel}
        modalClose={snackHandler}
        title={"Plan Updated"}
        description={`Thank you for updating your subscription. Your new plan will start immediately.`}
      >
        <div className={classes.modalFlex}>
          <YellowBtn
            name="RETURN TO MY WALLET"
            clickHandler={() => {
              dispatch({ type: ActionType.CLEAR_RESPONSE });
              navigate("/my-wallet");
            }}
          />
        </div>
      </CustomModal>
    </>
  );
};
export default withStyles(Styles)(ManageSubscription);
