export const Styles = Theme => ({
    notFountOuter: {
        height: "calc(100vh - 150px)",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent:"center",
        padding: "0 20px",
        position:"relative",
    },
    notFountInner:{
        maxWidth: "400px",
        textAlign: "center",
    },
    imgContent: {
        maxWidth: "100px",
        height: "auto",
        margin:"0 auto 30px",
        "@media(max-width:640px)":{
            margin:"0 auto 20px",
        },
        "& img":{
            height:"auto",
            width:"100%",
        }
    },
})