import { ActionType } from "../../ActionTypes";

export const cardsListReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.CARDS_LIST_REQUEST:
      return { ...state, loading: true };
    case ActionType.CARDS_LIST_SUCCESS:
      return { ...state, loading: false, success: true, cards: action.payload };
    case ActionType.CARDS_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addCardReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.USER_ADD_CARD_REQUEST:
      return { loading: true };
    case ActionType.USER_ADD_CARD_SUCCESS:
      return { loading: false, success: true, messages: action.payload };
    case ActionType.USER_ADD_CARD_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const deleteCardReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.DELETE_CARD_REQUEST:
      return { loading: true };
    case ActionType.DELETE_CARD_SUCCESS:
      return { loading: false, success: true, messageDlt: action.payload };
    case ActionType.DELETE_CARD_FAIL:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const getOneTimeCreditsReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_ONE_TIME_CREDITS_REQUEST:
      return { loading: true };
    case ActionType.GET_ONE_TIME_CREDITS_SUCCESS:
      return { loading: false, success: true, messages: action.payload };
    case ActionType.GET_ONE_TIME_CREDITS_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const purchaseCreditsReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.PURCHASE_CREDITS_REQUEST:
      return { loading: true };
    case ActionType.PURCHASE_CREDITS_SUCCESS:
      return { loading: false, success: true, messages: action.payload };
    case ActionType.PURCHASE_CREDITS_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};

export const creditTransactionsReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.CREDIT_TRANSACTIONS_REQUEST:
      return { loading: true };
    case ActionType.CREDIT_TRANSACTIONS_SUCCESS:
      return { loading: false, success: true, creditTransac: action.payload };
    case ActionType.CREDIT_TRANSACTIONS_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const cardsDefaultReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.CARD_DEFAULT_REQUEST:
      return { loading: true };
    case ActionType.CARD_DEFAULT_SUCCESS:
      return { loading: false, success: true, cardsDefault: action.payload };
    case ActionType.CARD_DEFAULT_FAILURE:
      return { loading: false, error: action.payload };
    case ActionType.CLEAR_RESPONSE:
      return {};
    default:
      return state;
  }
};
