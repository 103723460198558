export const Styles = Theme => ({
    fromControl: {
        margin: "0 0 25px",
    },
    inputBox: {
        padding: "12px 20px",
        borderRadius: "20px",
        minHeight: "45px",
        background: "#fff",
        border: "1px solid #ddd !important",
        "@media(max-width:640px)": {
            border: "none !important",
        }
    },
    dFlex: {
        display: "flex",
        "& > div": {
            width: "50%"
        }
    },
    ml: {
        marginLeft: "15px",
    },
    textCheckbox: {
        display: "flex",
        alignItems: "center",
        margin: "0 !important",
        maxWidth: "240px",
        "@media(max-width:640px)": {
            marginBottom: "20px !important",
        },
        "& p": {
            padding: "0",
            margin: "0",
            color: "#3F4444",
            fontSize: "14px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
            cursor:"pointer",
            "& a": {
                fontWeight: "700",
                padding: "0 2px",
                fontSize: "14px",
                lineHeight: "14px",
            }
        },
        "& span": {
            padding: "0",
            margin: "0 5px 0 0",
            color: "#3F4444",
            fontSize: "12px",
            lineHeight: "14px",
            fontWeight: "400",
            fontFamily: "'Noto Serif', serif",
        },
    },
    btnBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "@media(max-width:640px)": {
            display: "block",
        },
        "& button": {
            marginLeft: "10px",
            "@media(max-width:640px)": {
                margin:"2rem 0 0 0",
            }
        }
    }
})