import { Styles } from "./Style";
import { Link, useNavigate } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { Fragment, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
const OrderCardBox = (props) => {
  const { classes, data, isRecurring = false } = props;
  // console.log("orderdata",data)
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState(null);
  const selectionHandler = (orderId, outletId, totalOrders, typeDisplay) => {
    setSelectedId(orderId);
    localStorage.setItem("orderId", orderId);
    localStorage.setItem("outletId", outletId);
    localStorage.setItem("totalOrders", totalOrders);
    localStorage.setItem("typeDisplay", typeDisplay);
  };
  const renderTimeOptions = (data) => {
   
    if (data?.order_type === 0) {
      return <></>;
    } else {
      return (
        <>
          <p>
            {data?.order_type !== 0 && data?.order_type !== 2
              ? data?.selected_days === "daily"
                ? `${"Every Day " + data?.order_time_slot}`
                : `${"Every " + data?.selected_days.replaceAll(",",", ")} ${data?.order_time_slot}`
              : null}{" "}
            {data?.order_type === 2
              ? ` ${data?.display_date} ${data?.order_time_slot}`
              : null}
          </p>
          {data?.order_type === 0 && data?.end_date !== "-" ? (
            <p>{`From ${data?.start_date} -${data?.end_date}`}</p>
          ) : null}
        </>
      );
    }
  };
  return (
    <div className={classes.p20}>
      <Accordion
        className={classes.accordionBox}
        onChange={() =>
          selectionHandler(
            data?.order_id,
            data?.outlet_id,
            data?.total_orders,
            data?.order_type_display
          )
        }
      >
        <AccordionSummary
          expandIcon={<img src="./images/arrow-open.svg" alt="Better Coffee" />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <div className={classes.topInner}>
            <div className={classes.topBox}
              onClick={() =>
                data?.order_type != 0 &&
                navigate(`/recurring-details/${data?.order_id}`)
              }
            >
              {data?.order_type === 0 ? null : (
                <div className={classes.ltBox}>
                  <h4>{`${data?.current_order}/${data?.total_orders}`}</h4>
                  <span className={classes.divider}></span>
                  <p>Orders</p>
                </div>
              )}
              <div className={classes.rtBox}>
                <h4>{data?.order_type_display=="Pickup Today"?"PICK-UP TODAY":data?.order_type_display=="One-Time Pickup"?"One-time Pick-up":data?.order_type_display=="Pickup Recurring"?"Recurring Pick-up":data?.order_type_display=="Pickup Custom"?"Custom Pick-up":data?.order_type_display=="Delivery Recurring"?"Recurring Delivery":data?.order_type_display=="Delivery Custom"?"Custom Delivery":data?.order_type_display}</h4>
                <p>Order {data?.order_number}</p>
              </div>
            </div>
            <div className={classes.btBox}>
              {/* Need Section here  */}
              {renderTimeOptions(data)}
              <p>
                {data?.order_type !== 0
                  ? data?.end_date === "-"
                    ? null
                    : `From ${data?.start_date} - ${data?.end_date}`
                  : data?.display_date}{" "}
                {data?.order_type === 0 ? " " + data?.order_time_slot : null}
              </p>
              <p>
                {/* {data?.order_mode} @ {data?.outlet_name} */}
                {data?.address_display}
              </p>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.content}>
          {data?.items?.map((data, index) => (
            <Fragment key={index}>
              <h6>{`${data?.item_quantity} ${data?.item_name}`}</h6>
            </Fragment>
          ))}
          <Link to={`/view-details/${selectedId}`}>View order details</Link>
          {data?.items?.length === 0 && <b>No items to show</b>}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default withStyles(Styles)(OrderCardBox);
