export const Styles = Theme => ({
    addItems: {
        "& > a": {
            display: "flex",
            alignItems: "center",
            background: "#fff",
            borderRadius: '10px',
            padding: "20px",
            color: "#3F4444",
            "& img": {
                height: "15px",
                marginRight: "10px",
            },
            "& :hover": {
                color: "#3F4444",
            }
        },
    },
    modalFlex: {
        display: "flex",
        "& div:nth-child(2)": {
          marginLeft:"15px",
        }
    }
})