export const Styles = (Theme) => ({
  fromControl: {
    margin: "20px 0 25px",
  },
  btnBox: {
    "& > div": {
      "@media(max-width:640px)": {
        float: "unset",
        margin: "auto",
      },
    },
    "& button":{
      marginBottom:"0",
    }
  },
  numberField: {
    position: "relative",
    "& input": {
      paddingRight: "40px",
    },
    "& img": {
      position: "absolute",
      right: "15px",
      top: "50%",
      transform: "translate(-10%, -50%)",
    },
  },
  boxFlex: {
    "@media(max-width:640px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 300px)",
    },
  },
});
