export const Styles = Theme => ({
    sidebarBox: {
        width: "360px !important",
        height: "100vh",
        display: "table",
        background: "#F1EEDB",
        "@media(max-width:640px)": {
            width: "275px !important",
        }
    },
    headerOuter: {
        minHeight: "65px",
        background: "#F1EEDB",
        // padding: "0",
        display: "flex",
        alignItems: "center",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        zIndex: "999",
        // boxShadow: "-2px -3px 6px #000",
    },
    headerBox: {
        display: "flex",
        alignItems: "center !important",
        justifyContent: "space-between",
        padding: "5px 0",
    },
    logoContainer: {
        display: "flex",
        alignItems: "center",
        "@media(max-width:640px)": {
            display: "block",
        }
    },
    logoBox: {
        height: "auto",
        maxWidth: "140px",
        // width: "20%",
        "@media(max-width:767px)": {
            // width: "28%",
        },
        "@media(max-width:640px)": {
            display: "none",
        },
        "& a": {
            display: "block",
            cursor: "pointer",
            "@media(max-width:640px)": {
                // width: "120px",
            }
        },
        "& img": {
            height: "auto",
            width: "100%",
        }
    },
    iconBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        // width: "100%",
        "& button": {
            height: "35px",
            width: "35px",
            padding: "0",
            minWidth: "auto",
            marginLeft: "10px",
            borderRadius: "100%",
            position: "relative",
            "@media(max-width:640px)": {
                height: "25px",
                width: "25px",
                marginLeft: "5px",
            }
        },
    },
    iconImgContent: {
        display: "block",
        "& img": {
            height: "28px",
            width: "28px",
            "@media(max-width:640px)": {
                height: "22px",
                width: "22px",
            }
        },
    },
    count: {
        height: "18px",
        width: "18px",
        borderRadius: "50%",
        fontSize: "10px",
        background: "#FFC200",
        position: "absolute",
        top: "-2px",
        right: "-2px",
        lineHeight: "18px",
        color: "#3F4444 !important",
    },
    staging: {
        background: "#FFC200 !important",
        fontSize: "20px !important",
        fontWeight: "700",
        textTransform: "uppercase",
        "& span": {
            // fontFamily: '"Bebas Neue", serif',
        },
        "@media(max-width:720px)": {
            "& span": {
                fontSize: "14px !important",
            },
        },
        "@media(max-width:420px)": {
            "& span": {
                fontSize: "12px !important",
            },
        }
    },
    dasktop: {
        "@media(max-width:375px)": {
            display: 'none',
        }
    },
    mob: {
        display: 'none',
        "@media(max-width:375px)": {
            display: 'flex',
            // justifyContent:'center',
            padding:'5px 0',
        }
    },
    CurrentItem:{
        opacity: '.5',
    }
})
