import React from "react-dom";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home/Home";
import Dashboard from "../Pages/Home/Dashboard";
import Login from "../Pages/Login/Login";
import Signup from "../Pages/Signup/Signup";
import Verify from "../Pages/Verify/Verify";
import Success from "../Pages/Success/Success";
import ChoosePlan from "../Pages/ChoosePlans/ChoosePlan/ChoosePlan";
import PurchaseCredits from "../Pages/ChoosePlans/PurchaseCredits/PurchaseCredits";
import ChoosePaymentMethod from "../Pages/ChoosePlans/ChoosePaymentMethod/ChoosePaymentMethod";
import PurchaseComplete from "../Pages/ChoosePlans/PurchaseComplete/PurchaseComplete";
import GetOneTimeCredits from "../Pages/ChoosePlans/GetOneTimeCredits/GetOneTimeCredits";
import OneOfPurchaseCredits from "../Pages/ChoosePlans/OneOfPurchaseCredits/OneOfPurchaseCredits";
import OneOffGetOneTimeCredits from "../Pages/ChoosePlans/OneOffGetOneTimeCredits/OneOffGetOneTimeCredits";
import ProductList from "../Pages/PickupPlan/ProductList/ProductList";
import ChooseMode from "../Pages/PickupPlan/ChooseMode/ChooseMode";
import PickupToday from "../Pages/PickupPlan/PickupToday/PickupToday";
import SchedulePickUp from "../Pages/PickupPlan/SchedulePickUp/SchedulePickUp";
import ScheduleDelivery from "../Pages/PickupPlan/ScheduleDelivery/ScheduleDelivery";
import ScheduleDeliverynodata from "../Pages/PickupPlan/ScheduleDelivery/SchdeuleDeliveryNodata";
import OrderReview from "../Pages/PickupPlan/ScheduleOneTimeDeliveryDetail/OrderReview/OrderReview";
import VerifyPhoneNumberOtp from "../Pages/PickupPlan/ScheduleOneTimeDeliveryDetail/VerifyPhoneNumberOtp/VerifyPhoneNumberOtp";
import PhoneNumberVerified from "../Pages/PickupPlan/ScheduleOneTimeDeliveryDetail/PhoneNumberVerified/PhoneNumberVerified";
import OrderConfirm from "../Pages/PickupPlan/ScheduleOneTimeDeliveryDetail/OrderConfirm/OrderConfirm";
import EditPhoneNumber from "../Pages/PickupPlan/ScheduleOneTimeDeliveryDetail/EditPhoneNumber/EditPhoneNumber";
import MyOrder from "../Pages/MyOrder/MyOrder/MyOrder";
import ViewDetails from "../Pages/MyOrder/ViewDetails/ViewDetails";
import MyAccount from "../Pages/MyAccount/MyAccount/MyAccount";
import Profile from "../Pages/MyAccount/Profile/Profile";
import VerifyOtp from "../Pages/MyAccount/VerifyOtp/VerifyOtp";
import ManagePaymentMethods from "../Pages/MyAccount/ManagePaymentMethods/ManagePaymentMethods";
import ManageAddresses from "../Pages/MyAccount/ManageAddresses/ManageAddresses";
import AddAddresses from "../Pages/MyAccount/AddAddress/AddAddress";
import ChangePassword from "../Pages/MyAccount/ChangePassword/ChangePassword";
import ForgotPassword from "../Pages/MyAccount/ForgotPassword/ForgotPassword";
import PrivateRoutes from "../Common/PrivateRoutes";
import SetPassword from "../Pages/SetPassword/SetPassword";
import Forgot from "../Pages/Forgot/Forgot";
import AddPayment from "../Pages/ChoosePlans/AddPayment/AddPayment";
import ItemModifier from "../Pages/PickupPlan/ScheduleOneTimeDeliveryDetail/ItemModifier/ItemModifier";
import ItemMenu from "../Pages/ItemMenu/ItemMenu";
import CartDetail from "../Pages/PickupPlan/ScheduleOneTimeDeliveryDetail/CartDetail/CartDetail";
import DeleteAccount from "../Pages/MyAccount/DeleteAccountPage/DeleteAccount/DeleteAccount";
import AccountDeletionSuccess from "../Pages/MyAccount/DeleteAccountPage/AccountDeletionSuccess/AccountDeletionSuccess";
import SubmissionFeedback from "../Pages/MyAccount/DeleteAccountPage/SubmissionFeedback/SubmissionFeedback";
import MyWallet from "../Pages/MyWallet/MyWallet/MyWallet";
import ManageSubscription from "../Pages/MyWallet/ManageSubscription/ManageSubscription";
import CancelSubscription from "../Pages/MyWallet/CancelSubscription/CancelSubscription";
import EditOrder from "../Pages/EditOrder/EditOrder/EditOrder";
import EditSummary from "../Pages/EditOrder/EditSummary/EditSummary";
import SuccessfullyOrder from "../Pages/EditOrder/SuccessfullyOrder/SuccessfullyOrder";
import OrderRating from "../Pages/MyOrder/OrderRating/OrderRating";
import OrderSuccessfully from "../Pages/MyOrder/OrderSuccessfully/OrderSuccessfully";
import Notifications from "../Pages/Notifications/Notifications/Notifications";
import PromotionalOffer from "../Pages/Notifications/PromotionalOffer/PromotionalOffer";
import NotFound from "../Pages/NotFound/NotFound";
import RestrictedPage from "../Pages/RestrictedPage/RestrictedPage";
import NoDataFound from "../Pages/NoDataFound/NoDataFound";
import CancelOrder from "../Pages/MyOrder/CancelOrder/CancelOrder";
import PublicRoutes from "../Common/PublicRoutes";
import EmailVerify from "../Pages/Email/EmailVerify";
import BillingHistory from "../Pages/MyWallet/BillingHistory/BillingHistory";
import CreditTransactions from "../Pages/MyWallet/CreditTransactions/CreditTransactions";
import ForgotSuccess from "../Pages/ForgotSuccess/ForgotSuccess";
import OrderHistory from "../Pages/OrderHistory/OrderHistory";
import RecurringDetails from "../Pages/RecurringDetails/RecurringDetails";
import { useGoogleAnalytics } from "../Hooks/useGoogleAnalytics";
const Navigation = () => {
  // useGoogleAnalytics();
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoutes>
            <Home />
          </PrivateRoutes>
        }
        exact
      />
      <Route path="/login" element={<Login />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/forgot-password" element={<Forgot />} />
      <Route path="/sign-up" element={<Signup />} />
      <Route path="/success" element={<Success />} />
      <Route path="/forgot-success" element={<ForgotSuccess />} />
      <Route path="/resend-email/:email" element={<Verify />} />
      <Route path="/email-verify/:verifyToken" element={<EmailVerify />} />
      {/*setting up the password (for first time) */}
      <Route path="/set-password/:password_token" element={<SetPassword />} />
      {/*forgot password (no password) */}
      <Route
        path="/verify-otp"
        element={
          <PrivateRoutes>
            <VerifyOtp />
          </PrivateRoutes>
        }
      />
      <Route
        path="/change-password"
        element={
          <PrivateRoutes>
            <ForgotPassword />
          </PrivateRoutes>
        }
      />
      {/*change the password (you have the password) */}
      <Route
        exact
        path="/menu"
        element={
          <PublicRoutes>
            <ProductList onPage={"/menu"} />
          </PublicRoutes>
        }
      />
      <Route
        path="/change-password"
        element={
          <PrivateRoutes>
            <ForgotPassword />
          </PrivateRoutes>
        }
      />
      <Route
        path="/my-dashboard"
        element={
          <PrivateRoutes>
            <Home />
          </PrivateRoutes>
        }
        exact
      />
      <Route
        path="/purchase-credits/:index"
        element={
          <PrivateRoutes>
            <PurchaseCredits />
          </PrivateRoutes>
        }
      />
      <Route
        path="/purchase-credits"
        element={
          <PrivateRoutes>
            <GetOneTimeCredits />
          </PrivateRoutes>
        }
      />
      <Route
        path="/subscription"
        element={
          <PrivateRoutes>
            <PurchaseCredits />
          </PrivateRoutes>
        }
      />
      <Route
        path="/add-card"
        element={
          <PrivateRoutes>
            <AddPayment />
          </PrivateRoutes>
        }
      />
      <Route
        path="/purchase-complete"
        element={
          <PrivateRoutes>
            <PurchaseComplete />
          </PrivateRoutes>
        }
      />
      <Route
        path="/change-password1"
        element={
          <PrivateRoutes>
            <ChangePassword />
          </PrivateRoutes>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoutes>
            {" "}
            <Dashboard />{" "}
          </PrivateRoutes>
        }
      />
      <Route
        path="/choose-plan"
        element={
          <PrivateRoutes>
            {" "}
            <ChoosePlan />{" "}
          </PrivateRoutes>
        }
      />
      <Route
        path="/choose-payment-method"
        element={
          <PrivateRoutes>
            {" "}
            <ChoosePaymentMethod />
          </PrivateRoutes>
        }
      />
      {/* <Route
        path="/purchase-credits"
        element={
          <PrivateRoutes>
            {" "}
            <OneOfPurchaseCredits />
          </PrivateRoutes>
        }
      /> */}
      {/* <Route
        path="/one-off-get-one-time-credits"
        element={
          <PrivateRoutes>
            {" "}
            <OneOffGetOneTimeCredits />
          </PrivateRoutes>
        }
      /> */}
      <Route
        path="/choose-mode"
        element={
          <PrivateRoutes isVisible={false}>
            {" "}
            <ChooseMode />{" "}
          </PrivateRoutes>
        }
      />
      <Route
        path="/pick-up-today"
        element={
          <PrivateRoutes isVisible={false}>
            {" "}
            <PickupToday isVisible={true} />{" "}
          </PrivateRoutes>
        }
      />
      <Route
        path="/schedule-pick-up"
        element={
          <PrivateRoutes isVisible={false}>
            {" "}
            <SchedulePickUp />
          </PrivateRoutes>
        }
      />
      <Route
        path="/delivery"
        element={
          <PrivateRoutes isVisible={false}>
            {" "}
            <ScheduleDeliverynodata />
          </PrivateRoutes>
        }
      />
      <Route
        path="/schedule-delivery"
        element={
          <PrivateRoutes isVisible={false}>
            {" "}
            <ScheduleDelivery />
          </PrivateRoutes>
        }
      />
      <Route
        path="/item-modifier"
        element={
          <PrivateRoutes isVisible={true}>
            {" "}
            <ItemModifier />
          </PrivateRoutes>
        }
      />
       <Route
        path="/item-modifier/id"
        element={
          <PrivateRoutes isVisible={true}>
            {" "}
            <ItemModifier />
          </PrivateRoutes>
        }
      />
      <Route
        path="/order-review"
        element={
          <PrivateRoutes isVisible={true}>
            {" "}
            <OrderReview />
          </PrivateRoutes>
        }
      />
      <Route
        path="/verify-phone-number-otp"
        element={
          <PrivateRoutes>
            {" "}
            <VerifyPhoneNumberOtp />
          </PrivateRoutes>
        }
      />
      <Route
        path="/phone-number-verified"
        element={
          <PrivateRoutes>
            <PhoneNumberVerified />
          </PrivateRoutes>
        }
      />
      <Route
        path="/edit-phone-number"
        element={
          <PrivateRoutes>
            {" "}
            <EditPhoneNumber />
          </PrivateRoutes>
        }
      />
      <Route
        path="/order-confirm"
        element={
          <PrivateRoutes isVisible={true}>
            {" "}
            <OrderConfirm />
          </PrivateRoutes>
        }
      />
      <Route
        path="/cart-detail"
        element={
          <PrivateRoutes isVisible={false}>
            {" "}
            <CartDetail />
          </PrivateRoutes>
        }
      />
      <Route
        path="/my-orders"
        element={
          <PrivateRoutes>
            {" "}
            <MyOrder />
          </PrivateRoutes>
        }
      />
      <Route
        path="/view-details/:id"
        element={
          <PrivateRoutes>
            {" "}
            <ViewDetails />{" "}
          </PrivateRoutes>
        }
      />
      <Route
        path="/my-account"
        element={
          <PrivateRoutes>
            {" "}
            <MyAccount />
          </PrivateRoutes>
        }
      />
      <Route
        path="/delete-account"
        element={
          <PrivateRoutes>
            {" "}
            <DeleteAccount />
          </PrivateRoutes>
        }
      />
      <Route
        path="/account-deletion-success"
        element={
          <PrivateRoutes>
            {" "}
            <AccountDeletionSuccess />
          </PrivateRoutes>
        }
      />
      <Route
        path="/submission-feedback"
        element={
          <PrivateRoutes>
            {" "}
            <SubmissionFeedback />
          </PrivateRoutes>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoutes>
            {" "}
            <Profile />{" "}
          </PrivateRoutes>
        }
      />
      <Route
        path="/manage-payment-methods"
        element={
          <PrivateRoutes>
            {" "}
            <ManagePaymentMethods />{" "}
          </PrivateRoutes>
        }
      />
      <Route
        path="/manage-addresses"
        element={
          <PrivateRoutes>
            {" "}
            <ManageAddresses />
          </PrivateRoutes>
        }
      />
      <Route
        path="/select-product"
        element={
          <PrivateRoutes isVisible={true}>
            {" "}
            <ItemMenu onPage={"/select-product"} />
          </PrivateRoutes>
        }
      />
      <Route
        path="/add-address"
        element={
          <PrivateRoutes>
            {" "}
            <AddAddresses />
          </PrivateRoutes>
        }
      />
      <Route
        path="/add-address/:id"
        element={
          <PrivateRoutes>
            {" "}
            <AddAddresses />
          </PrivateRoutes>
        }
      />
      <Route
        path="/my-wallet"
        element={
          <PrivateRoutes>
            {" "}
            <MyWallet />
          </PrivateRoutes>
        }
      />
      <Route
        path="/manage-subscription"
        element={
          <PrivateRoutes>
            {" "}
            <ManageSubscription />
          </PrivateRoutes>
        }
      />
      <Route
        path="/cancel-subscription"
        element={
          <PrivateRoutes>
            {" "}
            <CancelSubscription />
          </PrivateRoutes>
        }
      />
      <Route
        path="/billing-history"
        element={
          <PrivateRoutes>
            {" "}
            <BillingHistory />
          </PrivateRoutes>
        }
      />
      <Route
        path="/credit-transactions"
        element={
          <PrivateRoutes>
            {" "}
            <CreditTransactions />
          </PrivateRoutes>
        }
      />
      <Route
        path="/edit-order/:orderId"
        element={
          <PrivateRoutes>
            {" "}
            <EditOrder />
          </PrivateRoutes>
        }
      />
      <Route
        path="/edit-summary"
        element={
          <PrivateRoutes>
            {" "}
            <EditSummary />
          </PrivateRoutes>
        }
      />
      <Route
        path="/successfully-order"
        element={
          <PrivateRoutes>
            {" "}
            <SuccessfullyOrder />
          </PrivateRoutes>
        }
      />
      <Route
        path="/order-rating"
        element={
          <PrivateRoutes>
            {" "}
            <OrderRating />
          </PrivateRoutes>
        }
      />
      <Route
        path="/order-successfully"
        element={
          <PrivateRoutes>
            {" "}
            <OrderSuccessfully />
          </PrivateRoutes>
        }
      />
      <Route
        path="/notifications"
        element={
          <PrivateRoutes>
            {" "}
            <Notifications />
          </PrivateRoutes>
        }
      />
      <Route
        path="/promotional-offer"
        element={
          <PrivateRoutes>
            {" "}
            <PromotionalOffer />
          </PrivateRoutes>
        }
      />
      <Route
        path="*"
        element={
          <PublicRoutes>
            {" "}
            <NotFound />
          </PublicRoutes>
        }
      />
      <Route
        path="restricted"
        element={
          <PrivateRoutes>
            {" "}
            <RestrictedPage />
          </PrivateRoutes>
        }
      />
      {/* <Route
        path="*"
        element={
          <PrivateRoutes>
            {" "}
            <NoDataFound />
          </PrivateRoutes>
        }
      /> */}
      <Route
        path="/cancel-order"
        element={
          <PrivateRoutes>
            {" "}
            <CancelOrder />
          </PrivateRoutes>
        }
      />
      <Route
        path="/order-history"
        element={
          <PrivateRoutes>
            {" "}
            <OrderHistory />
          </PrivateRoutes>
        }
      />
      <Route
        path="/recurring-details/:orderId"
        element={
          <PrivateRoutes>
            {" "}
            <RecurringDetails />
          </PrivateRoutes>
        }
      />
    </Routes>
  );
};
export default Navigation;
