export const Styles = (Theme) => ({
    roundBox: {
        background: "#FFC200",
        // boxShadow:"2px -2px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.03)",
        borderRadius: "100%",
        position: "fixed",
        right: "30px",
        bottom: "30px",
        height: "80px",
        width: "80px",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        fontSize: "16px",
        lineHeight: "18px",
        textAlign: "center",
        fontFamily: "'Bebas Neue', serif",
        justifyContent: "center",
        fontWeight: "400",
        padding: "10px",
        color: "#3F4444 !important",
        zIndex: "9",
        "@media(max-width:640px)": {
            right: "10px"
        },
        "&:hover": {
            color: "#3F4444 !important",
        },
    },
});