export const Styles = (Theme) => ({
  outerInner: {
    margin: "auto",
    width: "100%",
    height: "auto",
  },
  cardOuter: {
    "& ul": {
      margin: "20px 0",
    },
    "&  li": {
      width: "33%",
      padding: "0 20px 20px 0",
      display: "inline-block",
      verticalAlign: "top",
      margin: "auto",
      "@media (max-width: 1024px)": {
        width: "50%",
        "&:nth-child(even)": {
          paddingRight: "0",
        },
      },
      "@media (max-width: 640px)": {
        width: "100%",
        paddingRight: "0",
      },
      "&:nth-child(3n+3)": {
        paddingRight: "0",
        "@media (max-width: 1024px)": {
          paddingRight: "20px",
        },
        "@media (max-width: 640px)": {
          paddingRight: "0",
        },
      },
      "&:last-child": {
        "@media (max-width: 991px)": {
          paddingBottom: "0",
        },
      },
    },
  },
  cardOuterSelected: {
    color: "red",
    backgroundColor: "green",
  },
  needTxt: {
    "& p": {
      color: "#3F4444",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "700",
    },
  },
  arrow: {
    paddingLeft: "5px",
    display: "inline-block",
    verticalAlign: "middle",
    "& img": {
      maxWidth: "15px",
    },
  },
  botTxt: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media(max-width:640px)": {
      margin: "50px 0 0",
      display: "block",
    },
    "& button": {
      margin: "0",
      "@media(max-width:640px)": {
        marginTop: "20px",
      },
    },
    "& p": {
      color: "#3F4444",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "400",
      margin: "0",
      fontFamily: "'Noto Serif', serif",
      textAlign: "center",
    },
    "& a": {
      display: "initial",
      padding: "0 2px",
    },
  },

  purchaseBtn: {
    "@media (max-width: 640px)": {
      paddingTop: "20px",
    },
  },
});
