import { ActionType } from "../../ActionTypes";
import serverURL from "../../../APIHandler/serverURL";
import callAPI from "../../../APIHandler/callAPI";
import { reloadHandler } from "../../../Common/utils/utils";

export const getCardsList = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.CARDS_LIST_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.getRequest(serverURL.cardList, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });
    dispatch({ type: ActionType.CARDS_LIST_SUCCESS, payload: data });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.CARDS_LIST_FAIL, payload: message });
  }
};

export const addCard = (paymentDetails) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.USER_ADD_CARD_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(
      serverURL.addCard,
      paymentDetails,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.USER_ADD_CARD_SUCCESS, payload: data });
    dispatch(getCardsList());
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.USER_ADD_CARD_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteCard = (cid) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.DELETE_CARD_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.deleteRequest(
      serverURL.deleteCard.concat(cid),
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.DELETE_CARD_SUCCESS, payload: data });
    dispatch(getCardsList());
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.DELETE_CARD_FAIL, payload: message });
  }
};

export const getOneTimeCredits = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GET_ONE_TIME_CREDITS_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  const url =
    localStorage.getItem("insufficient") === "true"
      ? serverURL.getOneTimeCredits + "?purchased=1"
      : serverURL.getOneTimeCredits;
  // console.log("url", url);
  // console.log("***", localStorage.getItem("insufficient"));
  try {
    // console.log("**", localStorage.getItem("insufficient") === "true");

    const { data } = await callAPI.postRequest(url, payload, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });
    dispatch({
      type: ActionType.GET_ONE_TIME_CREDITS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.GET_ONE_TIME_CREDITS_FAILURE,
      payload: message,
    });
  }
};

export const purchaseCredits = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.PURCHASE_CREDITS_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(serverURL.purchase, payload, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
      },
    });
    dispatch({
      type: ActionType.PURCHASE_CREDITS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ActionType.PURCHASE_CREDITS_FAILURE,
      payload: message,
    });
  }
};

export const creditTransactionsAction =
  (currentPage) => async (dispatch, getState) => {
    dispatch({
      type: ActionType.CREDIT_TRANSACTIONS_REQUEST,
    });
    const {
      userSignin: { userInfo },
    } = getState();
    try {
      const { data } = await callAPI.getRequest(
        serverURL.creditTransactions.concat(`?page=${currentPage}`),
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": true,
            Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
          },
        }
      );
      dispatch({
        type: ActionType.CREDIT_TRANSACTIONS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      reloadHandler(error?.response?.status);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ActionType.CREDIT_TRANSACTIONS_FAILURE,
        payload: message,
      });
    }
  };

export const cardsDefaultAction = (payload) => async (dispatch, getState) => {
  dispatch({
    type: ActionType.CARD_DEFAULT_REQUEST,
  });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await callAPI.postRequest(
      serverURL.cardsDefault,
      payload,
      {
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${userInfo?.data?.user_data?.auth_token}`,
        },
      }
    );
    dispatch({ type: ActionType.CARD_DEFAULT_SUCCESS, payload: data });
    dispatch(getCardsList());
  } catch (error) {
    reloadHandler(error?.response?.status);
    dispatch({
      type: ActionType.CARD_DEFAULT_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
