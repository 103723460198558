import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import Loader from "react-spinner-loader";
import { useSelector } from "react-redux";
import CustomPropover from "../../Components/CustomPropover/CustomPropover";
import Propover from "../Propover/Propover";
import YellowBtn from "../YellowBtn/YellowBtn";
import { Link, useNavigate } from "react-router-dom";
// import { modeLinkHandler } from "../../Common/utils/utils";
import { Fragment } from "react";
import { Tooltip } from "@mui/material";
const CardContent = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const setDefaultMode = localStorage.getItem("selected_modes");
  const { loading, dashboardDetail } = useSelector(
    (state) => state?.dashboardDetail
  );
  const userInfo = useSelector((state) => state?.userSignin);
  return (
    <>
      {loading && (
        <Loader
          show={loading}
          type="body"
          stack="vertical"
          message="Loading..."
        />
      )}
      {dashboardDetail?.data ? (
        <div className={classes.noWrap}>
          {!loading && (
            <div className={classes.card}>
              <div className={classes.cardInner}>
                <h4>My next Order</h4>
                {dashboardDetail?.data?.next_order?.order_id ? (
                  <div
                    className={classes.ltBox}
                    // onClick={() => navigate('/my-order')}
                  >
                    <Fragment>
                      <p className={classes.fontWeightBold}>
                        {`${dashboardDetail?.data?.next_order?.display_date}, ${dashboardDetail?.data?.next_order?.order_time_slot}`}
                      </p>

                      {/* {dashboardDetail?.data?.default_mode?.id == 1 ||
                      dashboardDetail?.data?.default_mode?.id == 2 ? (
                        <p>
                          Pick-up at{" "}
                          {dashboardDetail?.data?.next_order?.outlet_name}
                        </p>
                      ) : (
                        <p>
                           Delivery to{" "}
                          {dashboardDetail?.data?.default_address
                              ?.address_tag === "Custom" ?
                            dashboardDetail?.data?.default_address
                                ?.custom_address_tag : dashboardDetail?.data?.default_address
                                ?.address_tag} 
                          {
                            dashboardDetail?.data?.next_order
                              ?.order_type_display
                          }{" "}
                          at {dashboardDetail?.data?.next_order?.address}
                        </p>
                      )} */}
                      {dashboardDetail?.data?.next_order?.address_display}
                    </Fragment>{" "}
                  </div>
                ) : dashboardDetail != undefined ? (
                  <div className={classes.btnContent}>
                    {" "}
                    <YellowBtn
                      name={"Place Order"}
                      clickHandler={() => {
                        // navigate(modeLinkHandler(setDefaultMode));
                        navigate("/choose-mode");
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <div className={classes.rtBox}>
                {/* <Link to={modeLinkHandler(setDefaultMode)}> */}
                <Link to={"/my-orders"}>
                  <div className={classes.imgContent}>
                    <img src="./images/arrow.svg" alt="Better Coffee" />
                  </div>
                </Link>
              </div>
            </div>
          )}
          {!loading && (
            <div className={`${classes.card} ${classes.pdLt}`}>
              <div className={classes.ltBox}>
                <h4>My wallet</h4>
                {dashboardDetail?.data?.wallet?.wallet_balance > 0 ? (
                  dashboardDetail?.data?.wallet?.wallet_balance > 0 && (
                    <>
                      <p className={classes.fontWeightBold}>
                        {dashboardDetail?.data?.wallet?.wallet_balance} credits
                      </p>
                      <div className={classes.dFlex}>
                        {" "}
                        Expires on{" "}
                        {dashboardDetail?.data?.wallet?.balance_expiry}
                        <CustomPropover>
                          Your credits expire 12 months upon purchase. With
                          credits that have been purchased on different dates,
                          the expiry date shown here is based on your latest
                          purchase.
                        </CustomPropover>
                      </div>
                    </>
                  )
                ) : (
                  <div className={classes.btnContent}>
                    {userInfo?.userInfo?.data?.user_data?.role_name ===
                    "company_employee" ? null : (
                      <YellowBtn
                        name={"purchase credits"}
                        clickHandler={() => navigate("/purchase-credits")}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className={classes.rtBox}>
                <Link to={"/my-wallet"}>
                  <div className={classes.imgContent}>
                    <img src="./images/arrow.svg" alt="Better Coffee" />
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default withStyles(Styles)(CardContent);
