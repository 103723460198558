import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { emailVerify } from "../../redux/actions/UserActions";
import YellowBtn from "../../Components/YellowBtn/YellowBtn";
// import AlterMessage from "../../Components/AlertMessage/AlertMessage";
import StrConstant from "../../Common/StrConstant";
const EmailVerify = (props) => {
  const { verifyToken } = useParams();
  const dispatch = useDispatch();
  const { classes } = props;
  const navigation = useNavigate();
  // const [isOpen, setIsOpen] = useState(true);
  const data = useSelector((state) => state?.verifyEmail);
  useEffect(() => {
    dispatch(emailVerify(verifyToken));
  }, [verifyToken]);
  console.log("data",data?.error)
  return (
    <div className={classes.thankOuter}>
      {/* {data?.success && (
        <AlterMessage
          closeHandler={() => setIsOpen(false)}
          description={data?.verifyEmail.message}
          url={"./images/success.svg"}
          title={"Success"}
          type={"Success"}
          open={isOpen}
        />
      )} */}
      {/* {data?.error && (
        <AlterMessage
          closeHandler={() => setIsOpen(false)}
          description={data?.error?.message}
          url={"./images/error.svg"}
          title={"Error"}
          type={"Error"}
          open={isOpen}
        />
      )} */}
      {data?.success ? (
        <div className={classes.thankBox}>
          <img src="./images/success.svg" alt="bettr coffee" />
          <h2>
            {data?.verifyEmail?.data?.user_data?.role_name === "customer"
              ? StrConstant.Success.heading
              : StrConstant.VerifiedEmail.heading}
          </h2>
          <div className={classes.topBox}>
            <p>
              {/* {data?.verifyEmail?.data?.user_data?.role_name === "customer"
                ? "YOUR EMAIL HAS BEEN VERIFIED"
                : "You're one step closer to getting your coffee fix!"} */}
              {data?.verifyEmail?.data?.user_data?.role_name === "customer"
                ? "Login to get started on your coffee fix!"
                : "You're one step closer to getting your coffee fix!"}
            </p>
          </div>
          {/* <p>You're good to go to getting your coffee fix! </p> */}
          {/* <div className={classes.content}> <CheckCircleIcon /> </div> */}
          <div className={classes.btnContent}>
            <YellowBtn
              name={
                data?.verifyEmail?.data?.user_data?.role_name === "customer"
                  ? "Login"
                  : "set password"
              }
              clickHandler={() =>
                navigation(
                  data?.verifyEmail?.data?.user_data?.role_name === "customer"
                    ? "/login"
                    : `/set-password/${data?.verifyEmail?.data?.user_data?.password_token}`
                )
              }
            />
          </div>
        </div>
      ) : null}
      {data?.error && data?.error?.message==="Your email has been verified. Please login." ? (
        <div className={classes.thankBox}>
          <img src="./images/success.svg" alt="bettr coffee" />
          <h2>{StrConstant.AlreadyVerifiedEmail.heading}</h2>
          {/* <div className={classes.content}></div> */}
          <p>
           Let's get your coffee fix.
          </p>
          <div className={classes.btnContent}>
            <YellowBtn
              name="Login"
              clickHandler={() =>
                navigation(`/login`)
              }
            />
          </div>
        </div>
      ) : null}

{data?.error && (data?.error?.message==="Expired token." || data?.error?.message==="Invalid token, token already used. Generate a new token.") ? (
        <div className={classes.thankBox}>
          <h2>THIS LINK HAS EXPIRED </h2>
          {/* <div className={classes.content}></div> */}
          <p>
          The link is valid for 60 minutes so please complete your account set up within this time. Request a new link below.
          </p>
          <div className={classes.btnContent}>
          <YellowBtn
              name="RESEND EMAIL VERIFICATION"
              clickHandler={() =>
                navigation(`/resend-email/${data?.error?.data?.email}`)
              }
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withStyles(Styles)(EmailVerify);
