import { ActionType } from "../../ActionTypes";
import serverURL from "../../../APIHandler/serverURL";
import callAPI from "../../../APIHandler/callAPI";
import { reloadHandler } from "../../../Common/utils/utils";
// import { encryptValue, decryptValue } from "../../../Common/utils/utils";

export const getDashboardData = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GET_DASHBOARD_DETAILS_REQUEST,
  });
  const userInfoLocal = JSON.parse(localStorage.getItem("userInfo"));
  const {
    userSignin: { userInfo },
  } = getState();
  const key = userInfo
    ? userInfo?.data?.user_data?.auth_token
    : userInfoLocal?.data?.user_data?.auth_token;
  try {
    const { data } = await callAPI.getRequest(serverURL.dashboard, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
        Authorization: `Bearer ${key}`,
      },
    });
    return dispatch({
      type: ActionType.GET_DASHBOARD_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    localStorage.setItem("access", error?.response?.status);
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    return dispatch({
      type: ActionType.GET_DASHBOARD_DETAILS_FAILURE,
      payload: message,
    });
  }
};

export const getConfiguration = () => async (dispatch, getState) => {
  dispatch({
    type: ActionType.GET_CONFIGURATION_REQUEST,
  });
  try {
    const { data } = await callAPI.getRequest(serverURL.configurations, {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    });
    dispatch({ type: ActionType.GET_CONFIGURATION_SUCCESS, payload: data });
    const { configuration } = getState();
    localStorage.setItem(
      "config",
      JSON.stringify(configuration?.DashboardDetail?.data?.config)
    );
  } catch (error) {
    reloadHandler(error?.response?.status);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ActionType.GET_CONFIGURATION_FAILURE, payload: message });
  }
};
