import React, { useState, useRef, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "./Style";
import DatePicker, { DateObject } from "react-multi-date-picker";
const MultiDatePicker = (props) => {
  const { classes, onBinding, allowedDays = null, allowAfter = 2 } = props;
  const datePickerRef = useRef();
  const [values, setValues] = useState([]);
  const [minDate, setMinDate] = useState(new DateObject());

  const selected_modes = localStorage.getItem("selected_modes");
  useEffect(() => {
    if (values.length > 0) {
      onBinding(values);
    } else {
      const days = allowedDays;
      let affowdaysafter=""
      const newdate = new Date()
      let newdays = newdate.getDay() == 0 ? "Sunday" : newdate.getDay() == 1 ? "Monday" : newdate.getDay() == 2 ? "Tuesday" : newdate.getDay() == 3 ? "Wednesday" : newdate.getDay() == 4 ? "Thursday" : newdate.getDay() == 5 ? "Friday" : "Saturday"
      for(let i=0;i<days?.length;i++){
        if(days[i]?.indexOf(newdays)>-1){
         
          affowdaysafter="np"

        }
      }
      if (days != null) {

        const workingDate = validateWorkingDays(affowdaysafter==""?allowAfter-1: allowAfter);
        setMinDate(workingDate)
        // console.log("workingDate --- ", workingDate);

        setValues([workingDate]);
      } else {
        const setTempDate = new DateObject().add(1, "days");
        // console.log("setTempDate", setTempDate.weekDay.index)
        if (setTempDate.weekDay.index === 0) {
          const setTempDate = new DateObject().add(2, "days");
          setValues([setTempDate]);
        } else if (setTempDate.weekDay.index === 6) {
          const setTempDate = new DateObject().add(3, "days");
          setValues([setTempDate]);
        } else {
          setValues([setTempDate]);
        }
        // setValues([new DateObject().add(3, "days")]);
      }
    }
  }, [values]);


  const validateWorkingDays = (afterDay) => {
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let setDate = "";
    let countWorkingDay = 0;
    const days = allowedDays;
    for (let i = 0; i < 5; i++) {
      const preAddedDate = (i * 7);
      // console.log("workingDate afterDay", afterDay);
      // console.log("workingDate preAddedDate", preAddedDate);
      weekdays.map((data, index) => {
        // const setTempDate = addDaysWRONG(processDate, preAddedDate + index); //new DateObject().add(1 + index, "days");
        const setTempDate = new DateObject().add((preAddedDate + index), "days");
        // console.log("workingDate setTempDate", setTempDate);
        const day = setTempDate.weekDay.name;
        if (days.indexOf(day) > -1) {
          if (countWorkingDay < afterDay) {
            countWorkingDay++
          } else {
            if (setDate === "") {
              setDate = setTempDate;

            }
          }
        }
      });
      if (setDate != "") {
        break;
      }

    }

    return setDate;
  }


  return (
    <div className={classes.multiDatePic}>
      <DatePicker
        mapDays={({ date }) => {
          const weekdays = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          const days = allowedDays;
          let isWeekend = [];
          weekdays.map((data, index) => {
            if (days != null && days?.indexOf(data) < 0) {
              isWeekend.push(index);
            }
          });
          let isCheckWeekend = isWeekend.includes(date.weekDay.index);
          if (isCheckWeekend)
            return {
              disabled: true,
              style: { color: "#9EA1A1" },
              // onClick: () => alert("weekends are disabled")
            };
        }}
        format={"DD MMM YYYY"}
        minDate={minDate}
        maxDate={new DateObject().add(3, "month")}
        onChange={setValues}
        ref={datePickerRef}
        multiple={false}
        value={values}
      >
        <div className={classes.btnContent}>
          <button
            className={classes.grayBtn}
            onClick={() => datePickerRef.current.closeCalendar()}
          >
            Cancel
          </button>
          <button
            className={classes.yellowBtn}
            onClick={() => datePickerRef.current.closeCalendar()}
          >
            save
          </button>
        </div>
      </DatePicker>
      <img
        src="images/calendar.svg"
        alt="Better Coffee"
        onClick={() => datePickerRef.current.openCalendar()}
      />
    </div>
  );
};
export default withStyles(Styles)(MultiDatePicker);
