import { ActionType } from "../../ActionTypes";

export const outletListReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_OUTLETS_LISTING_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_OUTLETS_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        outletList: action.payload,
      };
    case ActionType.GET_OUTLETS_LISTING_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const outletCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_OUTLET_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_OUTLET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        outletCategory: action.payload,
      };
    case ActionType.GET_OUTLET_CATEGORY_FAILURE:
      return { ...state, loading: false, error: action.payload };
   case ActionType.GET_OUTLET_CATEGORY_CLEAR:
    return {}
      default:
      return state;
  }
};

export const getOutletReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_OUTLET_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_OUTLET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        outlet: action.payload,
      };
    case ActionType.GET_OUTLET_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case "CLEAR_OUTLET":
      return {};
    default:
      return state;
  }
};



export const getOutletidReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionType.GET_OUTLET_ID_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case ActionType.GET_OUTLET_ID_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        outletCategoryid: action.payload,
      };
    case ActionType.GET_OUTLET_ID_CATEGORY_FAILURE:
      return { ...state, loading: false, error: action.payload };
  case ActionType.CLEAR_OUTLET_ID:
    return {}
      default:
      return state;
  }
};
