import { Styles } from "./Style";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { ActionType } from "../../redux/ActionTypes";
import { useDispatch, useSelector } from "react-redux";
// import AlterMessage from "../AlertMessage/AlertMessage";
import {
  checkCoupon,
  applyCoupon,
  removeCouponAction,
} from "../../redux/actions/cart/cartActions";
const PromoCode = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState("");
  const cart_id = localStorage.getItem("cart_id");
  const { userCart } = useSelector((state) => state?.getUserCart);
  const { error, status } = useSelector((state) => state?.checkCoupon);
  const { error: err } = useSelector((state) => state?.applyCoupon);

  const couponApplyHandler = () => {
    const payload = {
      cart_id,
      coupon_code: coupon,
    };
    dispatch(
      applyCoupon(payload),
      status?.message === "You may continue." && setCoupon("")
    );
  };

  useEffect(() => {
    if (coupon === "") {
      dispatch({ type: ActionType.CLEAR_COUPON });
    }

    // if (coupon && coupon.length > 0) {

    //   dispatch(checkCoupon({ coupon_code: coupon }));
    // }
  }, [coupon]);
  useEffect(() => {
    const couponcode = setTimeout(() => {
      if (coupon && coupon.length > 0) {

        dispatch(checkCoupon({ coupon_code: coupon }));
      }
    }, 2000)
    return ()=>clearTimeout(couponcode)
  }, [coupon])

  


  // const snackHandler = (event, reason) => {
  //   if (reason === "clickaway") return;
  //   dispatch({ type: ActionType.CLEAR_RESPONSE });
  // };
  // console.log("checkError", error, err);
  return (
    <>
      {/* <AlterMessage
        description={status?.message}
        url={"./images/success.svg"}
        closeHandler={snackHandler}
        title={"Success"}
        type={"Success"}
        open={isOpen}
      /> */}

      {/* <AlterMessage
        closeHandler={snackHandler}
        url={"./images/error.svg"}
        description={error}
        title={"Error"}
        type={"Error"}
        open={error}
      /> */}

      <div
        className={`${userCart?.data?.coupon_used !== "" ? classes.dFlex : ""
          } ${classes.promoBox}`}
      >
        <div className={classes.ltBox}>
          <h5>Promo code</h5>
        </div>
        {userCart?.data?.coupon_used === "" ? (
          <div className={classes.promoInput}>
            <input
              type="text"
              name="coupon"
              value={coupon}
              placeholder="Enter promo code"
              onChange={(e) => {

                setCoupon(e.target.value.toUpperCase());
                dispatch({ type: ActionType.CLEAR_COUPON });
              }}
            />
            {status?.message === "You may continue." &&
              !error &&
              !err &&
              coupon !== "" ? (
              <p className={classes.successMsg}>Coupon verified successfully</p>
            ) : null}
            {error && coupon !== "" ? (
              <p className={classes.errorMsg}>{error ? error : null}</p>
            ) : null}
            {err && !error ? (
              <p className={classes.errorMsg}>{err ? err : null}</p>
            ) : null}
            <Button disabled={Boolean(error)} onClick={couponApplyHandler}>
              Apply
            </Button>
          </div>
        ) : (
          <div className={`${classes.rtBox} ${classes.promoCode}`}>
            <p>{userCart?.data?.coupon_used}</p>
            <Tooltip title="Delete Coupon" arrow>
              <span
                className={classes.promoCodeDel}
                onClick={() =>
                  dispatch(
                    removeCouponAction({
                      cart_id,
                      coupon_code: userCart?.data?.coupon_used,
                    })
                  )
                }
              >
                <CloseIcon />
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
};
export default withStyles(Styles)(PromoCode);
